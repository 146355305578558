import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('modbusPortController', modbusPortController);

  modbusPortController.$inject = ['$rootScope', '$scope', '$state', 'ModBusPort', 'commFactory', 'unitFactory'];

  function modbusPortController($rootScope, $scope, $state, ModBusPort, commFactory) {
    var vm = this;
    var backup;
    vm.save;
    vm.cancel;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.currentUnit = currentUnit;
      vm.modBusPort = ModBusPort;
      backup = angular.copy(vm.modBus);
    }

    function save() {
      vm.form.$setPristine();
      commFactory.updateModBusClient(vm.currentUnit.id, vm.modBusPort).then((result) => {
        vm.modBus = result;
        backup = angular.copy(vm.modBus);
      });
    }

    function cancel_edition() {
      vm.modBus = angular.copy(backup);
    }

    $scope.$on('refresh', function () {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
