(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('unitparser', unitparser)
    .directive('convertToNumber', convertToNumber)
    .directive('sensorunit', sensorUnit)
    .directive('numberRequired', numberRequired);

  unitparser.$inject = [];
  convertToNumber.$inject = [];
  sensorUnit.$inject = [];

  function unitparser() {
    return {
      restrict: 'A',
      require: 'ngModel',
      template: '<span></span>',
      scope: {
        sensor: '@',
        ctype: '@',
        formats: '=',
        showunit: '@',
        sensors: '=',
      },
      link: function (scope, element, attr, ngModel) {
        function parseMaxlength() {
          if (parseInt(scope.sensor) > 0) {
            var id = parseInt(scope.sensor);
            var format;
            if (id === 41) {
              format = {
                pk: { id: 99 },
                integers: 2,
                decimals: 1,
              };
            } else {
              format = scope.formats[scope.sensors[id - 1].formatId - 1];
            }
            if (format !== null) {
              var max = format.integers + format.decimals + 1;
              if (format.sign) max = max + 1;
              element.attr('maxlength', max);
            }
          }
        }

        function fromUser(text) {
          if (parseInt(scope.sensor) > 0) {
            var id = parseInt(scope.sensor);
            var format;

            if (id === 41) {
              format = {
                pk: { id: 99 },
                integers: 2,
                decimals: 1,
              };
            } else {
              format = scope.formats[scope.sensors[id - 1].formatId - 1];
            }
            if (format !== null) {
              var total = format.integers + format.decimals;
              var integers = format.integers;
              var decimals = format.decimals;

              //                        if(scope.ctype === "3"  || scope.ctype === "7" || scope.ctype === "8" || scope.ctype === "9"){
              //                            if(format.pk.id === "1"){
              //                                integers = 4, decimals = 0;
              //                                total = 4;
              //                            }else if(format.pk.id === "2"){
              //                                integers = 5, decimals = 0;
              //                                total = 5;
              //                            }
              //                        }

              var newT;
              var modal;

              var input_value = Number(text);

              if (_.isFinite(input_value)) {
                var maximum = '';
                _.times(integers, (el) => {
                  maximum = maximum + '9';
                });
                if (decimals > 0) {
                  maximum = maximum + '.';
                  _.times(decimals, (el) => {
                    maximum = maximum + '9';
                  });
                }
                maximum = Number(maximum);
                if (!format.sign && input_value < 0) {
                  input_value = -input_value;
                }
                text = input_value + '';
                if (input_value > maximum) {
                  //If maximum permited we return model max -> 999.9 (integers).(decimals)
                  element.val(maximum);
                  return maximum;
                }
                if (input_value < -maximum) {
                  element.val(-maximum);
                  return -maximum;
                }

                var parts = text.split('.');

                if (input_value < 0) {
                  integers = integers + 1; //If number is negative increment the number of digits for de sign simbol +-
                }

                var first = parts[0].slice(0, integers);
                var second = '';
                var second_n_parsed = 0;
                if (decimals > 0 && parts[1] && parts[1].length > 0) {
                  second = parts[1].slice(0, decimals);
                  second_n_parsed = second;
                  second = '.' + second;
                } else if (decimals > 0) {
                  _.times(decimals, (el) => {
                    second = second + '0';
                  });
                  second_n_parsed = second;
                  second = '.' + second;
                }

                if (first.length < integers && !format.sign) {
                  _.times(integers - first.length, (el) => {
                    first = '0' + first;
                  });
                } else {
                  first = Number(first);
                  if (input_value < 0) {
                    first = -first;
                    first = first + '';
                    _.times(integers - first.length - 1, (el) => {
                      first = '0' + first;
                    });
                    first = '-' + first;
                  }
                }
                element.val(first + second);
                return Number(first + second_n_parsed);
              } else {
                // If not number return model format -> 000.0 (integers).(decimals)
                var final = '';
                _.times(integers, (el) => {
                  final = final + '0';
                });
                if (decimals > 0) {
                  final = final + '.';
                  _.times(decimals, (el) => {
                    final = final + '0';
                  });
                }
                element.val(final);
              }
            }
          }
        }

        function toUser(text) {
          if (parseInt(scope.sensor) > 0) {
            var id = parseInt(scope.sensor);
            var format;
            if (id === 41) {
              format = {
                pk: { id: 99 },
                integers: 2,
                decimals: 1,
                units: 'L/m2',
              };
            } else {
              format = scope.formats[scope.sensors[id - 1].formatId - 1];
            }
            if (format !== null) {
              var total = format.integers + format.decimals;
              var integers = format.integers;
              var decimals = format.decimals;
              if (scope.ctype === '3' || scope.ctype === '7' || scope.ctype === '8' || scope.ctype === '9') {
                if (format.pk.id === '1') {
                  (integers = 4), (decimals = 0);
                  total = 4;
                  format.units = 'ºCh';
                } else if (format.pk.id === '2') {
                  (integers = 5), (decimals = 0);
                  total = 5;
                  format.units = 'Whm2';
                }
              }
              var newT = '' + text;

              if (newT.length < total) {
                for (var i = newT.length; i < total; i++) {
                  newT = '0' + newT;
                }
              }

              if (decimals > 0) {
                var first = newT.slice(0, integers);
                var last = newT.slice(integers, total);
                newT = first + '.' + last;
              }
              if (scope.showunit) {
                newT = newT + ' ' + (format.units || '');
              }
              element.val(newT);

              return newT;
            } else {
              element.val('');
            }
          } else {
            element.val('');
          }
        }

        parseMaxlength();

        scope.$watch('sensor', function (newValue, oldValue) {
          parseMaxlength();
          angular.forEach(ngModel.$formatters, function (parser) {
            ngModel.$modelValue = 0;

            parser(ngModel.$modelValue); //ngModel.$modelValue set value to 0, reseting on sensor change
          });
        });

        ngModel.$parsers.push(fromUser);
        ngModel.$formatters.push(toUser);
      },
    };
  }
  function convertToNumber() {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (val) {
          return val != null ? parseInt(val, 10) : null;
        });
        ngModel.$formatters.push(function (val) {
          return val != null ? '' + val : null;
        });
      },
    };
  }

  function numberRequired() {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (val) {
          val = Number(val);
          if (_.isNumber(val)) {
            element.val(val | 0);
            return val | 0;
          } else {
            element.val(0);
            return 0;
          }
        });
      },
    };
  }

  function sensorUnit() {
    return {
      require: 'ngModel',
      scope: {
        integers: '=',
        decimals: '=',
        units: '=',
        max: '=',
        min: '=',
      },
      link: function (scope, element, attrs, ngModel) {
        var signed;
        var sign;
        ngModel.$parsers.push(function (val) {
          if (val !== null && scope.decimals !== undefined) {
            if (scope.max !== undefined && val > scope.max) {
              val = scope.max;
            }
            if (scope.min !== undefined && val < scope.min) {
              val = scope.min;
            }

            var parts = val.toString().split('.');

            if (parts.length > 2) {
              element.val(0);
              return 0;
            }
            var integer = parts[0] || 0;
            var decimal = parts[1] || 0;
            if (integer.charAt(0) === '+' || integer.charAt(0) === '-') {
              signed = true;
              sign = integer.charAt(0);
              integer = integer.substr(1);
            } else {
              signed = false;
            }

            var decimal_string = '';
            _.times(scope.decimals, (obj) => {
              decimal_string = decimal_string + '0';
            });

            integer = integer.toString().slice(0, scope.integers);
            decimal = decimal.toString().slice(0, scope.decimals);
            if (signed) {
              integer = sign + integer;
            }
            var result = integer + '.' + decimal;
            element.val(Number(result));

            if (decimal === '0') {
              return Number(integer + decimal_string);
            } else {
              _.times(scope.decimals - decimal.length, () => {
                decimal = decimal + '0';
              });

              return Number(integer + decimal);
            }
          }
        });
        ngModel.$formatters.push(function (val) {
          if (val !== null && scope.decimals !== undefined) {
            var decimals = scope.decimals;
            val = Number(val);
            for (var i = 0; i < decimals; i++) {
              val = val / 10;
            }
            val = val.toFixed(scope.decimals);
          }
          if (scope.units !== undefined) {
            element.val(val + scope.units);
          } else {
            element.val(val);
          }

          return parseFloat(val);
        });

        scope.$watch(['decimals', 'units'], function (newValue, oldValue) {
          angular.forEach(ngModel.$formatters, function (parser) {
            parser(ngModel.$modelValue);
          });
        });
      },
    };
  }
})();
