import { UNITS, UNITS_V2 } from '../../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap, take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outEnginesController', outEnginesController);

  outEnginesController.$inject = ['$rootScope', '$scope', '$state', 'configFactory'];

  function outEnginesController($rootScope, $scope, $state, configFactory) {
    var vm = this;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;
    vm.setStopSectors = setStopSectors;
    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();
    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        loadData(currentUnit);
      });
    }

    function loadData(unit) {
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.UNITS = UNITS;
      vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
      vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
      vm.currentState = $state.includes;
      vm.currentUnit = unit;
      vm.currentHeader = $state.params.headerId || 1;
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.ioFacade.loadConfiguredOutputs({
          deviceId: vm.currentUnit.id,
          deviceType: UNITS_V2[vm.currentUnit.type],
        });

        // Init StagingList in order to check the current io selector.
        vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
        var params = { add: ['engines'] };
        configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((data) => {
          vm.config = data.plain();
          vm.engines = vm.config.engines;
        });
      } else {
        configFactory.getEngines(vm.currentUnit.id).then((data) => {
          vm.currentUnit.engines = data.plain();
          vm.engines = angular.copy(vm.currentUnit.engines);
        });
      }

      if (vm.currentUnit.type === UNITS.A_2500) {
        vm.doubleVolt = vm.currentUnit.fabric.doubleVoltage !== 1;
      }
      if (vm.currentUnit.type === UNITS.A_BIT) {
        configFactory.getAlarms(vm.currentUnit.id).then((data) => {
          vm.currentUnit.alarms = data.plain();
          vm.alarms = angular.copy(vm.currentUnit.alarms);
        });
      }
      if (vm.currentUnit.type === UNITS.A_7000) {
        const params = {};
        configFactory.get(vm.currentUnit.id, params).then((result) => {
          vm.config = result;
        });
      }
      vm.selected = 0;
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function setStopSectors($event, engine) {
      vm.form.$setDirty();
      if (vm.currentUnit.type === UNITS.A_4500) {
        engine.stopSectors = $event.detail.checked ? 1 : 0;
      } else {
        engine.stopSectors = $event.detail.checked;
      }
    }

    function save() {
      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
          vm.config.engines = vm.engines;

          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(configFactory.updateConfigHeader(vm.currentUnit.id, vm.config));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe((response) => {
              if (response) {
                vm.config = response;
                if (vm.config) {
                  vm.config.engines = _.sortBy(vm.config.engines, [
                    function (o) {
                      return o.pk.id;
                    },
                  ]);
                }
                vm.form.$setPristine();
              }
            });
          break;
        default:
          _.forEach(vm.engines, (engine) => {
            engine.unittype = vm.currentUnit.type;
          });

          configFactory.updateEngines(vm.currentUnit.id, vm.engines).then((result) => {
            vm.engines = result.engines.sort((a, b) => a.pk.id - b.pk.id);

            if (vm.currentUnit.type === UNITS.A_BIT) {
              vm.alarms = vm.alarms.map((alarm) => ({ ...alarm, unittype: UNITS.A_BIT }));
              configFactory.updateAlarms(vm.currentUnit.id, vm.alarms).then((result) => {
                vm.currentUnit = result;
                vm.alarms = _.sortBy(vm.currentUnit.alarms, [
                  function (o) {
                    return o.pk.id;
                  },
                ]);
              });
            }
            if (vm.currentUnit.type === UNITS.A_7000) {
              configFactory.updateConfig(vm.currentUnit.id, vm.config).then((result) => {
                vm.config = result;
              });
            }
            vm.form.$setPristine();
          });
          break;
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.engines = angular.copy(vm.config).engines;
      } else {
        vm.engines = {};
        $state.go('outputs.engines', { unit: vm.currentUnit }, { reload: 'outputs.engines' });
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
