// @ts-ignore
import { showLoader, hideLoader } from '@vegga/front-utils';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  const veggaFacade = getContainer().resolve('veggaFacade');

  angular
    .module('agronicwebApp')

    .directive('veggaLoader', [
      '$http',
      '$rootScope',
      '$transitions',
      function ($http, $rootScope, $transitions) {
        const getOverlayState = () => {
          const overlays = document.querySelectorAll('vegga-overlay');
          return overlays.length > 0 && !!Array.from(overlays).find((overlay) => overlay.toggle === 'show');
        };
        return {
          restrict: 'A',
          link: function (scope) {
            scope.isLoading = function () {
              return $http.pendingRequests.length > 0;
            };

            $transitions.onStart({}, function () {
              if ($rootScope.isRefreshingApp) {
                $rootScope.isRefreshingApp = false;
                $http.pendingRequests.forEach(function (pendingReq) {
                  if (pendingReq.cancel) {
                    pendingReq.cancel.resolve();
                    showLoading();
                  }
                });
              }
            });

            veggaFacade.isLoading$.subscribe((value) => {
              const overlayIsOpen = getOverlayState();
              if (!overlayIsOpen) {
                $rootScope.$broadcast('showSkeleton', value && !$rootScope.isRefreshingApp);
              }
            });

            scope.$watch(scope.isLoading, function (v) {
              if (v) {
                const overlayIsOpen = getOverlayState();
                if (!$rootScope.isRefreshingApp) {
                  showLoading();
                  if (!overlayIsOpen) {
                    $rootScope.$broadcast('showSkeleton', true);
                  }
                }
              } else {
                $rootScope.$broadcast('showSkeleton', false);
                hideLoader();
              }
            });

            function showLoading() {
              showLoader();
            }
          },
        };
      },
    ]);
})();
