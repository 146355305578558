import maskConversionValueTemplate from './mask-conversion-value.html';

(function () {
  'use strict';
  angular.module('agronicwebApp').directive('maskConversionValue', maskConversionValue);

  function maskConversionValue() {
    return {
      restrict: 'E',
      require: 'ngModel',
      template: maskConversionValueTemplate,
      scope: {
        label: '@',
        name: '@',
        ngModel: '=',
        vgForm: '=',
        maxlength: '@',
        placeholder: '@',
        suffixText: '@',
        prefixText: '@',
        integers: '=',
        decimals: '=',
        units: '=',
        max: '=',
        min: '=',
        formatLabel: '@',
        onChange: '&',
        disabled: '=',
        conversionFactor: '=',
      },
      link: function ($scope, element, ngModel, attrs) {
        var signed;
        var sign;
        $scope.input = $scope.ngModel * $scope.conversionFactor;
        $scope.input = $scope.input.toFixed($scope.decimals);

        $scope.$on('VeggaInputBlur', (_e, data) => {
          var val = data.target.value.replace(',', '.');

          if (val !== null && val !== undefined && val && $scope.decimals !== undefined) {
            $scope.veggaInputChange = true;

            if ($scope.max !== undefined && val > $scope.max) {
              val = $scope.max;
            }
            if ($scope.min !== undefined && val < $scope.min) {
              val = $scope.min;
            }

            var parts = val.toString().split('.');

            if (parts.length > 2) {
              element.val(0);
              return 0;
            }
            var integer = parts[0] || 0;
            var decimal = parts[1] || 0;
            if (integer.charAt(0) === '+' || integer.charAt(0) === '-') {
              signed = true;
              sign = integer.charAt(0);
              integer = integer.substr(1);
            } else {
              signed = false;
            }

            var decimal_string = '';
            _.times($scope.decimals, (obj) => {
              decimal_string = decimal_string + '0';
            });

            integer = integer.toString().slice(0, $scope.integers);
            decimal = decimal.toString().slice(0, $scope.decimals);
            if (signed) {
              integer = sign + integer;
            }

            if (decimal === '0') {
              $scope.ngModel = Number(integer + decimal_string);
              if ($scope.decimals === 0) {
                $scope.input = integer;
              } else {
                $scope.input = Number(integer + '.' + decimal_string);
              }
            } else {
              if ($scope.decimals === 0) {
                $scope.input = integer;
              } else {
                _.times($scope.decimals - decimal.length, () => {
                  decimal = decimal + '0';
                });
                $scope.input = Number(integer + '.' + decimal).toFixed($scope.decimals);
              }
              $scope.ngModel = Number(integer + decimal);
            }
            $scope.ngModel = Math.round($scope.input / $scope.conversionFactor);
          }
        });

        $scope.$watch('ngModel', function () {
          $scope.input = $scope.ngModel * $scope.conversionFactor;
          $scope.input = $scope.input.toFixed($scope.decimals);
          var val = Number($scope.input);

          if (val !== null && $scope.decimals !== undefined) {
            val = val.toFixed($scope.decimals);
          }

          if ($scope.units !== undefined) {
            $scope.input = val + ' ' + $scope.units.replace('.', ',');
          } else {
            //$scope.input = val.replace(".", ",");
          }

          if ($scope.veggaInputChange) {
            if ($scope.onChange) {
              $scope.onChange();
            }

            if ($scope.vgForm) {
              $scope.vgForm.$setDirty();
            }

            $scope.veggaInputChange = false;
          }
        });

        $scope.$watch('units', function () {
          if ($scope.input && $scope.units && !$scope.input.includes($scope.units)) {
            const unit = $scope.units.trim();
            $scope.input = $scope.input + ' ' + unit;
          }
        });
      },
    };
  }
})();
