(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('mapsFactory', mapsFactory);

  mapsFactory.$inject = ['Restangular', '$rootScope', '$q', 'sectorFactory', 'progFactory', '$window'];

  function mapsFactory(Restangular, $rootScope, $q, sectorFactory, progFactory, $window) {
    var op = {
      mapList: get,
      saveMap: saveMap,
      updateMap: updateMap,
      deleteMap: deleteMap,
      loadSectors: loadSectors,
      loadSector: loadSector,
      loadProgram: loadProgram,
      saveMarker: saveMarker,
      updateMarker: updateMarker,
      updateSector: updateSector,
      getMarkers: getMarkers,
      deleteMarker: deleteMarker,
      getAreas: getAreas,
      deleteArea: deleteArea,
      updateAreaSectorLayer: updateAreaSectorLayer,
      setFilterStorage: setFilterStorage,
      getFilterStorage: getFilterStorage,
      editable: editable,
      prepareGEOJsonLayer: prepareGEOJsonLayer,
    };

    return op;

    function get(id, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('maps')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateMap(id, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('maps')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function saveMap(params) {
      var deferred = $q.defer();
      Restangular.one('maps')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteMap(mapId) {
      var deferred = $q.defer();
      Restangular.one('maps', mapId)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function saveGeoJson(id, params) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('geojson')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getAreas(id) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('areas')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getMarkers(id, type) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('markers')
        .get({ type: type })
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function saveMarker(id, params) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('marker')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateMarker(id, params) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('updatemarker')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function loadSectors(unit) {
      return sectorFactory.allSectors(unit).then((sectors) => {
        return sectors.plain();
      });
    }

    function loadSector(unit, sector) {
      return sectorFactory.one(unit, sector).then((sectors) => {
        return sectors.plain();
      });
    }

    function loadProgram(deviceId, progId, params) {
      return progFactory.program(deviceId, progId, params).then(function (data) {
        return data.plain();
      });
    }

    /*** Leaflet & Map functions ***/

    function editable(e) {
      e.layer.enableEdit();
    }

    function deleteArea(mapId, areaId) {
      var deferred = $q.defer();
      Restangular.one('maps', mapId)
        .one('areas')
        .customDELETE(areaId)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteMarker(mapId, MarkerId) {
      var deferred = $q.defer();
      Restangular.one('maps', mapId)
        .one('markers')
        .customDELETE(MarkerId)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function prepareGEOJsonLayer(layer, unit, sector, map) {
      var geoJsonObject = layer.toGeoJSON();

      geoJsonObject.properties.device = unit.id;
      geoJsonObject.properties.user = $rootScope.user.id;
      geoJsonObject.properties.sector = sector && sector.pk ? sector.pk.id : null;
      geoJsonObject.properties.map = map.id;
      geoJsonObject.properties.sectorName = sector && sector.name;
      geoJsonObject.properties.deviceName = unit.name;

      return saveGeoJson(map.id, geoJsonObject).then((resp) => {
        geoJsonObject.properties.id = resp.uuid;
        var geoJson = L.geoJson(geoJsonObject);
        return geoJson;
      });
    }

    function updateAreaSectorLayer(id, params) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('areas')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateSector(id, params) {
      var deferred = $q.defer();
      Restangular.one('maps', id)
        .one('sector')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function setFilterStorage(key, val) {
      var stored = JSON.stringify(val);
      $window.localStorage && $window.localStorage.setItem(key, stored);
    }
    function getFilterStorage(key) {
      var stored = $window.localStorage && $window.localStorage.getItem(key);
      return JSON.parse(stored);
    }
  }
})();
