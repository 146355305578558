import { UNITS_IRRIDESK } from '../../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('expertIrrideskChannelInitController', expertIrrideskChannelInitController);

  expertIrrideskChannelInitController.$inject = ['$state', 'irrideskFactory', 'UserData', '$scope'];

  function expertIrrideskChannelInitController($state, irrideskFactory, UserData, $scope) {
    var vm = this;
    var channel, subId, channels;
    vm.formFactory;
    vm.form;

    activate();

    function activate() {
      if (!$state.params.channels) {
        $state.go('irrideskChannels');
        return;
      }

      subId = $state.params.idSubscription;
      channels = $state.params.channels;
      vm.sensors = $state.params.sensors;
      vm.units = $state.params.unitsList;

      vm.initChannel = initChannel;
      vm.setChannel = setChannel;
      vm.hasAnyChannels = hasAnyChannels;
      vm.showSeasonalPlan = false;

      checkChannelStatus();
    }

    function checkChannelStatus() {
      vm.status = 0;
      irrideskFactory.getFormFactory().then((result) => {
        vm.formFactory = result.plain();
      });
    }

    function setChannel(event) {
      const type = +event.value;
      if (type !== null && type !== undefined) {
        channel = channels.find((ch) => ch.type === type && ch.status === 0);
        if (channel) {
          vm.deviceName = getDevice('name');
          vm.deviceType = getDevice('type');
        }
      }
    }

    function hasAnyChannels(type) {
      return channels.find((ch) => ch.type === type && ch.status === 0);
    }

    function getDevice(attr) {
      let result = _.filter(vm.units, (o) => {
        return o.device.id === channel.deviceId;
      });
      if (result.length > 0) {
        return result[0].device[attr];
      } else {
        ('-');
      }
    }

    function initChannel() {
      if (!vm.form.$valid) {
        return;
      }

      let channel_outlines = _.mapValues(vm.formFactory.channel_outlines, 'value');
      channel_outlines.sensor_outlines = [];

      // loadSensorsToChannel();

      let device_outlines = {
        device_name: vm.selectedUnit.name,
        device_model: UNITS_IRRIDESK[vm.selectedUnit.type],
        id_in_platform: vm.selectedUnit.id + '',
        platform_name: 'AgronicWeb',
        max_num_programs: 99,
      };
      let farm_outline = _.mapValues(vm.formFactory.farm_outline, 'value');
      farm_outline.platform_name = 'AgronicWeb';
      farm_outline.user_name = UserData.id + '';
      farm_outline.farm_name = UserData.nif.replace(/\s+/g, '') + '';
      let outlines = {
        channel_outlines: [channel_outlines],
        farm_outline: farm_outline,
        device_outlines: [device_outlines],
      };

      irrideskFactory.initChannel(subId, channel.pk.id, outlines).then(() => {
        $scope.$emit('formSaved', vm.form);
      });
    }

    // function loadSensorsToChannel() {
    //   _.forEach(vm.sensors, (sensor) => {
    //     if (sensor.active) {
    //     } else {
    //     }
    //   });
    // }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      initChannel();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      // cancel();
    });
  }
})();
