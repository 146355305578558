import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('solarController', solarController);

  solarController.$inject = ['$rootScope', '$scope', '$state', 'solarFactory', 'unitFactory'];

  function solarController($rootScope, $scope, $state, solarFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.getType;
    vm.save = save;
    vm.cancel = cancel_edition;
    backup = {};
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$), take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadSolar();
        loadConditioners();
      });
    }

    function loadSolar() {
      solarFactory.solar(vm.currentUnit.id).then(
        (data) => {
          vm.solar = data.plain();
          
          backup = angular.copy(vm.solar);
        },
        () => {}
      );
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.currentUnit.conditioners = data.plain();
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.solar.unittype = vm.currentUnit.type;
      vm.loading = true;
      solarFactory.update(vm.currentUnit.id, vm.solar).then((result) => {
        vm.solar = result;
        backup = angular.copy(vm.solar);
        vm.loading = false;
      });
    }
    function cancel_edition() {
      vm.solar = angular.copy(backup);
      vm.form.$setPristine();
    }

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formToUnitUpdated', vm.form);
      }
    );
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    $scope.$on('formFromUnitCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
