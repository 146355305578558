import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';
import '../../resources/js/leaflet/leaflet-editable';
import moment from 'moment/moment';
import { takeUntil, switchMap, mergeMap, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { MapSensorType } from '../utils/enums/maps/maps-sensor-type.enum';
import { MapModule } from '../utils/enums/maps/maps-module.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    .constant('moment', moment)

    .controller('mapsController', mapsController);

  mapsController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$filter',
    'UserData',
    '$confirm',
    '$element',
    'unitFactory',
    'refreshFactory',
  ];

  function mapsController(
    $rootScope,
    $scope,
    $state,
    $filter,
    UserData,
    $confirm,
    $element,
    unitFactory,
    refreshFactory
  ) {
    var vm = this;
    var areasLayer;
    vm.newSector;
    vm.currentArea;
    vm.comboUnits;
    vm.disableAddButton = false;
    vm.overlay;
    vm.elementsInMap = [];
    vm.selectedArea;

    // Current selected adding tool type
    vm.toolType;
    // Current selected edit tool type
    vm.editToolType;
    // Current tab while adding or editing
    vm.actionView;
    vm.UNITS = UNITS;
    vm.LINKBOX = MapModule;
    vm.enabledSave = true;
    vm.displayFooterButtons;
    vm.moduleType;
    vm.destroy$ = new Subject();

    const sensorTypeEnum = {
      ANALOG: '0',
      METER: '1',
      DIGITAL: '2',
      DPV: '3',
      ETO: '4',
      WATER_DISP: '5',
    };
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICM$
        .pipe(takeUntil(vm.destroy$))
        .pipe(
          take(1),
          mergeMap((units) => {
            vm.units = units;
            loadInitialData();
            return refreshFactory.getRefresh$().pipe(switchMap(() => unitFactory.getUnits(UserData.id)));
          }),
          takeUntil(vm.destroy$)
        )
        .subscribe((units) => {
          vm.units = units;
          reloadMap();
        });
    }

    function loadInitialData() {
      if (!UserData.profesional) {
        vm.minDate = moment().subtract(7, 'day')._d;
      } else {
        vm.minDate = null;
      }

      vm.types = {
        1: { type: 'Monocable', name: 'AM120', cod: 'EAM', mod: 'MAM' },
        2: { type: 'Radio', name: 'AR868-16', cod: 'EAR', mod: 'MAR' },
        3: { type: 'Radio', name: 'AR24', cod: 'EAR', mod: 'MAR' },
        4: { type: 'Radio', name: 'AR433', cod: 'EAR', mod: 'MAR' },
        5: { type: 'Radio', name: 'MI', cod: 'MI' },
        6: { type: 'Radio', name: 'ME', cod: 'ME' },
        7: { type: 'Radio', name: 'R868', cod: 'EAR', mod: 'MAR' },
        8: { type: 'Radio', name: 'AgroBee', cod: 'Coordinador', mod: 'Módulo' },
        9: { type: 'Radio', name: 'AgroBeeL', cod: 'Coordinador', mod: 'Módulo' },
        10: { type: 'Radio', name: 'SDI12', cod: '', mod: 'Dispositivo' },
      };

      vm.map = mapInit();

      vm.backup;
      vm.editing = false;
      vm.irrigInfo = false;
      vm.newDraw = newDraw;
      vm.reloadMap = reloadMap;
      vm.newMarker = newMarker;
      vm.cancelAction = cancelAction;
      vm.saveChanges = saveChanges;
      vm.resetValues = resetValues;
      vm.getValueInUnit = getValueInUnit;

      vm.filterByMapElements = filterByMapElements;
      vm.getSectorsInMap = getSectorsInMap;
      vm.onEdit = onEdit;
      vm.deleteElement = deleteElement;
      vm.closeOverlay = closeOverlay;
      vm.sectorId;
      vm.program;
      vm.sectors = [];
      vm.filterUpdated = filterUpdated;

      vm.overlay = document.querySelector('#mapToolsOverlay');

      vm.isUserAddingElement = false;

      // vm.filter = mapsFactory.getFilterStorage('my-filter-storage') || {
      vm.filter = {
        sectors: {
          selected: 'irrig',
          active: true,
          irrig: true,
          water: false,
          waterstate: false,
        },
        comp: {
          active: true,
          units: true,
          modules: true,
          sensors: true,
          label: true,
        },
      };

      $scope.$watch('vm.filter.sectors.active', (newV) => {
        if (newV) {
          $scope.$broadcast('loadSectorsLayer');
        } else {
          vm.map.removeLayer(vm.areasLayer);
        }
      });

      $scope.$watch('vm.filter.comp.units', (newV) => {
        if (newV) {
          $scope.$broadcast('loadEquiposLayer', {});
        } else {
          vm.map.removeLayer(vm.equiposLayer);
        }
      });

      $scope.$watch('vm.filter.comp.modules', () => {
        if (vm.filter?.comp?.modules) {
          $scope.$broadcast('loadModulesLayer', {});
        } else {
          vm.map.removeLayer(vm.modulesLayer);
          vm.map.removeLayer(vm.conectionLines);
        }
      });

      $scope.$watch('vm.filter.comp.sensors', (newV) => {
        if (newV) {
          vm.map.addLayer(vm.sensorLayer);
          $scope.$broadcast('loadSensorLayer', {});
        } else {
          vm.map.removeLayer(vm.sensorLayer);
        }
      });

      $scope.$watch('vm.filter.comp.label', (newV) => {
        if (newV) {
          $scope.$broadcast('loadLabelsLayer', {});
        } else {
          vm.map.removeLayer(vm.labelLayer);
        }
      });
    }

    function mapInit() {
      var map = L.map('map', { editable: true, doubleClickZoom: false, editOptions: { zIndex: 9999 } });

      L.Icon.Default.imagePath = `${$rootScope.env.HOST}/images/`;
      L.Icon.Default.shadowPath = `${$rootScope.env.HOST}/images/`;
      L.Icon.Default.prototype.options.iconUrl = 'vegga-marker-icon-shadow.png';
      L.Icon.Default.prototype.options.shadowUrl = 'vegga-marker-icon-shadow.png';
      L.Icon.Default.prototype.options.iconSize = [40, 40];

      var Esri_WorldImagery = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      });
      var Esri_WorldImageryLabels = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}'
      );

      var Esri_WorldImageryTransport = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}'
      );

      /*Borrar area con ESC*/
      map.on('editable:drawing:cancel', function (e) {
        map.removeLayer(e.layer);
      });
      var onKeyDown = function (e) {
        if (e.keyCode == 27) {
          // esc button
          if (vm.isUserAddingElement) {
            vm.overlay.dismiss();
            vm.toolType = null;
            vm.isUserAddingElement = false;
            vm.map.editTools.stopDrawing();
          }

          if (!this.editTools._drawingEditor) {
            return;
          }
          map.editTools._drawingEditor.disable();
          vm.disableAddButton = false;
        }
      };
      L.DomEvent.addListener(document, 'keydown', onKeyDown, map);

      // Create a Leaflet Feature Group to create a basemap containing imagery and labels
      var esriImageryLabels = L.featureGroup([Esri_WorldImagery, Esri_WorldImageryLabels, Esri_WorldImageryTransport]);
      // Add the basemap to the map
      esriImageryLabels.addTo(map);
      vm.equiposLayer = L.featureGroup().addTo(map);
      vm.modulesLayer = L.featureGroup().addTo(map);
      vm.sensorLayer = L.featureGroup().addTo(map);
      vm.labelLayer = L.featureGroup().addTo(map);
      vm.areasLayer = L.geoJSON().addTo(map);
      vm.conectionLines = L.featureGroup().addTo(map);
      vm.waterWareasLayer = L.geoJSON().addTo(map);

      //Add fixed mapping var into our map
      //var geojsonLayer = new L.GeoJSON(maping).addTo(map);
      getMapHeight();
      return map;
    }

    function newDraw() {
      if (vm.toolType === 'sector') {
        vm.toolType = null;
        vm.isUserAddingElement = false;
        vm.map.editTools.stopDrawing();
        return;
      }
      vm.isUserAddingElement = true;
      vm.toolType = 'sector';
      vm.editToolType = null;
      vm.comboUnits = vm.units;
      vm.filter.sectors.active = true;
      vm.map.editTools.startPolygon();
      vm.map.on('editable:drawing:commit', polygonPrepare);
      vm.map.on('editable:drawing:cancel', cancelAction);
    }

    function castToNumberCssProperty(property) {
      return +property.replace('px', '');
    }

    function getMapHeight() {
      const mainEl = $element[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      const navbarHeight = '56px';
      const mainElStyles = window.getComputedStyle(mainEl);
      const headerMapElHeight = '40px';
      const toolbarMapElHeight = '32px';
      const irrigaMapEl = $element[0].querySelector('#irrig-map-js');
      const irrigaMapElHeight = (irrigaMapEl && window.getComputedStyle(irrigaMapEl).height) || '0px';

      const mapHeight =
        castToNumberCssProperty(mainElStyles.height) -
        (castToNumberCssProperty(navbarHeight) +
          castToNumberCssProperty(mainElStyles.paddingTop) +
          castToNumberCssProperty(mainElStyles.paddingBottom) +
          castToNumberCssProperty(headerMapElHeight) +
          castToNumberCssProperty(toolbarMapElHeight) +
          castToNumberCssProperty(irrigaMapElHeight));
      $element[0].querySelector('#map').style.height = `${mapHeight}px`;
    }

    function editingSector(layer) {
      vm.newSector = layer;
    }

    function polygonPrepare(e) {
      editingSector(e.layer);
      vm.overlay.show();
      vm.map.off('editable:drawing:commit');
    }

    function newMarker(type) {
      if (vm.toolType === type) {
        vm.toolType = null;
        vm.isUserAddingElement = false;
        vm.map.editTools.stopDrawing();
        return;
      }
      vm.isUserAddingElement = true;
      vm.toolType = type;
      vm.editToolType = null;

      vm.marker = vm.map.editTools.startMarker({ zIndex: 9999 });
      vm.map.on('editable:drawing:commit', createMarker, { type });
    }

    function cancelAction() {
      if (vm.addSensorStep === 1) {
        vm.addSensorStep = 0;
        return;
      }

      if (vm.marker) {
        vm.marker.remove();
      }

      if (vm.newSector) {
        vm.newSector.remove();
      }

      vm.closeOverlay();
      vm.resetValues();
    }

    function createMarker(event) {
      vm.comboUnits = vm.units;
      vm.filter.comp.active = true;
      vm.toolType = this.type;
      vm.overlay.show();

      $scope.$broadcast('createMarker', event);

      switch (this.type) {
        case 1: //Equipo
          vm.filter.comp.units = true;
          $scope.$broadcast('addEquipoMarker', event);
          break;
        case 2: //Module
          vm.filter.comp.modules = true;
          $scope.$broadcast('addModuleMarker', event);
          break;
        case 3: //Sensor
          vm.filter.comp.sensor = true;
          $scope.$broadcast('addSensorMarker', event);
          break;
        case 4: //Label
          vm.filter.comp.label = true;
          vm.enabledSave = true;
          $scope.$broadcast('addLabelMarker', event);
          break;
      }

      vm.map.off('editable:drawing:commit');
    }

    function saveChanges() {
      if (vm.editToolType === 4) {
        $scope.$broadcast('saveEditLabel');
      }

      switch (vm.toolType) {
        // saving add changes
        case 'sector':
          vm.saveSector();
          break;
        case 1:
          vm.addMarkerE();
          break;
        case 2:
          vm.addMarkerM();
          break;
        case 3:
          vm.addMarkerS();
          break;
        case 4:
          vm.addMarkerL();
          break;
        // saving edit changes
        default:
          $scope.$broadcast('saveEditSector');
          break;
      }
    }

    function deleteElement() {
      let type;
      let event;

      switch (vm.editToolType) {
        case 'sector':
          type = vm.editToolType;
          event = 'SectorArea';
          break;
        case 1:
          type = 'unit';
          event = 'Equipo';
          break;
        case 2:
          type = 'module';
          event = 'Module';
          break;
        case 3:
          type = 'sensor';
          event = 'Sensor';
          break;
        case 4:
          type = 'label';
          event = 'Label';
          break;
      }

      $confirm({
        text: $filter('translate')(`maps.confirm.delete_${type}`),
        ok: $filter('translate')('pivot.yes'),
        cancel: $filter('translate')('pivot.no'),
      }).then(() => {
        $scope.$broadcast(`delete${event}`);
        vm.closeOverlay();
        vm.resetValues();
      });
    }

    function filterUpdated() {
      if (!vm.map) {
        return;
      }

      $scope.$broadcast('loadLabelsLayer', {});
      $scope.$broadcast('loadEquiposLayer', {});
      $scope.$broadcast('loadModulesLayer', {});
      $scope.$broadcast('loadSensorLayer', {});
      $scope.$broadcast('loadSectorsLayer');
    }

    function getValueInUnit(input) {
      if (vm.program !== undefined && vm.currentSector !== undefined) {
        var unit = vm.program.subprograms[vm.currentSector.xSubprogramN - 1].unit;
        switch (unit) {
          case 0:
            return $filter('parsetime')(input * 60);
          case 1:
            var mins = input * 60 * 60;
            return $filter('parsetime')(mins);
          case 2:
            return input + 'm3';
          case 4:
            return input + 'l';
          case 16:
            return input + 'm3/h';
        }
      }
    }

    function filterByMapElements(element) {
      if (!vm.elementsInMap || vm.elementsInMap.length === 0) {
        return true;
      }

      switch (vm.toolType) {
        case 'sector':
          return vm.elementsInMap.every((eInMap) => _.get(eInMap, 'properties.sectorName') !== element.name);
        case 1:
          return vm.elementsInMap.every((eInMap) => eInMap.name !== element.name);
      }
    }

    function onEdit() {
      $scope.$broadcast('editAction');
    }

    function reloadMap() {
      //$scope.$broadcast('reloadMap', {});
      vm.filterUpdated();
    }

    function resetValues() {
      vm.unit = null;
      vm.sensor = null;
      vm.sensorType = null;
      vm.moduleAdding = null;
      vm.moduleType = null;
      vm.linkbox = null;
      vm.linkboxToAdd = null;

      vm.toolType = null;
      vm.editToolType = null;
      vm.actionView = 0;
    }

    function closeOverlay() {
      $scope.$broadcast('createdMarker');
      vm.overlay.dismiss();
      if (vm.editToolType && vm.editToolType !== 4) {
        $state.go('^');
      }
    }

    function getSectorsInMap() {
      $scope.$broadcast('getSectorsInMap');
    }

    $scope.$watch('vm.unit', (value) => (vm.enabledSave = !!value && vm.toolType == 0));
    $scope.$watch('vm.linkbox', (value) => (vm.enabledSave = !!value));
    $scope.$watch('vm.sensor', (value) => (vm.enabledSave = !!value));
    $scope.$watch('vm.actionView', (value) => {
      $scope.$broadcast('actionViewChange', value);
    });

    $scope.$on('removeAreaFromSectorLayer', () => {
      areasLayer.removeLayer(vm.currentArea);
    });

    $scope.$on('editFormSave', (e, { element }) => {
      vm.elementsInMap = vm.elementsInMap.map((el) => {
        if (el.id === element.uuid) {
          el.deviceId = +element.deviceId;
          el.sectorId = +element.sectorId;
          el.sectorName = element.sectorName;
          el.deviceName = element.deviceName;
          el.cropId = +element.cropId;

          vm.sectorId = element.sectorId;
          vm.activeElementName = `${$filter('translate')(`AUTOGENERATED.LABELS.LAB1040_SECTOR`)} - ${
            element.sectorName
          }`;

          return el;
        }
        return el;
      });
      vm.actionView = 0;
    });

    $scope.$on('selectedMapModule', (name, { moduleName, markerId, moduleType }) => {
      vm.showActiveElementName = false;
      vm.toolType = null;
      vm.editToolType = 2;
      vm.actionView = 0;
      vm.showPaginator = false;

      vm.activeElementName = `${
        moduleType === 1
          ? $filter('translate')(`modules.agrobeeST1`)
          : $filter('translate')(`AUTOGENERATED.LABELS.LAB1018_MODULE`)
      } - ${moduleName}`;

      vm.currentModuleId = markerId;

      setTimeout(() => {
        vm.showActiveElementName = true;
      }, 1);
    });

    $scope.$on(
      'editSectionChangeSector',
      (name, { sectorId, currentUnit, selectedAreaId, sectorName, elementsInMap }) => {
        vm.showActiveElementName = false;
        vm.editToolType = 'sector';
        vm.toolType = null;
        vm.actionView = 0;
        vm.showPaginator = false;

        vm.elementsInMap = elementsInMap;
        vm.selectedAreaId = selectedAreaId;
        vm.currentUnit = currentUnit;
        vm.sectorId = sectorId;
        vm.activeElementName = `${$filter('translate')(`AUTOGENERATED.LABELS.LAB1040_SECTOR`)} - ${sectorName}`;

        setTimeout(() => {
          vm.showActiveElementName = true;
        }, 1);
      }
    );

    $scope.$on('editSectionChangeSensor', (name, { sensor }) => {
      vm.showActiveElementName = false;
      vm.toolType = null;
      vm.editToolType = 3;
      vm.actionView = 0;
      vm.showPaginator = false;

      const label = $filter('translate')(`history.sensor`);

      switch (sensor.type) {
        case MapSensorType.ANALOG_SENSOR:
          vm.activeElementName = `${label} ${$filter('translate')(`general.analog`)}`;
          break;
        case MapSensorType.COUNTER_SENSOR:
          vm.activeElementName = `${label} ${$filter('translate')(`totals.meter`)}`;
          break;
        case MapSensorType.DIGITAL_SENSOR:
          vm.activeElementName = `${label} ${$filter('translate')(`general.digital`)}`;
          break;
        case MapSensorType.DPV:
          vm.activeElementName = `${label} DPV`;
          break;
        case MapSensorType.ETO:
          vm.activeElementName = `${label} ETO`;
          break;
        case MapSensorType.WATER_AVAILABLE:
          vm.activeElementName = `${label} ${$filter('translate')(`maps.e16`)}`;
          break;
      }
      vm.currentSensorId = sensor.sensorId;

      setTimeout(() => {
        vm.showActiveElementName = true;
      }, 1);
    });

    $scope.$on('editSectionChange', (name, { type, value, selectedAreaId, elementsInMap, currentUnit }) => {
      vm.showActiveElementName = false;
      vm.toolType = null;
      vm.editToolType = type;
      vm.actionView = 0;
      vm.showPaginator = false;
      vm.selectedAreaId = selectedAreaId;
      vm.elementsInMap = elementsInMap;

      if (type !== 1) {
        vm.currentUnit = currentUnit;
      }

      switch (type) {
        case 1:
          vm.activeElementName = `${$filter('translate')(`AUTOGENERATED.LABELS.LAB1020_UNIT`)} - ${
            vm.currentUnit.name
          }`;
          break;
        case 3: {
          const label = $filter('translate')(`history.sensor`);

          const selectedSensor = elementsInMap.find((el) => el.id === value);
          switch (selectedSensor.prop1) {
            case sensorTypeEnum.ANALOG:
              vm.activeElementName = `${label} ${$filter('translate')(`general.analog`)}`;
              break;
            case sensorTypeEnum.METER:
              vm.activeElementName = `${label} ${$filter('translate')(`totals.meter`)}`;
              break;
            case sensorTypeEnum.DIGITAL:
              vm.activeElementName = `${label} ${$filter('translate')(`general.digital`)}`;
              break;
            case sensorTypeEnum.DPV:
              vm.activeElementName = `${label} DPV`;
              break;
            case sensorTypeEnum.ETO:
              vm.activeElementName = `${label} ETO`;
              break;
            case sensorTypeEnum.WATER_DISP:
              vm.activeElementName = `${label} ${$filter('translate')(`maps.e16`)}`;
              break;
          }

          vm.currentSensorId = value;
          break;
        }
        case 4:
          vm.activeElementName = `${$filter('translate')(`maps.label`)} - ${
            elementsInMap.find((el) => el.id === value).prop1
          }`;
          vm.currentLabelId = value;
          break;
      }

      setTimeout(() => {
        vm.showActiveElementName = true;
      }, 1);
    });

    $scope.$on('manualSectorAction', () => {
      vm.actionView = 0;
    });

    $scope.$on('removeAreaFromSectorLayer', () => {
      areasLayer.removeLayer(vm.currentArea);
    });

    $scope.$on('completedSave', () => {
      vm.closeOverlay();
      vm.resetValues();
    });

    $scope.$on('mapChanged', () => {
      vm.equiposLayer.clearLayers();
      vm.modulesLayer.clearLayers();
      vm.sensorLayer.clearLayers();
      vm.labelLayer.clearLayers();
      vm.areasLayer.clearLayers();
      vm.conectionLines.clearLayers();
      vm.waterWareasLayer.clearLayers();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
