import moment from 'moment/moment';
import veggaSelectLegacyTemplate from './vegga-select-legacy.template.html';

(function () {
  'use strict';

  angular.module('agronicwebApp').constant('moment', moment).directive('veggaSelectLegacy', veggaSelectLegacy);

  function veggaSelectLegacy() {
    return {
      restrict: 'E',
      template: veggaSelectLegacyTemplate,
      transclude: true,
      scope: {
        label: '@',
        name: '@',
        ngModel: '=',
        ngForm: '=', //deprecated
        vgForm: '=',
        data: '=?',
        placeholder: '@?',
        prefixText: '@?',
        defaultOption: '@?',
        defaultValue: '@?',
        optionValueProperty: '@?',
        optionLabelProperty: '@?',
      },
      // controller: function($scope,$element) {
      // },
      link: function ($scope, $element, attrs, ngModel) {
        $scope.selected = $scope.ngModel;
        $scope.optionLabelProperty = 'text';
        $scope.optionValueProperty = 'value';

        $scope.onVeggaSelectChange = function ($event) {
          const option = $event.detail;
          $scope.ngModel = _.at(option, [$scope.optionValueProperty])[0];
          if ($scope.ngForm) {
            //deprecated
            $scope.ngForm.$setDirty();
          }

          if ($scope.vgForm) {
            //deprecated
            $scope.vgForm.$setDirty();
          }
          $scope.$emit('VeggaSelectChange', $event.detail);
        };
      },
    };
  }
})();
