import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('sdi12Controller', sdi12Controller);

  sdi12Controller.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'commFactory',
    'Module',
    '$confirm',
    '$filter',
    'unitFactory',
  ];

  function sdi12Controller($rootScope, $scope, $state, commFactory, Module, $confirm, $filter) {
    var vm = this;
    var backup;

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });

      vm.currentState = $state.includes;
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;

      vm.currentUnit = currentUnit;
      vm.module = Module;
      vm.select = select;
      vm.initExternalMod = initExternalMod;
      vm.selectGround = selectGround;
      vm.changeModule = changeModule;
      vm.changingModule = changingModule;
      vm.nextOrPreviousItem = nextOrPreviousItem;

      loadLinkBox();
      vm.modelList = [
        { id: 0, name: 'programs.nothing' },
        { id: 1, name: 'modules.sdi12Type1' },
        { id: 2, name: 'modules.sdi12Type2' },
        { id: 3, name: 'modules.sdi12Type3' },
        { id: 4, name: 'modules.sdi12Type4' },
        { id: 5, name: 'modules.sdi12Type5' },
        { id: 6, name: 'modules.sdi12Type6' },
      ];

      vm.save = save;
      vm.cancel = cancel_edition;
    }

    function changeModule(e) {
      if (e.detail) {
        vm.externalModulesId = parseInt(e.detail.value);
        select();
      }
    }

    function changingModule($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeModule($event);
          break;
      }
    }

    function selectGround() {
      switch (vm.externalModule.model) {
        case 1:
          vm.groundList = [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.mantillo' },
            { id: 2, name: 'modules.lana' },
            { id: 3, name: 'modules.perlita' },
          ];
          break;
        case 2:
        case 5:
          vm.groundList = [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.lana' },
            { id: 2, name: 'modules.turba' },
            { id: 3, name: 'modules.coco' },
          ];
          break;
        case 3:
        case 4:
          vm.groundList = [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.arenoso' },
            { id: 2, name: 'modules.arcilla' },
            { id: 3, name: 'modules.arenosomarga' },
            { id: 4, name: 'modules.marga' },
            { id: 5, name: 'modules.arcillamarga' },
          ];
          break;
      }
    }

    function loadLinkBox() {
      commFactory.linkBoxEM(vm.currentUnit.id, 10).then((result) => {
        vm.linkBox = result[0];
        vm.externalModulesId = 1;
        select();
        backup = angular.copy(vm.linkBox);
      });
    }

    function initExternalMod() {}

    function select() {
      vm.externalModule = vm.linkBox.externalModules.find((linkBox) => linkBox.pk.id === vm.externalModulesId);

      if (vm.externalModule) {
        selectGround();
      }
    }

    function save() {
      const index = vm.linkBox.externalModules.findIndex((linkBox) => linkBox.pk.id === vm.externalModulesId);

      vm.linkBox.externalModules[index] = vm.externalModule;

      vm.form.$dirty = false;
      commFactory.updateEM(vm.currentUnit.id, vm.linkBox.externalModules).then(() => {});
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.linkBox = angular.copy(backup);
    }

    $scope.$on('refresh', function () {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
