(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsControllerA55', programsControllerA55)

    .controller('ModalInstanceSuspendCtrl', ModalInstanceSuspendCtrl);

  programsControllerA55.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$uibModal',
    'manualFactory',
    '$confirm',
    'sectorFactory',
    '$filter',
  ];

  function programsControllerA55(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $uibModal,
    manualFactory,
    $confirm,
    sectorFactory,
    $filter
  ) {
    var vm = this;
    var loadingState;
    vm.activeList;
    vm.gridColumnsDef = [
      { headerName: 'Programas', field: 'name' },
      { headerName: 'Inicio Riego', field: 'irrigationHour' },
      { headerName: 'Estado', field: '_state' },
    ];
    vm.newProgram;
    vm.dataGridRowClick;

    activate();

    function activate() {
      vm.currentState = $state;
      vm.changeState = changeState;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.program = $state.params.program;
        vm.newProgram = newProgram;
        vm.openUnoperative = openUnoperative;
        vm.dataGridRowClick = dataGridRowClick;
        vm.changeProgram = changeProgram;
        vm.changingProgram = changingProgram;
        vm.save = save;
        vm.filterByName = filterByName;
        loadingState = false;
        loadFirstProgram($state.params.unit.id);
      } else {
        $state.go('units');
      }
    }

    function dataGridRowClick(e) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.programSelected = e.detail.pk.id;
      changeState({ program: e.detail, id: e.detail.pk.id }, $state.params.config);
    }

    function loadFirstProgram(id) {
      if (!loadingState) {
        return progFactory.programs(id, vm.currentUnit.type, true).then(function () {
          vm.activeList = progFactory.activePrograms();
          var actives = _.map(vm.activeList, (o) => {
            return Number(o.pk.id);
          });
          var comparator = _.range(1, 17);
          vm.unactiveList = _.xor(actives, comparator);

          vm.anyActiveProgram = vm.activeList.some((program) => program.op);
          filterByName();

          if (vm.program === undefined) {
            //Cargando los sectores que se analizaran en la vista de programa
            sectorFactory.allSectors(vm.currentUnit.id).then((sectors) => {
              vm.currentUnit.sectors = sectors.plain();
              // if(vm.activeList && vm.activeList.length > 0){
              //     changeState({unit:vm.currentUnit,program:vm.activeList[0],id:vm.activeList[0].pk.id},config)
              // }
            });
          }
        });
      }
    }

    function loadPrograms(id) {
      return progFactory.programs(id, vm.currentUnit.type, true).then(function () {
        vm.activeList = progFactory.activePrograms();
        var actives = _.map(vm.activeList, (o) => {
          return Number(o.pk.id);
        });
        var comparator = _.range(1, 17);
        vm.unactiveList = _.xor(actives, comparator);
      });
    }

    function changeState(params, config) {
      vm.active = null;
      vm.program = params.program;
      vm.program.op = true;
      if (
        $state.includes('a55programs.edit') ||
        $state.includes('a55programs.detail') ||
        $state.includes('a55programs.config')
      ) {
        $state.go('.', params);
      } else if (config) {
        $state.go('a55programs.config', params);
      } else {
        $state.go('a55programs.detail', params);
      }
    }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('a55programs.config')) {
          $state.go('a55programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('a55programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });

      //prepareModel(model,id);
    }

    function changeProgram(e) {
      if (e.detail.value) {
        vm.programSelected = e.detail.value;
        changeState(
          { program: vm.activeList.find((prog) => prog.pk.id === vm.programSelected), id: vm.programSelected },
          $state.params.config
        );
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function save() {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('a55programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.activeList, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function filterByName() {
      if (vm.searchByProgramName) {
        vm.filterList = vm.activeList.filter((program) =>
          program.name.toLowerCase().includes(vm.searchByProgramName.toLowerCase())
        );
      } else {
        vm.filterList = vm.activeList;
      }
    }

    $scope.$on('refresh', function () {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('a55programs.detail')) {
          if ($state.params.program !== undefined && vm.activeList.length > 0) {
            var program = _.filter(vm.activeList, (o) => {
              return o.pk.id === $state.params.program.pk.id;
            });
            if (program !== undefined && program.length > 0) {
              program[0].op = true;
              changeState({ program: program[0], id: program[0].pk.id });
              //$state.go($state.current,{program:program[0],id:program[0].pk.id, unit:$state.params.unit},{reload: true});
            } else if (!$state.params.program.op) {
              $state.go(
                $state.current,
                { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
                { reload: true }
              );
            } else {
              $state.go('a55programs', { unit: $state.params.unit });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          } else {
            $state.go(
              $state.current,
              { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
              { reload: true }
            );
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });
    $scope.$on('updateEdit55', function () {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('a55programs.edit') || $state.includes('a55programs.config')) {
          if ($state.params.program !== undefined && $state.params.program.op) {
            var program = _.filter(vm.activeList, (o) => {
              return o.pk.id === $state.params.program.pk.id;
            });
            if (program !== undefined && program.length > 0) {
              vm.program = program[0];
              vm.program.op = true;
              $state.go('a55programs.detail', { program: program[0], id: program[0].pk.id, unit: $state.params.unit });
            } else {
              $state.go('a55programs', { unit: $state.params.unit });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });
    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });
  }
  ModalInstanceSuspendCtrl.$inject = ['$uibModalInstance'];

  function ModalInstanceSuspendCtrl($uibModalInstance) {
    var vm = this;
    vm.selectedHours = 0;

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedHours);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
