import { UNITS, UNITS_V2 } from '../../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { getContainer } from '@vegga/front-store';
import { from, of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('waterGeneralController', waterGeneralController);

  waterGeneralController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'sensorsFactory', 'unitFactory'];

  function waterGeneralController($rootScope, $scope, $state, configFactory, sensorsFactory, unitFactory) {
    var vm = this;
    vm.UNITS = UNITS;
    vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
    vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;

    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.inputsCombo = _.range(0, 7);
      activate();

      function activate() {
        vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
          if (!currentUnit) {
            $state.go('units');
          }
          vm.currentUnit = currentUnit;
          vm.currentHeader = $state.params.headerId || 1;
          loadWaterMixing();
          vm.selected = 0;
        });
      }
    }

    function loadWaterMixing() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        configFactory.getHeaderWaterMix(vm.currentUnit.id, vm.currentHeader).then(function (data) {
          vm.waterMixing = data.plain();
          vm.waterMixing.pauseTimeVM2 = +vm.waterMixing.pauseTimeVM2;
          backup = angular.copy(vm.waterMixing);

          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });

          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
        });
        loadAnalogSensors();
      } else {
        configFactory.getWaterMix(vm.currentUnit.id).then((response) => {
          vm.currentUnit.waterMixing = response.plain();
          vm.waterMixing = vm.currentUnit.waterMixing;

          if (vm.waterMixing.positionBy !== undefined) {
            loadAnalogSensors();
          }
        });
      }
    }

    function loadAnalogSensors() {
      var params = { page: 1, limit: 40 };
      sensorsFactory.analogsPage(vm.currentUnit.id, params).then((result) => {
        vm.analogs = result.content;
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.ioFacade
          .validateOutputsToTheCurrentView()
          .pipe(
            switchMap((result) => {
              if (result) {
                return from(configFactory.saveHeaderWaterMix(vm.currentUnit.id, vm.currentHeader, vm.waterMixing));
              } else {
                return of(null);
              }
            })
          )
          .subscribe((response) => {
            if (response) {
              vm.form.$setPristine();
              vm.waterMixing = response;
              backup = angular.copy(vm.waterMixing);
            }
          });
      } else {
        vm.form.$setPristine();
        vm.waterMixing.unittype = vm.currentUnit.type;
        configFactory.updateWaterMix(vm.currentUnit.id, vm.waterMixing).then((result) => {
          vm.waterMixing = result.plain();
          vm.currentUnit.waterMixing[0] = vm.waterMixing;
        });
      }
    }

    function cancel_edition() {
      vm.waterMixing = angular.copy(backup);
      vm.form.$setPristine();
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
