(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('veggaOverlayView', function () {
      return {
        link: function ($scope, $element, $attrs) {
          var observer = new MutationObserver(function (mutations) {
            const veggaOverlayList = document.querySelectorAll('vegga-overlay');
            veggaOverlayList.forEach((overlay) => {
              overlay.getOverlayHeight();
            });
          });
          observer.observe($element[0], {
            attributes: true,
            childList: true,
            subtree: true,
          });
        },
      };
    });
})();
