const colors = require('../../resources/vegga.tokens.colors.json');

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('manualFactory', manualFactory);

  manualFactory.$inject = ['Restangular', '$rootScope', '$q', '$uibModal'];

  function manualFactory(Restangular, $rootScope, $q, $uibModal) {
    var params = {
      type: 0,
      action: 0,
      parameter1: 0,
      parameter2: 0,
      parameter3: 0,
      parameter4: 0,
      parameter5: 0,
      parameter6: 0,
      parameter7: 0,
      parameter8: 0,
      modified: 1,
    };

    var op = {
      start: start,
      stop: stop,
      outOfService: outOfService,
      runOfService: runOfService,
      stopUnit: stopUnit,
      exitStopUnit: exitStopUnit,
      exitOutUnit: exitOutUnit,
      outUnit: outUnit,
      stopMalfunction: stopMalfunction,
      showLoadingBar: showLoadingBar,
      genericManualAction: genericManualAction,
      getManualImages: getManualImages,
    };

    return op;

    function stopUnit(unit) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 0;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function exitStopUnit(unit) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 1;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function outUnit(unit) {
      var deferred = $q.defer();
      params.type = unit.type;
      switch (unit.type) {
        case 2:
          params.action = 2;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 3:
        case 4:
          params.action = 6;
          params.parameter1 = 1;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 5:
          params.action = 8;
          params.parameter1 = 1;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 6:
          params.action = 6;
          params.parameter1 = 1;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 7:
          params.action = 11;
          params.parameter1 = 1;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
      }
      return deferred.promise;
    }

    function exitOutUnit(unit) {
      var deferred = $q.defer();
      params.type = unit.type;
      switch (unit.type) {
        case 2:
          params.action = 3;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 3:
        case 4:
          params.action = 6;
          params.parameter1 = 0;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 5:
          params.action = 8;
          params.parameter1 = 0;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 6:
          params.action = 6;
          params.parameter1 = 0;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
        case 7:
          params.action = 11;
          params.parameter1 = 0;
          Restangular.one('units', unit.id)
            .one('manual')
            .customPOST(params)
            .then(function (response) {
              deferred.resolve(response);
            });
          break;
      }
      return deferred.promise;
    }

    function start(unit, program, subprogram) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 6;
      params.parameter1 = program;
      params.parameter2 = subprogram;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function stop(unit, program) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 7;
      params.parameter1 = program;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function outOfService(unit, program) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 4;
      params.parameter1 = program;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function runOfService(unit, program) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = 5;
      params.parameter1 = program;
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function stopMalfunction(unit, action, n) {
      var deferred = $q.defer();
      params.type = unit.type;
      params.action = action;
      if (n !== null) {
        params.parameter1 = n;
      }
      Restangular.one('units', unit.id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function showLoadingBar() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'modalLoading.html',
        backdrop: 'static',
        keyboard: false,
        controller: [
          '$uibModalInstance',
          '$timeout',
          'ngProgressFactory',
          function ($uibModalInstance, $timeout, ngProgressFactory) {
            var vm = this;
            vm.max = 100;
            vm.value = 0;

            vm.progressbar = ngProgressFactory.createInstance();
            vm.showLoading = function () {
              vm.progressbar.setParent(document.getElementById('modal-loading'));
              vm.progressbar.setHeight('20px');
              vm.progressbar.setColor(colors['vegga-color-primary-default']);
              vm.progressbar.start();
              countdown();
            };
            function countdown() {
              $timeout(function () {
                vm.value++;
                vm.progressbar.set(vm.value);
                if (vm.value > vm.max) {
                  vm.progressbar.complete();
                  $uibModalInstance.close();
                } else {
                  countdown();
                }
              }, 100);
            }
          },
        ],
        controllerAs: 'vm',
      });

      return modalInstance;
    }

    function genericManualAction(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('manual')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getManualImages() {
      return {
        unit: `${process.env.HOST}/images/md1.png`,
        ferlitzerMalfunction: `${process.env.HOST}/images/mal1.png`,
        filterMalfunction: `${process.env.HOST}/images/mal2.png`,
        phMalfunction: `${process.env.HOST}/images/mal3.png`,
        systemStopMalfunction: `${process.env.HOST}/images/mal4.png`,
        generalMalfunction: `${process.env.HOST}/images/mal4.png`,
        flowMalfunction: `${process.env.HOST}/images/mal5.png`,
        counterMalfunction: `${process.env.HOST}/images/mal6.png`,
        ceMalfunction: `${process.env.HOST}/images/mal8.png`,
        definitiveStopMalfunction: `${process.env.HOST}/images/mal9.png`,
        systemStop: `${process.env.HOST}/images/mal9.png`,
      };
    }
  }
})();
