import sensorUnitTemplate from './mask-sensor-unit.html';

(function () {
  'use strict';
  angular.module('agronicwebApp').directive('maskSensorUnit', sensorUnit);

  function sensorUnit() {
    return {
      restrict: 'E',
      require: 'ngModel',
      template: sensorUnitTemplate,
      scope: {
        label: '@',
        name: '@',
        ngModel: '=',
        ngForm: '=', //deprecated
        vgForm: '=',
        maxlength: '@',
        placeholder: '@',
        suffixText: '@',
        prefixText: '@',
        integers: '=',
        decimals: '=',
        units: '=',
        max: '=',
        min: '=',
        formatLabel: '@',
        onChange: '&',
        disabled: '=',
        type: '@', // deprecated
        // number that will divide the ngModel in order to show the input value and multiply the input in order to update the ngModel.
        conversionFactor: '=',
      },
      link: function ($scope, element, attrs) {
        // if the ngModel value is not a number, set everything to nothing
        if (isNaN(parseInt($scope.ngModel))) {
          // parseInt is used because isNaN(null) returns false, instead, if parseInt is used, it returns true
          $scope.input = '';
          $scope.ngModel = '';
        } else {
          // validate the ngModel maximum and minimum when the directive is rendered
          setNgModelMinMax($scope);
        }

        /* ------- EVENTS -------*/
        $scope.$on('VeggaInputBlur', (_e, data) => {
          var val = data.target.value.replace(',', '.');

          // if the inserted value is not a number, set everything to nothing
          if (isNaN(parseInt(val))) {
            // parseInt is used because isNaN(null) returns false, instead, if parseInt is used, it returns true
            $scope.input = '';
            $scope.ngModel = '';
            return;
          }

          // validate the input maximum and minimum on blur
          if ($scope.max !== undefined && Number(val) > Number($scope.max)) {
            val = $scope.max;
          }
          if ($scope.min !== undefined && Number(val) < Number($scope.min)) {
            val = $scope.min;
          }

          // if conversion factor is set, apply it, if not, default
          if ($scope.conversionFactor) {
            onBlurConversionFactor(val, $scope);
          } else {
            onBlurDefault(val, $scope);
          }
        });

        $scope.$watch('ngModel', function () {
          // if the inserted value is not a number, set everything to nothing
          if (isNaN(parseInt($scope.ngModel))) {
            // parseInt is used because isNaN(null) returns false, instead, if parseInt is used, it returns true
            $scope.input = '';
            $scope.ngModel = '';
          } else {
            // validate the ngModel maximum and minimum when the directive is rendered
            setNgModelMinMax($scope);

            var val = $scope.ngModel;

            if ($scope.conversionFactor) {
              onChangeConversionFactor(val, $scope, attrs);
            } else {
              onChangeDefault(val, $scope, attrs);
            }
          }
        });

        $scope.$watch('min', function () {
          // if the inserted value is not a number, set min to 0
          if (!isNaN(parseInt($scope.min))) {
            // parseInt is used because isNaN(null) returns false, instead, if parseInt is used, it returns true
            setNgModelMinMax($scope);
            var val = $scope.ngModel;
            if ($scope.conversionFactor) {
              onChangeConversionFactor(val, $scope, attrs);
            } else {
              onChangeDefault(val, $scope, attrs);
            }
          }
        });

        $scope.$watch('max', function () {
          if (!isNaN(parseInt($scope.max))) {
            // validate the ngModel maximum and minimum when the directive is rendered
            setNgModelMinMax($scope);

            var val = $scope.ngModel;

            if ($scope.conversionFactor) {
              onChangeConversionFactor(val, $scope, attrs);
            } else {
              onChangeDefault(val, $scope, attrs);
            }
          }
        });

        $scope.$watch('integers', function () {
          // if the inserted value is not a number, set integers to 0
          if (!isNaN(parseInt($scope.integers))) {
            setNgModelMinMax($scope);
            var val = $scope.ngModel;
            if ($scope.conversionFactor) {
              onChangeConversionFactor(val, $scope, attrs);
            } else {
              onChangeDefault(val, $scope, attrs);
            }
          }
        });

        $scope.$watch('decimals', function () {
          // if the inserted value is not a number, set decimals to 0
          if (!isNaN(parseInt($scope.decimals))) {
            setNgModelMinMax($scope);
            var val = $scope.ngModel;
            if ($scope.conversionFactor) {
              onChangeConversionFactor(val, $scope, attrs);
            } else {
              onChangeDefault(val, $scope, attrs);
            }
          }
        });

        $scope.$watch('units', function () {
          if ($scope.input && $scope.units && !$scope.input.includes($scope.units)) {
            const unit = $scope.units.trim();
            $scope.input = $scope.input + ' ' + unit;
          }
        });
      },
    };
  }

  function setNgModelMinMax($scope) {
    if ($scope.max !== undefined) {
      // convert the maximum to a non decimal maximum
      let tempMax = $scope.max;
      if ($scope.conversionFactor) {
        // if conversion factor is set, set the tempMax with the same order as the ngModel
        tempMax *= $scope.conversionFactor;
      } else if ($scope.decimals) {
        // if has decimals, set the tempMax with the same order as the ngModel
        tempMax = Number((tempMax * 10 ** $scope.decimals).toFixed()); // it is necessary to use toFixed since Floats may have problems. For example, 2.55 * (10**2) equals 254.99999999999997
      }
      if (Number($scope.ngModel) > Number(tempMax)) {
        $scope.ngModel = tempMax;
      }
    }

    if ($scope.min !== undefined) {
      // convert the minimum to a non decimal minimum
      let tempMin = $scope.min;
      if ($scope.conversionFactor) {
        // if conversion factor is set, set the tempMax with the same order as the ngModel
        tempMin *= $scope.conversionFactor;
      } else if ($scope.decimals) {
        // if has decimals, set the tempMax with the same order as the ngModel
        tempMin = Number((tempMin * 10 ** $scope.decimals).toFixed()); // it is necessary to use toFixed since Floats may have problems. For example, 2.55 * (10**2) equals 254.99999999999997
      }
      if (Number($scope.ngModel) < Number(tempMin)) {
        $scope.ngModel = tempMin;
      }
    }
    if (!isNaN(parseInt($scope.ngModel))) {
      // parseInt is used because isNaN(null) returns false, instead, if parseInt is used, it returns true
      $scope.ngModel = Number($scope.ngModel.toFixed());
    }
  }

  function onBlurDefault(val, $scope) {
    var signed;
    var sign;

    if ($scope.decimals !== undefined) {
      $scope.veggaInputChange = true;
      val = +val;

      // divide number into integers and decimals
      var parts = val.toString().split('.');

      // check it has at most 2 parts, if not, stop
      if (parts.length > 2) {
        element.val(0);
        return 0;
      }

      var integer = parts[0] || 0;
      var decimal = parts[1] || 0;
      if (integer.charAt(0) === '+' || integer.charAt(0) === '-') {
        signed = true;
        sign = integer.charAt(0);
        integer = integer.substr(1);
      } else {
        signed = false;
      }

      var decimal_string = '';
      _.times($scope.decimals, (obj) => {
        decimal_string = decimal_string + '0';
      });

      integer = integer.toString().slice(0, $scope.integers);
      decimal = decimal.toString().slice(0, $scope.decimals);
      if (signed) {
        integer = sign + integer;
      }

      if (decimal === '0') {
        $scope.ngModel = Number(integer + decimal_string);
        if ($scope.decimals === 0) {
          $scope.input = integer;
        } else {
          $scope.input = integer + ',' + decimal_string;
        }
      } else {
        if ($scope.decimals === 0) {
          $scope.input = integer;
        } else {
          _.times($scope.decimals - decimal.length, () => {
            decimal = decimal + '0';
          });
          $scope.input = Number(integer + '.' + decimal)
            .toFixed($scope.decimals)
            .toString()
            .replace('.', ',');
        }
        $scope.ngModel = Number(integer + decimal);
      }
    }
  }

  function onChangeDefault(val, $scope, attrs) {
    if ($scope.ngModel !== undefined && $scope.ngModel !== null) {
      if (!$scope.ngModel.toString().includes('.')) {
        if (val !== null && $scope.decimals !== undefined) {
          var decimals = $scope.decimals;
          val = Number(val);
          for (var i = 0; i < decimals; i++) {
            val = val / 10;
          }
          val = val.toFixed($scope.decimals);
        }

        if ($scope.units !== undefined && typeof $scope.units === 'string') {
          $scope.input = val + ' ' + $scope.units.replace('.', ',');
        } else {
          $scope.input = val.toString().replace('.', ',');
        }

        if ($scope.veggaInputChange) {
          if (attrs.onChange) {
            $scope.onChange();
          }

          if ($scope.ngForm) {
            //deprecated
            $scope.ngForm.$setDirty();
          }

          if ($scope.vgForm) {
            $scope.vgForm.$setDirty();
          }

          $scope.veggaInputChange = false;
        }
      } else {
        $scope.input = $scope.ngModel.toFixed($scope.decimals).replace('.', ',');
      }
    }
  }

  function onBlurConversionFactor(val, $scope) {
    const value = Number(val);
    if (value !== undefined && value !== null) {
      $scope.veggaInputChange = true;

      const splitValue = value.toString().split('.');
      let integer = splitValue[0];
      let decimal = splitValue[1] || '';

      if (integer && Number(integer) >= 0 && integer.length > $scope.integers) {
        integer = integer.slice(0, $scope.integers);
      }

      if (decimal && decimal.length > $scope.decimals) {
        decimal = decimal.slice(0, $scope.decimals);
      } else {
        for (let index = decimal.length; index < $scope.decimals; index++) {
          decimal = decimal.concat('0');
        }
      }

      $scope.ngModel = Number(integer + '.' + decimal) * $scope.conversionFactor;
      $scope.input = integer + ',' + decimal;
    }
  }

  function onChangeConversionFactor(val, $scope, attrs) {
    if ($scope.decimals !== undefined && val !== null) {
      if (val.toString().includes('.')) {
        const currentDecimals = val.toString().split('.')[1].length;

        if (currentDecimals === $scope.decimals) {
          $scope.input = val.toString().replace('.', ',');
        } else {
          $scope.input = val.toFixed($scope.decimals).replace('.', ',');
        }
      } else {
        const total = $scope.ngModel / $scope.conversionFactor;
        $scope.input = total.toFixed($scope.decimals).replace('.', ',');
      }

      if ($scope.veggaInputChange) {
        if (attrs.onChange) {
          $scope.onChange();
        }

        if ($scope.vgForm) {
          $scope.vgForm.$setDirty();
        }

        $scope.veggaInputChange = false;
      }
    }
  }
})();
