export const IRRIGATION_DEVICE_STATUS = {
  systemStop: 'violet-400',
  outService: 'blue-grey-400',
  generalMalfunction: 'red-teaberry-400',
  flowMalfunction: 'red-teaberry-400',
  counterMalfunction: 'red-teaberry-400',
  ferlitzerMalfunction: 'red-teaberry-400',
  filterMalfunction: 'red-teaberry-400',
  phMalfunction: 'red-teaberry-400',
  ceMalfunction: 'red-teaberry-400',
  definitiveStopMalfunction: 'red-teaberry-400',
  irrigation: 'blue-water-400',
  noIrrigation: 'grey-400',
  notconnected: 'black outline',
};

export const A4500_IRRIGATION_PROGRAM_STATUS = {
  'manual.definitiveStop': 'red-teaberry-400',
  'general.onhold': 'yellow-sun-400',
  'manual.outof': 'blue-grey-400',
  'cond.stop': 'grey-400',
  'general.pf1': 'violet-400',
  'general.pf4': 'black outline',
  'general.pf5': 'black outline',
  'general.pf6': 'black outline',
  'general.pf19': 'yellow-sun-400',
  'general.pf21': 'yellow-sun-400',
  'general.pf22': 'yellow-sun-400',
  'general.pf23': 'yellow-sun-400',
  'general.pf24': 'yellow-sun-400',
  'general.pf25': 'yellow-sun-400',
  'general.pf26': 'yellow-sun-400',
  'general.pf27': 'yellow-sun-400',
  'general.pf28': 'yellow-sun-400',
  'general.pf29': 'yellow-sun-400',
  'general.pf30': 'yellow-sun-400',
  'general.pf32': 'yellow-sun-400',
  'general.pf33': 'yellow-sun-400',
  'general.pf34': 'yellow-sun-400',
  'general.pf35': 'yellow-sun-400',
  'general.pf38': 'black outline',
  'general.pf39': 'yellow-sun-400',
  'general.pf40': 'yellow-sun-400',
};

export const A2500_IRRIGATION_PROGRAM_STATUS = {
  'manual.definitiveStop': 'red-teaberry-400',
  'manual.outof': 'blue-grey-400',
  'cond.stop': 'grey-400',
  'general.pf1': 'violet-400',
  'general.pf4': 'black outline',
  'general.pf5': 'black outline',
  'general.pf6': 'black outline',
  'general.pf18': 'yellow-sun-400',
  'general.pf19': 'yellow-sun-400',
  'general.pf21': 'yellow-sun-400',
  'general.pf22': 'yellow-sun-400',
  'general.pf23': 'yellow-sun-400',
  'general.pf24': 'yellow-sun-400',
  'general.pf25': 'yellow-sun-400',
  'general.pf26': 'yellow-sun-400',
  'general.pf27': 'yellow-sun-400',
  'general.pf28': 'yellow-sun-400',
  'general.pf29': 'yellow-sun-400',
  'general.pf30': 'yellow-sun-400',
  'general.pf31': 'yellow-sun-400',
  'general.pf32': 'yellow-sun-400',
  'general.pf33': 'yellow-sun-400',
};

export const ABIT_IRRIGATION_PROGRAM_STATUS = {
  'manual.definitiveStop': 'red-teaberry-400',
  'manual.outof': 'blue-grey-400',
  'cond.stop': 'grey-400',
  'general.pf1': 'violet-400',
  'general.pf4': 'black outline',
  'general.pf5': 'black outline',
  'general.pf6': 'black outline',
  'general.pf18': 'yellow-sun-400',
  'general.pf19': 'yellow-sun-400',
  'general.pf20': 'yellow-sun-400',
  'general.pf21': 'yellow-sun-400',
  'general.pf22': 'yellow-sun-400',
  'general.pf23': 'yellow-sun-400',
  'general.pf24': 'yellow-sun-400',
  'general.pf25': 'yellow-sun-400',
  'general.pf26': 'yellow-sun-400',
  'general.pf27': 'yellow-sun-400',
  'general.pf28': 'yellow-sun-400',
  'general.pf29': 'yellow-sun-400',
};

export const A4000_IRRIGATION_PROGRAM_STATUS = {
  'manual.outof': 'blue-grey-400',
  'io.func4': 'blue-grey-400',
  'cond.stop': 'grey-400',
  'events.IDS_18': 'yellow-sun-400',
  'general.pf1': 'violet-400',
  'general.pf3': 'black outline',
  'general.pf4': 'black outline',
  'general.pf5': 'black outline',
  'general.pf6': 'black outline',
  'general.pf7': 'yellow-sun-400',
  'general.pf8': 'yellow-sun-400',
  'general.pf9': 'yellow-sun-400',
  'general.pf10': 'yellow-sun-400',
  'general.pf12': 'black outline',
};
