import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .controller('historyAnalogsController', historyAnalogsController);

  historyAnalogsController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    'sensorsFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyAnalogsController(
    $scope,
    $state,
    UserData,
    historyFactory,
    sensorsFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.filter = {};

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;

    vm.destroy$ = new Subject();
    vm.UNITS = UNITS;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.checkDates10min = checkDates10min;

      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;
      vm.getHistory = getHistory;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;

      vm.groupBy = historyUtilsFactory.getGroupBy();
      vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];

      vm.sortGrid = sortGrid;

      vm.filter.groupBy = '2';
      vm.filter.sensor = '0';
      vm.sortDirection = 'DESC';
      vm.sortProperty = 'from';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;

      loadAnalogs();
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4500:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            {
              field: 'sensorName',
              width: 150,
            },
            { field: 'avg' },
            { field: 'max' },
            { field: 'min' },
            { field: 'errorMax' },
            { field: 'errorMin' },
            { field: 'errors' },
          ]);
          break;
        case UNITS.A_4000:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef(
            [
              historyUtilsFactory.getDateColumnDef(),
              { field: 'sensor' },
              {
                field: 'sensorName',
                width: 150,
              },
              { field: 'avg' },
              { field: 'max' },
              { field: 'min' },
              { field: 'sum' },
            ],
            true
          );
          break;
        default:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            {
              field: 'sensorName',
              width: 150,
            },
            { field: 'avg' },
            { field: 'max' },
            { field: 'min' },
            { field: 'sum' },
          ]);
          break;
      }
    }

    function checkDates(i) {
      if (i.from === undefined) {
        return i[2] + ' - ' + i[3];
      } else {
        return i.from + ' - ' + i.to;
      }
    }
    function checkDates10min() {
      if (vm.filter.groupBy === '0') {
        if (vm.filter.sensor === 0) {
          vm.filter.sensor = 1;
        }
        vm.maxDate = moment(vm.from).add(1, 'day')._d;
        vm.to = moment(vm.from).add(1, 'day')._d;
      } else {
        vm.maxDate = null;
      }
    }
    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
        'sort.direction': vm.sortDirection,
        'sort.property': vm.sortProperty,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.analogs(vm.currentUnit.id, params).then((data) => {
        var analog = data;
        vm.totalData = data.total;

        if (analog.content !== undefined && analog.content.length > 0) {
          vm.hstySAnalogsData = analog.content.map((i) => ({
            from: checkDates(i),
            sensor: Array.isArray(i) ? i[4] : i.sensorId,
            sensorName: Array.isArray(i) ? i[5] : i.sensorName,
            avg: Array.isArray(i) ? i[6] : i.avg,
            max: Array.isArray(i) ? i[7] : i.max,
            min: Array.isArray(i) ? i[8] : i.min,
            errorMax: i[10],
            errorMin: i[11],
            errors: i[12],
          }));
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySAnalogsData, {
              fileName: $filter('translate')('history.analogSensorHistory'),
            });
          }
        } else {
          vm.hstySAnalogsData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySAnalogsData, {
              fileName: $filter('translate')('history.analogSensorHistory'),
            });
          }
        }
      });
    }

    function sortGrid({ detail }) {
      const { colField, dir } = detail;

      vm.sortDirection = dir;

      vm.sortProperty = colField;

      loadHistory();
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    $scope.$on('refresh', function () {});

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadAnalogs() {
      if (vm.currentUnit.type !== UNITS.A_4500) {
        sensorsFactory.analogsactive(vm.currentUnit.id).then(function (data) {
          vm.sensorList = data.plain();
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            })
          );
        });
      } else {
        sensorsFactory.analogsAll(vm.currentUnit.id).then(function (data) {
          vm.sensorList = data.plain();
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            })
          );
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
