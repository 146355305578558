import { UNITS, UNITS_V2 } from '../../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap, take } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('outAlarmsHeadersController', outAlarmsHeadersController);

  outAlarmsHeadersController.$inject = ['$scope', '$state', 'configFactory', 'unitFactory'];

  function outAlarmsHeadersController($scope, $state, configFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.currentHeader;
    vm.UNITS = UNITS;
    vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
    vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;

    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      if ($state.params.unit !== null) {
      } else {
        $state.go('units');
      }
    }

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentState = $state.includes;
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.currentHeader = $state.params.headerId || 1;
        vm.currentUnit = currentUnit;
        loadAlarmsHeaders();
        vm.selected = 0;
      });
    }

    function loadAlarmsHeaders() {
      var params = { add: ['alarms'] };
      configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result) => {
        vm.config = result.plain();
        vm.loading = true;
        backup = angular.copy(vm.config);

        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });

          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
        }
      });
    }

    function save() {
      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(configFactory.updateConfigHeader(vm.currentUnit.id, vm.config));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe((response) => {
              if (response) {
                vm.config = response;
                vm.form.$setPristine();
                backup = angular.copy(vm.config);
              }
            });
          break;
        default:
          configFactory.updateConfigHeader(vm.currentUnit.id, vm.config).then((result) => {
            vm.config = result;
            vm.form.$setPristine();
            backup = angular.copy(vm.config);
          });
          break;
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
