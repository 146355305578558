(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('conditionerFactory', conditionerFactory);

  conditionerFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function conditionerFactory(Restangular, $rootScope, $q) {
    var op = {
      update: update,
      getProgramConditioners: getProgramConditioners,
      getFertConditioners: getFertConditioners,
    };

    return op;
    function update(id, params) {
      var deferred = $q.defer();

      // si el tipus seleccionat es el 3 (Tipo), seteja el valor del diferencial a 0
      if (params[0].function == 3) {
        params[0].valueB = 0;
      }

      Restangular.one('units', id)
        .one('conditioner')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getProgramConditioners(deviceId, programId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('programs', programId)
        .one('conditioners')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getFertConditioners(deviceId, headerId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('fertilizers', headerId)
        .one('conditioners')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
