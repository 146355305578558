(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsManualModal', pivotsManualModal);

  pivotsManualModal.$inject = ['$uibModalInstance', 'Pivot', 'manualFactory'];

  function pivotsManualModal($uibModalInstance, Pivot, manualFactory) {
    var vm = this;
    var params = {
      deviceId: 0,
      action: 0,
      parameter1: 0,
      parameter2: 0,
      parameter3: 0,
      parameter4: 0,
      parameter5: 0,
      parameter6: 0,
      parameter7: 0,
      parameter8: 0,
      modified: 1,
    };

    activate();

    function activate() {
      vm.pivot = Pivot;
      checkPivotStatus();
      vm.modalState = 0;
      vm.changeState = changeState;
    }

    function checkPivotStatus() {
      if (vm.pivot) {
        switch (vm.pivot.xState) {
          case 0: //Pivot parat
            vm.group = 1;
            break;
          case 1: //Automatic marxa en moviment
          case 6: //Espera
          case 7: //Aplaçat
          case 12: //Espera pressio
          case 14: //Reg porteria
            vm.group = 2;
            break;
          case 2: //Manual marxa amb temps
          case 4: //Manual marxa sense temps
            vm.group = 3;
            break;
          case 3: //Manual paro amb temps
          case 5:
            vm.group = 4;
            break;
          case 8: //Paro definitiu
            vm.continueirrig = 1;
            vm.group = 7;
            break;
          case 9: //Fora de servei
            vm.group = 5;
            break;
          case 10: //Aparcant
          case 11: //Sortin aparcat
          case 13: //Espera motor
            vm.group = 6;
            break;
        }
      }
    }

    function changeState(state) {
      vm.modalState = state;
    }

    vm.ok = function () {
      params.type = '3';
      params.deviceId = vm.pivot.pk.deviceId;
      params.parameter1 = vm.pivot.pk.id - 1;
      switch (vm.modalState) {
        case 1: //manual marxa
          var time = vm.manualTime;
          params.action = 33;
          params.parameter2 = time & 0xff;
          params.parameter3 = time >> 8;
          params.parameter4 = vm.pivot.xDirection;
          params.parameter5 = vm.velocity;
          params.parameter6 = vm.areatomove;
          break;
        case 2: //manual paro
          var time = vm.manualTime;
          params.action = 32;
          params.parameter2 = time & 0xff;
          params.parameter3 = time >> 8;
          break;
        case 3: //automatico
          var time = vm.manualTime;
          params.action = 34;
          params.parameter2 = time & 0xff;
          params.parameter3 = time >> 8;
          params.parameter4 = vm.pivot.xDirection;
          params.parameter5 = vm.velocity;
          params.parameter6 = vm.areatomove;
          break;
        case 4: //inicio
          params.action = 30;
          break;
        case 5: //paro
          params.action = 31;
          break;
        case 6: //fuera de servicio
          params.action = 29;
          params.parameter2 = 1;
          break;
        case 7: //salir fuera de servicio
          params.action = 29;
          params.parameter2 = 0;
          break;
        case 8:
          params.action = 16;
          params.parameter1 = vm.continueirrig;
          break;
      }
      manualFactory.genericManualAction(params.deviceId, params).then((result) => {
        $uibModalInstance.close(vm.selectedProg);
      });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
