export const UNITS = {
  A_4000: 2,
  A_2500: 3,
  A_BIT: 4,
  A_7000: 5,
  A_5500: 6,
  A_4500: 7,
};

export const UNITS_IRRIDESK = {
  2: 'Agronic4000',
  3: 'Agronic2500',
  4: 'AgronicBIT',
  5: 'Agronic7000',
  6: 'Agronic5500',
};

export const UNITS_V2 = {
  2: 'A4000',
  3: 'A2500',
  4: 'ABIT',
  5: 'A7000',
  6: 'A5500',
  7: 'A4500',
};
