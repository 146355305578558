(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('userFactory', userFactory);

  userFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function userFactory(Restangular, $rootScope, $q) {
    var resource = Restangular.all('users');

    var userList;
    var subList;
    var unit = {};

    var op = {
      users: users,
      units: units,
      updateName: updateName,
      subusers: subusers,
      resource: resource,
      checkMaster: checkMaster,
      masterUsers: masterUsers,
      unit: unit,
      link: link,
      unlink: unlink,
      unlinkUser: unlinkUser,
      updateShare: updateShare,
      shareUsers: shareUsers,
      trialStart: trialStart,
      getTrial: getTrial,
      setOrderStorage: setOrderStorage,
      getOrderStorage: getOrderStorage,
      configDevice: configDevice,
    };

    return op;

    function users(reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        userList = undefined;
      }
      if (userList === undefined) {
        resource.getList().then(function (response) {
          userList = response;
          deferred.resolve(userList);
        });
      } else {
        deferred.resolve(userList);
      }
      return deferred.promise;
    }

    function subusers(id, reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        subList = undefined;
      }
      if (subList === undefined) {
        Restangular.one('users', id)
          .one('subusers')
          .get()
          .then(function (response) {
            subList = response;
            deferred.resolve(subList);
          });
      } else {
        deferred.resolve(subList);
      }
      return deferred.promise;
    }

    function units(id, reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        unit = {};
      }

      Restangular.one('users', id)
        .one('units')
        .get()
        .then(function (response) {
          unit[id] = response;
          deferred.resolve(unit[id]);
        });

      return deferred.promise;
    }

    function updateName(id, unit) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .customPOST(unit)
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function masterUsers(id, reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        unit = {};
      }

      Restangular.one('users', id)
        .one('masterusers')
        .get()
        .then(function (response) {
          unit[id] = response;
          deferred.resolve(unit[id]);
        });

      return deferred.promise;
    }

    function checkMaster(id) {
      var deferred = $q.defer();

      Restangular.one('users')
        .one('master', id)
        .get()
        .then(function (response) {
          unit[id] = response;
          deferred.resolve(unit[id]);
        });

      return deferred.promise;
    }

    function link(user, master) {
      var deferred = $q.defer();

      Restangular.one('users')
        .one('linkmaster')
        .get({ user: user, master: master })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function unlink(user, master) {
      var deferred = $q.defer();

      Restangular.one('users')
        .one('unlinkmaster')
        .get({ user: user, master: master })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }
    function unlinkUser(user, master) {
      var deferred = $q.defer();

      Restangular.one('users')
        .one('unlinkuser')
        .get({ user: user, master: master })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function updateShare(id, list) {
      var deferred = $q.defer();

      Restangular.one('users', id)
        .one('share')
        .customPOST(list)
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function shareUsers(id) {
      var deferred = $q.defer();

      Restangular.one('users', id)
        .one('shareusers')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function trialStart(id, type) {
      var deferred = $q.defer();

      Restangular.one('users', id)
        .one('trialstart', type)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getTrial(id, type) {
      var deferred = $q.defer();

      Restangular.one('users', id)
        .one('trialstart')
        .one('check', type)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function setOrderStorage(iduser, column, direction) {
      var stored = JSON.stringify(column + ',' + direction);
      window.localStorage && window.localStorage.setItem('orderunits-' + iduser, stored);
    }
    function getOrderStorage(iduser) {
      var stored = window.localStorage && window.localStorage.getItem('orderunits-' + iduser);
      return JSON.parse(stored);
    }

    function configDevice(id, send) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('getConfig', send)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }
  }
})();
