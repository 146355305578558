import '../components/expert/irridesk/irridesk.channel.view-controller';
import irrideskChannelViewTemplate from '../components/expert/irridesk/irridesk.channel.view.html';

import '../components/expert/irridesk/sensors/irridesk.sensors-controller';
import irrideskSensorsTemplate from '../components/expert/irridesk/sensors/irridesk.sensors.html';

import '../components/expert/irridesk/channel_configuration/irridesk.channel-controller';
import irrideskChannelConfigTemplate from '../components/expert/irridesk/channel_configuration/irridesk.channel.config.html';

import '../components/expert/irridesk/init_channel/irridesk.channel-init-controller';
import irrideskChanelInitTemplate from '../components/expert/irridesk/init_channel/irridesk.channel.init.html';

import '../components/expert/irridesk/irridesk-factory';

(function () {
  'use strict';
  angular.module('agronicwebApp').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('irrideskSensors', {
          parent: 'main',
          url: `${process.env.BASE_HREF}/expertirridesk/sensors`,
          params: {
            units: null,
          },
          template: irrideskSensorsTemplate,
          controller: 'expertIrrideskSensorsController',
          authenticate: true,
          controllerAs: 'vm',
        })

        //config channel.
        .state('irrideskchannel', {
          parent: 'main',
          url: `${process.env.BASE_HREF}/expertirridesk/channel`,
          params: {
            units: null,
            channel: null,
            idSubscription: null,
            sensors: null,
            channels: null,
          },
          template: irrideskChannelConfigTemplate,
          controller: 'expertIrrideskChannelController',
          authenticate: true,
          controllerAs: 'vm',
        })

        // view all current channels active
        .state('irrideskChannels', {
          parent: 'main',
          url: `${process.env.BASE_HREF}/expertirridesk/channels`,
          params: {
            units: null,
          },
          template: irrideskChannelViewTemplate,
          controller: 'expertIrrideskChannelViewController',
          authenticate: true,
          controllerAs: 'vm',
        })
        //view to add new channel
        .state('irrideskChannels.channel', {
          //parent: 'irridesk',
          url: '/new',
          params: {
            idSubscription: null,
            sensors: null,
            channels: null,
            unitsList: null,
          },
          template: irrideskChanelInitTemplate,
          controller: 'expertIrrideskChannelInitController',
          authenticate: true,
          controllerAs: 'vm',
        });
    },
  ]);
})();
