import irrigTimeTemplate from './irrigationTime.html';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('irrigtime', [
      'unitFactory',
      function (unitFactory) {
        return {
          restrict: 'E',
          template: irrigTimeTemplate,
          scope: {
            vm: '=',
          },
          bindToController: true,
          link: function (scope, elem, attrs) {},
          controllerAs: 'dir',
          controller: [
            '$scope',
            'unitFactory',
            function ($scope, unitFactory) {
              var vm = $scope.$parent.$parent.vm;

              if (vm.currentUnit.inoptions && vm.currentUnit.inoptions.ph) {
                unitFactory.phreference(vm.currentUnit.id).then((data) => {
                  vm.phref = data;
                });
              }

              vm.formatPh = function (value) {
                var unit = String(value);
                if (unit.length > 1) {
                  var first = unit.slice(0, unit.length - 1);
                  var last = unit.slice(unit.length - 1, unit.length);
                  unit = first + '.' + last;
                }
                return unit;
              };
            },
          ],
        };
      },
    ]);
})();
