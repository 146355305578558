import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsConfigController', pivotsConfigController);

  pivotsConfigController.$inject = ['$scope', '$rootScope', '$state', 'pivotsFactory', 'sensorsFactory', '$filter'];

  function pivotsConfigController($scope, $rootScope, $state, pivotsFactory, sensorsFactory, $filter) {
    var vm = this;
    var backup_pivot;
    vm.cancel;
    vm.save;
    vm.UNITS = UNITS;

    vm.areaGridColumnsDef = [
      { headerName: $filter('translate')(''), field: '' },
      { headerName: $filter('translate')('pivot.finalpos'), field: '' },
      { headerName: $filter('translate')('pivot.sector'), field: '' },
    ];
    vm.extAreaGridColumnsDef = [
      { headerName: $filter('translate')(''), field: '' },
      { headerName: $filter('translate')('pivot.inpos'), field: '' },
      { headerName: $filter('translate')('pivot.finalpos'), field: '' },
      { headerName: $filter('translate')('pivot.usepistol'), field: '' },
      { headerName: $filter('translate')('pivot.usealero'), field: '' },
    ];

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null && $state.params.pivot !== null) {
        vm.pivot = $state.params.pivot;
        vm.currentUnit = $state.params.unit;

        vm.cancel = cancel_edition;
        vm.save = save;
        vm.direction = direction;

        backup();

        vm.totalSensors = _.range(1, 41);
        vm.totalAreas = _.range(1, 9);
        vm.digitalSensorsRange = _.range(1, 21);
        vm.conditionersRange = _.range(1, 31);
        vm.toogle = toogle;
        vm.selected = 1;
        loadDigitalSensors();
      } else {
        $state.go('units');
      }
    }

    function toogle(id) {
      vm.selected = id;
    }

    function loadDigitalSensors() {
      if (vm.digitalSensors === undefined || vm.digitalSensors === null) {
        sensorsFactory.digitalsPage(vm.currentUnit.id, { limit: 50, page: 1 }).then(function (data) {
          vm.digitalSensors = data.content;
        });
      }
    }

    function direction(d) {
      vm.pivot.direction = d;
    }

    function backup() {
      backup_pivot = {};
      angular.copy(vm.pivot, backup_pivot);
      vm.pivot.backup = true;
    }

    function save() {
      vm.form.$setPristine();

      // A2500 pivot can not be lineal
      if (vm.currentUnit.type === UNITS.A_2500) {
        vm.pivot.type = 0;
      }
      pivotsFactory.update(vm.currentUnit.id, vm.pivot).then((response) => {
        vm.pivot = response.plain();
        vm.pivot.areas = _.orderBy(vm.pivot.areas, 'id');
        vm.pivot.externalAreas = _.orderBy(vm.pivot.externalAreas, 'id');
        backup();
        $scope.$emit('refresh');
      });
    }

    function cancel_edition() {
      angular.copy(backup_pivot, vm.pivot);
      $state.go('pivots.detail', { unit: vm.currentUnit, pivot: backup_pivot });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
