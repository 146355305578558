import { getContainer } from '@vegga/front-store';
import { UNITS } from '../../utils/units.enum';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('wifiController', wifiController);

  wifiController.$inject = ['$scope', '$state', 'commFactory', 'Wifi', 'unitFactory'];

  function wifiController($scope, $state, commFactory, Wifi) {
    var vm = this;
    var backup;
    vm.save;
    vm.cancel;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });

      vm.currentState = $state.includes;
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.currentUnit = currentUnit;
      vm.wifi = Wifi;
      backup = angular.copy(vm.wifi);
      vm.portList = { 0: 'Ninguno', 1: 'COM 1', 2: 'COM 2' };
    }

    function save() {
      commFactory.updateWifi(vm.currentUnit.id, vm.wifi).then(
        (result) => {
          vm.wifi = result;
          backup = angular.copy(vm.wifi);
          vm.form.$setPristine();
        },
        () => {}
      );
    }

    function cancel_edition() {
      vm.wifi = angular.copy(backup);
    }

    $scope.$on('refresh', function () {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
