import veggaInputLegacyTemplate from './vegga-input-legacy.template.html';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular.module('agronicwebApp').constant('moment', moment).directive('veggaInputLegacy', veggaInputLegacy);

  function veggaInputLegacy() {
    return {
      restrict: 'E',
      template: veggaInputLegacyTemplate,
      scope: {
        label: '@',
        name: '@',
        ngModel: '=',
        ngForm: '=', //deprecated
        vgForm: '=',
        type: '=?',
        maxlength: '@',
        placeholder: '@',
        helperMessage: '@',
        errorMessage: '=',
        suffixText: '@',
        prefixText: '@',
        disabled: '=',
        readOnly: '@',
        suffixIcon: '@',
        toMinutes: '=?',
        max: '@',
        min: '@',
        onChange: '&',
        onFocus: '&',
        onBlur: '&',
        required: '@',
        decimals: '@',
        size: '@',
        overwriteFloatValue: '@',
      },
      // controller: function($scope) {
      // },
      link: function ($scope, $element, attrs) {
        $scope.type = !$scope.type ? 'text' : $scope.type;

        if ($scope.size) {
          const input = document.querySelector('vegga-input');
          input.classList.add($scope.size);
        }

        $scope.onVeggaInputFocus = function ($event) {
          if ($scope.onFocus) {
            $scope.onFocus();
          }
          $scope.$emit('VeggaInputFocus', $event.detail);
        };

        $scope.onVeggaInputBlur = function ($event) {
          if ($scope.onBlur) {
            $scope.onBlur($event);
          }

          if ($scope.type === 'number') {
            $scope.ngModel = Number($scope.ngModel);
          }

          if ($scope.type === 'float') {
            $scope.ngModel = $scope.overwriteFloatValue
              ? checkMaxMinValue($event.detail.value, $scope.max, $scope.min)
              : $event.detail.value;
          }
          $scope.$emit('VeggaInputBlur', $event.detail);
        };

        $scope.onVeggaInputChange = function ($event) {
          $scope.$emit('VeggaInputChange', $event.detail);
        };

        $scope.onVeggaInputKeyDown = function ($event) {
          $scope.$emit('VeggaInputKeyDown', $event.detail);
        };

        $scope.onVeggaInputKeyUp = function ($event) {
          $scope.$emit('VeggaInputKeyUp', $event.detail);
        };

        $scope.onVeggaInputInput = function ($event) {
          $scope.veggaInputChange = true;
          if ($scope.toMinutes) {
            $scope.ngModel = moment.duration($event.detail.target.value).asMinutes();
          } else {
            if ($scope.type === 'number') {
              $scope.ngModel = Number($event.detail.target.value);
              $scope.input = Number($event.detail.target.value);
            } else if ($scope.type === 'float') {
              $scope.ngModel = $event.detail.event.target.value;
            } else {
              $scope.ngModel = $event.detail.target.value;
              $scope.input = $event.detail.target.value;
            }
          }

          $scope.$emit('VeggaInputInput', $event.detail);
        };

        $scope.onVeggaInputSuffixChange = function ($event) {
          $scope.$emit('VeggaInputSuffixIconClick', $event);
        };

        $scope.$watch('ngModel', function () {
          if ($scope.type === 'time') {
            if (Number.isInteger($scope.ngModel)) {
              if (!($scope.ngModel >= 24 * 60 || $scope.ngModel < 0)) {
                var h = ($scope.ngModel / 60) | 0,
                  m = $scope.ngModel % 60 | 0;
                $scope.input = moment(new Date()).hours(h).minutes(m).seconds(0).milliseconds(0).format('HH:mm');
              }
            } else {
              $scope.input = $scope.ngModel;
            }
          } else {
            $scope.input = $scope.ngModel;
          }

          if ($scope.veggaInputChange) {
            if ($scope.ngForm) {
              //deprecated
              $scope.ngForm.$setDirty();
            }

            if ($scope.vgForm) {
              $scope.vgForm.$setDirty();
            }

            if (attrs.onChange) {
              $scope.onChange();
            }

            $scope.veggaInputChange = false;
          }
        });

        function checkMaxMinValue(value, max, min) {
          if (max || min) {
            if (max && value > max) {
              return max;
            }

            if (min && value < min) {
              return min;
            }
          }

          return value;
        }
      },
    };
  }
})();
