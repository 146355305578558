import '../components/programs/agronic45/programs-controller';
import a45ProgramsTemplate from '../components/programs/agronic45/programs.html';
import '../components/programs/agronic45/programs.detail-controller';
import a45DetailProgramsTemplate from '../components/programs/agronic45/programs.detail.html';
import '../components/programs/agronic45/programs.edit-controller';
import a45EditProgramsTemplate from '../components/programs/agronic45/programs.edit.html';
import a45EditGeneralProgramsTemplate from '../components/programs/agronic45/programs.edit.general.html';
import a45EditLinearProgramsTemplate from '../components/programs/agronic45/programs.edit.linear.html';
import a45EditFertilizerProgramsTemplate from '../components/programs/agronic45/programs.edit.fertilizer.html';
import '../components/programs/agronic45/programs.config-controller';
import a45ConfigProgramsTemplate from '../components/programs/agronic45/programs.config.html';

import '../components/manual/manual-factory';

import '../components/sectorsgroup/sectorsgroup-controller';
import sectorsGroupTemplate from '../components/sectorsgroup/sectorsgroup.html';

import '../components/register/register-factory';
import '../components/fertilizers/fertilizers-factory';
import '../components/general_outputs/config-factory';
import '../components/utils/filter';

import '../components/filters/filters-factory';

import '../components/filters/filters.header-controller';
import filtersHeaderTemplate from '../components/filters/filters.header.html';

import '../components/filters/filters.header.config-controller';
import filtersHeaderConfigTemplate from '../components/filters/filters.header.config.html';

import '../components/filters/filters.config-4500-controller';
import filtersConfigHeaderA45Template from '../components/filters/view/config-4500.html';

import '../components/others/agronic45/others45-controller';
import othersTemplate from '../components/others/agronic45/others45.html';

import '../components/others/agronic45/others45config-controller';
import othersa45ConfigTemplate from '../components/others/agronic45/others45config.html';

import '../components/others/agronic45/others45flow-controller';
import othersa45FlowTemplate from '../components/others/agronic45/others45flow.html';

import '../components/others/agronic45/others45fert-controller';
import othersa45FertilizerTemplate from '../components/others/agronic45/others45fert.html';

import '../components/others/agronic45/others45clock-controller';
import othersa45ClockTemplate from '../components/others/agronic45/others45clock.html';

import '../components/headerRegulation/headerRegulation-factory';

import '../components/headerRegulation/headerRegulation-controller';
import regulationTemplate from '../components/headerRegulation/headerRegulation.html';

import '../components/headerRegulation/headerGeneralRegulation-controller';
import regulationGeneralTemplate from '../components/headerRegulation/headerGeneralRegulation.html';

import '../components/headerRegulation/headerPidRegulation-controller';
import regulationPidTemplate from '../components/headerRegulation/headerPidRegulation.html';

import '../components/headerRegulation/headerRegulationEngineFlow-controller';
import regulationEngineFlowTemplate from '../components/headerRegulation/headerRegulationEngineFlow.html';

import '../components/headerRegulation/headerModulationRegulation-controller';
import regulationModulationTemplate from '../components/headerRegulation/headerModulationRegulation.html';

import '../components/headers/headers-controller';
import headersTemplate from '../components/headers/headers.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        console.log('app-state.a45.js');
        $stateProvider
          .state('a45programs', {
            parent: 'unit',
            url: '/a45/programs',
            params: {
              unit: null,
              config: null,
            },
            template: a45ProgramsTemplate,
            controller: 'programsControllerA45',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a45programs.detail', {
            // parent: 'a45programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/detail/{id}',
            params: {
              program: null,
              id: null,
            },
            template: a45DetailProgramsTemplate,
            controller: 'programsDetailControllerA45',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a45programs.edit', {
            // parent: 'a45programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/edit/{id}',
            params: {
              program: null,
              id: null,
              programs: null,
            },
            template: a45EditProgramsTemplate,
            controller: 'programsEditControllerA45',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a45programs.config', {
            // parent: 'a45programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config/{id}',
            params: {
              program: null,
              id: null,
            },
            template: a45ConfigProgramsTemplate,
            controller: 'programsConfigControllerA45',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('sectorsgroup', {
            parent: 'unit',
            url: '/sectorsgroup',
            params: {
              unit: null,
            },
            template: sectorsGroupTemplate,
            controller: 'sectorsGroupController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a45filters', {
            parent: 'unit',
            url: `/filters45`,
            template: filtersHeaderTemplate,
            controller: 'filtersHeaderController',
            authenticate: true,
            controllerAs: 'vm',
          })
          //-------> Sensors child states
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a45filters.config', {
            // parent:'sensors',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config',
            params: {
              headerId: null,
            },
            template: filtersHeaderConfigTemplate,
            controller: 'filtersHeaderConfigController',
            authenticate: true,
            controllerAs: 'vm',
          })
          //---------->Sensors config child states
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a45filters.config.header', {
            // parent:'sensors.config',
            // Use a url of '/' to set a states as the 'index'.
            url: '/header/{headerId}',
            params: {
              headerId: null,
            },
            template: filtersConfigHeaderA45Template,
            controller: 'filtersConfigA45Controller',
            authenticate: true,
            controllerAs: 'vm',
          })

          .state('a45others', {
            parent: 'unit',
            url: '/a45/others',
            params: {
              unit: null,
            },
            template: othersTemplate,
            controller: 'others45Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a45others.others', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/others',
            params: {
              unit: null,
            },
            template: othersa45ConfigTemplate,
            controller: 'others45configController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a45others.flow', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/others/flow',
            params: {
              unit: null,
            },
            template: othersa45FlowTemplate,
            controller: 'others45flowController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a45others.fertilizer', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/others/fert',
            params: {
              unit: null,
            },
            template: othersa45FertilizerTemplate,
            controller: 'others45fertController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a45others.clock', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/others/fert',
            params: {
              unit: null,
            },
            template: othersa45ClockTemplate,
            controller: 'others45clockController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('regulation', {
            parent: 'unit',
            url: '/regulation',
            params: {
              unit: null,
            },
            template: regulationTemplate,
            controller: 'regulationController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('regulation.general', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/general',
            params: {
              unit: null,
              headerId: null,
            },
            template: regulationGeneralTemplate,
            controller: 'regulationGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('regulation.pid', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/pid',
            params: {
              unit: null,
              headerId: null,
            },
            template: regulationPidTemplate,
            controller: 'regulationPidController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('regulation.engineflow', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/pid',
            params: {
              unit: null,
              headerId: null,
            },
            template: regulationEngineFlowTemplate,
            controller: 'regulationEngineFlowController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('regulation.modulation', {
            // Use a url of '/' to set a states as the 'index'.
            url: '/pid',
            params: {
              unit: null,
            },
            template: regulationModulationTemplate,
            controller: 'regulationModulationController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('headers', {
            parent: 'unit',
            url: '/headers',
            params: {
              unit: null,
            },
            template: headersTemplate,
            controller: 'headersController',
            authenticate: true,
            controllerAs: 'vm',
          });
      },
    ]);
})();
