import actionsMenuTemplate from './actions-menu.directive.html';

(function () {
  'use strict';
  angular.module('agronicwebApp').directive('actionsMenu', [
    function () {
      return {
        restrict: 'E',
        transclude: true,
        template: actionsMenuTemplate,
        scope: {
          elementId: '@',
        },
        link: function (scope, element) {
          const targetElement = document.getElementById(scope.elementId);

          const observer = new IntersectionObserver(
            function (entries) {
              entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                  element[0].classList.add('hidden-action-menu');
                } else {
                  element[0].classList.remove('hidden-action-menu');
                }
              });
            },
            {
              root: document,
              rootMargin: '0px',
              threshold: 0.05,
            }
          );

          observer.observe(targetElement);

          scope.$on('$destroy', function () {
            observer.unobserve(targetElement);
          });
        },
      };
    },
  ]);
})();
