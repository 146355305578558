import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';
import { Subject, takeUntil } from 'rxjs';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersController', filtersController);

  filtersController.$inject = [
    '$scope',
    '$state',
    'filterFactory',
    'registerFactory',
    'manualFactory',
    '$confirm',
    '$filter',
    'unitFactory',
  ];

  function filtersController(
    $scope,
    $state,
    filterFactory,
    registerFactory,
    manualFactory,
    $confirm,
    $filter,
    unitFactory
  ) {
    var vm = this;
    vm.activeList;
    vm.filtersGroup;
    vm.getNumberArray;
    vm.stopCleaning;
    vm.startCleaning;
    vm.exitMalfunction;
    vm.overlayEl;
    vm.formFilters;

    vm.registerData;
    vm.currentPage = 1;
    vm.itemsPerPage = 5;
    vm.totalRegisters;
    vm.registerType;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.gridColumnsDef = [
      {
        headerName: '',
        field: 'warningNewRegisterType',
        width: 70,
        cellTemplate: (registerItem) =>
          registerItem.type === 0 || registerItem.type === null ? '<vegga-icon icon="bell"> </vegga-icon>' : '',
      },
      {
        headerName: $filter('translate')('register.date'),
        field: 'date',
        cellTemplate: (registerItem) => vm.toDate(registerItem.date),
      },
      {
        headerName: $filter('translate')('register.hour'),
        field: 'hour',
        cellTemplate: (registerItem) => vm.toHour(registerItem.date),
      },
      { headerName: $filter('translate')('register.desc'), field: 'desc' },
    ];
    vm.currentState = $state.includes;
    vm.getTimeFromMinutes = getTimeFromMin;
    vm.registerLoad = loadRegisterData;
    vm.toDate = toDate;
    vm.toHour = toHour;
    vm.exitMalfunction = exitMalfunction;
    vm.stopCleaning = stopCleaning;
    vm.startCleaning = startCleaning;
    vm.getNextCleaning = getNextCleaning;
    vm.showOverlay = showOverlay;
    vm.save = save;
    vm.cancel = cancel;
    vm.changeFilterGroup = changeFilterGroup;
    vm.index = 0;
    vm.UNITS = UNITS;
    vm.handleRegisterFilter = handleRegisterFilter;
    vm.pageChangeHandler = pageChangeHandler;
    vm.pageSizeChangeHandler = pageSizeChangeHandler;
    vm.getNumberArray = getNumberArray;

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        loadData(currentUnit);
      });
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      if (unit.type === vm.UNITS.A_4000 || unit.type === vm.UNITS.A_7000) {
        loadFilters();
      } else {
        loadFiltersA2();
      }
      loadRegisterData();

      if (
        $state.current.name === 'filters.config' ||
        $state.current.name === 'filters45.config' ||
        $state.current.name === 'filters45.config.header'
      ) {
        showOverlay('filters.config');
      }
    }

    function showOverlay() {
      $state.go('filters.config');
      vm.overlayEl = document.querySelector('vegga-overlay');
      vm.overlayEl.show();
    }

    function getNextCleaning(filter) {
      var result = filter.volumeUnits - filter.xVolumeUnits / 1000;
      if (_.isNumber(result)) {
        return result.toFixed(2);
      } else {
        return '-';
      }
    }

    function loadFilters() {
      filterFactory.all(vm.currentUnit.id).then((data) => {
        vm.filtersGroup = data.plain().filter((o) => {
          return o.numberFilters > 0;
        });
        checkState();
      });
    }

    function loadFiltersA2() {
      filterFactory.filters(vm.currentUnit.id).then(function (data) {
        var temp = data.plain();
        vm.filtersGroup = [];
        if (temp.filters && temp.filters[0].numberFilters > 0) {
          //vm.filtersGroup[0] = temp.filters;
          vm.filtersGroup = temp.filters;
          checkState();
        }
      });
    }

    function checkState() {
      vm.filtersGroup.forEach((filter) => {
        if (vm.currentUnit.type === UNITS.A_5500) {
          //filter = filter[0];
          if (filter.xStatus === 3) {
            filter.state = 'malfunction';
          } else if (filter.xStatus === 1) {
            filter.state = 'cleaning';
          } else {
            filter.state = 'noactive';
          }
        } else {
          if (filter.xStatus) {
            filter.state = 'cleaning';
          } else if (vm.currentUnit.ram && vm.currentUnit.ram.filterMalfunction && filter.xActivation) {
            filter.state = 'malfunction';
          } else {
            const engines2 = [];
            Object.keys(filter).forEach((key) => {
              if (key.startsWith('engine')) {
                engines2.push({ key: key, val: filter[key] });
              }
            });

            engines2.forEach((engine) => {
              if (engine.val) {
                const index = engine.key.substr(engine.key.length - 1);
                if (vm.currentUnit.engines) {
                  const engineTemp = vm.currentUnit.engines[index - 1];
                  if (engineTemp.xState) {
                    filter.state = 'filtering';
                  }
                }
              }
            });
            if (filter.state !== 'filtering') {
              filter.state = 'noactive';
            }
          }
        }
      });
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return $filter('parsetime')(min * 60); //progFactory.getTimeFromMins(min).format("HH:mm");
      }
      return min;
    }

    function getNumberArray(num) {
      return new Array(num);
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function handleRegisterFilter(event) {
      vm.registerType = event.detail.value;
      if (vm.registerType.length === 2) {
        vm.registerType = null;
      }

      loadRegisterData();
    }

    function loadRegisterData() {
      return filterFactory
        .filterRegisters(vm.currentUnit.id, vm.registerType, vm.currentPage, vm.itemsPerPage)
        .then((data) => {
          var register = data.plain();
          vm.totalRegisters = data.totalElements;
          if (register.content !== undefined && register.content.length > 0) {
            registerFactory.parseRegister(vm.currentUnit.type, register.content);

            vm.registerData = register.content
              .filter((i) => i.type !== 2)
              .map((i) => ({
                warningNewRegisterType: '',
                date: i.date,
                hour: i.date,
                desc: i.textLine,
              }));
          }
        });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadRegisterData();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadRegisterData();
    }

    function exitMalfunction() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();

        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopMalfunction(vm.currentUnit, 19, group).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 23;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 11;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 23;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
        }

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function stopCleaning(group) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();

        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopMalfunction(vm.currentUnit, 9, group).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 0;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function startCleaning(group) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();

        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopMalfunction(vm.currentUnit, 8, group).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 1;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            params.parameter1 = 1;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 1;
            manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});
            break;
        }

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function save() {
      if (vm.formFilters && vm.formFilters.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('filtersFormSave', true);
        });
      }
    }

    function cancel($event) {
      if (vm.formFilters && vm.formFilters.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formFilters.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('vegga-overlay').dismiss();
          $state.go('filters');
        });
      } else {
        document.querySelector('vegga-overlay').dismiss();
        $state.go('filters');
      }
    }

    function changeFilterGroup(index) {
      if (vm.formFilters && vm.formFilters.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.index = index;
          $scope.$broadcast('tabChange', vm.index);
        });
      } else {
        vm.index = index;
        $scope.$broadcast('tabChange', vm.index);
      }
    }

    $scope.$on('lang-update', function () {
      loadRegisterData();
    });

    $scope.$on('formUpdated', (e, args) => {
      vm.formFilters = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
