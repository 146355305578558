import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('regulationEngineFlowController', regulationEngineFlowController);

  regulationEngineFlowController.$inject = [
    '$state',
    '$scope',
    '$rootScope',
    'headerRegulationFactory',
    '$q',
    'configFactory',
    'unitFactory',
  ];

  function regulationEngineFlowController(
    $state,
    $scope,
    $rootScope,
    headerRegulationFactory,
    $q,
    configFactory,
    unitFactory
  ) {
    var vm = this;
    var headerScalingEngines_backup = [];
    var header_backup = [];

    vm.save = save;
    vm.cancel = cancel_edition;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        vm.currentHeader = $state.params.headerId || '1';

        loadHeaderScalingEngines();
        loadGeneralHeader();
      });
    }

    function backup() {
      angular.copy(vm.headerScalingEngines, headerScalingEngines_backup);
      angular.copy(vm.generalHeader, header_backup);
    }

    function loadGeneralHeader() {
      configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader).then((result) => {
        vm.generalHeader = result.plain();
        angular.copy(vm.generalHeader, header_backup);
      });
    }

    function loadHeaderScalingEngines() {
      headerRegulationFactory.getHeaderScalingEngines(vm.currentUnit.id, vm.currentHeader).then(function (data) {
        vm.headerScalingEngines = data.plain();
        angular.copy(vm.headerScalingEngines, headerScalingEngines_backup);
      });
    }

    function save() {
      let queries = [];
      queries.push(
        configFactory.updateConfigHeader(vm.currentUnit.id, vm.generalHeader),
        headerRegulationFactory.updateHeaderScalingEngines(vm.currentUnit.id, vm.currentHeader, vm.headerScalingEngines)
      );
      $q.all(queries).then((response) => {
        vm.form.$setPristine();
        backup();
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(headerScalingEngines_backup, vm.headerScalingEngines);
      angular.copy(header_backup, vm.generalHeader);
    }
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
