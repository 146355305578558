import maskIpTemplate from './mask-ip.html';

(function () {
  'use strict';
  angular.module('agronicwebApp').directive('maskIp', sensorUnit);

  function sensorUnit() {
    return {
      restrict: 'E',
      require: 'ngModel',
      template: maskIpTemplate,
      scope: {
        label: '@',
        name: '@',
        ngModel: '=',
        vgForm: '=',
        maxlength: '@',
        placeholder: '@',
        suffixText: '@',
        prefixText: '@',
        onChange: '&',
      },
      link: function ($scope, element, attrs) {
        // Initialize regex...
        // Match leading zero
        var regexLeadingZero = new RegExp('^0', 'g');
        // Match leading period
        var regexLeadingPeriod = new RegExp('^\\.', 'g');
        // Match duplicate period
        var regexDupePeriods = new RegExp('\\.\\.+', 'g');

        // Initialize caret position tracker
        var curPos = 0;

        $scope.$on('VeggaInputKeyUp', (_e, data) => {
          $scope.ngModel = data.target.value;
          evalInput(data.target.value);
        });

        function evalInput(val) {
          if (!val) {
            return;
          }

          var newVal = val;

          // Set caret position tracker
          curPos = element[0].selectionStart;

          // Initialize validation result tracker
          var validationResult = true;

          // Remove leading period
          newVal = newVal.replace(regexLeadingPeriod, '');

          // Remove any duplicate periods
          newVal = newVal.replace(regexDupePeriods, '.');

          // Break the IP address into segments
          var valArray = newVal.split('.');
          // Eval length to be used later
          var valArrayLength = valArray.length;

          // If there are less than four IP segments...
          if (valArrayLength < 4) {
            // Set validity tracker to false
            validationResult = false;
          }
          // Otherwise, if there are more than four IP segments...
          else if (valArrayLength > 4) {
            // Enforce 4 segment limit
            valArray.length = 4;
            // Update array length
            valArrayLength = 4;
          }

          // For each segment...
          for (var i = 0; i < valArrayLength; i++) {
            // Eval array value to be used later so the array isn't getting accessed so often
            var arrayVal = valArray[i];
            // If the ip segment value is longer than one digit...
            if (arrayVal.length > 1) {
              // Delete leading zeroes and any number after the third
              arrayVal = arrayVal.replace(regexLeadingZero, '').substring(0, 3);
              // If the value isn't a number or is greater than 255...
              if (!isNumeric(arrayVal) || arrayVal > 255) {
                // Set validity tracker to false
                validationResult = false;
              }
            }
            // Otherwise, check if the value is empty...
            else if (!arrayVal) {
              // Set validity tracker to false
              validationResult = false;
            }
            // Set the final value back to the segment
            valArray[i] = arrayVal;
          }

          // Reassemble the segments
          newVal = valArray.join('.');

          // Update the view if the new values differs from the entered value
          if (newVal !== val) {
            $scope.ngModel = newVal;
          } else {
            $scope.ngModel = val;
          }
        }

        // Determines if a passed value is numeric
        function isNumeric(n) {
          return !isNaN(parseFloat(n)) && isFinite(n);
        }
      },
    };
  }
})();
