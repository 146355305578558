import { GraphicsFilter } from '../utils/graphics';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('graphicsController', graphicsController);

  graphicsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'graphicsFactory',
    'UserData',
    '$filter',
    '$confirm',
  ];

  function graphicsController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    graphicsFactory,
    UserData,
    $filter,
    $confirm
  ) {
    var vm = this;
    vm.parent = GraphicsFilter.irrigationParent;
    vm.sort = {
      column: '',
      descending: false,
    };

    let GRAPH_SENSORS = {
      ANALOG: 1,
      FLOW: 2,
      CONSUMPTION_METER: 3,
      CONSUMPTION: 4,
      ETC: 7,
      INTEGRATION: 10,
      DEW_POINT: 5,
      ETO: 6,
      DPV: 8,
      WATERDISP: 9,
      FLOW_SECTOR: 11,
    };

    vm.elementsArray = GraphicsFilter.irrigationControlGraphic;

    vm.destroy$ = new Subject();
    vm.dataGridRowClick = dataGridRowClick;

    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICM$.pipe(take(1)).subscribe((units) => {
        vm.units = units.map((unit) => ({ ...unit, device: unit }));
        vm.currentState = $state;

        if ($state.$current.name === GraphicsFilter.agroclimaticParent) {
          vm.parent = GraphicsFilter.agroclimaticParent;
          vm.elementsArray = [...GraphicsFilter.irrigationControlGraphic, ...GraphicsFilter.climaPlantaSolGraphic];
        } else {
          vm.isFromAgroclimatic = GraphicsFilter.irrigationParent;
        }
        loadGraphics();
      });

      vm.changeState = changeState;
      vm.changeSorting = changeSorting;
      vm.changeGraphic = changeGraphic;
      vm.changingGraphic = changingGraphic;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.dataGridRowClick = dataGridRowClick;
      vm.save = save;
      vm.cancel = cancel;
      vm.deleteGraphic = deleteGraphic;
      vm.changeGraphicState = changeGraphicState;
      vm.updateGridFilterValues = updateGridFilterValues;
      vm.createGraphic = createGraphic;

      vm.searchNameValue = '';
      vm.graphicTypeFilterValue = [];
      vm.unitsFilterValue = [];
      vm.descriptionFilterValue = '';

      vm.gridFilter = {
        name: vm.searchNameValue,
        types: vm.graphicTypeFilterValue,
        units: vm.unitsFilterValue,
        description: vm.descriptionFilterValue,
      };

      vm.gridColumnsDef = [
        { headerName: $filter('translate')('graphics.graphics'), field: 'pkId' },
        {
          headerName: $filter('translate')('graphics.name'),
          field: 'name',
          filterParams: (cellValue) => {
            return cellValue.toLowerCase().includes(vm.gridFilter.name.toLowerCase());
          },
          cellTemplate: (graph) => {
            return `<vegga-text>${graph.name ? graph.name : '-'}</vegga-text>`;
          },
        },
        {
          headerName: $filter('translate')('graphics.type'),
          field: 'types',
          width: 250,
          cellTemplate: (graph) => getCellTemplate('types', graph),
          filterParams: (cellValue) => {
            const filteredTypes = vm.gridFilter.types.map((type) => type.value);
            return !filteredTypes.length ? true : cellValue.some((type) => filteredTypes.includes(type));
          },
        },
        {
          headerName: $filter('translate')('AUTOGENERATED.LABELS.LAB1021_UNITS'),
          field: 'units',
          width: 250,
          cellTemplate: (graph) => getCellTemplate('units', graph),
          filterParams: (cellValue) => {
            const filteredUnits = vm.gridFilter.units.map((unit) => unit.value);

            return !filteredUnits.length ? true : cellValue.some((type) => filteredUnits.includes(type));
          },
        },
        {
          headerName: $filter('translate')('graphics.description'),
          field: 'description',
          cellTemplate: (graph) => {
            return `<vegga-text>${graph.description ? graph.description : '-'}</vegga-text>`;
          },
          filterParams: (cellValue) => {
            if (!cellValue) cellValue = '-';
            return cellValue.toString().toLowerCase().includes(vm.gridFilter.description.toString().toLowerCase());
          },
        },
        { headerName: $filter('translate')('graphics.datemod'), field: 'modifiedDate' },
      ];
    }

    function getCellTemplate(type, graph) {
      if (type === 'types') {
        const types = Array.from(new Set(graph.graphicElements.map(({ type }) => type)));
        if (!types.length) return `<vegga-text>-</vegga-text>`;
        return `
          <vegga-ellipsis-wrapper>
            <div slot="content">
            ${types.map((type) => {
              switch (type) {
                case GRAPH_SENSORS.ANALOG:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g1')}" enable-remove="false"></vegga-tag>`;

                case GRAPH_SENSORS.FLOW:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g2')}" enable-remove="false"></vegga-tag>`;

                case GRAPH_SENSORS.CONSUMPTION_METER:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g3')}" enable-remove="false"></vegga-tag>`;

                case GRAPH_SENSORS.CONSUMPTION:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g4')}" enable-remove="false"></vegga-tag>`;

                case GRAPH_SENSORS.ETC:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g7')}" enable-remove="false"></vegga-tag>`;

                case GRAPH_SENSORS.INTEGRATION:
                  return `<vegga-tag tag="${$filter('translate')(
                    'general.integration'
                  )}" enable-remove="false"></vegga-tag>`;
                case GRAPH_SENSORS.DEW_POINT:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g5')}" enable-remove="false"></vegga-tag>`;
                case GRAPH_SENSORS.ETO:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g6')}" enable-remove="false"></vegga-tag>`;
                case GRAPH_SENSORS.DPV:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g8')}" enable-remove="false"></vegga-tag>`;
                case GRAPH_SENSORS.WATERDISP:
                  return `<vegga-tag tag="${$filter('translate')('graphics.g9')}" enable-remove="false"></vegga-tag>`;
                case GRAPH_SENSORS.FLOW_SECTOR:
                  return `<vegga-tag tag="${$filter('translate')(
                    'graphics.flow_sector'
                  )}" enable-remove="false"></vegga-tag>`;
              }
            })}
            </div>
          </vegga-ellipsis-wrapper>
        `.replaceAll(',', '');
      }
      if (type === 'units') {
        const devices = Array.from(new Set(graph.graphicElements.map(({ deviceName }) => deviceName))).filter(
          (device) => device
        );
        if (!devices.length) return `<vegga-text>-</vegga-text>`;
        return `<vegga-ellipsis-wrapper>
                  <div slot="content">
                    ${devices.map((device) => `<vegga-tag tag="${device}" enable-remove="${false}"></vegga-tag>`)}
                  </div>
                </vegga-ellipsis-wrapper>
    `.replaceAll(',', '');
      }
    }

    function updateGridFilterValues($event, filterName) {
      if (filterName === 'description') {
        vm.gridFilter = { ...vm.gridFilter, [filterName]: $event };
        return;
      }
      vm.gridFilter = { ...vm.gridFilter, [filterName]: $event.detail };
    }

    function loadGraphics() {
      const filter =
        vm.parent === GraphicsFilter.irrigationParent
          ? GraphicsFilter.irrigationControlGraphic
          : GraphicsFilter.climaPlantaSolGraphic;

      graphicsFactory.getGraphics(UserData.id).then((result) => {
        if (result) {
          vm.graphicsList = [];
          result.forEach((graphic) => {
            const graphicBackup = JSON.parse(JSON.stringify(graphic));
            graphicBackup.graphicElements = [];
            graphic.graphicElements.forEach((graphicEl) => {
              const typeExist = filter.find((element) => element.id === graphicEl.type);
              if (typeExist) {
                graphicBackup.graphicElements.push(graphicEl);
              }
            });

            if (vm.parent === GraphicsFilter.irrigationParent) {
              if (graphicBackup.graphicElements.length === graphic.graphicElements.length) {
                vm.graphicsList = result.filter((graph) => !graph.graphicElements.find((el) => el.userSensor));
              }
            } else {
              if (graphicBackup.graphicElements.length > 0) {
                vm.graphicsList = result.filter((graph) => !!graph.graphicElements.find((el) => el.userSensor));
              }
            }
          });
          vm.graphicListRaw = vm.graphicsList;
          vm.graphicsList = vm.graphicsList.map((graph) => {
            return {
              ...graph,
              pkId: graph.pk.id,
              types: graph.graphicElements.map((el) => el.type),
              units: graph.graphicElements.map((el) => el.deviceName),
            };
          });
        }
      });
    }

    function dataGridRowClick({ detail }, state) {
      const graphic = vm.graphicsList.find((graph) => graph.pk.id === detail.pk.id);
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      changeState(state, graphic);
    }

    function createGraphic() {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      changeState('edit');
    }

    function changeState(state, g) {
      vm.graphic = g;
      $state.go(`${vm.parent}.${state}`, { graphic: g, unitList: vm.units });
    }

    function changeGraphic(e) {
      if (e.detail.value) {
        const path = $state.current.name.split('.')[1];
        changeState(`${path || 'detail'}`, e.detail.value);
      }
    }

    function changingGraphic($event) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeGraphic($event);
          break;
      }
    }

    function changeSorting(column) {
      if (vm.sort.column == column) {
        vm.sort.descending = !vm.sort.descending;
      } else {
        vm.sort.column = column;
        vm.sort.descending = false;
      }
      graphicsFactory.setOrderStorage(UserData.id, vm.sort.column, vm.sort.descending);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeGraphicState(state, g) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial
          changeState(state, g);
        });
      } else {
        changeState(state, g);
      }
    }

    function cancel(from, $event) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial

          if (vm.graphic && from === 'button') {
            changeState('detail', vm.graphic);
          } else {
            document.querySelector('vegga-overlay').dismiss();
            $state.go(vm.parent, { units: vm.units });
          }
        });
      } else {
        if (vm.graphic && from === 'button') {
          changeState('detail', vm.graphic);
        } else {
          $state.go(vm.parent, { units: vm.units });
          document.querySelector('vegga-overlay').dismiss();
        }
      }
    }

    function save() {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function deleteGraphic() {
      $confirm({ text: $filter('translate')('graphics.delgraphic') }).then(() => {
        graphicsFactory.deleteGraphic(vm.graphic.pk.userId, vm.graphic.pk.id).then(() => {
          document.querySelector('vegga-overlay').dismiss();
          $state.go(vm.parent);
          vm.graphicsList = vm.graphicsList.filter((graphic) => graphic.pk.id !== vm.graphic.pk.id);
        });
      });
    }

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formGraphic = args;
    });

    $scope.$on('closeOverlay', () => {
      document.querySelector('vegga-overlay').dismiss();
      $state.go(vm.parent, { units: vm.units });
      loadGraphics();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
