import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('extractionsController', extractionsController);

  extractionsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'extractionsFactory',
  ];

  function extractionsController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    extractionsFactory
  ) {
    var vm = this;
    var extractionBackup;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.units !== null) {
        vm.filterText;
        vm.changeState = changeState;
        vm.getDate = getDate;
        vm.filtrarLista = filtrarLista;
        vm.getExtractionStatus = getExtractionStatus;
        loadextractions();
      } else {
        $state.go('units');
      }
    }
    function filtrarLista() {
      if (vm.filterText === '') {
        vm.extractions = extractionBackup;
      } else {
        vm.extractions = _.filter(extractionBackup, (ext) => {
          return ext.code.includes(vm.filterText) || ext.description.includes(vm.filterText);
        });
      }
    }

    function loadextractions() {
      extractionsFactory.get().then((extractions) => {
        vm.extractions = extractions.plain();
        extractionBackup = extractions.plain();
      });
    }
    function getExtractionStatus(item) {
      if (item.cancelDate !== null) {
        return 'Pozo inactivo - baja';
      } else if (item.canceled) {
        return 'Sincronización parada';
      } else {
        return 'Pozo activo - Sincronizando';
      }
    }
    function getDate(item) {
      if (item.registerDate !== null) {
        return moment(item.registerDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
      } else {
        return '-';
      }
    }
    function changeState(state, e) {
      $state.go('eextra.' + state, { extractions: e });
    }
  }
})();
