//TODO import template
import veggaRangedTimePickerLegacyTemplate from './vegga-ranged-time-picker-legacy.template.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('veggaRangedTimePickerLegacy', veggaRangedTimePickerLegacy);

  function veggaRangedTimePickerLegacy() {
    return {
      restrict: 'E',
      template: veggaRangedTimePickerLegacyTemplate,
      scope: {
        label: '@',
        labelStart: '@',
        labelEnd: '@',
        nameStart: '@',
        nameEnd: '@',
        ngModelStart: '=',
        ngModelEnd: '=',
        toMinutes: '=?',
        ngForm: '=', //deprecated
        vgForm: '=',
      },
      // controller: function($scope,$element) {
      // },
    };
  }
})();
