import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { getType, unitTimeFormatter } from '../../utils/utils';
import { Subject, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('equipoLayerController', equipoLayerController);

  equipoLayerController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'mapsFactory',
    'manualFactory',
    '$confirm',
    'unitFactory',
    '$translate',
    'UserData',
    '$filter',
    'sectorFactory',
  ];

  function equipoLayerController(
    $rootScope,
    $scope,
    $state,
    mapsFactory,
    manualFactory,
    $confirm,
    unitFactory,
    $translate,
    UserData,
    $filter,
    sectorFactory
  ) {
    const vm = this;
    let currentMarker;
    let selectedUnit;

    vm.destroy$ = new Subject();
    activate();

    /*******************/

    function activate() {
      vm.images = manualFactory.getManualImages();
      vm.isUnitDataLoading = true;
      vm.checkStatus = unitFactory.checkStatus;
      if (!$state.params.unit) {
        $state.go('^');
        return;
      }
      vm.equipoInfo = false;
      vm.addMarkerE = addMarker;
      vm.deleteEquipoMarker = deleteEquipoMarker;
      vm.closeEquipoInfo = closeEquipoInfo;
      vm.saveEquipo = saveEquipo;
      vm.returnType = returnType;
      vm.manualStop = manualStop;
      vm.manualExitStop = manualExitStop;
      vm.manualOut = manualOut;
      vm.manualExitOut = manualExitOut;

      vm.stopProgress = stopProgress;
      vm.endMalfunction25 = endMalfunction25;
      vm.endExitAlarms = endExitAlarms;
      vm.endAllMalfunctions7 = endAllMalfunctions7;
      vm.endMalfunctionsGeneral = endMalfunctionsGeneral;
      vm.sectorsIrrig = [];

      vm.checkStatus = checkStatus;
      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
        vm.currentUnit = $state.params.unit;

        vm.shared = $state.params.map.shared;
        vm.sharingLvl = $state.params.map.sharingLvl;
        vm.areaInfo = false;
        vm.moduleInfo = false;
        vm.equipoInfo = true;
        vm.sensorInfo = false;

        const unitsValue = unitFactory.getUnitsValue();

        const unitsReq$ = unitsValue ? of(unitsValue) : unitFactory.getUnits(UserData.id);
        unitsReq$
          .pipe(
            switchMap(() => {
              const params = vm.currentUnit.type === UNITS.A_7000 ? {} : { add: ['format'] };
              return unitFactory.getMergedUnit(UserData.id, vm.currentUnit.id, params, false).pipe(take(1));
            }),

            takeUntil(vm.destroy$)
          )
          .subscribe((unit) => {
            processCurrentUnit(unit);
          });
      }
    }

    function processCurrentUnit(unit) {
      vm.currentUnit = unit;

      vm.currentUnit.typeName = getType(vm.currentUnit.type);
      vm.currentUnit.ram.lastReception = unitTimeFormatter($translate, vm.currentUnit.ram.lastReception);
      vm.currentUnit.ram.date = unitTimeFormatter($translate, vm.currentUnit.ram.date);
      vm.currentUnit.date = unitTimeFormatter($translate, vm.currentUnit.date);
      vm.currentUnit.statusLabel = `units.${
        vm.currentUnit.status === 'ok' ? (vm.currentUnit.irrigation ? 'irrig' : 'nirrig') : vm.currentUnit.status
      }`;
      vm.isUnitDataLoading = false;
      vm.sectorsIrrig = [];
      sectorFactory.isIrrigationActive(vm.currentUnit.id).then((data) => {
        const sectors = data.plain();
        sectors.forEach((sector) => {
          vm.sectorsIrrig.push(sector.pk.id);
        });
      });
    }

    function getEquipoStateClass(unit) {
      switch (unit.status) {
        case 'systemStop':
        case 'definitiveStopMalfunction':
          return 'stop';
        case 'outService':
          return 'out';
        case 'notconnected':
          return 'nok';
        case 'ok':
          if (unit.irrigation) {
            return 'irrig';
          } else {
            return 'noirrig';
          }
        default:
          return 'malfunction';
      }
    }

    function markerPrepare(e) {
      const className = getEquipoStateClass(vm.unit);
      e.layer.setIcon(createEquipoIcon(className));

      saveMarker(e.latlng).then((resp) => {
        e.layer.properties = {};
        e.layer.properties.id = resp.id;
        e.layer.properties.deviceid = resp.deviceId;
        $scope.$emit('completedSave');
        vm.equiposLayer.addLayer(e.layer);
        e.layer.toggleEdit();
        vm.map.off('editable:drawing:commit', markerPrepare);
      });
    }

    function saveMarker(latLng) {
      let params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 0;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      vm.map.editTools.startMarker({ zIndex: 9999 });
      vm.map.on('editable:drawing:commit', markerPrepare);
    }

    const createEquipoIcon = function (labelClass) {
      const content = `<div class="map-equipo-marker ${labelClass}"></div>`;
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function saveEquipo() {
      let params = {};
      params.id = currentMarker.properties.id;
      params.deviceId = currentMarker.properties.deviceid = vm.currentUnit.id;
      mapsFactory.updateMarker(vm.map.id, params);
    }

    function closeEquipoInfo() {
      $rootScope.$broadcast('closeEquipo');
      $state.go('^');
    }

    function returnType(type) {
      switch (type) {
        case UNITS.A_4000:
          return 'Agrónic 4000';
        case UNITS.A_2500:
          return 'Agrónic 2500';
        case UNITS.A_BIT:
          return 'Agrónic BIT';
        case UNITS.A_7000:
          return 'Agrónic 7000';
        case UNITS.A_5500:
          return 'Agrónic 5500';
        case UNITS.A_4500:
          return 'Agrónic 4500';
      }
    }

    function deleteEquipoMarker() {
      $rootScope.$broadcast('deleteEquipo');
    }

    /* Manual functionality */

    function manualStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.y`),
      }).then(function () {
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopUnit(vm.currentUnit);
            break;
          case 3:
            params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
      });
    }

    function manualExitStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.y`),
      }).then(function () {
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.exitStopUnit(vm.currentUnit);
            break;
          case 3:
            params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
      });
    }

    function manualOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.y`),
      }).then(function () {
        manualFactory.outUnit(vm.currentUnit);
      });
    }

    function manualExitOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.y`),
      }).then(function () {
        manualFactory.exitOutUnit(vm.currentUnit);
      });
    }

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }

    function stopProgress() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        let params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 13;
        manualFactory.genericManualAction(params.deviceId, params);
      });
    }

    function endMalfunction25() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        let params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 25;
        } else {
          params.action = 16;
        }
        params.parameter1 = vm.continueirrig;
        manualFactory.genericManualAction(params.deviceId, params);
      });
    }

    function endAllMalfunctions7() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        let params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 24;
        manualFactory.genericManualAction(params.deviceId, params);
      });
    }

    function endMalfunctionsGeneral() {
      let action = null;
      let parameters = null;
      switch (vm.currentUnit.type) {
        case 2:
          action = null;
          parameters = null;
          switch (vm.currentUnit.status) {
            case 'generalMalfunction':
              action = 12;
              break;
            case 'flowMalfunction':
              action = 13;
              break;
            case 'counterMalfunction':
              action = 14;
              break;
            case 'ferlitzerMalfunction':
              action = 15;
              break;
            case 'filterMalfunction':
              action = 19;
              break;
            case 'phMalfunction':
              action = 16;
              break;
            case 'ceMalfunction':
              action = 17;
              break;
            case 'definitiveStopMalfunction':
              action = 18;
              parameters = 1;
              break;
            case 'systemStopMalfunction':
              action = 1;
              break;
            case '2lMalfunction':
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send_action'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters);
          });

          break;

        case 3:
          action = null;
          parameters = null;
          switch (vm.currentUnit.status) {
            case 'filterMalfunction':
              action = 23;
              break;
            case 'systemStopMalfunction':
              action = 7;
              break;
            default:
              parameters = vm.currentUnit.input;
              action = 16;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send_action'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters);
          });

          break;

        case 4:
          action = null;
          parameters = null;
          switch (vm.currentUnit.status) {
            case 'definitiveStopMalfunction':
              action = 15; //El 22 no sutiltiza
              break;
            //FINAL PAROS I AVERIES
            default:
              parameters = vm.currentUnit.input;
              action = 15;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send_action'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters);
          });

          break;

        case 5:
          action = null;
          parameters = null;
          switch (vm.currentUnit.status) {
            case 'generalMalfunction':
              action = 14;
              break;
            case 'flowMalfunction':
              action = 17;
              break;
            case 'counterMalfunction':
              action = 14;
              break;
            case 'ferlitzerMalfunction':
              action = 15;
              break;
            case 'filterMalfunction':
              action = 11;
              break;
            case 'phMalfunction':
              action = 16;
              break;
            case 'ceMalfunction':
              action = 15;
              break;
            case 'definitiveStopMalfunction':
              action = 18;
              parameters = 1;
              break;
            case 'systemStopMalfunction':
              action = 1;
              break;
            case '2lMalfunction':
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send_action'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters);
          });
          break;

        case 6:
          action = null;
          parameters = null;
          switch (vm.currentUnit.status) {
            case 'filterMalfunction':
              action = 23;
              break;
            case 'systemStopMalfunction':
              break;
            default:
              parameters = vm.currentUnit.input;
              action = 16;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send_action'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters);
          });
          break;
      }
    }

    function endExitAlarms() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        let params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 40;
        manualFactory.genericManualAction(params.deviceId, params);
      });
    }

    function showOtherSectorFromUnit(id) {
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) === id) {
          l.setStyle({ color: '#ffc72c', weight: 3 });
        }
      });
    }

    $scope.$on('remarkSectors', () => {
      if (selectedUnit !== undefined && selectedUnit !== null) {
        showOtherSectorFromUnit(selectedUnit.id);
      }
    });

    $scope.$on('reloadEquipo', (event, args) => {
      vm.currentUnit = args.unit;
    });

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });
  }
})();
