import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('historyAvgPhCeController', historyAvgPhCeController);

  historyAvgPhCeController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'UserData',
    'historyFactory',
    'sectorFactory',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyAvgPhCeController(
    $scope,
    $state,
    $filter,
    UserData,
    historyFactory,
    sectorFactory,
    unitFactory,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.filter = {};

    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;

      vm.groupBy = historyUtilsFactory.getGroupBy();

      vm.sectorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];
      vm.filter.groupBy = '2';
      vm.filter.sector = '0';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
      loadSectors();
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_7000:
        case UNITS.A_5500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sector' },
            {
              field: 'sectorName',
              width: 150,
            },
            { field: 'program' },
            { field: 'time' },
            { field: 'avgPh' },
            { field: 'avgCe' },
          ]));
        case UNITS.A_4500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sector' },
            {
              field: 'sectorName',
              width: 150,
            },
            { field: 'program' },
            { field: 'programName' },
            { field: 'avgPh' },
            { field: 'avgCe' },
          ]));
      }
    }

    function checkDates(i) {
      if (vm.currentUnit.type === vm.UNITS.A_7000 || vm.currentUnit.type === vm.UNITS.A_4500) {
        return i[2] + ' - ' + i[3];
      } else {
        return i[1] + ' - ' + i[2];
      }
    }

    function getPrograms7(i) {
      if (Number(i[6]) > 1) {
        return 'varios';
      } else {
        return i[11];
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.avgphce(vm.currentUnit.id, params).then((data) => {
        var phCe = data;
        vm.totalData = data.total;

        if (phCe.content !== undefined && phCe.content.length > 0) {
          vm.hstyAvgPhCeData = phCe.content.map((i) => {
            const hoursAndMinutes =
              vm.currentUnit.type === vm.UNITS.A_7000
                ? moment.duration(i[7], 'seconds')
                : moment.duration(i[6], 'seconds'); //5500
            return {
              from: checkDates(i),
              sector: vm.currentUnit.type === vm.UNITS.A_5500 ? i[3] : i[4],
              sectorName: vm.currentUnit.type === vm.UNITS.A_5500 ? i[4] : i[5],
              program:
                vm.currentUnit.type === vm.UNITS.A_7000
                  ? getPrograms7(i)
                  : vm.currentUnit.type === vm.UNITS.A_5500
                  ? i[5]
                  : i[6], //4500
              programName: i[7],
              time: `${hoursAndMinutes.hours()}h ${hoursAndMinutes.minutes()}min`,
              avgPh:
                vm.currentUnit.type === vm.UNITS.A_7000
                  ? (i[8] / 10).toFixed(2)
                  : vm.currentUnit.type === vm.UNITS.A_5500
                  ? (i[7] / 10).toFixed(1)
                  : i[9], //4500
              avgCe:
                vm.currentUnit.type === vm.UNITS.A_7000
                  ? (i[9] / 10).toFixed(2)
                  : vm.currentUnit.type === vm.UNITS.A_5500
                  ? (i[8] / 10).toFixed(1)
                  : i[8], //4500
            };
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyAvgPhCeData, {
              fileName: $filter('translate')('history.avgPhCeHistory'),
            });
          }
        } else {
          vm.hstyAvgPhCeData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyAvgPhCeData, {
              fileName: $filter('translate')('history.avgPhCeHistory'),
            });
          }
        }
      });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    function loadSectors() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        sectorFactory.allSectors(vm.currentUnit.id).then(function (data) {
          vm.sectorList = data.plain();
          vm.sectorListDTO.push(
            ...vm.sectorList.map((sector) => {
              return {
                text: sector.pk.id + ' - ' + (sector.name ? sector.name : 'Sector ' + sector.pk.id),
                value: sector.pk.id,
              };
            })
          );
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
