import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('r16Controller', r16Controller);

  r16Controller.$inject = ['$scope', '$state', 'commFactory', 'Module', 'unitFactory'];

  function r16Controller($scope, $state, commFactory, Module) {
    var vm = this;
    var backup;
    vm.attemptsAR16;
    vm.netCodeAR16;
    vm.suspendedAR16;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;

      vm.currentUnit = currentUnit;
      vm.module = Module;
      vm.attemptsAR16 = angular.copy(vm.module.attemptsAR16);
      vm.netCodeAR16 = angular.copy(vm.module.netCodeAR16);
      vm.suspendedAR16 = angular.copy(vm.module.suspendedAR16);

      loadLinkBox();

      vm.netCodes = _.range(0, 100);
      vm.chanels = {
        0: '1',
        1: '2',
        2: '3',
        3: '4',
        4: '5',
        5: '6',
        6: '7',
        7: '8',
        8: '9',
        9: '10',
        10: '11',
        11: '12',
        12: '13',
        13: '14',
      };
      vm.modBusList = {
        0: '0',
        1: '001',
        2: '002',
        3: '003',
        4: '004',
        5: '005',
        6: '006',
        7: '007',
        100: '100',
        101: '101',
        102: '102',
        103: '103',
        104: '104',
        105: '105',
        106: '106',
        107: '107',
      };
      vm.protocolList = {
        0: '0 = 8"',
        1: '1 = 2"',
        2: '2 = 3"',
        3: '3 = 7"',
        4: '4 = 4"',
        5: '5 = 5"',
        6: '6 = 6"',
      };
      vm.save = save;
      vm.cancl = cancel_edition;
    }

    function loadLinkBox() {
      commFactory.linkBoxEM(vm.currentUnit.id, 2).then((result) => {
        vm.linkBox = result[0];
        backup = angular.copy(vm.linkBox);
      });
    }

    function save() {
      vm.linkBox.unittype = vm.currentUnit.type;
      vm.loading = true;
      vm.module.linkBoxes = [vm.linkBox];
      vm.module.attemptsAR16 = angular.copy(vm.attemptsAR16);
      vm.module.netCodeAR16 = angular.copy(vm.netCodeAR16);
      vm.module.suspendedAR16 = angular.copy(vm.suspendedAR16);

      commFactory.updateModule(vm.currentUnit.id, vm.module).then((result) => {
        vm.module = result;
        vm.module.linkBoxes[0].externalModules.sort((a, b) => a.pk.id - b.pk.id);

        vm.linkBox = vm.module.linkBoxes[0];
        backup = angular.copy(vm.linkBox);
        vm.loading = false;
        vm.form.$dirty = false;
      });
    }

    function cancel_edition() {
      vm.linkBox = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
