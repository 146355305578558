(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('modulosWebController', modulosWebController);

  modulosWebController.$inject = ['$log', '$scope', 'userFactory', 'UserData', '$mdDialog', '$window'];

  function modulosWebController($log, $scope, userFactory, UserData, $mdDialog, $window) {
    var vm = this;
    vm.cctype = false;
    vm.subUser = false;
    activate();
    vm.activeCuaderno = activeCuaderno;

    function activate() {
      console.log(UserData.authorities);
      if (UserData.role === 'SUBUSER') vm.subUser = true;
      vm.newUserTrial = false;
      userFactory.getTrial(UserData.id, 0).then((result) => {
        if (result === undefined) {
          vm.newUserTrial = true;
        }
      });

      $log.debug('modulosWebController loading');
    }

    function activeCuaderno(type) {
      userFactory.trialStart(UserData.id, type).then((result) => {
        vm.cctype = result;
        if (vm.cctype) {
          $mdDialog
            .show(
              $mdDialog
                .alert()
                .clickOutsideToClose(true)
                .title('Cuaderno de Campo')
                .textContent('Se ha activado la version de prueba!')
                .ariaLabel('Alert Dialog')
                .ok('Cerrar')
            )
            .then(() => {
              $window.location.reload();
            });
        }
      });
    }
  }
})();
