export const SLOGICS_AND_OR_OPS_TYPE = {
  DIGITAL_SENSOR: 1,
  CONDITIONER: 2,
  DIGITAL_OUTPUT: 3,
  DIGITAL_LOGIC_SENSOR: 4,
  SECTOR: 5,
  ANALOG_SENSOR: 6,
  FLOW_METER_SENSOR: 7,
  VALUE_LOGIC_SENSOR: 8,
  ANALOG_OUTPUT: 9,
};
