import { getContainer } from '@vegga/front-store';
import { switchMap, take, tap } from 'rxjs';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersConfigA45Controller', filtersConfigA45Controller);

  filtersConfigA45Controller.$inject = [
    '$scope',
    '$state',
    '$filter',
    'sensorsFactory',
    'filterFactory',
    'unitFactory',
    '$confirm',
    '$rootScope',
  ];

  function filtersConfigA45Controller(
    $scope,
    $state,
    $filter,
    sensorsFactory,
    filterFactory,
    unitFactory,
    $confirm,
    $rootScope
  ) {
    var vm = this;
    vm.form;
    vm.calcNumFilter3;
    vm.analogs = [];
    vm.save = save;
    vm.processNumFilter = processNumFilter;

    vm.currentHeader = $state.params.headerId;
    vm.currentState = $state.includes;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          tap((currentUnit) => {
            if (!currentUnit) {
              $state.go('units');
            }
            vm.currentUnit = currentUnit;
          }),
          switchMap(() => filterFactory.getFiltersResponse()),
          take(1)
        )
        .subscribe((filters) => {
          loadData(filters);
        });
    }

    function loadData(filters) {
      getInstaller();
      loadAnalogs();
      vm.filters = filters.filter((filter) => {
        const isInHeader = filter.pk.headerId === vm.currentHeader;
        const hasOldVersion = vm.currentUnit.version < +$rootScope.env.A4500_VERSION;
        return hasOldVersion ? isInHeader && +filter.pk.id === 1 : isInHeader;
      });
      vm.filters.forEach((filter, i) => {
        // initial calculation of filter subgroup 3 numFilters value
        processNumFilter(null, filter, 3, i + 1);
      });
    }

    function loadAnalogs() {
      sensorsFactory.analogsAll(vm.currentUnit.id).then(function (data) {
        vm.analogs = data;
      });
    }

    /**
     * Parses introduced values to be only numbres,
     * checks for num filter errors based on the sum
     * of all group numfilters. Also resolves subgroup 3 numfilter
     * value, since backend is not saving this value
     * @param ev keyboard event
     * @param filter filter source object
     * @param subgroup filter subgroup
     * @param group filter group
     * @returns
     */
    function processNumFilter(ev, filter, subgroup, group) {
      const value = ev && ev.detail.data;
      if (!value && ev && !ev.target.value) {
        return;
      }

      // if not a number, override input value to erase last character
      if (value && !value.match(/^\d+$/)) {
        setTimeout(() => {
          ev.target.value = +filter[subgroup] || ev.target.value[0];
        });
        return;
      }

      // helper that keeps filter source object updated according
      // input changes
      const getCurrentNumFilters = (changedGroup, group) => {
        if (changedGroup === group) {
          const currValue = ev.target.value || 0;
          filter[`numFiltersGroup${group}`] = +currValue;
        }
        return filter[`numFiltersGroup${group}`] || 0;
      };

      const numFiltersG1 = getCurrentNumFilters(+subgroup, 1);
      const numFiltersG2 = getCurrentNumFilters(+subgroup, 2);
      const totalFilters = numFiltersG1 + numFiltersG2;

      // assign error in case group filters are exceeding group numfilters
      vm[`numFiltersGroupErrors${group}`] =
        (totalFilters > filter.numFilters && $filter('translate')('filters.num_filters_exceeded')) || '';

      // in case of exist, assign remaining filters to numfiltersgroup 3
      if (totalFilters <= filter.numFilters) {
        const rest = filter.numFilters - totalFilters;
        filter.numFiltersGroup3 = rest;
      }

      vm.hasViewErrors = vm.numFiltersGroupErrors1 || vm.numFiltersGroupErrors2;
      // when ev is truthy we know user editing the form
      if (ev && !vm.hasViewErrors) {
        vm.form.$setDirty();
      } else {
        vm.form && vm.form.$setPristine();
      }
      $scope.$emit('hasViewErrors', vm.hasViewErrors);
    }

    function getInstaller() {
      filterFactory.getInstaller(vm.currentUnit.id).then(function (data) {
        vm.installer = data.plain();
      });
    }

    function save() {
      if (vm.hasViewErrors) {
        return;
      }

      if (vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          vm.filters.forEach((filter) => (filter.modifiedForGeneral = false));
          vm.installer.unittype = vm.currentUnit.type;
          filterFactory.saveHeaderFilter(vm.currentUnit.id, vm.currentHeader, vm.filters).then(() => {
            filterFactory.updateInstaller(vm.currentUnit.id, vm.installer).then(() => {
              vm.form.$setPristine();
            });
          });
        });
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
      $scope.$emit('headerGeneralFiltersUpdate', vm.installer.headerGeneralFilters);
    });
  }
})();
