(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('trays7Factory', trays7Factory);

  trays7Factory.$inject = ['Restangular', '$q'];

  function trays7Factory(Restangular, $q) {
    var op = {
      getTrays: getTrays,
      update: update,
      updateAll: updateAll,
      allTrays: allTrays,
    };
    return op;

    function getTrays(unitId, params) {
      var deferred = $q.defer();
      Restangular.one('units', unitId)
        .one('trays')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function allTrays(unitId) {
      var deferred = $q.defer();
      Restangular.one('units', unitId)
        .one('trays')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function update(tray) {
      var deferred = $q.defer();
      Restangular.one('units', tray.pk.deviceId)
        .one('trays', tray.pk.id)
        .customPOST(tray)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateAll(trays, deviceid) {
      var deferred = $q.defer();
      Restangular.one('units', deviceid)
        .one('trays')
        .customPOST(trays)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
