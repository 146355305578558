import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('others45configController', others45configController);

  others45configController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'configFactory',
    'filterFactory',

  ];

  function others45configController($rootScope, $scope, $state, configFactory, filterFactory) {
    var vm = this;
    vm.installer;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        getInstaller();
      });
    }

    function backup() {
      vm.installer_backup = {};
      angular.copy(vm.installer, vm.installer_backup);
    }

    function getInstaller() {
      filterFactory.getInstaller(vm.currentUnit.id).then(function (data) {
        vm.installer = data.plain();
        backup();
      });
    }

    function save() {
      vm.installer.unittype = vm.currentUnit.type;
      filterFactory.updateInstaller(vm.currentUnit.id, vm.installer).then(() => {
        //vm.installer = data;
        vm.form.$setPristine();

        backup();
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(vm.installer_backup, vm.installer);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e, vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
