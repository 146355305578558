import { getContainer } from '@vegga/front-store';
import { UNITS } from '../../utils/units.enum';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('gprsController', gprsController);

  gprsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'commFactory',
    'GPRS',
    'unitFactory',
  ];

  function gprsController($log, $rootScope, $scope, $state, Restangular, $mdDialog, UserData, commFactory, GPRS) {
    var vm = this;
    var backup;
    vm.save;
    vm.cancel;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.currentUnit = currentUnit;
      vm.gprs = GPRS;
      backup = angular.copy(vm.gprs);
      vm.gprs.pin = vm.gprs.pin || '0000';
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
    }

    function save() {
      vm.form.$setPristine();

      if (vm.currentUnit.type === UNITS.A_BIT) {
        vm.gprs.gprscall = false;
      }
      commFactory.updateGPRS(vm.currentUnit.id, vm.gprs).then((result) => {
        vm.gprs = result;
        backup = angular.copy(vm.gprs);
      });
      $rootScope.$broadcast('refresh', { message: 'refreshing' });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.gprs = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
