import singleSpaAngularJS from 'single-spa-angularjs';
import angular from 'angular';

import app from './main.html';

import './main/app';
import './states/app-states';

// import './styles/css/main.css';
import './styles/sass/main.scss';
import 'angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.min.css';
import 'angular-material/angular-material.css';

const AGRONIC_STYLESHEET_ID = 'agronic-styles';

// const domElementGetter = () => document.getElementById('home-app');

const link = document.createElement('link');
link.id = 'agronic-css';
link.rel = 'stylesheet';
link.href = 'http://localhost:7004/agronic.css';

let agronicStyle = undefined;

const ngLifecycles = singleSpaAngularJS({
  angular,
  // domElementGetter,
  mainAngularModule: 'agronicwebApp',
  uiRouter: true,
  preserveGlobal: false,
  template: '<div ui-view="" vegga-loader state-class autoscroll="false" id="container-ui-view"></div>',
});

export const { bootstrap } = ngLifecycles;
// export const { mount } = ngLifecycles;
// export const { unmount } = ngLifecycles;

export const mount = [
  async () => {
    if (agronicStyle) {
      agronicStyle.forEach((styleNode) => {
        document.head.appendChild(styleNode);
      });
      return;
    }
    agronicStyle = document.head.querySelectorAll(`#${AGRONIC_STYLESHEET_ID}`);
  },
  ngLifecycles.mount,
];
export const unmount = [
  async () => {
    document.head.querySelectorAll(`#${AGRONIC_STYLESHEET_ID}`).forEach((styleNode) => styleNode.remove());
  },
  ngLifecycles.unmount,
];

// export function mount(props) {
//   console.log('mount', props);
//   document.head.appendChild(link)
//   return ngLifecycles.mount(props);
// }

// export function unmount(props) {
//   link.parentNode.removeChild(link);
//   return ngLifecycles.mount(props);
// }

// export function bootstrap(props) {
//   return Promise.resolve().then(() => {
//     console.log('bootstrap', props);
//     console.log(ngLifecycles);
//     ngLifecycles.bootstrap;
//   });
// };
// export function mount(props) {
//   return Promise.resolve().then(() => {
//     console.log('mount', props);
//     console.log(ngLifecycles);
//     ngLifecycles.mount;
//   });
// }
// export function unmount(props) {
//   return Promise.resolve().then(() => {
//     console.log('unmount', props);
//     console.log(ngLifecycles);
//     ngLifecycles.unmount;
//   });
// }
