export const IO = {
  DIGITAL_IN: 'digitalin',
  DIGITAL_IN_5: 'digitalin5',
  DIGITAL_OUT: 'digitalout',
  DIGITAL_OUT_5: 'digitalout5',
  ANALOG_IN: 'analogin',
  ANALOG_IN_5: 'analogin5',
  ANALOG_OUT: 'analogout',
  ANALOG_OUT_5: 'analogout5',
};
