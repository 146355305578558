export const HISTORY_LABELS_ENUM = {
  area: 'history.area',
  avg: 'history.avg',
  avgCE: 'history.ceavg',
  avgCe: 'history.ceavg',
  avgPH: 'history.phavg',
  avgPh: 'history.phavg',
  ceavg: 'events.ceavg',
  from: 'history.fromto',
  desv: 'history.desc',
  desvFlow: 'history.desc',
  drainage: 'history.drainage',
  error: 'history.errors',
  errors: 'sensors.error',
  errorMax: 'history.errorOfMaximum2',
  errorMin: 'history.errorOfMinimum2',
  escape: 'history.out',
  evap: 'history.evap',
  fert1: 'history.f1',
  fert1L: 'history.f1l',
  fert2: 'history.f2',
  fert2L: 'history.f2l',
  fert3: 'history.f3',
  fert3L: 'history.f3l',
  fert4: 'history.f4',
  fert4L: 'history.f4l',
  fert5: 'history.f5',
  fert5L: 'history.f5l',
  fert6: 'history.f6',
  fert6L: 'history.f6l',
  fert7: 'history.f7',
  fert7L: 'history.f7l',
  fert8: 'history.f8',
  fert8L: 'history.f8l',
  filter: 'history.filt',
  flow: 'history.flow',
  group1: 'history.filter1',
  group2: 'history.filter2',
  group3: 'history.filter3',
  group4: 'history.filter4',
  header: 'sectors.header',
  irrigTime: 'pivot.irrigtime',
  kc: 'history.kc',
  max: 'history.max',
  min: 'history.min',
  mm: 'graphics.mm',
  phavg: 'history.phavg',
  prevFlow: 'history.prevflow',
  program: 'history.prog',
  programName: 'history.program',
  pump1: 'filters.pump1',
  pump2: 'filters.pump2',
  pump3: 'filters.pump3',
  pump4: 'filters.pump4',
  pump5: 'filters.pump5',
  pump6: 'filters.pump6',
  radiation: 'history.radiation',
  rain: 'history.rain',
  realFlow: 'history.realflow',
  sector: 'history.sector',
  name: 'history.sensorname',
  sensor: 'history.sensor',
  sensorError: 'sensors.error',
  sensorName: 'history.sensorname',
  starts: 'history.starts',
  sum: 'general.integration',
  temp: 'history.temp',
  time: 'history.time',
  tray: 'tray.tray',
  type: 'events.type',
  volume: 'history.volume',
  temperature: 'history.temp',
  sectorName: 'sectors.c3',
};
