const colors = require('../../resources/vegga.tokens.colors.json');
import { getContainer } from '@vegga/front-store';
import { mergeMap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapSectorStatus } from '../utils/enums/maps/maps-sector.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sectorMapController', sectorMapController);

  sectorMapController.$inject = ['$rootScope', '$scope', '$state', 'mapsFactory', '$timeout'];

  function sectorMapController($rootScope, $scope, $state, mapsFactory, $timeout) {
    let vm = $scope.$parent.vm;
    let props;
    vm.destroySectorArea$ = new Subject();
    vm.destroySectorStatus$ = new Subject();
    vm.destroySectorUnit$ = new Subject();

    activate();

    /*******************/

    function activate() {
      vm.mapsFacade = getContainer().resolve('mapsFacade');
      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area

      vm.editSect = false;
      vm.areaInfo = false;
      vm.cancelSector = cancel;
      vm.loadSectors = loadSectors;
      vm.saveSector = saveSector;
      vm.cancelSect = cancelSect;
      initSubscriptions();
    }

    function initSubscriptions() {
      vm.mapsFacade.mapSectorsArea$
        .pipe(
          mergeMap((mapSectorsArea) => {
            vm.sectorsInMap = mapSectorsArea;
            drawSectors(mapSectorsArea);
            return vm.mapsFacade.mapSectorsStatus$.pipe(take(1));
          }),
          takeUntil(vm.destroySectorArea$)
        )
        .subscribe((mapSectorStatus) => {
          if (mapSectorStatus.irrigationStatus && vm.areasLayer?.getLayers()) {
            fillSectorsColor(mapSectorStatus.irrigationStatus);
          } else if (mapSectorStatus.waterDisp && vm.areasLayer?.getLayers()) {
            fillWaterDispColor(mapSectorStatus.waterDisp);
          }
        });
    }

    function loadSectorsLayer() {
      if (vm.map.id) {
        vm.mapsFacade.loadMapSectorsArea(vm.map.id);
        vm.mapsFacade.loadMapSectorsStatus(vm.map.id);

        //code commented due new specifications, maybe  it will be used again in the future

        /*if (vm.filter.sectors.irrig) {
          vm.mapsFacade.loadMapSectorsStatus(vm.map.id);
        } else if (vm.filter.sectors.waterstate) {
          fillWaterStateColor();
        } else if (vm.filter.sectors.water) {
          vm.mapsFacade.loadMapSectorsWaterDisp(vm.map.id);
        }*/
      }
    }

    function drawSectors(mapSectorsArea) {
      vm.areasLayer.getLayers().forEach((s) => (s.toGeoJSON().features[0].properties.update = false));

      mapSectorsArea.forEach((sectorToUpdate) => {
        const sector = vm.areasLayer
          .getLayers()
          .find((s) => s.toGeoJSON()?.features[0]?.properties?.id === sectorToUpdate.id);

        if (sector) {
          updateSectorDrawed(sector, sectorToUpdate);
        } else {
          vm.areasLayer.addLayer(prepareSectorToDraw(sectorToUpdate));
        }
      });

      //remove deleted sectors drawed in map
      const removeSectors = vm.areasLayer.getLayers().filter((s) => !s.toGeoJSON()?.features[0]?.properties?.update);
      if (removeSectors?.length > 0) {
        removeSectors.forEach((s) => vm.areasLayer.removeLayer(s));
      }

      vm.map.addLayer(vm.areasLayer);
    }

    function updateSectorDrawed(sector, sectorToUpdate) {
      sector.toGeoJSON().features[0].properties.update = true;
      const { ...properties } = sectorToUpdate;

      sector.toGeoJSON().features[0].properties = properties;

      const stringPopup =
        '<div class="title-popup"><div class="img-logo"></div>' +
        sectorToUpdate.deviceName +
        '</div>' +
        '<div class="title-sector"><span>S' +
        sectorToUpdate.sectorId +
        '</span>' +
        '<span> | </span>' +
        '<span>' +
        sectorToUpdate.sectorName +
        '</span></div>';
      sector.setPopupContent(stringPopup);
      sector.getPopup().update();
    }

    function prepareSectorToDraw(area) {
      const { coordinates, ...properties } = area;
      properties.update = true;

      const geometry = {
        geometry: { coordinates: coordinates, type: 'Polygon' },
        properties: properties,
        type: 'Feature',
      };

      const geoJson = L.geoJson(geometry, {
        style: {
          fillColor: colors['vegga-color-primary-default'],
          opacity: 0.8,
          color: colors['vegga-color-primary-dark'],
          fillOpacity: 0.7,
          weight: 1,
        },
      });
      geoJson.on({
        mouseover: overAreaSector,
        mouseout: outAreaSector,
        click: areaInformation,
      });
      const stringPopup =
        '<div class="title-popup"><div class="img-logo"></div>' +
        area.deviceName +
        '</div>' +
        '<div class="title-sector"><span>S' +
        area.sectorId +
        '</span>' +
        '<span> | </span>' +
        '<span>' +
        area.sectorName +
        '</span></div>';
      const mypopup = L.popup({ closeButton: false, className: 'popup-map-sector' }).setContent(stringPopup);
      geoJson.bindPopup(mypopup);
      let styleProperties = {
        fillColor: 'var(--vegga-color-grey-400)',
        opacity: 0,
        color: 'var(--vegga-color-grey-700)',
        fillOpacity: 0,
        weight: 1,
      };
      geoJson.setStyle(styleProperties);

      return geoJson;
    }

    function fillSectorsColor(mapSectorsStatus) {
      vm.areasLayer.getLayers().forEach((area) => {
        const properties = area?.toGeoJSON()?.features[0]?.properties;
        if (properties) {
          const sectorStatus = mapSectorsStatus.find(
            (sector) => sector.deviceId === properties.deviceId && sector.sectorId === properties.sectorId
          );

          if (sectorStatus) {
            let styleProperties = {
              fillColor: 'var(--vegga-color-grey-400)',
              opacity: 1,
              color: 'var(--vegga-color-grey-700)',
              fillOpacity: 0.8,
              weight: 1,
            };

            if (sectorStatus.errorStatus === MapSectorStatus.ERROR) {
              styleProperties.fillColor = 'var(--vegga-color-red-teaberry-400)';
              styleProperties.color = 'var(--vegga-color-red-teaberry-700)';
            } else if (sectorStatus.manualStatus === MapSectorStatus.MANUAL) {
              styleProperties.fillColor = 'var(--vegga-color-violet-400)';
              styleProperties.color = 'var(--vegga-color-violet-700)';
            } else if (sectorStatus.status === MapSectorStatus.IRRIGATING) {
              styleProperties.fillColor = 'var(--vegga-color-blue-water-400)';
              styleProperties.color = 'var(--vegga-color-blue-water-700)';
            }
            area.setStyle(styleProperties);
          }
        }
      });
    }

    function fillWaterDispColor(mapSectorWaterDisp) {
      vm.areasLayer.getLayers().forEach((area) => {
        const properties = area?.toGeoJSON()?.features[0]?.properties;
        const sensor = mapSectorWaterDisp.find(
          (s) => s.deviceId === properties.deviceId && s.sectorId === properties.sectorId
        );

        if (sensor) {
          let styleProperties = {
            fillColor: 'var(--vegga-color-grey-400)',
            opacity: 1,
            color: 'var(--vegga-color-grey-700)',
            fillOpacity: 0.8,
            weight: 1,
          };

          if (!sensor.waterDispUserId) {
            area.setStyle(styleProperties);
            return;
          }

          if (sensor.currentValue > sensor.lComfortHigh) {
            styleProperties.fillColor = 'var(--vegga-color-blue-violet-400)';
            styleProperties.color = 'var(--vegga-color-blue-violet-900)';
          } else if (sensor.currentValue > sensor.lComfortMedium) {
            styleProperties.fillColor = 'var(--vegga-color-blue-violet-500)';
            styleProperties.color = 'var(--vegga-color-blue-violet-900)';
          } else if (sensor.currentValue > sensor.lComfortLow) {
            styleProperties.fillColor = 'var(--vegga-color-blue-violet-600)';
            styleProperties.color = 'var(--vegga-color-blue-violet-900)';
          } else if (sensor.currentValue > sensor.lDeficit) {
            styleProperties.fillColor = 'var(--vegga-color-blue-violet-700)';
            styleProperties.color = 'var(--vegga-color-blue-violet-900)';
          } else {
            styleProperties.fillColor = 'var(--vegga-color-red-teaberry-400)';
            styleProperties.color = 'var(--vegga-color-red-teaberry-700)';
          }

          area.setStyle(styleProperties);
        }
      });
    }

    /*function fillWaterStateColor() {
      vm.areasLayer.getLayers().forEach((area) => {
        const styleProperties = {
          fillColor: 'var(--vegga-color-blue-water-500)',
          opacity: 1,
          color: 'var(--vegga-color-blue-water-800)',
          fillOpacity: 0.8,
          weight: 1,
        };
        area.setStyle(styleProperties);
      });
    }*/

    function closeAreaInfo() {
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
      clearSectorSelection();
    }

    function cancel() {
      vm.currentSector = vm.selectedSector;
      vm.currentUnit = _.filter(vm.units, (o) => {
        return o.id === Number(vm.currentSector.deviceId);
      })[0];
      vm.actionView = 0;
    }

    function saveSector() {
      vm.newSector.remove();

      mapsFactory.prepareGEOJsonLayer(vm.newSector, vm.unit, vm.sector, vm.map).then((result) => {
        let geoJsonLayer = result;
        vm.newSector.toggleEdit();
        geoJsonLayer.on({
          mouseover: overAreaSector,
          mouseout: outAreaSector,
          click: areaInformation,
        });
        vm.unit = null;
        vm.sector = null;
        reloadSectors();
        $scope.$emit('completedSave');
        $rootScope.$broadcast('reload', { message: 'refreshing' });
      });
    }

    function cancelSect() {
      vm.newSector.toggleEdit();
      vm.newSector.remove();
      vm.editSect = false;
    }

    function loadSectorDetails(obj) {
      if (vm.isCreatingMarker) return;
      props = obj;
      vm.currentUnit = _.filter(vm.units, (o) => {
        return o.id === Number(obj.properties.deviceId);
      })[0];
      vm.waterHistory = false;
      vm.waterValue = '-';
      if (vm.currentUnit) {
        vm.equipoInfo = false;
        vm.moduleInfo = false;
        vm.sensorInfo = false;
        vm.actionView = 0;

        $scope.$emit('editSectionChangeSector', {
          sectorId: obj.properties.sectorId,
          currentUnit: vm.currentUnit,
          selectedAreaId: obj.properties.id,
          sectorName: obj.properties.sectorName,
          elementsInMap: vm.sectorsInMap,
        });
        $state.go(
          'maps.sector',
          {
            unit: vm.currentUnit,
            sectorId: obj.properties.sectorId,
            map: vm.map,
            layerId: obj.properties.id,
            elementsInMap: vm.sectorsInMap,
            cropId: obj.properties.cropId,
          },
          { reload: 'maps.sector' }
        );
        vm.overlay.show();
      } else {
        //sin acceso al equipo
        vm.areaInfo = true;
        vm.equipoInfo = false;
        vm.moduleInfo = false;
        vm.sensorInfo = false;
        vm.actionView = 0;
      }
    }

    function clearSectorSelection() {
      vm.areasLayer.eachLayer(function (l) {
        l.setStyle({ color: 'white', weight: 1 });
      });
    }

    function loadSectors(unit) {
      vm.areSectorsLoading = true;
      mapsFactory.loadSectors(unit.id).then((sectors) => {
        unit.sectors = sectors;
        vm.sector = sectors[0].id;
        vm.sectorId = null;
        vm.areSectorsLoading = false;
      });
    }

    function overAreaSector() {
      if (!vm.areaInfo && !vm.irrigInfo) {
        this.openPopup();
      }
    }

    function outAreaSector() {
      if (!vm.areaInfo && !vm.irrigInfo) {
        this.closePopup();
      }
    }

    function areaInformation(event) {
      vm.selectedAreaId = _.get(event, 'layer.feature.properties.sector') || event;
      this.closePopup();

      $timeout(() => {
        vm.map.invalidateSize();
        const overlay = document.getElementById('mapToolsOverlay');
        const overlayWidth = overlay.shadowRoot.querySelector('.vegga-overlay__container').offsetWidth / 2;

        const offset = [-Math.abs(overlayWidth), 0]; // Desplazamiento en píxeles
        const targetPoint = vm.map.project(this.getBounds().getCenter(), vm.map.getZoom()).subtract(offset);
        const targetLatLng = vm.map.unproject(targetPoint, vm.map.getZoom());
        vm.map.setView(targetLatLng, vm.map.getZoom());
      }, 200);
      const geoJSONObject = this.toGeoJSON();
      vm.currentArea = this._leaflet_id;
      loadSectorDetails(geoJSONObject.features[0]);
    }

    function reloadSectors() {
      loadSectorsLayer();
    }

    $scope.$on('editAreaFromSectorLayer', () => {
      vm.editSect = true;
    });

    $scope.$on('deleteSectorArea', () => {
      mapsFactory.deleteArea(props.properties.mapId, props.properties.id).then(() => {
        vm.areasLayer.removeLayer(vm.currentArea);
        vm.overlay.dismiss();
        vm.unit = null;
        vm.sector = null;
        $scope.$emit('completedSave');
        reloadSectors();
        closeAreaInfo();
      });
    });

    $scope.$on('closeSector', () => {
      closeAreaInfo();
    });

    $scope.$on('reloadMap', () => {});

    $scope.$on('loadSectorsLayer', () => {
      if (vm.filter.sectors.active) {
        vm.mapsFacade.clearMapSectorsStatus();
        loadSectorsLayer();
      }
    });

    $scope.$on('createMarker', () => {
      vm.isCreatingMarker = true;
    });

    $scope.$on('createdMarker', () => {
      vm.isCreatingMarker = false;
    });

    $scope.$on('loadSectorData', () => {});

    $scope.$on('$destroy', function () {
      vm.destroySectorArea$.next();
      vm.destroySectorArea$.complete();
      vm.destroySectorStatus$.next();
      vm.destroySectorStatus$.complete();
      vm.destroySectorUnit$.next();
      vm.destroySectorUnit$.complete();
      vm.mapsFacade.clearMapSectors();
    });
  }
})();
