import moment from 'moment/moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('calcsWaterController', calcsWaterController);

  calcsWaterController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$filter',
    'unitFactory',
    '$confirm',
  ];

  function calcsWaterController(
    $scope,
    $state,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $filter,
    unitFactory,
    $confirm
  ) {
    var vm = this;
    vm.activeList;
    var unitsLoaded;
    var update;
    vm.lConfortA = 140;
    vm.lDeficit = 0;
    vm.destroy$ = new Subject();
    vm.alertType = 'warning';
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      update = false;
      unitsLoaded = [];
      vm.sanalogs = {};

      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICM$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
        vm.unitsLoaded = true;
      });

      if ($state.params.sensor !== null) {
        vm.waterDisp = $state.params.sensor;
        vm.awList = $state.params.list;
        if (vm.waterDisp.recalcDate) {
          vm.date = vm.waterDisp.recalcDate;
        }
        loadSensorsConfig(vm.waterDisp);
      }

      vm.maxDate = moment().format('DD/MM/yyyy');

      vm.water_backup = {};
      angular.copy(vm.waterDisp, vm.water_backup);

      vm.nextSensor = nextSensor;
      vm.lastSensor = lastSensor;
      vm.selectSensor = selectSensor;
      vm.selectSensorPage = selectSensorPage;
      vm.loadSAnalogs = loadSAnalogs;
      vm.removeSensor = removeSensor;
      vm.addNewSensor = addNewSensor;
      vm.addAnalogSensor = addAnalogSensor;
      vm.save = save;
      vm.prepareLegend = prepareLegend;
      vm.updateRecalc = updateRecalc;
      vm.cancel = cancel;
      vm.setDate = setDate;
      vm.changeLegend = changeLegend;
    }

    function loadSensorsConfig(sensor) {
      vm.empty = false;
      vm.waterDisp = sensor;
      vm.reloadedGraph = true;
      selectSensor();
    }

    function addNewSensor() {
      calculatedsFactory.newWater(UserData.id).then((data) => {
        vm.awList = data.plain();
        loadSensorsConfig(_.last(vm.awList));
      });
    }

    function selectSensor() {
      vm.reloadedGraph = false;
      if (vm.waterDisp.sensors !== undefined) {
        vm.sensors_water = vm.waterDisp.sensors;
        vm.sensors_water.forEach((sensor) => {
          loadSAnalogs(sensor.deviceId);
        });
      }
    }

    function changeLegend() {
      vm.warningMessage = null;

      vm.form.$setDirty();
      const legendValues = [
        vm.waterDisp.lConfortA,
        vm.waterDisp.lConfortM,
        vm.waterDisp.lConfortB,
        vm.waterDisp.lDeficit,
      ];
      const sortedLegendValues = [
        vm.waterDisp.lConfortA,
        vm.waterDisp.lConfortM,
        vm.waterDisp.lConfortB,
        vm.waterDisp.lDeficit,
      ].sort((a, b) => b - a);

      if (!legendValues.every((val, i) => val === sortedLegendValues[i])) {
        vm.warningMessage = $filter('translate')('calcs.alerts.unsorted');
        vm.form.$setPristine();
        return;
      }

      if (legendValues.some((val) => val < 0)) {
        vm.warningMessage = $filter('translate')('calcs.alerts.negative');
        vm.form.$setPristine();
        return;
      }

      if (legendValues.filter((val) => val > 139).length) {
        vm.warningMessage = $filter('translate')('calcs.alerts.max_value_exceed');
        vm.form.$setPristine();
        return;
      }
    }

    function prepareLegend() {
      if (
        vm.waterDisp !== null &&
        (vm.waterDisp.lConfortA === null ||
          vm.waterDisp.lConfortB === null ||
          vm.waterDisp.lConfortM === null ||
          vm.waterDisp.lDeficit === null)
      ) {
        let rp = Number(vm.waterDisp.cicles[0].recargaPoint);
        let tmp = 100 - rp;
        if (tmp > 0) {
          tmp = Number((tmp / 3).toFixed(2));
          vm.waterDisp.lConfortA = 100;
          vm.waterDisp.lConfortB = rp + tmp;
          vm.waterDisp.lConfortM = vm.waterDisp.lConfortB + tmp;
          vm.waterDisp.lDeficit = rp;
          vm.form.$setDirty();
        }
      }
    }

    function selectSensorPage(sensor) {
      loadSensorsConfig(sensor);
    }

    function removeSensor(index) {
      if (!vm.sensors_water[index]) return;

      vm.form.$setDirty();
      if (vm.sensors_water?.[index]?.newAdded) {
        vm.sensors_water.splice(index, 1);
      } else {
        calculatedsFactory.deleteWaterSensor(vm.waterDisp.pk.userId, vm.waterDisp.pk.id, index + 1).then(() => {
          vm.sensors_water.splice(index, 1);
        });
      }
    }

    function loadSAnalogs(deviceId, index) {
      if (index) {
        vm.sensors_water[index].analogId = 0;
      }
      if (deviceId !== null) {
        if (!unitsLoaded.includes(deviceId)) {
          unitsLoaded.push(deviceId);
          sensorsFactory.analogsactive(deviceId, true).then((response) => {
            vm.reloadedGraph = true;
            vm.sanalogs[deviceId] = response.plain();
          });
        } else {
          $timeout(() => {
            vm.reloadedGraph = true;
          }, 500);
        }
      }
    }

    function addAnalogSensor() {
      var id = vm.sensors_water.length + 1;

      if (vm.sensors_water.length < 8) {
        vm.form.$setDirty();
        vm.sensors_water.push({
          pk: {
            userId: vm.waterDisp.pk.userId,
            sensorId: vm.waterDisp.pk.id,
            id: id,
          },
          name: null,
          deviceId: null,
          analogId: null,
          graficColor: null,
          showGrafic: null,
          calc: null,
          point100: null,
          point0: null,
          creationDate: null,
          modificationDate: null,
          xStatusOrigin: null,
          xValueOrigin: null,
          xDateOrigin: null,
          xStatus: null,
          xValue: null,
          newAdded: true,
        });
      }
    }

    function lastSensor(wd) {
      var page = _.findIndex(vm.awList, (o) => {
        return o.pk.id === (wd === undefined ? vm.waterDisp.pk.id : wd.pk.id);
      });
      if (page >= 0) {
        var s;
        --page;
        if (page < 0) {
          s = _.last(vm.awList);
        } else {
          s = vm.awList[page];
        }
        if (!(s.shared && s.sharingLvl === 0)) {
          loadSensorsConfig(s);
        } else {
          lastSensor(s);
        }
      }
    }

    function nextSensor(wd) {
      var page = _.findIndex(vm.awList, (o) => {
        return o.pk.id === (wd === undefined ? vm.waterDisp.pk.id : wd.pk.id);
      });
      if (page >= 0) {
        var s;
        ++page;
        if (page >= vm.awList.length) {
          s = _.first(vm.awList);
        } else {
          s = vm.awList[page];
        }
        if (!(s.shared && s.sharingLvl === 0)) {
          loadSensorsConfig(s);
        } else {
          nextSensor(s);
        }
      }
    }

    function updateRecalc($event) {
      update = $event.detail.checked;
      if (vm.waterDisp.recalcDate === null) {
        vm.waterDisp.recalcDate = new Date();
      }
    }

    function save(creating) {
      if (vm.waterDisp.recalc) {
        vm.alertType = 'error';
        return;
      }
      if (!update) {
        $confirm({
          title: $filter('translate')('calcs.alerts.no_recalculation.title'),
          text: $filter('translate')('calcs.alerts.no_recalculation.body'),
        }).then(() => {
          updateWaterDisp(creating);
        });
      } else {
        updateWaterDisp(creating);
      }
    }

    function updateWaterDisp(creating) {
      vm.waterDisp.sensors = vm.sensors_water;
      if (!vm.waterDisp.sharedList) {
        vm.waterDisp.sharedList = [];
      }
      if (vm.recalc && !vm.date) {
        vm.dateErrorMessage = $filter('translate')('calcs.errors.no_selected_date');
        return;
      }

      if (update) {
        vm.waterDisp.recalc = vm.recalc;
        update = false;
      }

      vm.waterDisp.recalcDate = vm.date;
      vm.form.$setPristine();

      calculatedsFactory.updateWater(vm.waterDisp.pk.userId, vm.waterDisp).then((response) => {
        $scope.$emit('updateWaterList', response.plain());
        if (creating) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
        }
      });
    }

    function setDate($event) {
      vm.waterDisp.recalcDate = $event.detail;
      vm.date = $event.detail;
      vm.dateErrorMessage = null;
      vm.form.$setDirty();
    }

    function cancel() {
      angular.copy(vm.water_backup, vm.waterDisp);
      vm.form.$setPristine();
    }

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('saveWater', (_e, args) => {
      const { creating } = args;
      save(creating);
    });

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('cancelEditWater', () => {
      cancel();
    });

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formWaterUpdated', vm.form);
      }
    );

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
