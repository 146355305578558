import { getContainer } from '@vegga/front-store';
import { UNITS } from '../utils/units.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('commController', commController);

  commController.$inject = ['$scope', '$state', '$confirm', '$filter', 'unitFactory', 'veggaModalConfirmService'];

  function commController($scope, $state, $confirm, $filter, unitFactory, veggaModalConfirmService) {
    var vm = this;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
      vm.currentState = $state.includes;
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.currentUnit = currentUnit;
      vm.changeState = changeState;
    }

    function changeState(location, params) {
      if (vm.form && vm.form.$dirty) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('programs.edit.cancelq'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
              vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
              vm.form = null;
              $state.go(location, params); //Canviem d'estat
            }
          });
      } else {
        $state.go(location, params);
      }
    }

    $scope.$on('refresh', function () {});

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
      $scope.$emit('formToUnitUpdated', vm.form);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
