(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('managerController', managerController);

  managerController.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'unitFactory',
    'userFactory',
    'UserData',
  ];

  function managerController($log, $scope, $state, Restangular, $mdDialog, unitFactory, userFactory, UserData) {
    var vm = this;
    vm.newUser;
    vm.state;
    vm.goParent;
    vm.unitsType = [
      { id: 2, name: 'A-4000' },
      { id: 3, name: 'A-2500' },
      { id: 4, name: 'A-BIT' },
      { id: 5, name: 'A-7000' },
      { id: 6, name: 'A-5500' },
    ];
    activate();

    function activate() {
      vm.state = $state;
      vm.goParent = goParent;
      vm.newUser = newUser;
      vm.regEmail =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      vm.regLogin = /^[a-zA-Z0-9]*$/;
      vm.regName = /^[a-zA-Z0-9ñÑ\-_,. \s]*$/;
      vm.user = UserData;
      loadSubUsers();
    }

    function loadSubUsers(load) {
      userFactory.subusers(UserData.id, true).then(function (response) {
        vm.subusers = response;
        checkLimit();
        let user;
        if (load) user = _.find(vm.subusers, { id: load });
        if (user !== undefined) {
          $state.go('user', { user: user });
        } else {
          $state.go('user', { user: UserData });
        }
      });
    }

    function checkLimit() {
      if (UserData.userDevice) {
        var devices = UserData.userDevice.length;
        var length = vm.subusers.length;
        vm.newUserLimit = devices + 5 <= length;
      }
    }

    function newUser() {
      $state.go('new');
    }
    function goParent() {
      $state.go('manager');
    }

    $scope.$on('refresh-users', function (event, args) {
      if (args.load) {
        loadSubUsers(args.load);
      }
    });
  }
})();
