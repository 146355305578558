// (function() {
// 'use strict';

import moment from 'moment/moment';
import { take } from 'rxjs/operators';
import { showLoader, hideLoader } from '@vegga/front-utils';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';

const EXCLUDES_STATES = ['agroclimatic'];

angular
  .module('agronicwebApp')

  //TODO - check moment
  .constant('moment', moment)

  .controller('navController', navController);

navController.$inject = [
  '$log',
  '$scope',
  '$rootScope',
  '$state',
  'UserData',
  'userFactory',
  'resFactory',
  'unitFactory',
  'refreshFactory',
  '$timeout',
  '$mdToast',
  '$translate',
  '$mdDialog',
  '$anchorScroll',
  'graphicsFactory',
  '$confirm',
  '$q',
  '$stateParams',
  '$location',
];

function navController(
  $log,
  $scope,
  $rootScope,
  $state,
  UserData,
  userFactory,
  resFactory,
  unitFactory,
  refreshFactory,
  $timeout,
  $mdToast,
  $translate,
  $mdDialog,
  $anchorScroll,
  graphicsFactory,
  $confirm,
  $q,
  $stateParams,
  $location
) {
  var vm = this;
  vm.logoutExit;
  vm.devicesArray;
  vm.returnType;
  vm.selectUnit;
  vm.selected;
  vm.currentUnit;
  vm.menu;
  vm.notifications;
  vm.loadState;
  vm.newDay;
  vm.alertsMessages;
  var stopped;
  var toogledConfig;
  var eextra = false;
  var irridesk = false;
  var cuaderno = false;
  var _status, _irrigation;
  vm.pendingRequests = [];

  /*vm.unitsType = [
    { id: 2, name: 'A-4000' },
    { id: 3, name: 'A-2500' },
    { id: 4, name: 'A-BIT' },
    { id: 5, name: 'A-7000' },
    { id: 6, name: 'A-5500' },
  ];*/

  activate();

  function activate() {
    vm.currentState = checkStateInclude;
    $log.debug('navController loading');
    vm.profesional = UserData.profesional;
    vm.logoutExit = logoutExit;
    vm.selected = selected;
    vm.countdown = countdown;
    vm.stop = stop;
    vm.restart = restart;
    vm.openMenu = openMenu;
    vm.notifications = [];
    vm.loadMenuConfig = loadMenuConfig;
    vm.loadMenuView = loadMenuView;
    vm.loadState = loadState;
    vm.unitHour = unitHour;
    vm.selectedName = null;
    vm.useLang = useLang;
    vm.openModal = openModal;
    vm.checkClassStatus = checkClassStatus;
    vm.returnType = returnType;
    vm.selectUnit = selectUnit;
    vm.userHasExtra = userHasExtra;
    vm.userHasIrridesk = userHasIrridesk;
    vm.userHasCuaderno = userHasCuaderno;
    vm.alertsMessages = [];
    $rootScope.restart = restart;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    if ($state.current.name === 'crops' || $state.current.name === 'maps') {
      loadUnits($state.current.name === 'maps' ? $state.current.name : '');
    } else {
      loadUnits($state.current.name);
    }

    vm.refreshValue = $rootScope.env.REFRESH_COUNTDOWN_VALUE || 30;
    vm.active = 'view';
    vm.counter = vm.refreshValue;
    stopped = null;
    countdown();

    toogledConfig = true;
    vm.lang = $translate.use();
    eextra = _.includes(UserData.authorities, 'EEXTRA');
    irridesk = _.includes(UserData.authorities, 'EXPERT_IRRIDESK');
    cuaderno = _.includes(UserData.authorities, 'CCUSER');

    unitFactory
      .getUnitResponse()
      .pipe(take(1))
      .subscribe((unit) => {
        vm.currentUnit = unit;
      });

    // subscribe to app pending requests
    refreshFactory.getPendingRequests$().subscribe((pendingRequests) => {
      const hasPendingReq = !!pendingRequests.length;
      // refresh doesn't show skeleton nor loader
      if (refreshFactory.isRefreshing()) {
        return;
      }

      // set correct loader & skeleton status according pending requests lengths
      refreshFactory.setSkeletonStatus(hasPendingReq);
      if (hasPendingReq) {
        showLoader();
      } else {
        hideLoader();
      }
    });
  }

  function checkStateInclude(stateName) {
    if (!$state.includes(stateName)) {
      if (stateName.includes('.detail') || stateName.includes('.edit') || stateName.includes('.config')) {
        stateName = stateName.split('.')[0];
      }
    }

    return $state.includes(stateName);
  }

  function useLang(lang) {
    $translate.use(lang);
    vm.lang = lang;
    $rootScope.$broadcast('lang-update', { lang: lang });
    vm.showsubmenu = false;
  }

  function openModal() {
    $('#myModal').modal('show');
  }

  function openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  function countdown() {
    stopped = $timeout(function () {
      if (UserData.authenticated) {
        vm.counter--;
      }
      if (vm.counter < 0 && !$state.current.name.includes(EXCLUDES_STATES)) {
        vm.counter = vm.refreshValue;
        $rootScope.isRefreshingApp = true;

        // new refresh for programs, filtered when outside programs
        // in unit-controller
        refreshFactory.restartRefreshCountdown();

        // activate old refresh only when outside programs & units
        // const currentNameList = [
        //   'program',
        //   'communications',
        //   'outputs',
        //   'pivot',
        //   'module',
        //   'totals',
        //   'fertilizer',
        //   'history',
        //   'conditioner',
        //   'a45others',
        //   'sectorsgroup',
        //   'nebulization',
        //   'tray',
        // ];
        if (
          $state.current.name !== 'units'
          // && $state.current.parent !== 'unitV2' &&
          // !currentNameList.some((name) => $state.current.name.includes(`${name}.`))
        ) {
          const unit = unitFactory.getUnitValue();
          const units = unitFactory.getUnitsValue();
          $scope.$broadcast('refresh', { message: 'refreshing', unit, units });
        }

        vm.countdown();
      } else {
        vm.countdown();
      }
    }, 1000);
  }

  function stop() {
    $timeout.cancel(stopped);
    stopped = null;
  }
  function restart() {
    vm.counter = vm.refreshValue;
  }

  function selected(unit) {
    vm.selectedName = unit.name;
    _status = unit.status;
    _irrigation = unit.irrigation;

    vm.devicesFacade.currentDevice = unit;
    vm.currentUnit = unit;
    $state.go('unit', { unitId: unit.id });

    $('#myModal').modal('hide');
  }

  function checkClassStatus() {
    if (_status === 'ok') {
      return _irrigation ? 'irrig' : 'nirrig';
    } else {
      return _status;
    }
  }
  function loadState(item) {
    if ($state.current.parent === item.state && toogledConfig) {
      return;
    } else {
      toogledConfig = true;
      $state.go(item.state, { unit: vm.currentUnit, config: item.config });
    }
  }

  function prepareMenuBasic() {
    if (!vm.currentUnit.inoptions.plus) {
      //BASIC
      vm.menu[6].disabled = true; // Disable Conditioners
    }
    if (!vm.currentUnit.inoptions.pivots) {
      //BASIC
      vm.menu[11].disabled = true; // Disable Pivots
    }
  }

  function loadMenuConfig() {
    vm.menu = [];
    vm.active = 'config';
    toogledConfig = false;
    resFactory.menuconf(vm.currentUnit.type).then(function (response) {
      vm.menu = response;
      vm.config = true;
      if (vm.currentUnit.type === UNITS.A_2500 && !vm.currentUnit.inoptions.plus) {
        //BASIC{
        vm.menu[4].disabled = true; // Disable Sensors
        vm.menu[5].disabled = true; // Disable Conditioners
        vm.menu[6].disabled = true; // Disable Pivots
        vm.menu[7].disabled = true; // Disable Riego Solar
      }
    });
  }

  function loadMenuView() {
    vm.menu = [];
    vm.active = 'view';
    toogledConfig = false;
    resFactory.menu(vm.currentUnit.type).then(function (response) {
      vm.menu = response;
      if (vm.currentUnit.type === UNITS.A_2500) prepareMenuBasic();
      vm.config = false;
    });
  }

  function unitHour() {
    if (vm.currentUnit && vm.currentUnit.ram && vm.currentUnit.ram.date) {
      let dayWeek;
      dayWeek = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').day();
      switch (dayWeek) {
        case 1:
          vm.newDay = '_mon';
          break;
        case 2:
          vm.newDay = '_tue';
          break;
        case 3:
          vm.newDay = '_wed';
          break;
        case 4:
          vm.newDay = '_thu';
          break;
        case 5:
          vm.newDay = '_fri';
          break;
        case 6:
          vm.newDay = '_sat';
          break;
        default:
          vm.newDay = '_sun';
          break;
      }
      return moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
    } else {
      return '--:--';
    }
  }

  function logout() {
    return $.ajax({
      data: '',
      timeout: 1000,
      type: 'GET',
      url: 'logout',
    });
  }

  function loadUnits(state) {
    // $rootScope.isRefreshingApp = true;
    vm.currentUnit = {};
    vm.notifications = [];

    // clearing response every time units is called
    // to avoid wrong units on changing user
    vm.devicesFacade.devicesICMResponse.clear();
    if ($location.path().includes('/unit/')) {
      vm.devicesFacade.loadICMDevices(UserData.id, true);
    }
    vm.devicesFacade.devicesICM$.pipe(take(1)).subscribe((units) => {
      // if no id present in url, return same currentUnit since it is refreshing old way,
      // keep it until old refresh is completely removed
      vm.currentUnit = units.find((unit) => unit.id === +$stateParams.unitId) || vm.currentUnit;
      vm.devicesFacade.currentDevice = vm.currentUnit;
      if (state) $state.go(state, { units: vm.devicesArray });
      if ($state.includes('malfunctions')) $state.go('malfunctions', { units });
    });
  }

  function refreshData(callback) {
    $rootScope.isRefreshingApp = true;
    vm.notifications = [];
    if (vm.currentUnit.id) {
      var params = { add: ['format'] };
      unitFactory.getUnitById(vm.currentUnit.id, params).subscribe(
        function (data) {
          if (!data) {
            return;
          }

          $rootScope.isRefreshingApp = false;

          loadUnits();

          vm.currentUnit = data.plain();

          if (vm.currentUnit.type === 3 || vm.currentUnit.type === 4 || vm.currentUnit.type === 6) {
            var options = vm.currentUnit.inoptions[0];
            vm.currentUnit.inoptions = options;
          }

          vm.currentUnit.selected = true;

          if (callback) {
            callback || callback();
          } else {
            $scope.$broadcast('refresh', { message: 'refreshing', unit: vm.currentUnit, units: vm.devicesArray });
          }
        },
        function () {
          //TODO
          $scope.$broadcast('refresh', { message: 'refreshing' });
        }
      );
    } else {
      vm.currentUnit = {};
      unitFactory.getUnits(UserData.id, true).subscribe(function (response) {
        vm.devicesArray = response;

        if (callback) {
          callback || callback();
        } else {
          $scope.$broadcast('refresh', { message: 'refreshing', unit: vm.currentUnit, units: vm.devicesArray });
        }
      });
    }
    //        vm.countdown();
  }

  function logoutExit() {
    logout().done(() => {
      $rootScope.user = undefined;
      stop();
      graphicsFactory.clearGraphics();
      $state.go('login', { reload: true });
    });
  }

  /**
   *
   * Events listeners
   *
   **/

  $rootScope.$on('$stateChangeStart', (_event, toState) => {
    $anchorScroll();
    if (toState.name.includes('.config') && !vm.config) {
      // loadMenuConfig();
    } else if ((toState.name.includes('.detail') || toState.name.includes('.edit')) && vm.config) {
      loadMenuView();
    }
  });

  $scope.$on('reload', function () {
    vm.counter = vm.refreshValue;
    refreshData();
  });

  $scope.$on('reloadUnits', function (_event, args) {
    vm.currentUnit = args.unit;
    var callback;
    callback = function () {
      $state.go('malfunctions', { units: vm.devicesArray });
    };
    vm.counter = vm.refreshValue;
    refreshData(callback);
  });

  $scope.$on('selectUnit', function (_event, args) {
    if (args.unit.connected === false) {
      if ($('#myModal')) $('#myModal').modal('hide');
      $confirm({
        text: 'Quiere seleccionar el equipo?',
        title: 'Este equipo esta desconectado',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        if (args.unit) {
          vm.selectectedUnit = args.unit;
          vm.selected(args.unit);
        }
        if (args.callback) {
          args.callback();
        }
      });
    } else {
      if (args.unit) {
        vm.selectectedUnit = args.unit;
        vm.selected(args.unit);
      }
      if (args.callback) {
        args.callback();
      }
    }
  });

  $rootScope.$on('cfpLoadingBar:loading', function () {
    vm.loadingbar = true; //Loading spiner
  });

  $scope.$on('cfpLoadingBar:loaded', function () {
    vm.loadingbar = false;
  });

  $scope.$on('refresh-format', function (_event, args) {
    vm.currentUnit = args.unit;
  });

  /* functions for modal selector of units --> copied to units-controller.js*/
  function selectUnit(obj) {
    if (!obj.connect) {
      if ($('#myModal')) $('#myModal').modal('hide');
      $confirm({
        text: 'Quiere seleccionar el equipo?',
        title: 'Este equipo esta desconectado',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var callback = function () {
          $scope.$emit('selectUnit', { message: 'reload', unit: obj });
        };
        validateOperation(obj, callback);
      });
    } else {
      var callback = function () {
        $scope.$emit('selectUnit', { message: 'reload', unit: obj });
      };
      validateOperation(obj, callback);
    }
  }

  function returnType(type) {
    switch (type) {
      case 2:
        return 'A-4000';
      case 3:
        return 'A-2500';
      case 4:
        return 'A-BIT';
      case 5:
        return 'A-7000';
      case 6:
        return 'A-5500';
    }
  }

  function validateOperation(obj, callback) {
    /* if(obj.type === 2 || obj.type === 3 || obj.type === 4 || obj.type === 6){ */
    if (obj.connected) {
      callback(); // $scope.$emit('selectUnit',{message:'reload',unit:obj});
    } else {
      callback();
    }
  }
  function userHasExtra() {
    return eextra;
  }
  function userHasIrridesk() {
    return irridesk;
  }

  function userHasCuaderno() {
    return cuaderno;
  }
}
