import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { MapSensorStatus } from '../../utils/enums/maps/maps-sensor-status.enum';
import { MapSensorType } from '../../utils/enums/maps/maps-sensor-type.enum';
import { MapSensorFormatStatus } from '../../utils/enums/maps/maps-sensor-format-status.enum';
import { UNITS_OF_MEASUREMENT_ENUM } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('sensorLayerController', sensorLayerController);

  sensorLayerController.$inject = ['$rootScope', '$scope', '$state', 'sensorsFactory', 'graphicsFactory', '$filter'];

  function sensorLayerController($rootScope, $scope, $state, sensorsFactory, graphicsFactory, $filter) {
    let vm = this;

    let datos_grafica = {};
    activate();

    /*******************/

    function activate() {
      if (!$state.params.sensor) {
        $state.go('^');
        return;
      }

      vm.deleteSensorMarker = deleteSensorMarker;
      vm.closeSensorInfo = closeSensorInfo;
      vm.sensorHistory = sensorHistory;
      vm.advanceWeek = advanceWeek;
      vm.delayWeek = delayWeek;
      vm.history_type = 0;
      vm.checkStatus = checkStatus;
      vm.loadHistoryData = loadHistoryData;
      vm.actionView = 0;
      vm.addWeekButton = true;
      vm.mapSensorStatus = MapSensorStatus;
      vm.mapSensorType = MapSensorType;
      vm.mapSensorFormatStatus = MapSensorFormatStatus;

      vm.dateFrom = new moment().subtract(7, 'days')._d;
      vm.dateTo = new moment()._d;
      vm.dateFromFormat = new moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = new moment(vm.dateTo).format('DD-MM-YYYY');
      vm.units = $state.params.units || [];
      vm.unitsEnum = UNITS;

      if ($state.params.sensor !== null) {
        vm.sensor = $state.params.sensor;
        vm.sensor.units = UNITS_OF_MEASUREMENT_ENUM[vm.sensor.units]?.SYMBOL ?? vm.sensor.units;
        vm.sensor.xValue = vm.sensor.value;
        vm.currentUnit = $state.params.unit;
        vm.shared = $state.params.map.shared;
        vm.sharingLvl = $state.params.map.sharingLvl;
        vm.type = $state.params.type;
        if (vm.currentUnit !== undefined && vm.currentUnit !== null) {
          loadHistoryData();
        }
      }
    }

    function advanceWeek() {
      let today = new moment().format('DD-MM-YYYY');

      vm.dateFrom = new moment(vm.dateFrom).add(7, 'days')._d;
      vm.dateTo = new moment(vm.dateTo).add(7, 'days')._d;

      vm.dateFromFormat = new moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = new moment(vm.dateTo).format('DD-MM-YYYY');

      if (vm.dateToFormat !== today) vm.addWeekButton = false;
      else vm.addWeekButton = true;
      sensorHistory();
    }

    function delayWeek() {
      let today = new moment().format('DD-MM-YYYY');

      vm.dateFrom = new moment(vm.dateFrom).subtract(7, 'days')._d;
      vm.dateTo = new moment(vm.dateTo).subtract(7, 'days')._d;

      vm.dateFromFormat = new moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = new moment(vm.dateTo).format('DD-MM-YYYY');

      if (vm.dateToFormat !== today) vm.addWeekButton = false;
      sensorHistory();
    }

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }

    function getRangeColor(caption, value) {
      const rangeColors = {
        3: getRangeColorFor3Levels,
        5: getRangeColorFor5Levels,
      };

      return rangeColors[caption.numLvl](caption, value);
    }

    function getRangeColorFor3Levels(caption, value) {
      if (value > caption.range1from && value <= caption.range1to) {
        return caption.color1;
      }
      if (value > caption.range2from && value <= caption.range2to) {
        return caption.color2;
      }
      if (value > caption.range3from && value <= caption.range3to) {
        return caption.color3;
      }
      if (value <= caption.range1from) {
        return caption.color1;
      }
      if (value >= caption.range3to) {
        return caption.color3;
      }
    }

    function getRangeColorFor5Levels(caption, value) {
      if (value > caption.range1from && value < caption.range1to) {
        return caption.color1;
      }
      if (value > caption.range2from && value < caption.range2to) {
        return caption.color2;
      }
      if (value > caption.range3from && value < caption.range3to) {
        return caption.color3;
      }
      if (value > caption.range4from && value < caption.range4to) {
        return caption.color4;
      }
      if (value > caption.range5from && value < caption.range5to) {
        return caption.color5;
      }
      if (value < caption.range1from) {
        return caption.color1;
      }
      if (value > caption.range5to) {
        return caption.color5;
      }
    }

    function validateCaption(sensor) {
      if (sensor.caption === null) {
        sensor.caption_valid = false;
        return sensor.caption_valid;
      } else {
        switch (sensor.caption.numLvl) {
          case 3:
            if (validateRange3Levels(sensor.caption) && validateColor3Levels(sensor.caption)) {
              sensor.caption_valid = true;
              return sensor.caption_valid;
            }
            break;
          case 5:
            if (validateRange5Levels(sensor.caption) && validateColor5Levels(sensor.caption)) {
              sensor.caption_valid = true;
              return sensor.caption_valid;
            }
            break;
        }
        sensor.caption_valid = false;
        return sensor.caption_valid;
      }
    }

    function validateRange3Levels(caption) {
      return (
        caption.range1from !== null &&
        caption.range2from !== null &&
        caption.range3from !== null &&
        caption.range1to !== null &&
        caption.range2to !== null &&
        caption.range3to !== null
      );
    }

    function validateColor3Levels(caption) {
      return caption.color1 !== null && caption.color2 !== null && caption.color3 !== null;
    }

    function validateRange5Levels(caption) {
      return (
        caption.range1from !== null &&
        caption.range2from !== null &&
        caption.range3from !== null &&
        caption.range4from !== null &&
        caption.range5from !== null &&
        caption.range1to !== null &&
        caption.range2to !== null &&
        caption.range3to !== null &&
        caption.range4to !== null &&
        caption.range5to !== null
      );
    }

    function validateColor5Levels(caption) {
      return (
        caption.color1 !== null &&
        caption.color2 !== null &&
        caption.color3 !== null &&
        caption.color4 !== null &&
        caption.color5 !== null
      );
    }

    function loadHistoryData() {
      vm.history = false;

      switch (vm.sensor.type) {
        case MapSensorType.ANALOG_SENSOR:
          if (vm.currentUnit.type === UNITS.A_4000 || vm.currentUnit.type !== UNITS.A_4000) {
            switch (vm.history_type) {
              case 0:
                sensorsFactory
                  .registerAnalogs(vm.currentUnit.id, vm.sensor.sensorId, vm.currentUnit.type)
                  .then(function (data) {
                    if (!data.plain().length) {
                      vm.isAnalogsDataEmtpy = true;
                      return;
                    }
                    if (
                      (vm.currentUnit.type === UNITS.A_5500 || vm.currentUnit.type === UNITS.A_4500) &&
                      !Object.keys(data.plain()[0]).length
                    ) {
                      vm.isAnalogsDataEmtpy = true;
                      return;
                    }

                    vm.isAnalogsDataEmtpy = false;
                    populateHistoryBar(data.plain());
                  });
                break;
              case 1:
                sensorsFactory.historyAnalogs(vm.currentUnit.id, vm.sensor.sensorId).then(function (data) {
                  if (!data.length) {
                    vm.isAvgDataEmpty = true;
                    return;
                  }
                  vm.isAvgDataEmpty = false;
                  prepareLineChart(data);
                });
                break;
            }
          } else {
            vm.isAnalogsDataEmtpy = true;
            vm.isAvgDataEmpty = true;
          }
          break;
        case MapSensorType.COUNTER_SENSOR:
          vm.history_type = 0;
          sensorsFactory
            .registerDigitals(vm.currentUnit.id, vm.sensor.sensorId, vm.currentUnit.type)
            .then(function (data) {
              if (!data.plain().length) {
                vm.isAnalogsDataEmtpy = true;
                return;
              }
              vm.isAnalogsDataEmtpy = false;
              populateHistoryBar(addEmptyDays(data.plain()));
            });
          break;
        case MapSensorType.DIGITAL_SENSOR:
          vm.history_type = 2;
          break;
        default:
          vm.history_type = 0;
          break;
      }
    }
    function closeSensorInfo() {
      $rootScope.$broadcast('closeSensor');
      $state.go('^');
    }
    function addEmptyDays(dates) {
      let labels = [];
      labels.push({ value: 0, type: '4', date: new moment().subtract(6, 'days') });
      labels.push({ value: 0, type: '4', date: new moment().subtract(5, 'days') });
      labels.push({ value: 0, type: '4', date: new moment().subtract(4, 'days') });
      labels.push({ value: 0, type: '4', date: new moment().subtract(3, 'days') });
      labels.push({ value: 0, type: '4', date: new moment().subtract(2, 'days') });
      labels.push({ value: 0, type: '4', date: new moment().subtract(1, 'days') });
      labels.push({ value: 0, type: '4', date: new moment() });

      labels.forEach((label, i) => {
        dates.forEach((date) => {
          if (label.date.format('DD') === date.date[0] + date.date[1]) {
            labels[i] = date;
            return false;
          }
        });
      });
      return labels;
    }
    function formatSensor(value, format) {
      switch (vm.sensor.type) {
        case MapSensorType.ANALOG_SENSOR:
          return formatAnalog(value);
        case MapSensorType.COUNTER_SENSOR:
          if (_.isNumber(format)) return formatCounterA25(value, format);
          return formatCounter(value);
      }
    }
    function formatCounterA25(value, format) {
      //        vm.currentUnit.version  <= 218
      switch (format) {
        case 0: // 0000m3 register l.
          if (vm.currentUnit.version <= 218) value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 1: // 000.0m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 2: // 000.00m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 3: // 00000l. register l.
          vm.sensor.label = 'l';
          break;
        case 4: // 000.0l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 5: // 000.00l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 6: // 0000l/m2 register l.
          vm.sensor.label = 'l/m2';
          break;
        case 7: // 000.0 l/m2 register cl.
          value = value / 100;
          vm.sensor.label = 'l/m2';
          break;
        case 8: // 000.00 l/m2 register cl.
          vm.sensor.label = 'l/m2';
          value = value / 100;
          break;
        default: // Old
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
      }
      return value;
    }
    function formatAnalog(value) {
      if (vm.sensor.format !== undefined) {
        value = '' + value;
        if (vm.sensor.format.decimals > 0) {
          let first = value.slice(0, value.length - vm.sensor.format.decimals);
          let last = value.slice(value.length - vm.sensor.format.decimals, value.length);
          value = first + '.' + last;
        }
        value = _.toNumber(value);
        vm.sensor.label = vm.sensor.format.units;
      }
      return value;
    }

    function formatCounter(value) {
      value = '' + value;
      if (vm.currentUnit.installer) {
        let decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        let first = value.slice(0, value.length - decimals);
        let last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function populateHistoryBar(dates) {
      vm.labels = _.map(dates, (o) => {
        return new moment(o.date, 'DD-MM-YYYY HH:mm').format('DD/MM');
      });
      let background = [];
      let value = _.map(dates, (o) => {
        return formatSensor(o.value, o.type);
      });

      if (!value.length || !dates.length) {
        vm.data = null;
        return;
      }
      vm.data = [value];
      if (validateCaption(vm.sensor)) {
        _.forEach(value, (val, i) => {
          if (vm.sensor.type === MapSensorType.ANALOG_SENSOR) {
            background.push(getRangeColor(vm.sensor.caption, dates[i].value));
          } else {
            background.push(getRangeColor(vm.sensor.caption, val));
          }
        });
      } else {
        _.forEach(value, () => {
          background.push('#5882FA');
        });
      }
      vm.datasetOverride = [
        {
          yAxisID: 'y-axis-1',
          label: vm.sensor.label,
          borderWidth: 2,
          backgroundColor: background,
          hoverBackgroundColor: background,
          fill: false,
          pointRadius: 5,
        },
      ];
      vm.options = {
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };

      vm.history = true;
    }

    function prepareLineChart(data) {
      let maxSeries = [];
      let minSeries = [];
      let avgSeries = [];
      let tooltips = [];

      data.forEach((item) => {
        let fromDate = new moment(item.from, 'DD-MM-YYYY HH:mm').toDate();

        maxSeries.push([fromDate.getTime(), parseFloat(item.max)]);
        minSeries.push([fromDate.getTime(), parseFloat(item.min)]);
        avgSeries.push([fromDate.getTime(), parseFloat(item.avg)]);

        let formattedDate = new moment(item.from, 'DD-MM-YYYY HH:mm').format('DD/MM');
        let formattedTimeFrom = new moment(item.from, 'DD-MM-YYYY HH:mm').format('HH:mm');
        let formattedTimeTo = new moment(item.to, 'DD-MM-YYYY HH:mm').format('HH:mm');

        let tooltipContent = `<strong>${formattedDate}: ${formattedTimeFrom}h - ${formattedTimeTo}h</strong><br>
                                ${$filter('translate')('history.max')}: ${item.max}<br>
                                ${$filter('translate')('history.min')}: ${item.min}<br>
                                ${$filter('translate')('history.avg')}: ${item.avg}<br><br>`;
        tooltips.push({
          date: fromDate,
          content: tooltipContent,
        });
      });

      maxSeries.sort((a, b) => a[0] - b[0]);
      minSeries.sort((a, b) => a[0] - b[0]);
      avgSeries.sort((a, b) => a[0] - b[0]);

      if (vm.lineChart) {
        vm.lineChart.destroy();
      }

      vm.lineChart = {
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d/%m}',
            style: {
              fontSize: '12px',
            },
          },
        },
        yAxis: {
          title: {
            text: '',
            style: {
              fontSize: '12px',
            },
          },
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          formatter: function () {
            let point = tooltips.find((t) => t.date.getTime() === this.x);
            if (point) {
              return point.content;
            }
            return '';
          },
        },
        legend: {
          itemStyle: {
            fontSize: '12px',
          },
        },
        series: [
          {
            name: $filter('translate')('history.max'),
            data: maxSeries,
            color: '#36A2EB',
            marker: {
              enabled: true,
            },
          },
          {
            name: $filter('translate')('history.min'),
            data: minSeries,
            color: '#66ff99',
            marker: {
              enabled: true,
            },
          },
          {
            name: $filter('translate')('history.avg'),
            data: avgSeries,
            color: '#FF6384',
            marker: {
              enabled: true,
            },
          },
        ],
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
            },
            enableMouseTracking: true,
          },
        },
      };

      vm.history = true;
    }

    function deleteSensorMarker() {
      $rootScope.$broadcast('deleteSensor');
      closeSensorInfo();
    }

    /* FUNCIONALITATS GRAFIQUES (DPV,ETO,WATERDISP) */
    function sensorHistory() {
      vm.sensor.history = true;
      datos_grafica = [];
      vm.type = vm.type.toString();

      let params = {
        to: new moment(vm.dateTo).format('DD-MM-YYYY'),
        from: new moment(vm.dateFrom).format('DD-MM-YYYY'),
        group: '2',
      };
      switch (vm.type) {
        case MapSensorType.DPV:
          params.group = '1';
          graphicsFactory
            .getDPV(vm.sensor.agronicUserId, vm.sensor.sensorId, params)
            .then((result) => {
              let mdata = [];
              mdata = result;
              mdata.forEach(function (valor) {
                let date_timestamp = new moment(valor.date, 'DD-MM-YYYY HH:mm').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });
            })
            .catch(function () {});
          break;

        case MapSensorType.ETO:
          graphicsFactory
            .getETO(vm.sensor.agronicUserId, vm.sensor.sensorId, params)
            .then((result) => {
              let mdata = [];
              mdata = result;
              mdata.forEach(function (valor) {
                let date_timestamp = new moment(valor.date, 'DD-MM-YYYY').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });
            })
            .catch(function () {});
          break;

        case MapSensorType.WATER_AVAILABLE:
          params.group = '1';
          graphicsFactory
            .getWaterDisp(vm.sensor.agronicUserId, vm.sensor.sensorId, 0, params)
            .then((result) => {
              let mdata = [];
              mdata = result;
              mdata.forEach(function (valor) {
                let date_timestamp = new moment(valor.date, 'DD-MM-YYYY HH:mm').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });
            })
            .catch(function () {});
          break;
      }
    }

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });
  }
})();
