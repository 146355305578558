import { navigateToUrl } from 'single-spa';

(function () {
  'use strict';
  angular
    .module('agronicwebApp')

    .factory('httpErrorHandler', httpErrorHandler);

  httpErrorHandler.$inject = ['$rootScope', '$q', '$state'];

  function httpErrorHandler($rootScope, $q, $state) {
    return {
      error: function (rejection) {
        if ($rootScope.user) $rootScope.user.authenticated = false;
        navigateToUrl(`/authentication/sign-in`);
        location.reload(); // HOTFIX - in order to escape the AngularJS router
      },
    };
  }
})();
