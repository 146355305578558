(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('irrideskFactory', irrideskFactory);

  irrideskFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function irrideskFactory(Restangular, $rootScope, $q) {
    var op = {
      getFormFactory: get,
      getChannel: channel,
      getSubscription: getSubscription,
      updateSensors: updateSensors,
      initChannel: initChannel,
      saveChannel: saveChannel,
      acceptPlan: acceptPlan,
      getChannelWrappers: getChannelWrappers,
      saveWrappers: saveWrappers,
      getChannelPrescription: getChannelPrescription,
      getHistoryProgramMM: getHistoryProgramMM,
      deleteSensor: deleteSensor,
      resetChannel: resetChannel,
      deleteChannel: deleteChannel,
      recalcSeasonalPlan: recalcSeasonalPlan,
      getCampaignPlans: getCampaignPlans,
      getChannelAccumulated: getChannelAccumulated,
      deleteWrapper: deleteWrapper,
    };

    return op;

    function getSubscription() {
      var deferred = $q.defer();
      Restangular.one('irridesk/subscription')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function get() {
      var deferred = $q.defer();
      Restangular.one('irridesk/formfactory')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function channel(id) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel')
        .one(id)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateSensors(id, params) {
      var deferred = $q.defer();
      Restangular.one('irridesk', id)
        .one('subscription/sensors')
        .customPUT(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
      //Restangular.one('irridesk/subscription')
    }

    function initChannel(subscriptionId, channelId, ch) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subscriptionId)
        .one('channel', channelId)
        .one('init')
        .customPOST(ch)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function saveChannel(subscriptionId, channelId, ch) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subscriptionId)
        .one('channel', channelId)
        .customPOST(ch)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function acceptPlan(subscriptionId, planId, plan) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subscriptionId)
        .one('plan', planId)
        .one('accept')
        .customPOST(plan)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function saveWrappers(subscriptionId, channelId, ch) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subscriptionId)
        .one('channel', channelId)
        .one('wrappers')
        .customPOST(ch)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getChannelWrappers(id) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel')
        .one(id)
        .one('wrappers')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getChannelPrescription(id, params) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel')
        .one(id)
        .one('prescriptions')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function resetChannel(subsId, channelId) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subsId)
        .one('subscription', channelId)
        .one('testreset')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getHistoryProgramMM(id, program, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('history')
        .one('programs', program)
        .one('mm')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteSensor(id, unitid, sensorid) {
      var deferred = $q.defer();
      Restangular.one('irridesk', id)
        .one('subscription', unitid)
        .one('sensor', sensorid)
        .customDELETE()
        .then(function (response) {
          //list = null;
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteChannel(subsId, id) {
      var deferred = $q.defer();
      Restangular.one('irridesk', subsId)
        .one('channel', id)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function recalcSeasonalPlan(id) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel', id)
        .one('recalcSeasonalPlan')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getCampaignPlans(id) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel')
        .one(id)
        .one('seasonal')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getChannelAccumulated(id, params) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel')
        .one(id)
        .one('dailystate')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function deleteWrapper(id, wrapperId) {
      var deferred = $q.defer();
      Restangular.one('irridesk/channel', id)
        .one('wrappers', wrapperId)
        .one('delete')
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
