(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('registerController', registerController);

  registerController.$inject = ['$scope', '$state'];

  function registerController($scope, $state) {
    var vm = this;
    vm.activeList;

    $scope.$on('refresh', function (event, args) {});
  }
})();
