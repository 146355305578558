import waterDirectiveTemplate from './water-directive.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('watergraph', function () {
      var dragging = false;

      var min = 0;
      var max = 100;

      var value_pe;
      var value_pr;
      var value_m1;
      var value_m2;

      var _scope;
      /* Bar elements */
      var slider;

      var thumb_pe;
      var thumb_pr;
      var thumb_m1;
      var thumb_m2;

      var shift;

      /* Event functions*/
      var mouseMoveCallback;
      var mouseUpCallback;

      return {
        restrict: 'E',
        template: waterDirectiveTemplate,
        scope: {
          pr: '=',
          pe: '=',
          m1: '=',
          m2: '=',
          vgForm: '=',
        },
        link: function (scope, elem, attrs) {
          _scope = scope;
          slider = document.getElementById('slide1');

          thumb_pe = document.getElementById('thumb-slider-pe');
          thumb_pr = document.getElementById('thumb-slider-pr');
          thumb_m1 = document.getElementById('thumb-slider-m1');
          thumb_m2 = document.getElementById('thumb-slider-m2');

          if (scope.pe === null || scope.pe === undefined) {
            scope.pe = 20;
          }
          if (scope.pr === null || scope.pr === undefined) {
            scope.pr = 50;
          }
          if (scope.m1 === null || scope.m1 === undefined) {
            thumb_m1.style.display = 'none';
            document.getElementById('demo-waterdisp-m1').style.display = 'none';
          }

          if (scope.m2 === null || scope.m2 === undefined) {
            thumb_m2.style.display = 'none';
            document.getElementById('demo-waterdisp-m2').style.display = 'none';
          }

          shift = thumb_pe.offsetHeight / 2;

          setValue(Number(scope.pe), value_pe, thumb_pe);
          setValue(Number(scope.pr), value_pr, thumb_pr);
          setValue(Number(scope.m1), value_m1, thumb_m1);
          setValue(Number(scope.m2), value_m2, thumb_m2);

          thumb_pe.addEventListener('mousedown', mouseDownCallback, false);
          thumb_pr.addEventListener('mousedown', mouseDownCallback, false);
          thumb_m1.addEventListener('mousedown', mouseDownCallback, false);
          thumb_m2.addEventListener('mousedown', mouseDownCallback, false);
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.showM1 = $scope.m1 !== null;
            $scope.showM2 = $scope.m2 !== null;

            $scope.active = function () {
              if ($scope.vgForm) {
                $scope.vgForm.$setDirty();
              }

              if ($scope.showM1) {
                if ($scope.m1 !== null) {
                  $scope.m1 = 0;
                }
                thumb_m1.style.display = 'block';
                document.getElementById('demo-waterdisp-m1').style.display = 'block';
              } else {
                $scope.m1 = null;
                thumb_m1.style.display = 'none';
                document.getElementById('demo-waterdisp-m1').style.display = 'none';
              }
              if ($scope.showM2) {
                if ($scope.m2 !== null) {
                  $scope.m2 = 0;
                }
                thumb_m2.style.display = 'block';
                document.getElementById('demo-waterdisp-m2').style.display = 'block';
              } else {
                $scope.m2 = null;
                thumb_m2.style.display = 'none';
                document.getElementById('demo-waterdisp-m2').style.display = 'none';
              }
            };
          },
        ],
      };

      function mouseDownCallback(evt) {
        var currentThumb = this;
        var thumbYOffset = evt.clientY - currentThumb.offsetTop;
        mouseMoveCallback = function (evt) {
          var target = evt.target;
          var yRange = slider.offsetHeight;
          var y = Math.max(0, Math.min(yRange, evt.clientY - thumbYOffset));
          currentThumb.style.top = y - shift + 'px';

          var value = max - (y / yRange) * (max - min);

          onSliderChange(currentThumb, value);
          evt.preventDefault();
        };

        mouseUpCallback = function (evt) {
          if (_scope.vgForm) {
            _scope.vgForm.$setDirty();
          }
          document.removeEventListener('mousemove', mouseMoveCallback, false);
          document.removeEventListener('mouseup', mouseUpCallback, false);
        };

        document.addEventListener('mousemove', mouseMoveCallback, false);
        document.addEventListener('mouseup', mouseUpCallback, false);

        evt.preventDefault();
      }

      function onSliderChange(el, value) {
        // We can use this.getId() to identify the slider
        el.getElementsByClassName('slider-text')[0].innerHTML = value.toFixed(0) + '%';
        switch (el.id) {
          case 'thumb-slider-pe':
            var d = angular.element(document.getElementById('demo-waterdisp-defcrit'));
            d.css('height', value.toFixed(0) + '%');
            _scope.pe = value.toFixed(0);
            break;
          case 'thumb-slider-pr':
            var d = angular.element(document.getElementById('demo-waterdisp-defperm'));
            //d.style.height= value.toFixed(0)+'%';
            d.css('height', value.toFixed(0) + '%');
            _scope.pr = value.toFixed(0);
            break;
          case 'thumb-slider-m1':
            var d = angular.element(document.getElementById('demo-waterdisp-m1'));
            //d.style.height= value.toFixed(0)+'%';
            d.css('height', value.toFixed(0) + '%');
            _scope.m1 = value.toFixed(0);
            break;
          case 'thumb-slider-m2':
            var d = angular.element(document.getElementById('demo-waterdisp-m2'));
            //d.style.height= value.toFixed(0)+'%';
            d.css('height', value.toFixed(0) + '%');
            _scope.m2 = value.toFixed(0);
            break;
        }
      }

      function setValue(val, input, element) {
        val = Math.max(min, Math.min(max, val));
        var yRange = slider.clientHeight;
        var y = Math.floor(((max - val) / (max - min)) * yRange);
        element.style.top = y - shift + 'px';
        input = val;
        onSliderChange(element, val);
      }
    });
})();
