import moment from 'moment/moment';
import { BehaviorSubject, filter, from } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)
    .factory('filterFactory', filterFactory);

  filterFactory.$inject = ['Restangular', '$q'];

  function filterFactory(Restangular, $q) {
    const filtersResponse = new BehaviorSubject(null);
    var op = {
      all: all,
      getFilters,
      filters: filters,
      updateFilters: updateFilters,
      filterRegisters: filterRegisters,
      getHeaderFilters: getHeaderFilters,
      saveHeaderFilter: saveHeaderFilter,
      getInstaller: getInstaller,
      updateInstaller: updateInstaller,

      getFiltersResponse: () => filtersResponse.asObservable().pipe(filter((filters) => !!filters)),
      getFiltersValue: () => filtersResponse.getValue(),
      clearFiltersResponse: () => filtersResponse.next(null),
    };

    return op;

    function filters(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .get({ add: 'filter' })
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function all(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('filters')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getFilters(unitId) {
      return from(
        Restangular.one('units', unitId)
          .one('filters')
          .get()
          .then(function (res) {
            filtersResponse.next(res.plain());
          })
      );
    }

    function getHeaderFilters(id, header) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headers', header)
        .one('filters')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function saveHeaderFilter(id, header, filter) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headers', header)
        .one('filters')
        .customPOST(filter)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function registers(id, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function filterRegisters(id, type, page, limit) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().add(1, 'days').format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('filters')
        .one('register')
        .get({ page: page, limit: limit, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function updateFilters(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('filters25')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getInstaller(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('installer')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateInstaller(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .one('others')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
