(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('newUserController', newUserController);

  newUserController.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'unitFactory',
    'userFactory',
    'UserData',
  ];

  function newUserController($log, $scope, $state, Restangular, $mdDialog, unitFactory, userFactory, UserData) {
    var vm = this;
    vm.devicesArray;
    vm.createUser;

    vm.regEmail =
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    vm.regLogin = /^[a-zA-Z0-9]*$/;
    vm.regName = /^[a-zA-Z0-9ñÑ\-_,. \s]*$/;
    vm.regPass = /^[a-zA-Z0-9ñÑ\-_!.?@:]*$/;

    vm.unitsType = [
      { id: 2, name: 'A-4000' },
      { id: 3, name: 'A-2500' },
      { id: 4, name: 'A-BIT' },
      { id: 5, name: 'A-7000' },
      { id: 6, name: 'A-5500' },
    ];

    activate();

    function activate() {
      vm.createUser = createUser;
      vm.quadern = _.includes(UserData.authorities, 'CCUSER');
      loadUserDevices();
    }

    function loadUserDevices() {
      userFactory.units(UserData.id).then(function (response) {
        vm.devicesArray = response.plain();
        _.forEach(vm.devicesArray, (d) => {
          d.device.lvl = null;
        });
      });
    }

    function createUser(form) {
      var devices = _.filter(_.map(vm.devicesArray, 'device'), 'lvl');
      if (form.$valid) {
        if (devices.length > 0) {
          if (vm.user.password === vm.user.password2) {
            vm.user.devices = {};
            _.forEach(devices, function (obj) {
              vm.user.devices[obj.id] = obj.lvl;
            });

            vm.user.authorities = [];
            if (vm.user.quadern) {
              vm.user.authorities.push('CCUSER');
            }

            userFactory.resource.post(vm.user).then(
              function () {
                //$state.go('manager', {}, {reload: true});
                $scope.$emit('refresh-users', { load: true });
              },
              (error) => {
                var msg = 'Error creando el usuario.';
                if (error.data.code === 100) {
                  msg = 'El login de usuario ya existe.';
                }
                $mdDialog
                  .show($mdDialog.alert().clickOutsideToClose(true).title('Error').textContent(msg).ok('OK'))
                  .then(() => {});
              }
            );
          } else {
            $mdDialog
              .show(
                $mdDialog
                  .alert()
                  //.parent(angular.element(document.querySelector('#popupContainer')))
                  .clickOutsideToClose(true)
                  .title('Alerta')
                  .textContent('Contraseñas distintas')
                  .ok('OK')
              )
              .then(() => {});
          }
        } else {
          $mdDialog
            .show(
              $mdDialog
                .alert()
                //.parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title('Alerta')
                .textContent('Seleccionar minimo 1 agronic')
                .ok('OK')
            )
            .then(() => {});
        }
      }
    }
  }
})();
