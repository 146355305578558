import moment from 'moment/moment';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import { filter, map, switchMap, tap, catchError, take } from 'rxjs/operators';
import { UNITS } from './units.enum';
import { unitIncludedIn } from './utils';
import { IRRIGATION_DEVICE_STATUS } from './irrgation-status.enum';

(function () {
  'use strict';

  angular.module('agronicwebApp').constant('moment', moment).factory('unitFactory', unitFactory);

  unitFactory.$inject = ['Restangular', '$q', '$translate', '$state'];

  function unitFactory(Restangular, $q, $translate, $state) {
    var resource = Restangular.all('units');
    const unitsMap = {};
    const notifications = [];
    const alertsMessages = [];
    var unitElem;
    var unitList;

    const unitsResponse = new BehaviorSubject(null);
    const conditionersResponse = new BehaviorSubject(null);
    const unitResponse = new BehaviorSubject(null);
    const totalsResponse = new BehaviorSubject(null);
    const totalsMeterResponse = new BehaviorSubject(null);

    return {
      units: units,
      unit: unit,
      getUnits,
      getUnitById,
      programRegisters,
      phreference,
      registers,
      meter,
      totals,
      totalsMeter,
      conditioners,
      getConditionersByUnitId$,
      getType,
      checkStatus,
      checkNotifications,
      checkUnitStatus,
      checkStatusImg,
      getMergedUnit,

      getUnitsResponse: () => unitsResponse.asObservable().pipe(filter((units) => !!units)),
      getConditionersResponse: () => conditionersResponse.asObservable().pipe(filter((conditioners) => !!conditioners)),
      setConditionersResponse: (conditioners) => conditionersResponse.next(conditioners),

      getUnitsValue: () => unitsResponse.getValue(),
      getConditionersValue: () => conditionersResponse.getValue(),

      getUnitResponse: () => unitResponse.asObservable().pipe(filter((unit) => !!unit)),
      getUnitValue: () => unitResponse.getValue(),

      getTotalsResponse: () => totalsResponse.asObservable(),
      getTotalsMeterResponse: () => totalsMeterResponse.asObservable(),

      getIrrigationStatus,
    };

    function totals(id) {
      Restangular.one('units', id)
        .one('totals')
        .get()
        .then(function (response) {
          totalsResponse.next(response.plain());
        });
    }

    function totalsMeter(id) {
      Restangular.one('units', id)
        .one('totalsmeter')
        .get()
        .then(function (response) {
          totalsMeterResponse.next(response.plain());
        });
    }

    function meter(id, id2) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('meters', id2)
        .get()
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function programRegisters(id, id2, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().add(1, 'days').format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('programs', id2)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function registers(id, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().add(1, 'days').format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function getUnits(id, preventEmission = false) {
      return from(
        Restangular.one('users', id)
          .one('units')
          .get()
          .then((response) => {
            const parsedUnits = parseUnits(response.plain());
            unitsMap[id] = parsedUnits.map((unit) => ({ ...unit.device, lvl: unit.level }));

            // avoid executing next if old refreshing is performancing
            if (!preventEmission) {
              unitsResponse.next(unitsMap[id]);
            }
            return unitsMap[id];
          })
      );
    }

    function unit(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .withHttpConfig({ cancel: deferred })
        .get(params)
        .then(function (response) {
          unitElem = response;
          deferred.resolve(unitElem);
        });

      return deferred.promise;
    }

    function units(reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        unitList = undefined;
      }
      if (unitList === undefined) {
        resource.getList().then(function (response) {
          unitList = response;
          deferred.resolve(unitList);
        });
      } else {
        deferred.resolve(unitList);
      }
      return deferred.promise;
    }

    function phreference(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('phref')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    /**
     * Merges unit data from getUnits and getUnit, since BE
     * sends different data in each response
     * @param userId UserData.id
     * @param unitId unit ID
     * @param params extra params for getUserById
     * @param refreshUnits if false, units are fetched again
     * @returns
     */
    function getMergedUnit(userId, unitId, params, refreshUnits = false) {
      const cachedUnits = unitsResponse.getValue();
      return (cachedUnits && !refreshUnits ? of(cachedUnits) : getUnits(userId)).pipe(
        take(1),
        switchMap((units) => forkJoin([of(units.find((unit) => unit.id === unitId)), getUnitById(unitId, params)])),
        map(([unit1, unit2]) => ({ ...unit2.plain(), ...unit1 })),
        tap((unit) => unitResponse.next(unit)),
        catchError(() => {
          $state.go('units');
          //return needed return an observable to avoid error
          return of({});
        })
      );
    }

    function getUnitById(unitId, params) {
      return from(
        Restangular.one('units', unitId)
          .get(params)
          .then(function (unit) {
            if (unitIncludedIn(unit.type, [UNITS.A_2500, UNITS.A_BIT, UNITS.A_5500])) {
              const options = unit.inoptions[0];
              unit.inoptions = options;
            }

            checkUnitStatus(unit);
            checkNotifications(unit);

            return unit;
          })
      );
    }

    function conditioners(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('conditioners')
        .get()
        .then(function (response) {
          conditionersResponse.next(response.plain());

          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function getConditionersByUnitId$(id) {
      return from(Restangular.one('units', id).one('conditioners').get());
    }

    function getType(type) {
      switch (type) {
        case 2:
          return 'A-4000';
        case 3:
          return 'A-2500';
        case 4:
          return 'A-BIT';
        case 5:
          return 'A-7000';
        case 6:
          return 'A-5500';
        case 7:
          return 'A-4500';
      }
    }

    function checkStatusImg(unit) {
      if (unit.status === 'ok') {
        unit.statusImg = unit.irrigation ? 'irrig' : 'nirrig';
      } else {
        unit.statusImg = unit.status;
      }
    }

    function checkUnitStatus(obj) {
      if (obj.ram !== undefined) {
        if (obj.connect) {
          /*"Paro Sistema"*/
          if (obj.ram.systemStopMalfunction) {
            obj.status = 'systemStop';
          } else if (obj.ram.outService) {
            /*"Fuera de servicio"*/
            obj.status = 'outService';
          } else if (obj.ram.generalMalfunction) {
            /*"Avería General"*/
            obj.status = 'generalMalfunction';
          } else if (obj.ram.flowMalfunction) {
            /*"Avería Caudal"*/
            obj.status = 'flowMalfunction';
          } else if (obj.ram.counterMalfunction) {
            /*"Avería Contador"*/
            obj.status = 'counterMalfunction';
          } else if (obj.ram.ferlitzerMalfunction) {
            /*"Avería Fertilizante sin control"*/
            obj.status = 'ferlitzerMalfunction';
          } else if (obj.ram.filterMalfunction) {
            /*"Avería Filtros sin control"*/
            obj.status = 'filterMalfunction';
          } else if (obj.ram.phMalfunction) {
            /*"Avería regulación PH"*/
            obj.status = 'phMalfunction';
          } else if (obj.ram.ceMalfunction) {
            /*"Avería control conductividad"*/
            obj.status = 'ceMalfunction';
          } else if (obj.ram.definitiveStopMalfunction) {
            /*"Paro Definitivo"*/
            obj.status = 'definitiveStopMalfunction';
          } else {
            obj.status = 'ok';
          }
        } else {
          obj.status = 'notconnected';
        }
      } else {
        if (obj.connect) {
          /*"Paro Sistema"*/
          if (obj.systemStopMalfunction) {
            obj.status = 'systemStop';
          } else if (obj.outService) {
            /*"Fuera de servicio"*/
            obj.status = 'outService';
          } else if (obj.generalMalfunction) {
            /*"Avería General"*/
            obj.status = 'generalMalfunction';
          } else if (obj.flowMalfunction) {
            /*"Avería Caudal"*/
            obj.status = 'flowMalfunction';
          } else if (obj.counterMalfunction) {
            /*"Avería Contador"*/
            obj.status = 'counterMalfunction';
          } else if (obj.ferlitzerMalfunction) {
            /*"Avería Fertilizante sin control"*/
            obj.status = 'ferlitzerMalfunction';
          } else if (obj.filterMalfunction) {
            /*"Avería Filtros sin control"*/
            obj.status = 'filterMalfunction';
          } else if (obj.phMalfunction) {
            /*"Avería regulación PH"*/
            obj.status = 'phMalfunction';
          } else if (obj.ceMalfunction) {
            /*"Avería control conductividad"*/
            obj.status = 'ceMalfunction';
          } else if (obj.definitiveStopMalfunction) {
            /*"Paro Definitivo"*/
            obj.status = 'definitiveStopMalfunction';
          } else {
            obj.status = 'ok';
          }
        } else {
          obj.status = 'notconnected';
        }
      }

      checkStatusImg(obj);
    }

    function getIrrigationStatus(unit) {
      const status = `${unit.status === 'ok' ? (unit.irrigation ? 'irrigation' : 'no_irrigation') : unit.status}`;
      let badgeColor = IRRIGATION_DEVICE_STATUS[unit.status];

      if (unit.status === 'ok') {
        badgeColor = unit.irrigation
          ? IRRIGATION_DEVICE_STATUS['irrigation']
          : IRRIGATION_DEVICE_STATUS['noIrrigation'];
      }

      return { status, badgeColor };
    }

    function checkNotifications(obj) {
      var malfunctions = _.pickBy(obj, function (value, key) {
        return _.endsWith(key, 'Malfunction');
      });
      obj.malfunctions = [];
      if (!_.isEmpty(malfunctions)) {
        _.forEach(malfunctions, (v, k) => {
          if (v) {
            var el = {};
            el[obj.name] = k;
            notifications.push(el);
            obj.malfunctions.push(el);
          }
        });
      }
      return obj.malfunctions;
    }

    function showNotification(obj) {
      const toast = document.querySelector('vegga-toast-manager');
      var translate = '';
      if (!_.values(obj)[0]) {
        return;
      }

      $translate('alerts.' + _.values(obj)[0]).then(function (value) {
        translate = value;
        var text = _.keys(obj)[0] + ' | ' + translate;
        if (!alertsMessages.includes(text)) {
          alertsMessages.push(text);
          toast.create({ message: text, statusClass: 'vegga-alert--warning', persist: true });
        }
      });
    }

    function checkStatus(device) {
      if (device?.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else {
        return device?.status;
      }
    }

    function parseUnits(units) {
      units.forEach((unit) => {
        checkUnitStatus(unit.device);
        checkNotifications(unit.device);
        unit.device.lvl = unit.level;
      });
      showNotification(notifications[0]);
      return units;
    }
  }
})();
