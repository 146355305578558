import '../components/general_outputs/outputs-controller';
import outputsTemplate from '../components/general_outputs/outputs.html';

import '../components/general_outputs/inputs/inputs-controller';
import inputTemplate from '../components/general_outputs/inputs/inputs.html';

import '../components/general_outputs/engines/outEngines-controller';
import enginesTemplate from '../components/general_outputs/engines/engines.html';

import '../components/general_outputs/outGeneral-controller';
import programTemplate from '../components/general_outputs/programs/programs.html';

import '../components/general_outputs/fertilizers/outFertilizers-controller';
import fertilizersTemplate from '../components/general_outputs/fertilizers/fertilizers.html';

import '../components/general_outputs/filters/outFilters-controller';
import filterTemplate from '../components/general_outputs/filters/filters.html';

import '../components/general_outputs/alarms/outAlarmsHeader-controller';
import alarmTemplate from '../components/general_outputs/alarms/alarms.html';

import '../components/general_outputs/diesel/outDiesel-controller';
import dieselTemplate from '../components/general_outputs/diesel/diesel.html';
import diesel45Template from '../components/general_outputs/diesel/diesel-4500.html';

import '../components/general_outputs/fitos/outFitos-controller';
import fitosTemplate from '../components/general_outputs/fitos/fitos-4500.html';

import '../components/general_outputs/acids/outAcids-controller';
import acidsTemplate from '../components/general_outputs/acids/acids-4500.html';

import '../components/general_outputs/pressure_regulation/outPressureHeader-controller';
import pressureTemplate from '../components/general_outputs/pressure_regulation/pressure.html';

import '../components/general_outputs/watermix/watermix-controller';
import watermixGeneralTemplate from '../components/general_outputs/watermix/watermix.html';

import '../components/general_outputs/draintrays/draintrays-controller';
import draintraysTemplate from '../components/general_outputs/draintrays/draintrays.html';

import phregulationTemplate from '../components/general_outputs/ph_regulation/ph.html';

import '../components/events/events-controller';
import eventsTemplate from '../components/events/events.html';

import '../components/others/others-controller';
import othersTemplate from '../components/others/others.html';

import watermixTemplate from '../components/watermix/watermix.html';

import headersTemplate from '../components/general_outputs/headers/header.html';
import '../components/general_outputs/headers/headers-controller';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('outputs', {
            parent: 'unit',
            url: '/outputs',
            params: {
              unit: null,
              load: null,
            },
            template: outputsTemplate,
            controller: 'outputsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          //-------> Outputs child states
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.inputs', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/inputs',
            template: inputTemplate,
            controller: 'generalInputsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.engines', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/engines',
            template: enginesTemplate,
            controller: 'outEnginesController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.programs', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/programs',
            template: programTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.fertilizers', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/fertilizers',
            template: fertilizersTemplate,
            controller: 'outFertilizersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.filters', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/filters',
            template: filterTemplate,
            controller: 'outFiltersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.alarms', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/alarms',
            template: alarmTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.diesel', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/diesel',
            template: dieselTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.fitos', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/fitos',
            template: fitosTemplate,
            controller: 'outFitosController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.acids', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/acids',
            template: acidsTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.pressure', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/pressure',
            template: pressureTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.watermix', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/watermix',
            template: watermixGeneralTemplate,
            controller: 'waterGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.draintrays', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/draintrays',
            template: draintraysTemplate,
            controller: 'draintraysController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.ph', {
            //parent:'outputs',
            params: {
              unit: null,
              id: null,
            },
            url: '/ph_regulation',
            template: phregulationTemplate,
            controller: 'outGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers', {
            //parent:'outputs',
            params: {
              // unit:null,
              // id:null,
              headerId: null,
            },
            url: '/{headerId}',
            template: headersTemplate,
            controller: 'generalHeadersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.engines', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/engines',
            template: enginesTemplate,
            controller: 'outEnginesController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.fertilizers', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/fertilizers',
            template: fertilizersTemplate,
            controller: 'outFertilizersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.filters', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/filters',
            template: filterTemplate,
            controller: 'outFiltersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.alarms', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/alarms',
            template: alarmTemplate,
            controller: 'outAlarmsHeadersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.diesel', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/diesel',
            template: diesel45Template,
            controller: 'outDieselController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.fitos', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/fitos',
            template: fitosTemplate,
            controller: 'outFitosController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.acids', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/acids',
            template: acidsTemplate,
            controller: 'outAcidsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.pressure', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/pressure',
            template: pressureTemplate,
            controller: 'outPressureHeaderController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('outputs.headers.watermix', {
            //parent:'outputs',
            params: {
              headerId: null,
            },
            url: '/watermix',
            template: watermixGeneralTemplate,
            controller: 'waterGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          /******/
          .state('events', {
            parent: 'unit',
            params: {
              unit: null,
              id: null,
            },
            resolve: {
              Events: [
                'configFactory',
                '$stateParams',
                function (configFactory, $stateParams) {
                  if ($stateParams.unit !== null) {
                    return configFactory.events($stateParams.unit.id);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/events',
            template: eventsTemplate,
            controller: 'eventsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          /******/
          .state('others', {
            parent: 'unit',
            params: {
              unit: null,
              id: null,
            },
            url: '/others',
            template: othersTemplate,
            controller: 'othersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          /******/
          .state('watermix', {
            parent: 'unit',
            params: {
              unit: null,
              id: null,
            },
            url: '/watermix',
            template: watermixTemplate,
            controller: 'waterGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          });
      },
    ]);
})();
