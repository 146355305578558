import { UNITS } from '../utils/units.enum';
import { Subject, takeUntil } from 'rxjs';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('nebulizationController', nebulizationController);

  nebulizationController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'nebulizationFactory',
    'sensorsFactory',
    '$confirm',
    'unitFactory',
  ];

  function nebulizationController($scope, $state, $filter, nebulizationFactory, sensorsFactory, $confirm, unitFactory) {
    var vm = this;
    vm.nebulizationList;
    vm.overlayEl;
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('programs.prog2') },
      { headerName: $filter('translate')('programs.detail.name') },
      { headerName: $filter('translate')('fert.state') },
    ];
    vm.UNITS = UNITS;
    vm.changeNebulization = changeNebulization;
    vm.changeNebulizationState = changeNebulizationState;
    vm.changingNebulization = changingNebulization;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.dataGridRowClick = dataGridRowClick;
    vm.dismissOverlay = dismissOverlay;
    vm.cancel = cancel;
    vm.save = save;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadNebulization(vm.currentUnit.id);
      });
    }

    function loadNebulization(id) {
      return nebulizationFactory.getAll(id).then((result) => {
        vm.nebulizationList = result.plain();
        vm.currentNebulization = vm.nebulizationList.find((neb) => +neb.id === +$state.params.id);
        //loadSensors();
      });
    }

    function dismissOverlay() {
      $state.go('nebulization');
    }

    function dataGridRowClick(_e, neb) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      vm.currentNebulization = neb;
      veggaOverlay.show();
      changeState({ nebulization: neb, id: neb.id, unit: vm.currentUnit });
    }

    function changeNebulization(e) {
      if (e.detail.value) {
        vm.currentNebulization = e.detail.value;
        changeState({ nebulization: vm.currentNebulization, id: vm.currentNebulization.id, unit: vm.currentUnit });
      }
    }

    function changingNebulization($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeNebulization($event);
          break;
      }
    }

    function changeNebulizationState(state) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, {
            nebulization: vm.currentNebulization,
            id: vm.currentNebulization.id,
            unit: vm.currentUnit,
          }); //Canviem d'estat
          vm.currentState = state;
        });
      } else {
        $state.go(state, { nebulization: vm.currentNebulization, id: vm.currentNebulization.id, unit: vm.currentUnit });
        vm.currentState = state;
      }
    }

    function changeState(params) {
      vm.active = null;
      vm.nebulization = params.nebulization;
      vm.nebulization.op = true;
      const path = $state.current.name.split('.')[1];
      $state.go(`nebulization.${path || 'detail'}`, params);
      vm.currentState = $state;
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      const overlay = document.querySelector('vegga-overlay');
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go('nebulization', { nebulization: vm.nebulization, unit: vm.currentUnit });
          vm.currentState = $state;
          overlay.dismiss();
        });
      } else {
        $state.go('nebulization', { nebulization: vm.nebulization, unit: vm.currentUnit });
        vm.currentState = $state;
        overlay.dismiss();
      }
    }

    function save() {
      $scope.$broadcast('formSubmit');
      //vm.form.$setPristine();
      //nebulizationFactory.update(vm.currentUnit.id, vm.nebulizationList);
    }

    $scope.$on('updateEdit', function (event, args) {
      loadNebulization(vm.currentUnit.id).then(function () {
        if ($state.includes('nebulization.edit') || $state.includes('nebulization.config')) {
          if ($state.params.nebulization !== undefined && $state.params.nebulization.op) {
            var nebulization = _.filter(vm.nebulizationList, { name: $state.params.nebulization.name });
            if (nebulization !== undefined && nebulization.length > 0) {
              vm.nebulization = nebulization[0];
              vm.nebulization.op = true;
              $state.go($state.current, {
                nebulization: nebulization[0],
                id: nebulization[0].id,
                unit: vm.currentUnit,
              });
            } else {
              $state.go('nebulization', { unit: vm.currentUnit }, { reload: true });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, form) => {
      vm.form = form;
    });
  }
})();
