import veggaRadioButtonGroupLegacyTemplate from './vegga-radio-button-group-legacy.template.html';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular.module('agronicwebApp').directive('veggaRadioButtonGroupLegacy', veggaRadioButtonGroupLegacy);

  function veggaRadioButtonGroupLegacy() {
    return {
      restrict: 'E',
      template: veggaRadioButtonGroupLegacyTemplate,
      transclude: true,
      scope: {
        direction: '@',
        label: '@',
        ngModel: '=',
        ngForm: '=', // deprecated
        vgForm: '=',
        onChange: '&',
      },
      // controller: function($scope,$element) {
      // },
      link: function ($scope, $element, attrs, ngModel) {
        $scope.selectedValue = $scope.ngModel;

        $scope.veggaRadioButtonGroupChange = function ($event) {
          const option = $event.detail;

          if ($scope.ngModel || $scope.ngModel >= 0) {
            if (typeof $scope.ngModel === 'number') {
              $scope.ngModel = Number(option.value);
            } else {
              $scope.ngModel = option.value;
            }
          }

          if ($scope.ngForm) {
            //deprecated
            $scope.ngForm.$setDirty();
          }

          if ($scope.vgForm) {
            $scope.vgForm.$setDirty();
          }

          if (attrs.onChange) {
            $scope.onChange()($event.detail);
          }
        };

        $scope.$watch('ngModel', function () {
          $scope.selectedValue = $scope.ngModel;
        });
      },
    };
  }
})();
