import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { A2500_DEFAULT_CONFIG, ABIT_DEFAULT_CONFIG } from '../../utils/device-config';
import { getContainer } from '@vegga/front-store';
import { of, Subject, switchMap, take, throwError } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('programsConfigControllerA25', programsConfigControllerA25);

  programsConfigControllerA25.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    'resFactory',
    '$rootScope',
    'unitFactory',
    'veggaSelectFactory',
    '$filter',
  ];

  function programsConfigControllerA25(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    resFactory,
    $rootScope,
    unitFactory,
    veggaSelectFactory,
    $filter
  ) {
    var vm = this;
    vm.activeList;
    vm.formats = [];
    vm.conditioners;
    vm.formProgram;
    vm.irrigationMeasureUnit;
    vm.updateFormat = updateFormat;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.setIrrigationMeasureUnit = setIrrigationMeasureUnit;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.destroy$ = new Subject();
    vm.priorValues = _.range(0, 100);
    vm.priorValuesDTO = veggaSelectFactory.formatterVeggaSelect(vm.priorValues);
    vm.UNITS = UNITS;

    vm.groupList = [
      { name: $filter('translate')('programs.nothing'), type: 0 },
      { name: '1', type: 1 },
      { name: '2', type: 2 },
      { name: '3', type: 3 },
      { name: '4', type: 4 },
      { name: '5', type: 5 },
      { name: '6', type: 6 },
      { name: '7', type: 7 },
      { name: '8', type: 8 },
      { name: '9', type: 9 },
    ];

    activate();

    function activate() {
      initSubscriptions();
      vm.currentState = $state.includes;
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          switchMap((currentUnit) => {
            if (!currentUnit) {
              $state.go('units');
              return throwError(() => of(null));
            }

            vm.currentUnit = currentUnit;

            if (vm.currentUnit.inoptions.plus || vm.currentUnit.type === vm.UNITS.A_BIT) {
              vm.unitValues = ABIT_DEFAULT_CONFIG.measurementUnits.irrigation;
            } else {
              vm.unitValues = A2500_DEFAULT_CONFIG.measurementUnits.irrigation;
            }

            loadConditioners();

            return vm.programsFacade.currentProgram$;
          }),
          take(1)
        )
        .subscribe((prog) => {
          vm.program = prog;
          backup();
          // vm.formatedTime = getTimeFromMins(vm.program.securityTime);

          vm.irrigationMeasureUnit = vm.unitValues.find(
            (unit) => unit.deviceMeasurementUnitKey === vm.program.unit
          ).measurementUnit;

          if (vm.currentUnit.type === vm.UNITS.A_BIT) {
            vm.fertitlizers = _.range(1, 11);
            vm.currentUnit.installer.decimalsIrrigation = 0;
          }
        });
    }

    function loadConditioners() {
      vm.loading = true;
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.loading = false;
        vm.currentUnit.conditioners = [];

        const conditioners = data.plain();
        if (conditioners) {
          Object.keys(conditioners).forEach((key) => {
            vm.currentUnit.conditioners.push(conditioners[key]);
          });
        }
      });
    }
    function updateFormat(id, index) {
      if (id !== null && id > 0) {
        id = parseInt(id);
        if (id === 41) {
          vm.program.conditioners[index].value2 = 0;
          vm.formats[index] = {
            units: 'L/m2',
          };
        } else {
          vm.formats[index] = vm.currentUnit.formats[vm.currentUnit.sanalog[id - 1].formatId - 1];

          if (
            vm.program.conditioners[index].type === 3 ||
            vm.program.conditioners[index].type === 7 ||
            vm.program.conditioners[index].type === 8 ||
            vm.program.conditioners[index].type === 9
          ) {
            if (vm.currentUnit.sanalog[id - 1].formatId === 1) {
              vm.formats[index].units = 'ºCh';
            } else if (vm.currentUnit.sanalog[id - 1].formatId === 2) {
              vm.formats[index].units = 'Whm2';
            }
          }
        }
      } else {
        vm.formats[index] = null;
      }
    }

    function setIrrigationMeasureUnit($event) {
      vm.irrigationMeasureUnit = vm.unitValues.find(
        (unit) => unit.deviceMeasurementUnitKey === $event.detail.value
      ).measurementUnit;
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.program, vm.program_backup);
      vm.program.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.program);
    }

    function save() {
      vm.formProgram.$setPristine();
      vm.program.progtype = vm.currentUnit.type;
      //        vm.program.securityTime = vm.formatedTime
      progFactory.update(vm.program).then(() => {
        // $rootScope.$broadcast('updateEdit25', { message: 'refreshing' });
        vm.programsFacade.currentProgram = vm.program;
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.formProgram,
      () => {
        $scope.$emit('formUpdated', vm.formProgram);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
