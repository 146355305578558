import { getContainer } from '@vegga/front-store';
import { Subject, switchMap, take, takeUntil, tap } from 'rxjs';

(function () {
  'use strict';
  angular.module('agronicwebApp').controller('filtersHeaderConfigController', filtersHeaderConfigController);

  filtersHeaderConfigController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'unitFactory',
    'filterFactory',
    '$rootScope',
  ];

  function filtersHeaderConfigController($scope, $state, $stateParams, unitFactory, filterFactory, $rootScope) {
    var vm = this;
    vm.save;
    vm.cancel;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();
    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          tap((currentUnit) => {
            if (!currentUnit) {
              $state.go('units');
            }
            loadData(currentUnit);
          }),
          take(1),
          switchMap(() => filterFactory.getFiltersResponse()),
          takeUntil(vm.destroy$)
        )
        .subscribe(
          (filters) =>
            (vm.filters =
              vm.currentUnit.version < +$rootScope.env.A4500_VERSION
                ? filters.filter((filter) => +filter.pk.headerId === 1)
                : filters)
        );
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      $state.go('.header', { headerId: $stateParams.headerId });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
