import '../components/calc_sensors/calcs-controller';
import calcsTemplate from '../components/calc_sensors/calcs.html';

import '../components/calc_sensors/dpv/dpv-controller';
import calcsDpvTemplate from '../components/calc_sensors/dpv/dpv.html';

import '../components/calc_sensors/pdr/pdr-controller';
import calcsPdrTemplate from '../components/calc_sensors/pdr/pdr.html';

import '../components/calc_sensors/disponible_water/water-controller';
import '../components/calc_sensors/disponible_water/water.history-controller';
import calcsWaterConfigTemplate from '../components/calc_sensors/disponible_water/water_config.html';
import calcsWaterTemplate from '../components/calc_sensors/disponible_water/water_detail.html';

import '../components/calc_sensors/eto/eto-controller';
import calcsEtoTemplate from '../components/calc_sensors/eto/eto.html';

import '../components/calc_sensors/rain/rain-controller';
import calcsRainTemplate from '../components/calc_sensors/rain/rain.html';

import '../components/graphics/graphics-controller';
import '../components/graphics/graphics.detail-controller';
import '../components/graphics/graphics.edit-controller';
import graphicsTemplate from '../components/graphics/graphics.html';
import graphisDetailTemplate from '../components/graphics/view/graphics.detail.html';
import graphicsEditTemplate from '../components/graphics/view/graphics.edit.html';

(function () {
  'use strict';
  angular.module('agronicwebApp').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('agroclimatic', {
          parent: 'main',
          url: `${process.env.BASE_HREF}/agroclimatic-indices`,
          template: calcsTemplate,
          controller: 'calcsController',
          authenticate: true,
          controllerAs: 'vm',
        })
        .state('agroclimatic.water', {
          //parent:'calcs',
          url: '/waterdisp',
          template: calcsWaterTemplate,
          controller: 'calcsWaterHistoryController',
          authenticate: true,
          controllerAs: 'vm',
        })

        .state('agroclimatic.water.config', {
          //parent:'calcs',
          url: '/config',
          params: {
            units: null,
            list: null,
            sensor: null,
          },
          template: calcsWaterConfigTemplate,
          controller: 'calcsWaterController',
          authenticate: true,
          controllerAs: 'vm',
        })

        .state('agroclimatic.dpv', {
          //parent:'calcs',
          url: '/dpv',
          template: calcsDpvTemplate,
          controller: 'dpvController',
          authenticate: true,
          controllerAs: 'vm',
        })

        .state('agroclimatic.pdr', {
          //parent:'calcs',
          url: '/pdr',
          template: calcsPdrTemplate,
          controller: 'pdrController',
          authenticate: true,
          controllerAs: 'vm',
        })

        // TODO - If state is defined with ".", it's not necessary to add parent attribute
        .state('agroclimatic.eto', {
          //parent:'calcs',
          url: '/eto',
          params: {
            units: null,
            id: null,
          },
          template: calcsEtoTemplate,
          controller: 'etoController',
          authenticate: true,
          controllerAs: 'vm',
        })

        // TODO - If state is defined with ".", it's not necessary to add parent attribute
        .state('agroclimatic.rain', {
          //parent:'calcs',
          url: '/rain',
          params: {
            units: null,
            id: null,
          },
          template: calcsRainTemplate,
          controller: 'rainController',
          authenticate: true,
          controllerAs: 'vm',
        })

        .state('agroclimaticGraphics', {
          parent: 'main',
          url: `${process.env.BASE_HREF}/agroclimatic-indices/graphics`,
          params: {
            units: null,
          },
          template: graphicsTemplate,
          controller: 'graphicsController',
          authenticate: true,
          controllerAs: 'vm',
        })
        .state('agroclimaticGraphics.detail', {
          params: {
            unitList: null,
            graphic: null,
          },
          url: '/detail',
          template: graphisDetailTemplate,
          controller: 'graphicsDetailController',
          authenticate: true,
          controllerAs: 'vm',
        })
        .state('agroclimaticGraphics.edit', {
          params: {
            unitList: null,
            graphic: null,
          },
          url: '/edit',
          template: graphicsEditTemplate,
          controller: 'graphicsEditController',
          authenticate: true,
          controllerAs: 'vm',
        });
    },
  ]);
})();
