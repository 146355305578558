import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('moduleLayerController', moduleLayerController);

  moduleLayerController.$inject = ['$rootScope', '$scope', '$state', 'commFactory'];

  function moduleLayerController($rootScope, $scope, $state, commFactory) {
    var vm = this;
    vm.module;

    activate();

    /*******************/

    function activate() {
      if (!$state.params.moduleId || !$state.params.unit || !$state.params.moduleType || $state.params.type === null) {
        closeModuleInfo();
        return;
      }

      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.selectEquipo = false;
      vm.equipoInfo = false;
      vm.deleteModuleMarker = deleteModuleMarker;
      vm.closeModuleInfo = closeModuleInfo;
      vm.parseLastComm = parseLastComm;
      vm.checkStatus = checkStatus;
      vm.UNITS = UNITS;

      vm.currentUnit = $state.params.unit;
      vm.moduleId = $state.params.moduleId;
      vm.moduleType = $state.params.moduleType;
      vm.linkboxId = $state.params.linkboxId;
      vm.type = $state.params.type;

      commFactory.linkBoxEM(vm.currentUnit.id, vm.moduleType).then((list) => {
        vm.linkboxes = list.plain();
        let linkbox = vm.linkboxes[0];

        if (vm.linkboxId) {
          linkbox = vm.linkboxes.find((box) => box.pk.id === vm.linkboxId);
        }

        if (linkbox) {
          const { externalModulesLora, externalModulesMAM120, externalModulesMAR433, externalModules } = linkbox;
          const modules = externalModulesLora || externalModulesMAM120 || externalModulesMAR433 || externalModules;

          if (!vm.type) {
            vm.module = linkbox;
          } else {
            const module = modules.find((m) => m.pk.id === vm.moduleId);
            vm.module = module;
          }
          getModuleState(vm.module);
        }
      });
    }

    vm.types = {
      1: { type: 'Monocable', name: 'AM120', cod: 'EAM', mod: 'MAM' },
      2: { type: 'Radio', name: 'AR868-16', cod: 'EAR', mod: 'MAR' },
      3: { type: 'Radio', name: 'AR24', cod: 'EAR', mod: 'MAR' },
      4: { type: 'Radio', name: 'AR433', cod: 'EAR', mod: 'MAR' },
      5: { type: 'Radio', name: 'MI', cod: 'MI' },
      6: { type: 'Radio', name: 'ME', cod: 'ME' },
      7: { type: 'Radio', name: 'R868', cod: 'EAR', mod: 'MAR' },
      8: { type: 'Radio', name: 'AgroBee', cod: 'Coordinador', mod: 'Módulo' },
      9: { type: 'Radio', name: 'AgroBeeL', cod: 'Coordinador', mod: 'Módulo' },
      10: { type: 'Radio', name: 'SDI12', cod: '', mod: 'Dispositivo' },
    };

    vm.agroBeeTypes = {};

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }
    function parseLastComm(value) {
      var s = [];
      for (var j = 0; j < 16; j++) s.push((value & (1 << j)) >> j);
      return s;
    }

    function closeModuleInfo() {
      $rootScope.$broadcast('closeModule');
      $state.go('^');
    }

    function getModuleState(module) {
      if (module.linkBoxId === undefined) {
        switch (module.pk.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            if (module.xCommunicate) {
              if (module.pk.type === 7) {
                if (module.port !== null && module.port !== 0) {
                  module.state = 'comm';
                } else {
                  module.state = 'noconf';
                }
              } else {
                module.state = 'comm';
              }
            } else if (module.active && !module.xCommunicate) {
              module.state = 'error';
            } else {
              module.state = 'nocomm';
            }
            break;
          case 8:
          case 9:
            switch (module.xState) {
              case 0:
                module.state = 'noconf';
                break;
              case 1:
                module.state = 'nocomm';
                break;
              case 2:
                module.state = 'comm';
                break;
              case 3:
                module.state = 'error';
                break;
            }
            break;
        }
      } else {
        switch (module.pk.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            if (module.xState === 1) {
              module.state = 'comm';
            } else {
              module.state = 'nocomm';
            }
            break;
          case 8:
          case 9:
            if (module.xState === 1 || module.xState === 0) {
              if (module.xState === 1) module.state = 'nocomm';
              if (module.xState === 0) module.state = 'noconf';
            } else {
              module.state = 'comm';
            }
            break;
        }
      }
    }

    function deleteModuleMarker() {
      $rootScope.$broadcast('deleteModule');
      $state.go('^');
    }

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });
  }
})();
