(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsController', pivotsController);

  pivotsController.$inject = ['$scope', '$state', 'pivotsFactory', '$filter', '$confirm'];

  function pivotsController($scope, $state, pivotsFactory, $filter, $confirm) {
    var vm = this;
    vm.cancel;
    vm.save;
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('pivot.pivot'), field: '' },
      { headerName: $filter('translate')('pivot.name'), field: '' },
      { headerName: $filter('translate')('pivot.pi2'), field: '' },
      { headerName: $filter('translate')('pivot.pi3'), field: '' },
      { headerName: $filter('translate')('pivot.pi10'), field: '' },
    ];

    activate();

    function activate() {
      vm.currentState = $state;
      vm.changeState = changeState;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.pivot = $state.params.pivot;
        vm.dataGridRowClick = dataGridRowClick;
        vm.changePivot = changePivot;
        vm.changingPivot = changingPivot;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.changePivotState = changePivotState;
        vm.cancel = cancel;
        vm.save = save;

        if (!($state.includes('pivots.edit') || $state.includes('pivots.detail') || $state.includes('pivots.config'))) {
          $state.go('.', { unit: vm.currentUnit, pivot: vm.pivot });
        }

        loadPivots($state.params.unit.id, 0, $state.params.config);
      } else {
        $state.go('units');
      }
    }

    function dataGridRowClick(_e, pivot) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      changeState({ pivot: pivot, id: pivot.pk.id }, $state.params.config);
    }

    function changeState(params) {
      vm.active = null;
      vm.pivot = params.pivot;
      const path = $state.current.name.split('.')[1];
      $state.go(`pivots.${path || 'detail'}`, params);
    }

    function loadPivots(id, pivot, config) {
      return pivotsFactory.pivots(id).then(function (data) {
        vm.pivots = data.plain();
      });
    }

    function refresh(info) {
      var selected;
      if (vm.pivot !== undefined) {
        selected = vm.pivot.pk.id - 1;
      } else {
        selected = 0;
      }
      if (info === null) info = false;
      loadPivots($state.params.unit.id, selected, info);
    }

    $scope.$on('refresh', function (event, args) {
      if (args && args.info) {
        refresh(true);
      } else {
        refresh();
      }
    });

    $scope.$on('formUpdated', function (event, args) {
      vm.formPivots = args;
    });

    function dataGridRowClick(_e, pivot) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.pivotSelected = pivot;
      changeState({ pivot: pivot, id: pivot.pk.id }, $state.params.config);
    }

    function changePivot(e) {
      if (e.detail.value) {
        vm.pivotSelected = e.detail.value;
        changeState({ pivot: vm.pivotSelected, id: vm.pivotSelected.pk.id }, $state.params.config);
      }
    }

    function changingPivot($event) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changePivot($event);
          break;
      }
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changePivotState(state, _params) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, _params); //Canviem d'estat
        });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('pivots', { pivot: vm.pivot, id: vm.pivot.id });
          } else {
            $state.go('pivots.detail', { pivot: vm.pivot, id: vm.pivot.pk.id });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('pivots', { pivot: vm.pivot, id: vm.pivot.pk.id })
          : $state.go('pivots.detail', { pivot: vm.pivot, id: vm.pivot.pk.id });
      }
    }
    function save(_ev) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }
  }
})();
