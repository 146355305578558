(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('veggaSelectFactory', veggaSelectFactory);

  veggaSelectFactory.$inject = ['$filter'];

  function veggaSelectFactory($filter) {
    var op = {
      formatterVeggaSelect: formatterVeggaSelect,
      addedDefaultOption: addedDefaultOption,
    };

    return op;

    /**
     * Function that gives the correct format to be consumed in the vegga-select-legacy
     * @param {Array | Object} options
     * @param {string} valuePropertyName
     * @param {string} labelPropertyName
     * @param {string} typeOfValue Argument for to force the value type
     * @returns a list with data formatted in order to use in the vegga-select-legacy
     */
    function formatterVeggaSelect(options, valuePropertyName = 'value', labelPropertyName = 'text', typeOfValue) {
      if (!Array.isArray(options)) {
        return _createOptionFromObject(options);
      } else {
        return options.map((option) => {
          if (typeof option === 'object') {
            return _createOptionFromObjectArray(option, labelPropertyName, valuePropertyName, typeOfValue);
          } else {
            const typeOption = typeOfValue || typeof option;
            return _createOptionFromSimpleArray(option, typeOption);
          }
        });
      }
    }

    /**
     * Function for add a default option to the select
     * @param {Array} options Array that contains the select options
     * @param {string | number} defaultValue Value
     * @param {string} defaultLabel Label
     * @param {boolean} translate optional argument that enables the possibility of the label field being translated or not
     * @returns a list with default option at the first position
     */
    function addedDefaultOption(options, defaultValue, defaultLabel, translate = true) {
      var label = translate ? _utilsGenerateLabel(defaultLabel) : defaultLabel;
      options.unshift({ text: label, value: defaultValue });
      return options;
    }

    /* Generator option function */

    function _createOptionFromObject(options) {
      return Object.entries(options).map((option) => {
        return { value: _utilsPersistTypeOfValue(option[0]), text: option[1] };
      });
    }

    function _createOptionFromSimpleArray(option, typeOfValue) {
      return { text: option, value: _utilsSetValueWithTypeDefined(option, typeOfValue) };
    }

    function _createOptionFromObjectArray(option, labelPropertyName, valuePropertyName, typeOfValue) {
      const label = _utilsGenerateLabel(labelPropertyName, option);
      const value = _utilsGenerateValue(valuePropertyName, option);
      const tmpTypeOfValue = typeOfValue || typeof value;

      return { text: label, value: _utilsSetValueWithTypeDefined(value, tmpTypeOfValue) };
    }

    /* Utils functions */

    function _utilsGenerateLabel(label, option) {
      var compiledLabel = label;
      const rawLabel = label.match(/\{{.+?\}}/g);
      rawLabel.forEach((labelItem) => {
        const parsedLabel = labelItem.replace('{{', '').replace('}}', '');
        if (!option) {
          compiledLabel = compiledLabel.replace(labelItem, $filter('translate')(parsedLabel));
        } else {
          compiledLabel = compiledLabel.replace(labelItem, _.at(option, [parsedLabel])[0]);
        }
      });
      return compiledLabel;
    }

    function _utilsGenerateValue(val, option) {
      const rawValue = val.match(/\{{.+?\}}/g);
      const parsedValue = rawValue[0].replace('{{', '').replace('}}', '');
      const result = _.at(option, [parsedValue])[0];
      return result;
    }

    function _utilsSetValueWithTypeDefined(value, typeOfValue) {
      return typeOfValue === 'number' ? Number(value) : String(value);
    }

    function _utilsPersistTypeOfValue(val) {
      return isNaN(val) ? val : Number(val);
    }
  }
})();
