import '../components/programs/agronic7/programs-controller';
import program7Template from '../components/programs/agronic7/programs.html';

import '../components/programs/agronic7/programs.child-controller';
import programDetail7Template from '../components/programs/agronic7/programs.detail.html';
import programEdit7Template from '../components/programs/agronic7/programs.edit.html';
import programConfig7Template from '../components/programs/agronic7/programs.config.html';

import '../components/trays/trays7-controller';
import trayTemplate from '../components/trays/views/trays7.html';

import '../components/trays/trays7.detail-controller';
import trayDetailTemplate from '../components/trays/views/trays7.detail.html';

import '../components/trays/trays7.config-controller';
import trayConfigTemplate from '../components/trays/views/trays7.config.html';

//TODO check if it's necessary to import -> tray factory
import '../components/trays/trays7-factory';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider

          .state('a7programs', {
            parent: 'unit',
            url: '/a7/programs',
            params: {
              unit: null,
              config: null,
            },
            template: program7Template,
            controller: 'programsControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a7programs.detail', {
            //parent: 'a7programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/detail/{id}',
            params: {
              program: null,
              id: null,
            },
            template: programDetail7Template,
            controller: 'programsChildControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a7programs.edit', {
            //parent: 'a7programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/edit/{id}',
            params: {
              program: null,
              id: null,
            },
            template: programEdit7Template,
            controller: 'programsChildControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a7programs.config', {
            //parent: 'a7programs',
            // Use a url of '/' to set a states as the 'index'.
            url: '/config/{id}',
            params: {
              program: null,
              id: null,
            },
            template: programConfig7Template,
            controller: 'programsChildControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('a7trays', {
            parent: 'unit',
            url: '/a7/trays',
            params: {
              unit: null,
              config: null,
            },
            template: trayTemplate,
            controller: 'traysControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a7trays.detail', {
            //parent: 'a7trays',
            url: '/detail',
            params: {
              unit: null,
              id: null,
            },
            template: trayDetailTemplate,
            controller: 'traysDetailControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('a7trays.config', {
            //parent: 'a7trays',
            url: '/config',
            params: {
              unit: null,
              id: null,
            },
            template: trayConfigTemplate,
            controller: 'traysConfigControllerA7',
            authenticate: true,
            controllerAs: 'vm',
          });
        /***/
      },
    ]);
})();
