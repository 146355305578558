import moment from 'moment/moment';
import { exportToDocument } from '@vegga/front-utils';
import { HISTORY_LABELS_ENUM } from './history-label.enum';

(function () {
  'use strict';
  angular.module('agronicwebApp').constant('moment', moment).factory('historyUtilsFactory', historyUtilsFactory);

  historyUtilsFactory.$inject = ['$filter'];

  function historyUtilsFactory($filter) {
    let from = moment().subtract(7, 'day').format('DD-MM-YYYY');
    let to = moment().format('DD-MM-YYYY');
    const maxDateValue = moment().format('DD-MM-YYYY');

    return {
      from,
      to,
      maxDateValue,
      getMinDate,
      getGroupBy,
      getDateColumnDef,
      generatedColumnDef,
      exportXLS,
    };

    function getMinDate(isProfessional) {
      return isProfessional ? null : moment().subtract(7, 'day').format('DD-MM-YYYY');
    }

    function getGroupBy(remove10MinutesOption = false, removeHourOption = false) {
      let groupBy = [
        { text: '10 ' + $filter('translate')('history.minutes'), value: '0' },
        { text: $filter('translate')('history.hour'), value: '1' },
        { text: $filter('translate')('history.day'), value: '2' },
        { text: $filter('translate')('history.week'), value: '3' },
        { text: $filter('translate')('history.month'), value: '4' },
        { text: $filter('translate')('history.dates'), value: '5' },
      ];
      if (removeHourOption) {
        groupBy.filter((item) => item.value !== '1');
      }
      if (remove10MinutesOption) {
        groupBy.shift();
      }
      return groupBy;
    }

    function getDateColumnDef() {
      return {
        field: 'from',
        width: 400,
      };
    }

    function generatedColumnDef(data, sorting = false) {
      return data.map((gridColum) => {
        const sortingProps = {
          sorting: true,
          sortingDir: 'ASC',
        };
        const columnProps = {
          ...gridColum,
          headerName: $filter('translate')(HISTORY_LABELS_ENUM[gridColum.field]),
        };
        return sorting
          ? {
              ...columnProps,
              ...sortingProps,
            }
          : columnProps;
      });
    }

    function exportXLS(gridColumnDef, data, fileName) {
      exportToDocument('xls', gridColumnDef, data, {
        fileName: $filter('translate')(fileName),
      });
    }
  }
})();
