(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('pivotsFactory', pivotsFactory);

  pivotsFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function pivotsFactory(Restangular, $rootScope, $q) {
    var op = {
      pivots: pivots,
      update: update,
    };

    return op;

    function pivots(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('pivots')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('pivots')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
