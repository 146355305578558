(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('autoFormatFactory', autoFormatFactory);

  autoFormatFactory.$inject = ['commFactory', '$q'];

  function autoFormatFactory(commFactory, $q) {
    var operations = {
      setFormats: setFormats,
      setModule: setModule,
      parse: parseAutoFormat,
    };

    let formats;
    let module;

    let auto;

    return operations;

    function setFormats(f) {
      formats = f;
    }
    function setModule(m) {
      module = m;
    }
    function parseAutoFormat(selected) {
      auto = {
        id: null,
        format: null,
      };
      var deferred = $q.defer();
      if (selected !== undefined && selected !== null) {
        let type = Number(selected.type);
        let module = Number(selected.module);
        let number = Number(selected.number);
        switch (type) {
          case 0: //Base
            if ((module = 1)) {
              switch (number) {
                case 1:
                  auto.id = 40;
                  break;
                case 2:
                  auto.id = 39;
                  break;
                case 3:
                  auto.id = 38;
                  break;
              }
              deferred.resolve(auto);
            }
            break;
          case 1: //Agrobee
            switch (number) {
              case 13:
              case 14:
                auto.id = 39;
                deferred.resolve(auto);
                break;
              case 15:
              case 16:
                auto.id = 38;
                deferred.resolve(auto);
                break;
              default:
                deferred.resolve(parseAgrobeeFormat(module, number));
            }
            break;
          case 3: //Lora
            switch (number) {
              case 14:
              case 15:
                auto.id = 39;
                deferred.resolve(auto);
                break;
              case 16:
                auto.id = 38;
                deferred.resolve(auto);
                break;
              default:
                return parseAgrobeeLFormat(module, number);
            }
          case 4:
            {
              //SDI12
            }
            break;
          case 5:
            {
              //DAVIS
            }
            break;
        }
      } else {
        deferred.resolve({});
      }
      return deferred.promise;
    }

    function checkFormat(format) {
      let exists = formats.filter(format);
      if (!_.isEmpty(exists)) {
        return exists[0].id;
      } else {
        return null;
      }
    }

    function newFormat() {
      let exists = checkFormat({
        integers: 0,
        decimals: 0,
      });
      if (exists && Number(exists) <= 31) {
        return exists;
      } else {
        return null;
      }
    }

    function parseAgrobeeFormat(module, number) {
      let device = formats[0].pk.deviceId;
      let format = {};
      let result;
      return commFactory.externalModule(device, 8, module).then((response) => {
        let module = response.plain();
        if (module && module.model) {
          switch (module.model) {
            case 2: //H20
              switch (module.sensorType) {
                case 0: // Decagon
                  format = {
                    units: '%',
                    name: 'H20-Decagon',
                    sign: false,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 500,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  //sensoragrobee = true;
                  break;
                case 1: // Watermark
                  //ObtenerFormatoH2OWatermark(formato);
                  //sensoragrobee = true;
                  break;
                case 2: // Solfranc
                  //ObtenerFormatoH2OSolfranc(formato);
                  //sensoragrobee = true;
                  break;
                case 3: //Decagon Hoja LWS
                  //ObtenerFormatoH2ODecagonHoja(formato);
                  //sensoragrobee = true;
                  break;
                case 4: //Decagon EC-5
                  //ObtenerFormatoH2ODecagonEC5(formato);
                  //sensoragrobee = true;
                  break;
              }
              break;
            case 4: //SDI12
              break;
            case 9: //3lV
              break;
            case 10: //Dendrometre
              break;
            case 11: //Watermark
              break;
            case 12: //Parshall
              break;
            case 13: //GNSS
              break;
          }
          if (result !== null) {
            auto.id = result;
          } else {
            let new_format_id = newFormat();
            if (new_format_id !== null) {
              format.pk = {};
              format.pk.id = new_format_id;
              auto.format = format;
            }
          }
          return auto;
        }
      });
    }

    function parseAgrobeeLFormat(module, number) {
      let device = formats[0].pk.deviceId;
      let format = {};
      let result;
      let deferred = $q.defer();
      commFactory.externalModule(device, 9, module).then((response) => {
        let module = response.plain();
        if (module && module.model) {
          switch (module.model) {
            case 3: //H20
              switch (module.sensorType) {
                case 0: // Decagon
                  format = {
                    units: '%',
                    name: 'H20-Decagon',
                    sign: false,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 500,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 1: // Watermark
                  format = {
                    units: 'cbars',
                    name: 'Watermark',
                    sign: false,
                    integers: 3,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 2390,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 2: // Solfranc
                  format = {
                    units: '%',
                    name: 'Solfranc',
                    sign: false,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 2324,
                    linearizationPointR3: 4000,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 100,
                    linearizationPointL3: 500,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 3: //Decagon Hoja LWS
                  format = {
                    units: '%',
                    name: 'DecagonLWS',
                    sign: false,
                    integers: 3,
                    decimals: 0,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 100,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 4: //Decagon EC-5
                  format = {
                    units: '%',
                    name: 'Decagon EC-5',
                    sign: false,
                    integers: 3,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 1000,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 5: //Decagon GS1
                  format = {
                    units: '%',
                    name: 'Decagon GS1',
                    sign: false,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 570,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
                case 6: //Teros-10
                  format = {
                    units: '%',
                    name: 'Teros-10',
                    sign: false,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: 0,
                    linearizationPointL2: 700,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                  result = checkFormat(format);
                  break;
              }
              break;
            case 4: //SDI12
              let limitentradaHumitat;
              let limitentradaCE;
              let limitentradaTemperatura;
              switch (module.sensorType) {
                case 0: // 5TE Decagon
                  if (number % 3 == 1) {
                    // sensor VWC
                    if (module.readFormat == 1) {
                      format = {
                        units: '',
                        name: '5TE Decagon',
                        sign: false,
                        integers: 2,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 10,
                        linearizationPointL2: 800,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    } else {
                      format = {
                        units: '%',
                        name: '5TE Decagon',
                        sign: false,
                        integers: 3,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 0,
                        linearizationPointL2: 1000,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    }
                  } else if (number % 3 == 2) {
                    // sensor CE
                    format = {
                      units: 'mS/cm',
                      name: '5TE Decagon',
                      sign: false,
                      integers: 2,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 2300,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: '5TE Decagon',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -400,
                      linearizationPointL2: 500,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 1: // GS3 Decagon
                  if (number % 3 == 1) {
                    // sensor VWC
                    if (module.readFormat == 1) {
                      format = {
                        units: '',
                        sign: false,
                        integers: 2,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 10,
                        linearizationPointL2: 800,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    } else {
                      format = {
                        units: '%',
                        sign: false,
                        integers: 3,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 0,
                        linearizationPointL2: 1000,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    }
                  } else if (number % 3 == 2) {
                    // sensor CE
                    format = {
                      units: 'mS/cm',
                      name: 'GS3 Decagon',
                      sign: false,
                      integers: 2,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 2300,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      sign: false,
                      name: 'GS3 Decagon',
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -400,
                      linearizationPointL2: 500,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 2: // CS650 Campbell
                  if (number % 3 == 1) {
                    // sensor VWC
                    format = {
                      units: '%',
                      name: 'CS650 Campbell',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 500,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number % 3 == 2) {
                    // sensor CE
                    format = {
                      units: 'mS/cm',
                      name: 'CS650 Campbell',
                      sign: false,
                      integers: 1,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 800,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: 'CS650 Campbell',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -100,
                      linearizationPointL2: 700,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 3: // Hidraprobe II
                  if (number % 3 == 1) {
                    // sensor VWC
                    format = {
                      units: '%',
                      name: 'Hidraprobe II',
                      sign: false,
                      integers: 3,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number % 3 == 2) {
                    // sensor CE
                    format = {
                      units: 'S/m',
                      name: 'Hidraprobe II',
                      sign: false,
                      integers: 1,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 1,
                      linearizationPointL2: 150,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: 'Hidraprobe II',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -100,
                      linearizationPointL2: 550,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 4: //Aquacheck-4
                  if (number % 3 == 2) {
                    /*Entrada sin lectura para este modelo AgroBee*/
                  } else if (number % 3 == 1) {
                    // sensor VWC
                    format = {
                      units: '%',
                      name: 'Aquacheck-4',
                      sign: false,
                      integers: 3,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number % 3 == 0) {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: 'Aquacheck-4',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -100,
                      linearizationPointL2: 500,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 5: //Aquacheck-8
                  if (number > 12) {
                    /*Entrada sin lectura para este modelo AgroBee*/
                  } else if (number < 9) {
                    // sensor VWC
                    format = {
                      units: '%',
                      sign: false,
                      name: 'Aquacheck-8',
                      integers: 3,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number < 13) {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: 'Aquacheck-8',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -400,
                      linearizationPointL2: 500,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 6: //Sentek Humitat
                  //model.sentekLvl

                  if (model.sentekLvl == 0) {
                    // 3 nivells
                    limitentradaHumitat = 3;
                    limitentradaTemperatura = 6;
                  } else if (model.sentekLvl == 1) {
                    // 6 nivells
                    limitentradaHumitat = 6;
                    limitentradaTemperatura = 12;
                  } else if (model.sentekLvl == 2) {
                    // 9 nivells
                    limitentradaHumitat = 9;
                    limitentradaTemperatura = 12;
                  } else if (model.sentekLvl == 3) {
                    // 12 nivells
                    if (module.readFormat == 0) {
                      limitentradaHumitat = 12;
                      limitentradaTemperatura = 0;
                    } else {
                      limitentradaHumitat = 6;
                      limitentradaTemperatura = 12;
                    }
                  }

                  if (number <= limitentradaHumitat) {
                    //VWC Humitat (000.0 %)
                    format = {
                      units: '%',
                      sign: false,
                      integers: 3,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number <= limitentradaTemperatura) {
                    //Temperatura (00.0 ºC)
                    format = {
                      units: 'ºC',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -200,
                      linearizationPointL2: 600,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 7: //Sentek Triscan
                  if (model.sentekLvl == 0) {
                    // 3 nivells
                    limitentradaHumitat = 3;
                    limitentradaCE = 6;
                    limitentradaTemperatura = 9;
                  } else if (model.sentekLvl == 1) {
                    // 6 nivells
                    limitentradaHumitat = 6;
                    if (module.readFormat == 0) {
                      limitentradaCE = 12;
                      limitentradaTemperatura = 0;
                    } else {
                      limitentradaCE = 9;
                      limitentradaTemperatura = 12;
                    }
                  } else if (model.sentekLvl == 2) {
                    // 9 nivells
                    limitentradaHumitat = 9;
                    limitentradaCE = 12;
                    limitentradaTemperatura = 0;
                  } else if (model.sentekLvl == 3) {
                    // 12 nivells
                    limitentradaHumitat = 6;
                    limitentradaCE = 12;
                    limitentradaTemperatura = 0;
                  }

                  if (number <= limitentradaHumitat) {
                    //VWC Humitat (000.0 %)
                    format = {
                      units: '%',
                      name: 'Sentek Tri',
                      sign: false,
                      integers: 3,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number <= limitentradaCE) {
                    //Temperatura (00000)
                    format = {
                      units: '',
                      name: 'Sentek Tri',
                      sign: false,
                      integers: 5,
                      decimals: 0,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 10000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number <= limitentradaTemperatura) {
                    //Temperatura (00.0 ºC)
                    format = {
                      units: 'ºC',
                      name: 'Sentek Tri',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -200,
                      linearizationPointL2: 600,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 8: // TEROS-12
                  //module.readFormat
                  if (number % 3 == 1) {
                    // sensor VWC
                    if (module.readFormat == 1) {
                      format = {
                        units: '',
                        name: 'TEROS12',
                        sign: false,
                        integers: 2,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 10,
                        linearizationPointL2: 800,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    } else {
                      format = {
                        units: '%',
                        name: 'TEROS12',
                        sign: false,
                        integers: 3,
                        decimals: 1,
                        linearizationPointR1: 800,
                        linearizationPointR2: 4000,
                        linearizationPointR3: 0,
                        linearizationPointR4: 0,
                        linearizationPointL1: 0,
                        linearizationPointL2: 1000,
                        linearizationPointL3: 0,
                        linearizationPointL4: 0,
                      };
                    }
                  } else if (number % 3 == 2) {
                    // sensor CE
                    format = {
                      units: 'mS/cm',
                      name: 'TEROS12',
                      sign: false,
                      integers: 2,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: 0,
                      linearizationPointL2: 2300,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else {
                    // sensor temperatura
                    format = {
                      units: 'ºC',
                      name: 'TEROS12',
                      sign: true,
                      integers: 2,
                      decimals: 1,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -400,
                      linearizationPointL2: 600,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 9: //NDVI/PRI
                  if (number % 3 == 1) {
                    // sensor VWC
                    format = {
                      units: '',
                      name: 'NDVI/PRI',
                      sign: true,
                      integers: 1,
                      decimals: 2,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -1000,
                      linearizationPointL2: 1000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  } else if (number % 3 == 2) {
                    //RAW
                    format = {
                      units: '',
                      name: 'NDVI/PRI',
                      sign: true,
                      integers: 1,
                      decimals: 3,
                      linearizationPointR1: 800,
                      linearizationPointR2: 4000,
                      linearizationPointR3: 0,
                      linearizationPointR4: 0,
                      linearizationPointL1: -2000,
                      linearizationPointL2: 2000,
                      linearizationPointL3: 0,
                      linearizationPointL4: 0,
                    };
                  }
                  result = checkFormat(format);
                  break;
                case 10: //TEROS 21
                  break;
              }
              break;
            case 9: //3lV
              if (number % 3 == 1) {
                // distancia entre el sensor i la superficie
                format = {
                  units: 'm',
                  name: '3LV',
                  sign: false,
                  integers: 1,
                  decimals: 3,
                  linearizationPointR1: 0,
                  linearizationPointR2: 0,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 0,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
              } else if (number % 3 == 2) {
                // distancia entre la superficie i el fons
                format = {
                  units: 'm',
                  name: '3LV',
                  sign: false,
                  integers: 2,
                  decimals: 3,
                  linearizationPointR1: 0,
                  linearizationPointR2: 0,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 0,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
              } else {
                // volum
                format = {
                  units: 'm3',
                  name: '3LV',
                  sign: false,
                  integers: 2,
                  decimals: 3,
                  linearizationPointR1: 0,
                  linearizationPointR2: 0,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 0,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
              }
              result = checkFormat(format);
              break;
            case 10: //Dendrometre
              if (number == 1 || number == 2) {
                format = {
                  units: 'mm',
                  sign: false,
                  integers: 2,
                  decimals: 3,
                  linearizationPointR1: 0,
                  linearizationPointR2: 0,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 0,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
                // no es fan anar els punts logics i reals
              }
              result = checkFormat(format);
              break;
            case 11: //Watermark
              if (number == 4) {
                // sensor temperatura
                format = {
                  units: 'ºC',
                  name: 'Watermark',
                  sign: true,
                  integers: 2,
                  decimals: 1,
                  linearizationPointR1: 0,
                  linearizationPointR2: 0,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 0,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
                // no es fan anar els punts logics i reals
              } else {
                format = {
                  units: 'cbars',
                  sign: false,
                  integers: 3,
                  decimals: 1,
                  linearizationPointR1: 800,
                  linearizationPointR2: 4000,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 2390,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
              }
              result = checkFormat(format);
              break;
            case 12: //Parshall
              switch (module.readFormat) {
                case 0: // 000.00 m3/h
                  format = {
                    units: 'm3/h',
                    sign: false,
                    integers: 3,
                    decimals: 2,
                  };
                  break;
                case 1: // 0000.0 m3/h
                  format = {
                    units: 'm3/h',
                    sign: false,
                    integers: 4,
                    decimals: 1,
                  };
                  break;
                case 2: // 00000 m3/h
                  format = {
                    units: 'm3/h',
                    sign: false,
                    integers: 5,
                    decimals: 0,
                  };
                  break;
                case 3: // 000.00 m3/s
                  format = {
                    units: 'm3/s',
                    sign: false,
                    integers: 3,
                    decimals: 2,
                  };
                  break;
                case 4: // 0000.0 m3/s
                  format = {
                    units: 'm3/s',
                    sign: false,
                    integers: 4,
                    decimals: 1,
                  };
                  break;
                case 5: // 00000 m3/s
                  format = {
                    units: 'm3/s',
                    sign: false,
                    integers: 5,
                    decimals: 0,
                  };
                  break;
                case 6: // 000.00 l/s
                  format = {
                    units: 'l/',
                    sign: false,
                    integers: 3,
                    decimals: 2,
                  };
                  break;
                case 7: // 0000.0 l/s
                  format = {
                    units: 'l/s',
                    sign: false,
                    integers: 4,
                    decimals: 1,
                  };
                  break;
                case 8: // 00000 l/s
                  format = {
                    units: 'l/s',
                    sign: false,
                    integers: 5,
                    decimals: 0,
                  };
                  break;
                case 9: // 000.00 GPM
                  format = {
                    units: 'GPM',
                    sign: false,
                    integers: 3,
                    decimals: 2,
                  };
                  break;
                case 10: // 0000.0 GPM
                  format = {
                    units: 'GPM',
                    sign: false,
                    integers: 4,
                    decimals: 1,
                  };
                  break;
                case 11: // 00000 GPM
                  format = {
                    units: 'GPM',
                    sign: false,
                    integers: 5,
                    decimals: 0,
                  };
                  break;
              }
              result = checkFormat(format);
              break;
            case 13: //GNSS
              if (number == 1 || number == 3 || number == 4) {
                format = {
                  name: 'GNSS',
                  units: 'º',
                  sign: false,
                  integers: 3,
                  decimals: 1,
                };
                // no es fan anar els punts logics i reals
              } else if (number == 2) {
                format = {
                  name: 'GNSS',
                  units: 'm',
                  sign: false,
                  integers: 4,
                  decimals: 1,
                };
                // no es fan anar els punts logics i reals
              }
              result = checkFormat(format);
              break;
            case 14: //NUTRI
              if (number <= 4) {
                //NUTRISENS
                if (!module.readFormat) {
                  //Lectura directa
                  format = {
                    name: 'NUTRISENS',
                    units: 'mV',
                    sign: true,
                    integers: 4,
                    decimals: 0,
                  };
                } else if (module.readFormat == 1) {
                  //Lectura estimada
                  format = {
                    name: 'NUTRISENS',
                    units: 'mEq/L',
                    sign: false,
                    integers: 2,
                    decimals: 2,
                  };
                }
              } else if (number <= 8) {
                // sensor VWC
                format = {
                  name: 'VWC',
                  units: '%',
                  sign: false,
                  integers: 3,
                  decimals: 1,
                  linearizationPointR1: 800,
                  linearizationPointR2: 4000,
                  linearizationPointR3: 0,
                  linearizationPointR4: 0,
                  linearizationPointL1: 0,
                  linearizationPointL2: 1000,
                  linearizationPointL3: 0,
                  linearizationPointL4: 0,
                };
              } else if (number <= 12) {
                // sensors de temperatura
                if (module.sensorType == 5) {
                  // AQUACHECK-8
                  format = {
                    name: 'AQUACHECK-8',
                    units: 'ºC',
                    sign: true,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: -400,
                    linearizationPointL2: 500,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                }
                if (module.sensorType == 6) {
                  // SENTEK-H
                  format = {
                    name: 'SENTEK-H',
                    units: 'ºC',
                    sign: true,
                    integers: 2,
                    decimals: 1,
                    linearizationPointR1: 800,
                    linearizationPointR2: 4000,
                    linearizationPointR3: 0,
                    linearizationPointR4: 0,
                    linearizationPointL1: -200,
                    linearizationPointL2: 600,
                    linearizationPointL3: 0,
                    linearizationPointL4: 0,
                  };
                }
              }
              break;
          }
        }
        if (result !== null) {
          auto.id = result;
        } else {
          let new_format_id = newFormat();
          if (new_format_id !== null) {
            format.pk = {};
            format.pk.id = new_format_id;
            format.pk.deviceId = device;
            auto.format = format;
          }
        }
        deferred.resolve(auto);
      });
      return deferred.promise;
    }
  }
})();
