import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('dpvController', dpvController);

  dpvController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$confirm',
    '$filter',
    '$q',
  ];

  function dpvController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $confirm,
    $filter,
    $q
  ) {
    var vm = this;
    var backups;
    vm.VeggaOverlayTab = VeggaOverlayTab;
    vm.dpvList = [];
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICM$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
      });

      loadSensors();
      vm.unitName = unitName;
      vm.loadUnitSensors = loadUnitSensors;
      vm.newSensor = newSensor;
      vm.deleteSensor = deleteSensor;
      vm.updateSensor = updateSensor;
      vm.sensorEdition = sensorEdition;
      vm.cancelEdition = cancelEdition;
      vm.sensorHistory = sensorHistory;
      vm.changeTab = changeTab;
      vm.cancel = cancel;
      vm.changeDPV = changeDPV;
      vm.changingDPV = changingDPV;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      backups = {};
      $scope.$emit('stateChanged');
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        const unit = vm.units.find((unit) => {
          return unit.id === sensor.deviceId;
        });
        return unit.name;
      }
    }

    function loadSensors() {
      calculatedsFactory.getDPV(UserData.id).then((result) => {
        if (result.plain()) {
          vm.dpvList = result.plain();
        }
      });
    }
    function updateSensors() {
      calculatedsFactory.getDPV(UserData.id).then((result) => {
        if (result.content) {
          _.forEach(result.content, (o) => {
            let tmp2 = _.find(vm.dpvList, (d) => {
              return d.pk.id === o.pk.id;
            });
            if (tmp2 !== undefined) {
              tmp2.xValue = o.xValue;
            }
          });
        }
      });
    }

    function newSensor() {
      vm.currentDPVSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function deleteSensor() {
      calculatedsFactory.deleteDPV(UserData.id, vm.currentDPVSelected.pk.id).then(() => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.dpvList = vm.dpvList.filter((dpv) => dpv.pk.id !== vm.currentDPVSelected.pk.id);
        vm.currentDPVSelected = null;
      });
    }

    function sensorEdition() {
      var sensor_backup = {};
      angular.copy(vm.currentDPVSelected, sensor_backup);
      backups[vm.currentDPVSelected.pk.id] = sensor_backup;
      if (vm.currentDPVSelected.Templist === undefined || vm.currentDPVSelected.Hrlist === undefined) {
        loadUnitSensors(vm.currentDPVSelected, true);
      }
    }

    function loadUnitSensors(sensor, isFirstLoad, type) {
      if (!isFirstLoad) {
        if (type === 'temp') {
          vm.currentDPVSelected.analogIdTemp = 0;
        }
        if (type === 'hr') {
          vm.currentDPVSelected.analogIdHr = 0;
        }
      }
      if (isFirstLoad) {
        vm.isContentLoaded = false;
      }
      let params = {
        limit: 100,
        page: 1,
      };
      let binding = sensor;

      let promise1 = Promise.resolve();
      let promise2 = Promise.resolve();

      if ((sensor.deviceIdTemp && type === 'temp') || (isFirstLoad && sensor.deviceIdTemp)) {
        binding.Templist = [];
        promise1 = sensorsFactory.analogsPage(sensor.deviceIdTemp, params);
      }

      if ((sensor.deviceIdHr && type === 'hr') || (isFirstLoad && sensor.deviceIdHr)) {
        binding.Hrlist = [];
        promise2 = sensorsFactory.analogsPage(sensor.deviceIdHr, params);
      }

      $q.all([promise1, promise2]).then(([rs1, rs2]) => {
        if (rs1?.content) {
          binding.Templist = rs1.content;
        }
        if (rs2?.content) {
          binding.Hrlist = rs2.content;
        }

        vm.isContentLoaded = true;
      });
    }

    function cancelEdition() {
      if (vm.currentDPVSelected && vm.currentDPVSelected.pk) {
        angular.copy(backups[vm.currentDPVSelected.pk.id], vm.currentDPVSelected);
      }
      vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
    }

    function updateSensor() {
      var tmp = vm.currentDPVSelected;
      calculatedsFactory.updateDPV(UserData.id, vm.currentDPVSelected).then((result) => {
        vm.currentDPVSelected = result.plain();
        tmp.pk = vm.currentDPVSelected.pk;
        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.dpvList.push(vm.currentDPVSelected);
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentDPVSelected = null;
        }
        vm.form.$setPristine();
      });
    }

    function sensorHistory(sensor, reload) {
      vm.currentTab = VeggaOverlayTab.DETAIL;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.currentDPVSelected = sensor;
      let chart;
      var date_to;
      let datos_grafica = [];

      var today = new moment()._d;
      var date_ago = new moment().subtract(7, 'days')._d;

      if (sensor.fecha_desde === undefined) sensor.fecha_desde = date_ago;
      if (sensor.fecha_hasta === undefined) sensor.fecha_hasta = today;

      if (reload) date_to = moment(sensor.fecha_hasta).add(1, 'days').format('DD-MM-YYYY');
      else date_to = moment(sensor.fecha_hasta).format('DD-MM-YYYY HH:mm');

      let params = {
        to: date_to,
        from: moment(sensor.fecha_desde).format('DD-MM-YYYY'),
      };
      calculatedsFactory
        .historyDPV(sensor.pk.userId, sensor.pk.id, params)
        .then((result) => {
          var mdata = [];
          mdata = result;
          mdata.forEach(function (valor, indice) {
            var date_timestamp = moment(mdata[indice].date, 'DD-MM-YYYY HH:mm').format('x');

            date_timestamp = parseInt(date_timestamp);

            datos_grafica.push([date_timestamp, mdata[indice].value]);
          });
          $timeout(() => {
            chart = loadGraficas(datos_grafica, sensor.pk.id);
            chart.setTitle({ text: sensor.name });
            //chart.title.textStr = "sensorname";
          }, 100);
        })
        .catch(function () {});
    }

    function changeTab(tab, $event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();

          vm.currentTab = tab;
          if (tab === VeggaOverlayTab.EDIT) {
            sensorEdition();
          }

          if (tab === VeggaOverlayTab.DETAIL) {
            vm.sensorHistory(vm.currentDPVSelected);
          }
        });
      } else {
        vm.currentTab = tab;
        if (tab === VeggaOverlayTab.EDIT) {
          sensorEdition();
        }

        if (tab === VeggaOverlayTab.DETAIL) {
          sensorHistory(vm.currentDPVSelected);
        }
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentDPVSelected = null;
          }
        });
      } else {
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentDPVSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentDPVSelected = null;
        }
      }
    }

    function changeDPV(e) {
      if (e.detail.value) {
        vm.currentDPVSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.DETAIL;
        sensorHistory(vm.currentDPVSelected);
      }
    }

    function changingDPV($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeDPV($event);
          break;
      }
    }

    $scope.$on('refresh', function () {
      updateSensors();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    function loadGraficas(datos, index) {
      let id = 'graficaSensor' + index;

      let chart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              this.series.name +
              ':</b> ' +
              this.y.toFixed(2) +
              '<br/>' +
              moment(this.x).format('DD-MM-YYYY HH:mm')
            );
          },
        },
        chart: {
          zoomType: 'xy',
          renderTo: id,
          type: 'line',
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y %H:%M}',
            rotation: -65,
          },
        },
        yAxis: {
          title: '',
        },

        series: [
          {
            name: 'DPV',
            data: datos,
          },
        ],
      });
      return chart;
    }
  }
})();
