import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';
import { navigateToUrl } from 'single-spa';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyController', historyController);

  historyController.$inject = ['$scope', '$state', 'unitFactory', 'refreshFactory', '$transition$'];

  function historyController($scope, $state, unitFactory, refreshFactory, $transition$) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();
    vm.navigate = navigate;

    function navigate(path) {
      if (
        vm.currentUnit.type === UNITS.A_2500 ||
        vm.currentUnit.type === UNITS.A_4500 ||
        vm.currentUnit.type === UNITS.A_4000
      ) {
        const stateParams = $transition$.router.globals.params;
        navigateToUrl(`/irrigation-controls/devices/${+stateParams.unitId}/history/${path}`);
      } else {
        switch (path) {
          case 'sectors':
            $state.go('history.sectors', { unit: vm.currentUnit });
            break;
          case 'counters':
            $state.go('history.counters', { unit: vm.currentUnit });
            break;

          case 'analogs':
            $state.go('history.analogs', { unit: vm.currentUnit });
            break;

          default:
            break;
        }
      }
    }

    function activate() {
      vm.currentState = $state.includes;
      vm.showSkeleton = refreshFactory.getSkeletonStatus();
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        if ($state.current.name.includes('history.')) {
          return;
        }
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            $state.go('history.programs');
            break;
          case UNITS.A_2500:
            $state.go('history.sectors');
            break;
          case UNITS.A_BIT:
            $state.go('history.sectors');
            break;
          case UNITS.A_7000:
            $state.go('history.sectors');
            break;
          case UNITS.A_5500:
            $state.go('history.sectors');
            break;
          case UNITS.A_4500:
            $state.go('history.general');
            break;
        }
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
