import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('calcsController', calcsController);

  calcsController.$inject = ['$rootScope', '$scope', '$state', 'unitFactory'];

  function calcsController($rootScope, $scope, $state, unitFactory) {
    var vm = this;
    vm.activeList;
    vm.destroy$ = new Subject();
    vm.currentNavigationIndex = 0;

    vm.menu = [
      { name: 'calcs.waterdisp1', state: 'agroclimatic.water' },
      { name: 'calcs.dpv', state: 'agroclimatic.dpv' },
      { name: 'Punto de Rocío', state: 'agroclimatic.pdr' },
      { name: 'calcs.eto', state: 'agroclimatic.eto' },
      { name: 'calcs.erain', state: 'agroclimatic.rain' },
    ];

    vm.loadState = loadState;
    vm.navigationSelected = navigationSelected;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state;

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        $rootScope.isRefreshingApp = false;
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        $state.go(vm.menu[0].state);
      });
    }

    function loadState(event) {
      const item = vm.menu[event.detail.index];
      if (item && $state.current.parent !== item.state) {
        $state.go(item.state);
      }
    }

    function navigationSelected() {
      vm.menu.forEach((option, index) => {
        if (vm.currentState.includes(option.state)) {
          vm.currentNavigationIndex = index;
        }
      });
    }

    /**
     * Event listener per gestionar la navegacio
     */
    $scope.$on('stateChanged', (e, args) => {
      navigationSelected();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
