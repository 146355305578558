(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('homeController', homeController);

  homeController.$inject = ['$log', '$scope', 'Restangular'];

  function homeController($log, $scope, Restangular) {
    var vm = this;
    var baseAccounts = Restangular.all('users');

    activate();

    function activate() {
      $log.debug('homeController loading');
      vm.greeting = 'Welcome';
    }
  }
})();
