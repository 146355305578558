(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('headerRegulationFactory', headerRegulationFactory);

  headerRegulationFactory.$inject = ['Restangular', '$q'];

  function headerRegulationFactory(Restangular, $q) {
    var op = {
      getHeaderPid: getHeaderPid,
      updateHeaderPid: updateHeaderPid,
      getHeaderScalingEngines: getHeaderScalingEngines,
      updateHeaderScalingEngines,
      updateHeaderScalingEngines,
    };

    return op;

    function getHeaderPid(id, headerId) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headerPID', headerId)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateHeaderPid(id, headerId, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headerPID', headerId)
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getHeaderScalingEngines(id, headerId) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headerScalingEngines', headerId)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateHeaderScalingEngines(id, headerId, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('headerScalingEngines', headerId)
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
