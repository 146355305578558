import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('nebulizationEditController', nebulizationEditController);

  nebulizationEditController.$inject = ['$rootScope', '$scope', '$state', 'nebulizationFactory'];

  function nebulizationEditController($rootScope, $scope, $state, nebulizationFactory) {
    var vm = this;
    vm.activeList;
    vm.currentNebulization;
    vm.form;
    activate();

    function activate() {
      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
        vm.currentState = $state.includes;
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.currentUnit = $state.params.unit;
        vm.currentNebulization = $state.params.nebulization;
        backup();
      } else {
        $state.go('units');
      }
    }

    function backup() {
      vm.nebulization_backup = {};
      angular.copy(vm.currentNebulization, vm.nebulization_backup);
    }

    function cancel_edition() {
      angular.copy(vm.nebulization_backup, vm.currentNebulization);
    }

    function save() {
      vm.form.$setPristine();

      if (vm.currentUnit.type !== UNITS.A_4500) {
        nebulizationFactory.update(vm.currentUnit.id, vm.currentNebulization).then(() => {
          $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
        });
      } else {
        nebulizationFactory.update45(vm.currentUnit.id, vm.currentNebulization).then(() => {
          $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
        });
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
