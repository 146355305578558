import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('regulationModulationController', regulationModulationController);

  regulationModulationController.$inject = ['$state', '$scope', '$rootScope', 'fertilizerFactory', 'filterFactory'];

  function regulationModulationController($state, $scope, $rootScope, fertilizerFactory, filterFactory) {
    var vm = this;
    vm.activeList;
    vm.getType;
    vm.save = save;
    vm.cancel = cancel_edition;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;

        loadInstaller();
      });
    }

    function loadInstaller() {
      filterFactory.getInstaller(vm.currentUnit.id).then(function (data) {
        vm.installer = data.plain();
      });
    }

    // function loadFertilizationHeaders() {
    //   fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id).then((data) => {
    //     allFertilizationHeaders = data.plain();
    //     vm.fertilizationHeader = allFertilizationHeaders[vm.currentHeader - 1];
    //   });
    // }

    function save() {
      if ($state.params.headerId != null) {
        fertilizerFactory.updateFertilizationHeader(vm.currentUnit.id, [vm.fertilizationHeader]).then((response) => {
          vm.form.$setPristine();
          vm.fertilizationHeader = response[0].plain();
        });
      } else {
        vm.installer.unittype = vm.currentUnit.type;
        filterFactory.updateInstaller(vm.currentUnit.id, vm.installer).then(() => {
          vm.form.$setPristine();
        });
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
    }

    $scope.$on('refresh', function () {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
