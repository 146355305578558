import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outputsController', outputsController);

  outputsController.$inject = ['$scope', '$state', '$confirm', '$filter', 'unitFactory', '$rootScope'];

  function outputsController($scope, $state, $confirm, $filter, unitFactory, $rootScope) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.changeState = changeState;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentUnit = currentUnit;
      });
    }

    function changeState(location, params) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel');
          vm.form.$setPristine();
          vm.form = null;
          $state.go(location, params);
        });
      } else {
        $state.go(location, params);
      }
    }

    $rootScope.$on('generalStateChange', (e, args) => changeState(args.location, args.params));

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
