import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';
import { A4500_IRRIGATION_PROGRAM_STATUS } from '../../utils/irrgation-status.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    .controller('programsControllerA45', programsController)
    .controller('ModalInstanceSuspendCtrl', ModalInstanceSuspendCtrl)
    .controller('ModalInstanceActivationCtrl', ModalInstanceActivationCtrl)
    .controller('ModalInstanceDefStopCtrl', ModalInstanceDefStopCtrl);

  programsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'progFactory',
    '$uibModal',
    'manualFactory',
    '$confirm',
    '$filter',
    'utilsFactory',
    'unitFactory',
  ];

  function programsController(
    $log,
    $rootScope,
    $scope,
    $state,
    progFactory,
    $uibModal,
    manualFactory,
    $confirm,
    $filter,
    utilsFactory
  ) {
    var vm = this;
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    //Enumerat per definir els tipus de inici
    const startTypes = Object.freeze({
      hour: 0, //Inici per condicionant
      seq: 1, //Inici seqüencial
    });
    //Enumerat per definir els tipus d'unitat de reg
    const unitTypes = Object.freeze({
      HM: 0, //hh:mm
      M3: 1, //m3
      M3HA: 2, // m3/Ha -> Sols al Plus
      MS: 3, // Minuts, Segons
      HMHA: 4, // Hores, Minuts /Ha v.6.00   -> Sols al Plus
    });

    vm.substates = [
      'cond.stop',
      'general.pf6',
      'general.pf4',
      'general.pf5',
      'general.pf1',
      'general.pf38',
      'manual.definitiveStop',
      'manual.outof',
      'general.onhold',
      'general.pf19',
      'general.pf30',
      'general.pf21',
      'general.pf34',
      'general.pf22',
      'general.pf23',
      'general.pf24',
      'general.pf25',
      'general.pf26',
      'general.pf27',
      'general.pf28',
      'general.pf29',
      'general.pf32',
      'general.pf33',
      'general.pf39',
      'general.pf35',
      'general.pf40',
    ];

    vm.weekDays = [
      { value: 0, label: 'programs.edit.mon' },
      { value: 1, label: 'programs.edit.tue' },
      { value: 2, label: 'programs.edit.wed' },
      { value: 3, label: 'programs.edit.thu' },
      { value: 4, label: 'programs.edit.fri' },
      { value: 5, label: 'programs.edit.sat' },
      { value: 6, label: 'programs.edit.sun' },
    ];

    vm.startTypes = [
      {
        value: 0,
        label: 'programs.edit.hour',
      },
      {
        value: 1,
        label: 'programs.edit.seq',
      },
    ];

    vm.headerFilterValues = [
      { value: 0, label: $filter('translate')('programs.nothing') },
      { value: 1, label: `${$filter('translate')('sectors.header')} 1` },
      { value: 2, label: `${$filter('translate')('sectors.header')} 2` },
      { value: 3, label: `${$filter('translate')('sectors.header')} 3` },
      { value: 4, label: `${$filter('translate')('sectors.header')} 4` },
      { value: 255, label: $filter('translate')('programs.edit.headerError') },
    ];

    const irrigatingSubstates = ['general.pf6', 'general.pf4', 'general.pf5', 'general.pf1', 'general.pf38'];

    vm.getProgramStartType;
    vm.getProgramDaysFreq;
    vm.getProgramValueInUnit;
    vm.activeList = [];
    vm.destroy$ = new Subject();
    //TODO FIX i18n
    vm.gridColumnsDef = [
      {
        headerName: $filter('translate')('programs.prog3'),
        field: 'pkId',
        width: 100,
        cellTemplate: (prog) => `P${prog.pkId}`,
      },
      {
        headerName: $filter('translate')('programs.detail.name'),
        field: 'name',
        cellTemplate: (prog) => `<vegga-text class="semi-bold">${prog.name}</vegga-text>`,
        filterParams: (cellValue) => cellValue.toLowerCase().includes(vm.searchByProgramName.toLowerCase()),
      },
      {
        headerName: $filter('translate')('sectors.header'),
        field: 'xHeader',
        width: 100,
        cellTemplate: (prog) => getHeaderCellTemplate(prog),
        filterParams: (cellValue) => {
          if (!vm.searchByHeader.length) return true;
          return vm.searchByHeader.includes(cellValue);
        },
      },
      {
        headerName: $filter('translate')('programs.edit.start'),
        field: 'initType',
        cellTemplate: (prog) => `<vegga-text>${$filter('translate')(getProgramStartType(prog.initType))}</vegga-text>`,
        filterParams: (cellValue) => {
          if (!vm.searchByInitType.length) return true;
          return vm.searchByInitType.includes(cellValue);
        },
      },
      {
        headerName: $filter('translate')('history.days'),
        field: 'activeDays',
        width: 185,
        cellTemplate: (prog) => getFrequencyCellTemplate(prog),
        filterParams: (cellValue) => {
          if (!vm.searchByDay.length) return true;
          return vm.searchByDay.map((day) => cellValue[day]).filter((item) => item === true).length;
        },
      },
      {
        headerName: $filter('translate')('programs.detail.startirrig'),
        field: 'startTimes',
        cellTemplate: (prog) => getInitHourCellTemplate(prog),
      },
      {
        headerName: $filter('translate')('programs.edit.sectors'),
        field: 'progSectors',
        cellTemplate: (prog) => getProgSectorsCellTemplate(prog),
      },
      {
        headerName: $filter('translate')('programs.edit.irrig'),
        field: 'xState',
        cellTemplate: (prog) => getIrrigationCellTemplate(prog),
      },
      {
        headerName: $filter('translate')('fert.state'),
        field: 'substate',
        cellTemplate: (prog) => getStateCellTemplate(prog),
        filterParams: (cellValue) => {
          // revisar condicional (no es mostren si poses regant i no regant a la vegada)
          return vm.searchByProgramState.length
            ? vm.searchByProgramState.includes('irrigating')
              ? irrigatingSubstates.includes(cellValue) || vm.searchByProgramState.includes(cellValue)
              : vm.searchByProgramState.includes(cellValue)
            : true;
        },
      },
    ];

    vm.newProgram;
    vm.dataGridRowClick;
    vm.cancel;
    vm.save;
    vm.changeProgramState;
    vm.showSkeleton = true;

    vm.searchByProgramName = '';
    vm.searchByProgramState = [];
    vm.searchByDay = [];
    vm.searchByInitType = [];
    vm.searchByHeader = [];

    vm.showIrrigatingPrograms = false;

    vm.gridFilter = {
      name: vm.searchByProgramName,
      substate: vm.searchByProgramState,
      activeDays: vm.searchByDay,
      initType: vm.searchByInitType,
      xHeader: vm.searchByHeader,
    };
    vm.currentState = $state;
    vm.changeState = changeState;
    vm.getProgramStartType = getProgramStartType;
    vm.getProgramDaysFreq = getProgramDaysFreq;
    vm.getProgramStartTime = getProgramStartTime;
    vm.getProgramValueInUnit = getProgramValueInUnit;
    vm.cancel = cancel;
    vm.save = save;
    vm.changeProgramState = changeProgramState;
    vm.updateFilters = updateFilters;
    vm.newProgram = newProgram;
    vm.openUnoperative = openUnoperative;
    vm.manualStart = manualStart;
    vm.manualStop = manualStop;
    vm.manualOutOf = manualOutOf;
    vm.manualRunOf = manualRunOf;
    vm.manualSuspend = manualSuspend;
    vm.manualFreqDays = manualFreqDays;
    vm.dataGridRowClick = dataGridRowClick;
    vm.changeProgram = changeProgram;
    vm.changingProgram = changingProgram;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.getTimeFromMins = progFactory.getTimeFromMins;
    vm.getProgramSectors = getProgramSectors;
    vm.getSectorsFromActiveSubprogram = getSectorsFromActiveSubprogram;
    vm.getProgramSectorValue = getProgramSectorValue;
    vm.getProgramSectorXValue = getProgramSectorXValue;
    vm.changeState = changeState;

    activate();

    function activate() {
      initSubscriptions();

      if ($state.$current.parent.name === 'a45programs') {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
      }
    }

    function initSubscriptions() {
      const layoutFilter = document.querySelector('vegga-layout-filter');
      vm.programsFacade.currentProgram$.subscribe((prog) => (vm.programSelected = prog));

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
          return;
        }
        vm.currentUnit = currentUnit;

        layoutFilter.showSkeleton = true;

        // loadProgramsSectors($state.params.unit.id);
        loadPrograms(vm.currentUnit.id, !!vm.activeList.length);
      });

      vm.programsFacade.isLoading$.pipe(takeUntil(vm.destroy$)).subscribe((isLoading) => {
        if (!isLoading) {
          const grid = document.querySelector('vegga-data-grid');
          grid.showFullSkeleton = isLoading;
          grid.showRowSkeleton = isLoading;
          layoutFilter.showSkeleton = isLoading;
          vm.showSkeleton = isLoading;
        }
      });

      vm.programsFacade.programs$.pipe(takeUntil(vm.destroy$)).subscribe((programs) => {
        const programsData = programs;
        vm.programSectors = programs
          .map((prog) => prog.progSectors)
          .filter((prog) => prog?.length)
          .flat();

        const grid = document.querySelector('vegga-data-grid');
        vm.activeList = programsData.map((prog) => {
          const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = prog;
          return progFactory.checkIrrigation(
            {
              ...prog,
              pkId: prog.pk.id,
              startTimes: '',
              activeDays: [monday, tuesday, wednesday, thursday, friday, saturday, sunday],
              rowItemDisabled: !prog.configured,
            },
            vm.currentUnit.type
          );
        });

        if (grid) {
          grid.data = vm.activeList;
        }

        if ($state.params.id) {
          vm.programsFacade.currentProgram = programs.find((prog) => +prog.pk.id === +$state.params.id);
        }
      });
    }

    function getHeaderCellTemplate(prog) {
      return prog.xHeader === 0
        ? `<vegga-text>${$filter('translate')('programs.nothing')}</vegga-text>`
        : prog.xHeader === 255
        ? `<vegga-badge class="error">${$filter('translate')('sensors.error')}</vegga-badge>`
        : `<vegga-text>${prog.xHeader}</vegga-text>`;
    }

    function getFrequencyCellTemplate(prog) {
      if (prog.initType === 1) return '';
      switch (prog.daysFrequency) {
        case 0: {
          const DAYS = {
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday',
            7: 'sunday',
          };

          const parsedDays = Object.keys(DAYS)
            .filter((dayKey) => prog[DAYS[dayKey]])
            .map((dayKey) => +dayKey);
          return `<vegga-time-display days="[${parsedDays}]"></vegga-time-display>`;
        }
        case 1:
          // Activacions
          return `<vegga-text>${$filter('translate')('programs.edit.irrig')} ${$filter('translate')(
            'programs.edit.each'
          )} ${prog.frequencyDays}
          ${$filter('translate')('pivot.days')}
          </vegga-text>`;

        case 2: {
          // Període de dies
          const {
            dayPeriod1,
            dayPeriod2,
            dayPeriod3,
            dayPeriod4,
            dayPeriod5,
            monthPeriod1,
            monthPeriod2,
            monthPeriod3,
            monthPeriod4,
            monthPeriod5,
          } = prog;
          const months = [monthPeriod1, monthPeriod2, monthPeriod3, monthPeriod4, monthPeriod5];

          if (![dayPeriod1, dayPeriod2, dayPeriod3, dayPeriod4, dayPeriod5].filter((day) => day > 0).length) return '-';

          return `<div style="display:'flex'; flex-direction: 'column'">
            ${[dayPeriod1, dayPeriod2, dayPeriod3, dayPeriod4, dayPeriod5].map((day, i) => {
              if (day > 0) {
                return `<vegga-text>${day}/${months[i]}<vegga-text>`;
              }
              return ` `;
            })}
          </div>`.replaceAll(',', '');
        }
        default:
          return `-`;
      }
    }

    function getInitHourCellTemplate(prog) {
      if (prog.initType === 1) {
        return `<vegga-text>${$filter('translate')('programs.edit.seqprog')} ${prog.sequential}</vegga-text>`;
      }
      const { initHour1, initHour2, initHour3, initHour4, initHour5, initHour6 } = prog;
      if (![initHour1, initHour2, initHour3, initHour4, initHour5, initHour6].filter((hour) => hour > 0).length) {
        return '-';
      } else {
        return `<div style="display:'flex'; flex-direction: 'column'">
        ${[initHour1, initHour2, initHour3, initHour4, initHour5, initHour6]
          .filter((hour) => hour > 0)
          .map((min) => `<vegga-text>${progFactory.getTimeFromMins(min).format('HH:mm')}</vegga-text>`)}
          </div>`.replaceAll(',', '');
      }
    }

    function getProgSectorsCellTemplate(prog) {
      if (prog.programType === 0) {
        const sectorsFromActiveSubprogram = getSectorsFromActiveSubprogram(prog);

        if (!sectorsFromActiveSubprogram.filter((sec) => sec > 0).length) return `-`;
        return `<div style="display:'flex'; flex-direction: 'column'">
            ${sectorsFromActiveSubprogram
              .filter((sec) => sec > 0)
              .map((sec) => `<vegga-text>${$filter('translate')('general.sector')} ${sec}</vegga-text>`)}
        </div>`.replaceAll(',', '');
      } else if (prog.programType === 1) {
        const activeSectors = getProgramSectors(prog);
        return `<div style="display:'flex'; flex-direction: 'column'">
            ${activeSectors.map(
              (sec) => `<vegga-text>${$filter('translate')('general.sector')} ${sec.sector1}</vegga-text>`
            )}
        </div>`.replaceAll(',', '');
      } else {
        return '-';
      }
    }

    function getIrrigationCellTemplate(prog) {
      const irrigatingSectors = getProgramSectors(prog);
      if (!irrigatingSectors.length) return '-';
      if (prog.programType === 0) {
        return `
          <div style="display:'flex'; flex-direction: 'column'">
          ${irrigatingSectors.map(
            (sec) =>
              `
              <vegga-text tag="span" class="md">${$filter('translate')('general.subp2')} ${sec.pk.id}:</vegga-text>
              <vegga-text class="semi-bold">${getProgramSectorXValue(prog, sec)} / ${vm.getProgramSectorValue(
                prog,
                sec
              )}
              </vegga-text>`
          )}
          </div>`.replaceAll(',', '');
      } else if (prog.programType === 1) {
        return `
          <div style="display:'flex'; flex-direction: 'column'">
          ${irrigatingSectors.map(
            (sec) =>
              `<vegga-text class="semi-bold">
                  n${sec.pk.id} - ${getProgramSectorXValue(prog, sec)} / ${vm.getProgramSectorValue(prog, sec)}
                </vegga-text>`
          )}
          </div>`.replaceAll(',', '');
      } else {
        return '-';
      }
    }

    function getStateCellTemplate(prog) {
      const badgeColor = A4500_IRRIGATION_PROGRAM_STATUS[prog.substate];
      if (!prog.configured) {
        return `<vegga-text>-</vegga-text>`;
      }
      if (prog.irrigation) {
        return `
        <div style="display:'flex'; flex-direction: 'column'">
          <vegga-badge class="sm info mb-1">${$filter('translate')('programs.irrigating')}</vegga-badge>
          <vegga-badge class="${badgeColor}">${$filter('translate')(prog.substate)}</vegga-badge>
        </div>
        `;
      } else {
        return `<vegga-badge class="${badgeColor}">${$filter('translate')(prog.substate)}</vegga-badge>`;
      }
    }

    function updateFilters(param, field) {
      vm.gridFilter = {
        ...vm.gridFilter,
        [field]: param,
      };
    }

    function dataGridRowClick($event) {
      const veggaOverlay = document.querySelector('vegga-overlay');

      veggaOverlay.show();

      vm.programsFacade.currentProgram = $event.detail;
      vm.programSelected = $event.detail;

      changeState({ id: vm.programSelected.pk.id });
    }

    function changeProgram(e) {
      if (e.detail.value) {
        const program = vm.activeList.find((prog) => prog.pk.id === e.detail.value);
        vm.programSelected = program;
        changeState({ program: vm.programSelected, id: vm.programSelected.pk.id });
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeProgram($event);
          break;
      }
    }

    function loadPrograms(id) {
      vm.programsFacade.getA4500Programs(id);
    }

    function changeState({ id }) {
      vm.active = null;
      vm.programSelected.op = true;
      const path = $state.current.name.split('.')[1];
      $state.go(`a45programs.${path || 'detail'}`, { id });
    }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('a45programs.config')) {
          $state.go('a45programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('a45programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('a45programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.activeList, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function manualStart() {
      $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 4;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop() {
      $confirm({
        text: $filter('translate')('programs.send_program_stop_pause'),
        title: 'Manual AGRONIC',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 5;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualOutOf() {
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then(() => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualRunOf() {
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = vm.currentUnit.type;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then(() => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualSuspend() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'components/programs/suspend-modal.html',
        controller: 'ModalInstanceSuspendCtrl',
        controllerAs: 'vm',
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();

          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 1;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = selectedItem;
          params.parameter3 = selectedItem >> 8;
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});
          modalInstance.result.then(() => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    function manualFreqDays() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'components/programs/freq_days.html',
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 2;
          params.parameter1 = vm.program.pk.id - 1;
          params.parameter2 = selectedItem;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});

          modalInstance.result.then(() => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    /*
     * Funcions per parsejar valors de columna "calculats"
     */
    function getProgramStartType(type) {
      switch (type) {
        case startTypes.hour:
          return 'programs.edit.hour';
        case startTypes.seq:
          return 'programs.edit.seq';
        case startTypes.cond:
          if (vm.currentUnit.inoptions.plus) {
            return 'programs.cond';
          } else {
            return 'programs.edit.input';
          }
      }
    }
    function getProgramDaysFreq(program) {
      //Inici per freqüencia
      let each = $filter('translate')('programs.edit.each');
      let days_string = $filter('translate')('programs.edit._days');
      return each + ' ' + program.freqDays + ' ' + days_string;
    }
    function getProgramStartTime(prog, idx) {
      let minutes;
      eval(`minutes = prog.initHour${idx}`);
      return progFactory.getTimeFromMins(minutes).format('HH:mm');
    }
    function getProgramValueInUnit(prog) {
      var unit = prog.unit;
      var input = prog.value;
      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case unitTypes.HM:
          return $filter('parsetime')(input * 60);
        case unitTypes.M3:
          return (input / 1000).toFixed(2) + 'm3';
        case unitTypes.M3HA:
          return (input / 1000).toFixed(2) + ' m3';
        case unitTypes.MS:
          var mins = input;
          return $filter('parsetime', true)(mins);
        case unitTypes.HMHA:
          return input + ' l';
      }
    }

    function getProgramSectors(program) {
      if (!program) return [];
      return (vm.programSectors || []).filter((programSector) => {
        return programSector.pk.programId === program.pk.id;
      });
    }

    function getSectorsFromActiveSubprogram(program) {
      var sectors = [];
      var programSector = getProgramSectors(program)[0];
      if (programSector !== undefined) {
        for (let i = 1; i < 11; i++) {
          eval(`sectors.push(programSector.sector${i})`);
        }
      }
      return sectors;
    }

    function getIrrigUnits(program, programSector, prePostIrrigForSubprog) {
      return utilsFactory.getIrrigUnits(
        prePostIrrigForSubprog,
        program.programType,
        programSector.irrigUnits,
        program.sectorsByGroup,
        program.irrigUnits
      );
    }

    function getProgramSectorValue(program, programSector) {
      if (programSector === undefined) return;
      const value = programSector.value;
      const irrigUnits = getIrrigUnits(program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedValue4500(value, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getProgramSectorXValue(program, programSector) {
      if (programSector === undefined) return;
      const xValue = programSector.xValue;
      const irrigUnits = getIrrigUnits(program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedXValue4500(xValue, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeProgramState(state, _params) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, _params); //Canviem d'estat
        });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('a45programs');
          } else {
            $state.go('a45programs.detail', { id: vm.program.pk.id });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('a45programs')
          : $state.go('a45programs.detail', { id: vm.program.pk.id });
      }
    }
    function save() {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    //TODO Revisar com fer el reload en la nova vista de programes
    // $scope.$on('refresh', function (event, args) {
    //   loadPrograms(vm.currentUnit.id, 'refresh');
    // });

    //TODO Revisar amb la nova vista si té sentit caoturar aquest event
    $scope.$on('updateEdit45', function () {
      loadPrograms(vm.currentUnit.id, 'save');
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.previousState = null;
      vm.programsFacade.clearProgramsResponse();
    });
  }

  ModalInstanceSuspendCtrl.$inject = ['$uibModalInstance'];

  function ModalInstanceSuspendCtrl($uibModalInstance) {
    var vm = this;
    vm.selectedHours = 0;

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedHours);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  ModalInstanceActivationCtrl.$inject = ['$uibModalInstance', 'program'];

  function ModalInstanceActivationCtrl($uibModalInstance, program) {
    var vm = this;
    vm.program = program;

    vm.ok = function () {
      $uibModalInstance.close({ activations: vm.selectedProg, time: vm.selectedHours });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  ModalInstanceDefStopCtrl.$inject = ['$uibModalInstance'];

  function ModalInstanceDefStopCtrl($uibModalInstance) {
    var vm = this;
    vm.checkirrig = 1;

    vm.ok = function () {
      $uibModalInstance.close(vm.checkirrig);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
