//TODO import template
import timeSelectorTemplate from './timeSelector.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('timeSelector', timeSelector);

  function timeSelector() {
    return {
      restrict: 'E',
      //require:'ngModel',
      // templateUrl: '/components/directives/timeSelector/timeSelector.html',
      template: timeSelectorTemplate,
      scope: {
        input: '=',
        seconds: '=',
        focus: '&',
        blur: '&',
        maxhours: '=',
        suffixText: '=',
        prefixText: '=',
        ngForm: '=',
        vgForm: '=',
        label: '=',
        disabled: '=',
        onChange: '&',
      },
      // controller: ['$scope', function($scope,$element) {
      // $scope.hours;
      // $scope.minutes;
      // $scope.inputView;

      // if(_.isNumber($scope.input)){
      //     if($scope.seconds === true){
      //         $scope.input = $scope.input / 60;
      //     }
      //     var h = $scope.input / 60 | 0,
      //         m = $scope.input % 60 | 0;
      //     $scope.hours = h;
      //     $scope.minutes = m;
      // }

      // var update = function() {

      //     $scope.hours = Number($scope.hours) | 0;
      //     $scope.minutes = Number($scope.minutes) | 0;

      //     if($scope.hours === undefined ||  $scope.hours > 99){
      //         $scope.hours = 99;
      //     }
      //     if($scope.minutes === undefined || $scope.minutes > 59){
      //         $scope.minutes = 59;
      //     }
      //     if($scope.hours < 10){
      //         $scope.hours = '0'+$scope.hours;
      //     }
      //     if($scope.minutes < 10){
      //         $scope.minutes = '0'+$scope.minutes;
      //     }
      //     if($scope.seconds === true){
      //         $scope.input = ((Number($scope.hours) * 60) + Number($scope.minutes) )* 60;
      //     }else{
      //         $scope.input  = (Number($scope.hours) * 60) + Number($scope.minutes);
      //     }

      //     if($scope.maxhours !== undefined && $scope.hours>=$scope.maxhours){
      //         $scope.hours = $scope.maxhours - 1;
      //         $scope.minutes = 59;
      //     }
      // };

      // $scope.$watch('hours', function(newValue,oldValue){
      //     update();
      // })
      // $scope.$watch('minutes', function(newValue,oldValue){
      //     update();
      // })
      // }],
      link: function ($scope, $element) {
        fillInputView();

        $scope.$on('VeggaInputFocus', (_e, _data) => {
          //$scope.inputView = $scope.inputView.replace(':', '');
          if ($scope.focus()) {
            $scope.focus();
          }
        });

        $scope.$on('VeggaInputInput', (_e, data) => {
          if ((data.target.value.length === 2) & !data.target.value.includes(':')) {
            $scope.inputView = `${data.target.value}:`;
          }
          if ($scope.deleteColon) {
            $scope.inputView = $scope.inputView.slice(0, -2);
            $scope.deleteColon = false;
          }
        });
        $scope.$on('VeggaInputKeyDown', (_e, data) => {
          if (data.which === 8) {
            if ($scope.inputView.length === 3 && $scope.inputView.charAt($scope.inputView.length - 1) === ':') {
              $scope.deleteColon = true;
            }
          }
        });
        $scope.$on('VeggaInputBlur', (_e, data) => {
          let value = data.target.value.split(':');
          if (data.target.value.length > 0) {
            $scope.hours = !isNaN(Number(value[0])) ? Number(value[0]) : 0;
            $scope.minutes = !isNaN(Number(value[1])) ? (Number(value[1]) > 59 ? 59 : Number(value[1])) : 0;
            $scope.inputView = `${padTo2Digits($scope.hours)}:${padTo2Digits($scope.minutes)}`;

            if ($scope.maxhours !== undefined && $scope.hours >= $scope.maxhours) {
              $scope.hours = $scope.maxhours - 1;
              $scope.minutes = 59;
            }

            if ($scope.seconds === true) {
              $scope.input = (Number($scope.hours) * 60 + Number($scope.minutes)) * 60;
            } else {
              $scope.input = Number($scope.hours) * 60 + Number($scope.minutes);
            }
            $scope.inputView = `${padTo2Digits($scope.hours)}:${padTo2Digits($scope.minutes)}`;
          }
          if ($scope.blur) {
            $scope.blur();
          }
        });

        $scope.$watch('seconds', () => {
          fillInputView();
        });

        $scope.$watch('input', () => {
          fillInputView();
        });

        function padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }

        function fillInputView() {
          $scope.hours;
          $scope.minutes;
          $scope.inputView;

          $scope.deleteColon = false;

          if (Number.isInteger($scope.input)) {
            $scope.inputView = $scope.input;
            if ($scope.seconds === true) {
              $scope.inputView = $scope.input / 60;
            }
            var h = ($scope.inputView / 60) | 0,
              m = $scope.inputView % 60 | 0;
            $scope.hours = h;
            $scope.minutes = m;

            $scope.inputView = `${padTo2Digits(h)}:${padTo2Digits(m)}`;
          }
          if ($scope.onChange) {
            $scope.onChange();
          }
        }
      },
    };
  }
})();
