import { take } from 'rxjs';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('generalInputsController', generalInputsController);

  generalInputsController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'conditionerFactory',
    'sensorsFactory',
    'unitFactory',
  ];

  function generalInputsController($rootScope, $scope, $state, conditionerFactory, sensorsFactory, unitFactory) {
    var vm = this;

    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;

    vm.inputsCombo = _.range(0, 7);
    vm.selected = 0;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      unitFactory;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentState = $state.includes;
        vm.currentUnit = currentUnit;

        loadConditioners();
        loadSensors();
      });
    }

    function loadSensors() {
      sensorsFactory.digitalsPage(vm.currentUnit.id, { limit: 50, page: 1 }).then((digitals) => {
        var res = digitals.plain();
        vm.digitals = res.content;
      });
      sensorsFactory.meters(vm.currentUnit.id).then((meters) => {
        vm.meters = meters.plain();
      });
    }
    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.currentUnit.conditioners = data.plain();
        vm.conditioners = vm.currentUnit.conditioners;
      });
    }
    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      vm.form.$setPristine();
      vm.meters[2].pulseValue = vm.meters[1].pulseValue;
      vm.meters[2].delay = vm.meters[1].delay;
      vm.meters[3].pulseValue = vm.meters[1].pulseValue;
      vm.meters[3].delay = vm.meters[1].delay;
      vm.meters[4].pulseValue = vm.meters[1].pulseValue;
      vm.meters[4].delay = vm.meters[1].delay;

      _.forEach(vm.meters, (m) => {
        m.unittype = '3';
      });
      _.forEach(vm.conditioners, (m) => {
        m.unittype = '3';
      });
      _.forEach(vm.digitals, (d) => {
        d.unittype = '3';
      });

      sensorsFactory.updateMeters(vm.currentUnit.id, vm.meters).then(() => {});
      sensorsFactory.updateDigitalsArray(vm.currentUnit.id, vm.digitals).then(() => {});
      conditionerFactory.update(vm.currentUnit.id, vm.conditioners).then(() => {});
    }
    function cancel_edition() {
      vm.engines = {};
      vm.form.$setPristine();
      $state.go('outputs.inputs', { unit: vm.currentUnit }, { reload: 'outputs.inputs' });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
