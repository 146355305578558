import { UNITS, UNITS_V2 } from '../../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { getContainer } from '@vegga/front-store';
import { from, of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outFertilizersController', outFertilizersController);

  outFertilizersController.$inject = ['$scope', '$state', 'configFactory', 'unitFactory'];

  function outFertilizersController($scope, $state, configFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;

    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.loaded = false;

    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    vm.UNITS = UNITS;
    vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
    vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentHeader = $state.params.headerId || 1;
        vm.currentUnit = currentUnit;
        if (vm.currentUnit.type === UNITS.A_4500) {
          loadFertilizers45();
        } else {
          loadFertilizers();
        }
        vm.currentState = $state.includes;
        vm.selected = 0;
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadFertilizers45() {
      var params = { add: ['fertilizers', 'agitators'] };
      configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result) => {
        vm.config = result.plain();
        backup = angular.copy(vm.config);
        vm.loaded = true;

        vm.ioFacade.loadConfiguredOutputs({
          deviceId: vm.currentUnit.id,
          deviceType: UNITS_V2[vm.currentUnit.type],
        });

        // Init StagingList in order to check the current io selector.
        vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
      });
    }

    function loadFertilizers() {
      var params = { add: ['fertilizer', 'agitators'] };
      configFactory.get(vm.currentUnit.id, params).then((result) => {
        vm.config = result.plain();
        backup = angular.copy(vm.config);
        vm.loaded = true;
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = vm.currentUnit.type;

      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(configFactory.updateConfigHeader(vm.currentUnit.id, vm.config));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe((response) => {
              if (response) {
                vm.config = response;
                if (vm.config.fertilizers) {
                  vm.config.fertilizers = _.sortBy(vm.config.fertilizers, [
                    function (o) {
                      return o.pk.id;
                    },
                  ]);
                  vm.config.agitators = _.sortBy(vm.config.agitators, [
                    function (o) {
                      return o.pk.id;
                    },
                  ]);
                }
                backup = angular.copy(vm.config);
              }
            });
          break;
        default:
          configFactory.updateConfig(vm.currentUnit.id, vm.config).then((result) => {
            vm.config = result;
            if (vm.config.fertilizers) {
              vm.config.fertilizers = _.sortBy(vm.config.fertilizers, [
                function (o) {
                  return o.pk.id;
                },
              ]);
              vm.config.agitators = _.sortBy(vm.config.agitators, [
                function (o) {
                  return o.pk.id;
                },
              ]);
            }
            backup = angular.copy(vm.config);
          });
          break;
      }
    }
    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
