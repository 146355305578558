import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('regulationPidController', regulationPidController);

  regulationPidController.$inject = [
    '$state',
    '$scope',
    'headerRegulationFactory',
    'configFactory',
    '$q',
    'unitFactory',
  ];

  function regulationPidController($state, $scope, headerRegulationFactory, configFactory, $q, unitFactory) {
    var vm = this;
    var headerPid_backup = [];
    var header_backup = [];
    vm.destroy$ = new Subject();
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        vm.currentHeader = $state.params.headerId || '1';
        loadHeaderPid();
        loadGeneralHeader();
      });
    }

    function backup() {
      angular.copy(vm.headerPid, headerPid_backup);
      angular.copy(vm.generalHeader, header_backup);
    }

    function loadGeneralHeader() {
      configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader).then((result) => {
        vm.generalHeader = result.plain();
        angular.copy(vm.generalHeader, header_backup);
      });
    }

    function loadHeaderPid() {
      headerRegulationFactory.getHeaderPid(vm.currentUnit.id, vm.currentHeader).then(function (data) {
        vm.headerPid = data.plain();
        angular.copy(vm.headerPid, headerPid_backup);
      });
    }

    function save() {
      let queries = [];
      queries.push(
        configFactory.updateConfigHeader(vm.currentUnit.id, vm.generalHeader),
        headerRegulationFactory.updateHeaderPid(vm.currentUnit.id, vm.currentHeader, vm.headerPid)
      );
      $q.all(queries).then((response) => {
        vm.form.$setPristine();
        backup();
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(header_backup, vm.headerPid);
      angular.copy(headerPid_backup, vm.headerPid);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
