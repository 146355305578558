(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsController', totalsController);

  totalsController.$inject = ['$scope', '$state'];

  function totalsController($scope, $state) {
    var vm = this;
    vm.activeList;
    activate();

    function activate() {
      vm.currentState = $state.includes;
    }
  }
})();
