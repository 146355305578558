import { UNITS } from '../../utils/units.enum';
import { getContainer } from '@vegga/front-store';

import { A2500_IRRIGATION_PROGRAM_STATUS } from '../../utils/irrgation-status.enum';
import { Subject, takeUntil, take } from 'rxjs';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('programsControllerA25', programsController);

  programsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'progFactory',
    '$uibModal',
    'manualFactory',
    '$confirm',
    '$filter',
    'veggaModalConfirmService',
  ];

  function programsController(
    $log,
    $rootScope,
    $scope,
    $state,
    progFactory,
    $uibModal,
    manualFactory,
    $confirm,
    $filter,
    veggaModalConfirmService
  ) {
    var vm = this;
    vm.destroy$ = new Subject();
    //Enumerat per definir els tipus de inici
    const startTypes = Object.freeze({
      hour: 0, //Inici per condicionant
      seq: 1, //Inici seqüencial
      cond: 2, //Inici per condicionant
    });
    //Enumerat per definir els tipus d'unitat de reg
    const unitTypes = Object.freeze({
      HM: 0, //hh:mm
      M3: 1, //m3
      M3HA: 2, // m3/Ha -> Sols al Plus
      MS: 3, // Minuts, Segons
      HMHA: 4, // Hores, Minuts /Ha v.6.00   -> Sols al Plus
    });

    vm.getProgramStartType;
    vm.getProgramDaysFreq;
    vm.getProgramValueInUnit;
    vm.searchByProgramName = '';
    vm.rawPrograms = [];
    vm.gridData = [];

    vm.changeState = changeState;
    vm.getProgramStartType = getProgramStartType;
    vm.getProgramDaysFreq = getProgramDaysFreq;
    vm.getProgramStartTime = getProgramStartTime;
    vm.getProgramValueInUnit = getProgramValueInUnit;
    vm.cancel = cancel;
    vm.save = save;
    vm.changeProgramState = changeProgramState;
    vm.newProgram = newProgram;
    vm.openUnoperative = openUnoperative;
    vm.manualStart = manualStart;
    vm.manualStop = manualStop;
    vm.manualOutOf = manualOutOf;
    vm.manualRunOf = manualRunOf;
    vm.manualFreqDays = manualFreqDays;
    vm.dataGridRowClick = dataGridRowClick;
    vm.changeProgram = changeProgram;
    vm.changingProgram = changingProgram;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.updateFilter = updateFilter;
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.showSkeleton = true;
    vm.gridColumnsDef = [
      {
        headerName: $filter('translate')('programs.prog3'),
        field: 'pkId',
        width: 100,
        sorting: true,
        sortingDir: 'asc',
      },
      {
        headerName: $filter('translate')('programs.detail.name'),
        field: 'name',
        filterParams: (cellValue) => cellValue.toLowerCase().includes(vm.searchByProgramName.toLowerCase()),
      },
      { headerName: $filter('translate')('programs.edit.start'), field: 'editStart' },
      { headerName: $filter('translate')('programs.detail.startirrig'), field: 'startTime' },
      {
        headerName: $filter('translate')('history.days'),
        field: 'days',
        width: 160,
        cellTemplate: (prog) => getCellTemplate(prog, 'days'),
      },
      {
        headerName: $filter('translate')('programs.edit.sectors'),
        field: 'sectors',
        cellTemplate: (prog) => getSectorsCellTemplate(prog),
      },
      { headerName: $filter('translate')('programs.edit.irrig'), field: 'irrig' },
      {
        headerName: $filter('translate')('fert.state'),
        field: 'state',
        cellTemplate: (prog) => getCellTemplate(prog, 'tag'),
      },
    ];
    let grid = null;

    activate();

    function activate() {
      initSubscriptions();

      if ($state.$current.parent.name === 'a25programs') {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
      }
    }

    function initSubscriptions() {
      grid = document.querySelector('vegga-data-grid');
      vm.programsFacade.currentProgram$.subscribe((prog) => (vm.programSelected = prog));

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
          return;
        }

        vm.currentState = $state;
        vm.currentUnit = currentUnit;
        vm.getTimeFromMins = progFactory.getTimeFromMins;
        vm.gridFilter = { name: vm.searchByProgramName };
        loadPrograms(vm.currentUnit.id);
      });

      vm.programsFacade.currentProgram$.subscribe((prog) => (vm.program = prog));
      vm.programsFacade.isLoading$.pipe(takeUntil(vm.destroy$)).subscribe((isLoading) => {
        if (!isLoading) {
          grid.showFullSkeleton = isLoading;
          grid.showRowSkeleton = isLoading;
          vm.showSkeleton = isLoading;
        }
      });
    }

    function getCellTemplate(prog, type) {
      if (type === 'tag') {
        const badgeColor = A2500_IRRIGATION_PROGRAM_STATUS[prog.substate];
        if (!prog.substate) {
          return '';
        }
        if (prog.irrigation) {
          return `
            <div style="display:'flex'; flex-direction: 'column'">
              <vegga-badge class="sm blue-water-400 mb-1">${$filter('translate')('programs.irrigating')}</vegga-badge>
              <vegga-badge class="${badgeColor}">${$filter('translate')(prog.substate)}</vegga-badge>
            </div>
            `;
        } else {
          return `<vegga-badge class="${badgeColor}">${$filter('translate')(prog.substate)}</vegga-badge>`;
        }
      } else {
        if (prog.type !== 1 && prog.daysFreq) {
          return `<vegga-text>${getProgramDaysFreq(prog.freqDays)}</vegga-text>`;
        } else if (prog.type === 1) {
          return '<vegga-text>-</vegga-text>';
        } else if (prog.type !== 1 && !prog.daysFreq) {
          const DAYS = {
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday',
            7: 'sunday',
          };

          const parsedDays = Object.keys(DAYS)
            .filter((dayKey) => prog[DAYS[dayKey]])
            .map((dayKey) => +dayKey);
          return `<vegga-time-display days="[${parsedDays}]"></vegga-time-display>`;
        }
        return '';
      }
    }

    function getSectorsCellTemplate({ sector1, sector2, sector3, sector4 }) {
      const configuredSectors = [sector1, sector2, sector3, sector4].filter((sector) => sector !== 0);

      return `${configuredSectors.map((sector) => `<vegga-badge>S${sector} </vegga-badge>`)}`.replaceAll(',', '');
    }

    function dataGridRowClick({ detail }) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.programSelected = vm.rawPrograms.find((prog) => +prog.pk.id === +detail.pkId);
      vm.programsFacade.currentProgram = detail;
      changeState({ id: vm.programSelected.pk.id });
    }

    function updateFilter(param, field) {
      vm.gridFilter = {
        ...vm.gridFilter,
        [field]: param,
      };
    }

    function changeProgram(e) {
      if (e.detail.value) {
        const program = vm.rawPrograms.find((prog) => +prog.pk.id === +e.detail.value);
        vm.programSelected = program;
        changeState({ id: vm.programSelected.pk.id });
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeProgram($event);
          break;
      }
    }

    function loadPrograms(id, refreshType) {
      if (vm.currentUnit.type === UNITS.A_2500) {
        loadPrograms25(id, refreshType);
      }
      if (vm.currentUnit.type === UNITS.A_BIT) {
        loadProgramsBit(id, refreshType);
      }
    }

    function loadPrograms25(id, refreshType) {
      vm.programsFacade.getA2500Programs(id);
      vm.programsFacade.programs$.pipe(take(1)).subscribe((programs) => {
        const programsList = progFactory.filterA2500ActivePrograms(programs, vm.currentUnit.type);
        vm.anyActiveProgram = programsList.some((program) => program.op);

        grid.data = vm.rawPrograms = parseProgramsData(programs);
        if (refreshType === 'refresh') {
          handleRefreshState();
        }
        if (refreshType === 'save') {
          handleSavedState();
        }

        if ($state.params.id) {
          vm.programsFacade.currentProgram = programs.find((prog) => +prog.pk.id === +$state.params.id);
        }
      });
    }

    function loadProgramsBit(id, refreshType) {
      vm.programsFacade.clearProgramsResponse();
      vm.programsFacade.getABITPrograms(id);
      vm.programsFacade.programs$;
      vm.programsFacade.programs$.pipe(take(1)).subscribe((programs) => {
        const programsList = progFactory.filterA2500ActivePrograms(programs, vm.currentUnit.type);
        vm.anyActiveProgram = programsList.some((program) => program.op);

        grid.data = vm.rawPrograms = parseProgramsData(programs);
        if (refreshType === 'refresh') {
          handleRefreshState();
        }
        if (refreshType === 'save') {
          handleSavedState();
        }

        if ($state.params.id) {
          vm.programsFacade.currentProgram = programs.find((prog) => +prog.pk.id === +$state.params.id);
        }
      });
    }

    function parseProgramsData(programs) {
      return programs.map((i) => ({
        pkId: i.pk.id,
        name: i.name,
        editStart: $filter('translate')(getProgramStartType(i.type)),
        startTime: getProgramStartTime(i),
        days: [],
        sectors: getSectorsCell(i),
        irrig: getProgramValueInUnit(i),
        state: [],
        ...i,
      }));
    }

    function changeState({ id }) {
      vm.active = null;
      vm.programsFacade.currentProgram = vm.rawPrograms.find((prog) => prog.pk.id === id);
      vm.program.op = true;
      const path = $state.current.name.split('.')[1];
      $state.go(`a25programs.${path || 'detail'}`, { id });
    }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('a25programs.config')) {
          $state.go('a25programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('a25programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('a25programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.rawPrograms, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function manualStart() {
      $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 4;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop() {
      $confirm({
        text: $filter('translate')('programs.send_program_stop_pause'),
        title: 'Manual AGRONIC',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 5;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualOutOf() {
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then(() => {
            $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualRunOf() {
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = vm.currentUnit.type;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then(() => {
            $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualFreqDays() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'components/programs/freq_days.html',
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 2;
          params.parameter1 = vm.program.pk.id - 1;
          params.parameter2 = selectedItem;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then(() => {});

          modalInstance.result.then(() => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    /*
     * Funcions per parsejar valors de columna "calculats"
     */
    function getProgramStartType(type) {
      switch (type) {
        case startTypes.hour:
          return 'programs.edit.hour';
        case startTypes.seq:
          return 'programs.edit.seq';
        case startTypes.cond:
          if (vm.currentUnit.inoptions.plus) {
            return 'programs.cond';
          } else {
            return 'programs.edit.input';
          }
      }
    }

    function getProgramDaysFreq(freqDays) {
      //Inici per freqüencia
      let each = $filter('translate')('programs.edit.each');
      let days_string = $filter('translate')('programs.edit._days');
      return each + ' ' + freqDays + ' ' + days_string;
    }
    function getProgramStartTime(prog) {
      switch (prog.type) {
        case startTypes.hour:
          return progFactory.getTimeFromMins(prog.start).format('HH:mm');
        case startTypes.seq:
          return 'P' + prog.start;
        case startTypes.cond:
          return '-';
      }
    }

    function getProgramValueInUnit(prog) {
      var unit = prog.unit;
      var input = prog.value;
      const decimal = vm.currentUnit.installer.decimalsIrrigation;
      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case unitTypes.HM:
          return $filter('parsetime')(input * 60);
        case unitTypes.M3:
          return (input / 1000).toFixed(decimal >= 0 ? decimal : 2) + 'm3';
        case unitTypes.M3HA:
          return (input / 1000).toFixed(decimal >= 0 ? decimal : 2) + ' m3';
        case unitTypes.MS:
          var mins = input;
          return $filter('parsetime', true)(mins);
        case unitTypes.HMHA:
          return input + ' l';
      }
    }

    function getSectorsCell(prog) {
      const sectorKeys = Object.keys(prog).filter((sectorKey) => sectorKey.includes('sector') && prog[sectorKey]);
      return sectorKeys.map((sectorKey) => prog[sectorKey]).join(', ');
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeProgramState(state) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('programs.edit.cancelq'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
              vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
              $state.go(state, { id: vm.program.pk.id }); //Canviem d'estat
            }
          });
      } else {
        $state.go(state, { id: vm.program.pk.id });
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('a25programs');
          } else {
            $state.go('a25programs.detail', { id: vm.program.pk.id });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('a25programs')
          : $state.go('a25programs.detail', { id: vm.program.pk.id });
      }
    }
    function save() {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function handleRefreshState() {
      if ($state.includes('a25programs.detail')) {
        if ($state.params.program !== undefined && vm.rawPrograms.length > 0) {
          var program = _.filter(vm.rawPrograms, (o) => {
            return o.pk.id === $state.params.program.pk.id;
          });
          if (program !== undefined && program.length > 0) {
            program[0].op = true;
            changeState({ program: program[0], id: program[0].pk.id });
            //$state.go($state.current,{program:program[0],id:program[0].pk.id, unit:$state.params.unit},{reload: true});
          } else if (!$state.params.program.op) {
            $state.go(
              $state.current,
              { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
              { reload: true }
            );
          } else {
            $state.go('a25programs', { unit: $state.params.unit });
          }
          $scope.$emit('refreshed', { message: 'refreshed' });
        } else {
          $state.go(
            $state.current,
            { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
            { reload: true }
          );
        }
      } else {
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    }

    function handleSavedState() {
      if ($state.includes('a25programs.edit') || $state.includes('a25programs.config')) {
        if ($state.params.program !== undefined && $state.params.program.op) {
          var program = _.filter(vm.rawPrograms, (o) => {
            return o.pk.id === $state.params.program.pk.id;
          });
          if (program !== undefined && program.length > 0) {
            vm.program = program[0];
            vm.program.op = true;
            //$state.go('a25programs.detail',{program:program[0],id:program[0].pk.id, unit:$state.params.unit});
          } else {
            $state.go('a25programs', { unit: $state.params.unit });
          }
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      } else {
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    }

    // $scope.$on('refresh', function (event, args) {
    //   loadPrograms($state.params.unit.id, 'refresh');
    // });

    $scope.$on('updateEdit25', function () {
      loadPrograms($state.params.unit.id, 'save');
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.programsFacade.clearProgramsResponse();
    });
  }
})();
