(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('calculatedsFactory', calculatedsFactory);

  calculatedsFactory.$inject = ['Restangular', '$rootScope', '$q', '$window'];

  function calculatedsFactory(Restangular, $rootScope, $q, $window) {
    var op = {
      waterDisp: waterDisp,
      getOneWaterDisp: getOneWaterDisp,
      waterHistory: waterHistory,
      updateWater: updateWater,
      newWater: newWater,
      deleteWater: deleteWater,
      deleteWaterSensor: deleteWaterSensor,
      getFilterStorage: getFilterStorage,
      setFilterStorage: setFilterStorage,
      waterModel: waterModel,
      effectiveRain: effectiveRain,
      effectiveRainById: effectiveRainById,
      deleteEffectiveRain: deleteEffectiveRain,
      updateEffectiveRain: updateEffectiveRain,
      effectiveRainHistory: effectiveRainHistory,
      newEffectiveRain: newEffectiveRain,
      getDPV: getDPV,
      getOneDPV: getOneDPV,
      deleteDPV: deleteDPV,
      updateDPV: updateDPV,
      historyDPV: historyDPV,
      getETO: getETO,
      getOneETO: getOneETO,
      deleteETO: deleteETO,
      updateETO: updateETO,
      historyETO: historyETO,
      getPDR: getPDR,
      getOnePDR: getOnePDR,
      deletePDR: deletePDR,
      updatePDR: updatePDR,
      historyPDR: historyPDR,
    };

    return op;

    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('conditioner')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateWater(id, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function newWater(id) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp')
        .one('new')
        .customPOST()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deleteWater(id, id2) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp', id2)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deleteWaterSensor(id, id2, index) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp', id2)
        .one('sensor', index)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function waterDisp(id, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getOneWaterDisp(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('waterdisp', sensor)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function waterHistory(id, id2, index, params) {
      var deferred = $q.defer();
      if (params.group === undefined || params.group === null) params.group = 1;
      Restangular.one('users', id)
        .one('waterdisp', id2)
        .one('history', index)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function effectiveRain(id) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('effectiverain')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function effectiveRainById(user, id) {
      var deferred = $q.defer();
      Restangular.one('users', user)
        .one('effectiverain', id)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function newEffectiveRain(id) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('effectiverain')
        .one('new')
        .customPOST()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateEffectiveRain(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('effectiverain')
        .customPOST(sensor)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deleteEffectiveRain(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('effectiverain', sensor)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function effectiveRainHistory(id, sensor, params) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('effectiverain', sensor)
        .one('history')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function setFilterStorage(val, id) {
      var stored = JSON.stringify(val);
      $window.localStorage && $window.localStorage.setItem('slider-calc-storage' + id, stored);
    }
    function getFilterStorage(id) {
      var stored = $window.localStorage && $window.localStorage.getItem('slider-calc-storage' + id);
      return JSON.parse(stored);
    }

    function getDPV(id) {
      var deferred = $q.defer();
      const params = {
        limit: 100,
        page: 1,
      };
      Restangular.one('users', id)
        .one('dpv')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getOneDPV(iduser, idsensor) {
      var deferred = $q.defer();
      Restangular.one('users', iduser)
        .one('dpv', idsensor)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateDPV(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('dpv')
        .customPOST(sensor)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deleteDPV(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('dpv', sensor)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function historyDPV(user, sensor, params) {
      var deferred = $q.defer();
      Restangular.one('users', user)
        .one('dpv', sensor)
        .one('history')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getETO(id) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('eto')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getOneETO(iduser, idsensor) {
      var deferred = $q.defer();
      Restangular.one('users', iduser)
        .one('eto', idsensor)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateETO(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('eto')
        .customPOST(sensor)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deleteETO(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('eto', sensor)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function historyETO(user, sensor, params) {
      var deferred = $q.defer();
      Restangular.one('users', user)
        .one('eto', sensor)
        .one('history')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getPDR(id) {
      var deferred = $q.defer();
      const params = {
        limit: 100,
        page: 1,
      };
      Restangular.one('users', id)
        .one('pdr')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getOnePDR(iduser, idsensor) {
      var deferred = $q.defer();
      Restangular.one('users', iduser)
        .one('pdr', idsensor)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updatePDR(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('pdr')
        .customPOST(sensor)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function deletePDR(id, sensor) {
      var deferred = $q.defer();
      Restangular.one('users', id)
        .one('pdr', sensor)
        .customDELETE()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function historyPDR(user, sensor, params) {
      var deferred = $q.defer();
      Restangular.one('users', user)
        .one('pdr', sensor)
        .one('history')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function waterModel() {
      return {
        userId: 1,
        id: 1,
        type: 3,
        name: null,
        isOrigin: null,
        operation: 1,
        units: null,
        enters: null,
        decimals: null,
        sign: null,
        xValue: null,
        sensor: {
          userId: 1,
          sensorId: null,
          calculate: null,
          graficColor: null,
          showGrafic: null,
          graficLow: null,
          graficHigh: null,
          colorCapacidadCampo: null,
          colorPuntoRecarga: null,
          colorMarchitezPermanente: null,
          colorL1: null,
          colorL2: null,
          colorZonaConfort: null,
          colorDeficitPermisible: null,
          colorZonaDrenaje: null,
          colorDeficitCritico: null,
          name: null,
          creationDate: null,
          modificationDate: null,
          xLastCalcDate: null,
          xStatus: null,
          xValue: null,
          cicles: [
            {
              userId: 1,
              sensorId: null,
              id: 1,
              startDate: null,
              recagaPoint: null,
              estresPoint: null,
              line1: null,
              line2: null,
            },
          ],
          sensors: [
            {
              id: 1,
              name: null,
              deviceId: null,
              analogId: null,
              graficColor: null,
              showGrafic: null,
              calc: null,
              point100: null,
              point0: null,
              creationDate: null,
              modificationDate: null,
              xStatusOrigin: null,
              xValueOrigin: null,
              xDateOrigin: null,
              xStatus: null,
              xValue: null,
            },
          ],
        },
      };
    }
  }
})();
