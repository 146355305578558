import { getContainer } from '@vegga/front-store';
import { UNITS } from '../utils/units.enum';
import { Subject, switchMap, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsMetersController', totalsMetersController);

  totalsMetersController.$inject = ['$scope', '$state', 'unitFactory', '$filter', '$confirm', 'manualFactory'];

  function totalsMetersController($scope, $state, unitFactory, $filter, $confirm, manualFactory) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    vm.deleteTotals = deleteTotals;

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.showSkeleton = true;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      initSubscriptions();
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          switchMap((currentUnit) => {
            if (!currentUnit) {
              return;
            }

            vm.currentUnit = currentUnit;
            unitFactory.totalsMeter(vm.currentUnit.id);

            return unitFactory.getTotalsMeterResponse();
          }),
          takeUntil(vm.destroy$)
        )
        .subscribe((res) => initGrid(res));
    }

    function initGrid(res) {
      if (!res) return;
      vm.gridData = res;

      vm.showSkeleton = false;
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
        case UNITS.A_7000:
          get40007000GridColumnsDef();
          break;
        case UNITS.A_2500:
        case UNITS.A_BIT:
          get2500BITGridColumnsDef();
          break;

        default:
          break;
      }
    }

    function get40007000GridColumnsDef() {
      vm.gridColumnsDef = [
        {
          field: 'id',
          headerName: $filter('translate')('totals.meter'),
        },
        {
          field: 'irrigation',
          headerName: $filter('translate')('totals.time'),
          cellTemplate: ({ irrigation }) => `<vegga-text>${$filter('parsetime')(irrigation)}</vegga-text>`,
        },
        {
          field: 'irrigationVolume',
          headerName: $filter('translate')('totals.volume'),
          cellTemplate: ({ irrigationVolume }) =>
            `<vegga-text>${$filter('parsevolume')(irrigationVolume)}</vegga-text>`,
        },
        {
          field: 'avgVolume',
          headerName: $filter('translate')('totals.avg'),
          cellTemplate: ({ avgVolume }) =>
            avgVolume === 0
              ? `<vegga-text>0 m3/h</vegga-text>`
              : `<vegga-text>${avgVolume.toFixed(2)} m3/h</vegga-text>`,
        },
        {
          field: 'sectors',
          headerName: $filter('translate')('totals.sectors'),
        },
      ];
    }
    function get2500BITGridColumnsDef() {
      vm.gridColumnsDef = [
        {
          field: 'id',
          headerName: $filter('translate')('totals.meter'),
        },
        {
          field: 'name',
          headerName: $filter('translate')('totals.name'),
        },
        {
          field: 'irrigationTotal',
          headerName: $filter('translate')('totals.total'),
        },
        {
          field: 'irrigationOut',
          headerName: $filter('translate')('totals.out'),
        },
      ];
    }

    function deleteTotals() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        manualFactory.showLoadingBar();
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
          case 6:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 3:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 4:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 5:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
