export const IOSelectorItemResponseOutputTypeEnum = {
  OUTPUT: 'OUTPUT',
  OUTPUT_GENERAL: 'OUTPUT_GENERAL',
  OUTPUT_AUXILIARY: 'OUTPUT_AUXILIARY',
  OUTPUT_CLEANING: 'OUTPUT_CLEANING',
  OUTPUT_ANALOG: 'OUTPUT_ANALOG',
  GENERAL_FERTILIZER: 'GENERAL_FERTILIZER',
  GENERAL_ACID: 'GENERAL_ACID',
  OUTPUT_ANALOG_PRESSURE_M1: 'OUTPUT_ANALOG_PRESSURE_M1',
  OUTPUT_ANALOG_PRESSURE_M2: 'OUTPUT_ANALOG_PRESSURE_M2',
  OUTPUT_GENERAL_VM1: 'OUTPUT_GENERAL_VM1',
  OUTPUT_OPEN_VM1: 'OUTPUT_OPEN_VM1',
  OUTPUT_CLOSE_VM1: 'OUTPUT_CLOSE_VM1',
  OUTPUT_GENERAL_VM2: 'OUTPUT_GENERAL_VM2',
  OUTPUT_OPEN_VM2: 'OUTPUT_OPEN_VM2',
  OUTPUT_CLOSE_VM2: 'OUTPUT_CLOSE_VM2',
  OUTPUT_START: 'OUTPUT_START',
  OUTPUT_CONTACT: 'OUTPUT_CONTACT',
  OUTPUT_STOP: 'OUTPUT_STOP',
  OUTPUT_PREHEATING: 'OUTPUT_PREHEATING',
  OUTPUT_EMPTY: 'OUTPUT_EMPTY',
  OUTPUT_ACTIVATION: 'OUTPUT_ACTIVATION',
  OUTPUT_SPEED: 'OUTPUT_SPEED',
  OUTPUT_DIR_RIGHT: 'OUTPUT_DIR_RIGHT',
  OUTPUT_DIR_LEFT: 'OUTPUT_DIR_LEFT',
  OUTPUT_IRRIGATION: 'OUTPUT_IRRIGATION',
  OUTPUT_GUN: 'OUTPUT_GUN',
  OUTPUT_FERTILIZER: 'OUTPUT_FERTILIZER',
  OUTPUT_EAVES: 'OUTPUT_EAVES',
  OUTPUT_FIRST: 'OUTPUT_FIRST',
  OUTPUT_LAST: 'OUTPUT_LAST',
  UNKNOWN: 'UNKNOWN',
};
/**
 * @export
 * @enum {string}
 */
export const IOSelectorItemResponseElementTypeEnum = {
  SECTORS: 'SECTORS',
  NEBULIZATION: 'NEBULIZATION',
  FERTILIZERS: 'FERTILIZERS',
  FILTERS: 'FILTERS',
  ENGINES: 'ENGINES',
  AGITATORS: 'AGITATORS',
  GENERAL: 'GENERAL',
  ACIDS: 'ACIDS',
  PHYTOSANITARY: 'PHYTOSANITARY',
  ALARMS: 'ALARMS',
  WATERMIX: 'WATERMIX',
  DIESEL: 'DIESEL',
  LOGIC_SENSORS: 'LOGIC_SENSORS',
  DRAINS: 'DRAINS',
  PIVOTS: 'PIVOTS',
  LOGIC_SENSORS_ELEMENTS: 'LOGIC_SENSORS_ELEMENTS',
  UNKNOWN: 'UNKNOWN',
};
