import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('headersController', headersController);

  headersController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'configFactory',
    '$confirm',
    '$filter',
    'manualFactory',
    'sensorsFactory',
    'unitFactory',
  ];

  function headersController(
    $scope,
    $rootScope,
    $state,
    configFactory,
    $confirm,
    $filter,
    manualFactory,
    sensorsFactory,
    unitFactory
  ) {
    var vm = this;
    vm.save;
    vm.cancel;
    vm.filtersHeader;
    vm.activeAll;
    vm.headerId;
    vm.header1;
    vm.header2;
    vm.header3;
    vm.header4;
    vm.cancel;
    vm.save;
    vm.destroy$ = new Subject();
    vm.showHeaders = showHeaders;
    vm.showOverlay = showOverlay;
    vm.manualHeaderOut = manualHeaderOut;
    vm.manualHeaderStop = manualHeaderStop;
    vm.stopHeaderProgress = stopHeaderProgress;

    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.activeAll = true;
      vm.currentUnit = currentUnit;

      /*  vm.filters = $state.params.filters;  */
      vm.currentHeader = $state.params.headerId;

      loadHeaders();
      showManualButtons();
    }

    function loadHeaders() {
      var params = { add: ['engines', 'headersram'] };
      configFactory.getHeadersGeneral(vm.currentUnit.id, params).then((data) => {
        vm.headersEngines = data.plain();
        if (!vm.currentUnit.inoptions.optionPlus) {
          // Si l'equip no té PLUS només es mostra el capçal 1
          vm.headersEngines = [vm.headersEngines[0]];
        }
        _.forEach(vm.headersEngines, (h) => {
          if (h.idsAnalogPressure > 0) {
            sensorsFactory.analogById(vm.currentUnit.id, h.idsAnalogPressure).then((response) => {
              let result = response.plain();
              h.spresion = result.xValue / 10;
            });
          } else h.spresion = 0;
        });
      });
    }

    function loadAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        return result.xValue;
      });
    }

    function changeHeader() {
      if (vm.header1) {
        vm.headerId = 1;
      } else if (vm.header2) {
        vm.headerId = 2;
      } else if (vm.header3) {
        vm.headerId = 3;
      } else if (vm.header4) {
        vm.headerId = 4;
      } else {
        vm.headerId = 1;
        vm.activeAll = false;
        vm.header1 = true;
      }
    }

    function showOverlay(state) {
      if (state !== 'filters.headers') {
      }
      changeHeader();
      $state.go(state, { filters: vm.filtersHeader, headerId: vm.headerId });

      vm.overlayEl = document.querySelector('#filter-header-overlay');
      vm.overlayEl.show();
    }

    function showHeaders(value) {
      switch (value) {
        case 'all':
          vm.activeAll = true;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header1':
          vm.activeAll = false;
          vm.header1 = true;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header2':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = true;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header3':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = true;
          vm.header4 = false;
          break;
        case 'header4':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = true;
          break;
      }
    }

    function showManualButtons() {
      _.forEach(vm.filtersHeader, (filter) => {
        switch (filter.xStatus) {
          case 0:
            filter.btnStartFilter = true;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 1:
          case 2:
          case 4:
          case 5:
          case 6:
            filter.btnStartFilter = false;
            filter.btnStopFilter = true;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 3:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 7:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = true;
            break;
          default:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = false;
            break;
        }
      });
    }

    function manualHeaderOut(header, e) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 14;
        params.parameter1 = header;
        params.parameter2 = e;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualHeaderStop(header, e) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 16;
        params.parameter1 = header;
        params.parameter2 = e;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function stopHeaderProgress(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 17;
        params.parameter1 = header;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
