(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('traysControllerA7', traysControllerA7);

  traysControllerA7.$inject = ['$scope', '$state', 'trays7Factory', '$confirm', 'manualFactory', '$filter'];

  function traysControllerA7($scope, $state, trays7Factory, $confirm, manualFactory, $filter) {
    var vm = this;

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.showOverlay = showOverlay;
      vm.drainageUnits = $state.params.unit.installer.drainageUnits;
      vm.balanceRain = $state.params.unit.installer.balanceRain;
      vm.toogleManual = toogleManual;
      vm.manualChangeDP = manualChangeDP;
      vm.manualChangeCD = manualChangeCD;
      vm.cancel = cancel;
      vm.save = save;
      vm.saveManual = saveManual;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
      } else {
        $state.go('units');
      }
      loadAllTrays();
    }

    function showOverlay(state) {
      vm.currentPage = 'config';
      $state.go(state, { unit: vm.currentUnit });
      vm.overlayEl = document.querySelector('vegga-overlay');
      vm.overlayEl.show();
    }

    function loadAllTrays() {
      vm.trays = [];
      trays7Factory.allTrays($state.params.unit.id).then(function (data) {
        vm.trays = data.plain();

        for (var i = 0, len = vm.trays.length; i < len; i++) {
          if (vm.trays[i].xCorrectionIrrig > 0) {
            vm.trays[i].xCorrectionIrrig = vm.trays[i].xCorrectionIrrig / 10;
          }
          if (vm.trays[i].xDrainPh > 0) {
            vm.trays[i].xDrainPh = vm.trays[i].xDrainPh / 10;
          }
          if (vm.trays[i].xDrainCe > 0) {
            vm.trays[i].xDrainCe = vm.trays[i].xDrainCe / 10;
          }
        }
      });
    }
    function toogleManual(t) {
      vm.currentPage = 'manual';
      vm.currentTray = t;
      vm.overlayEl = document.querySelector('vegga-overlay');
      vm.overlayEl.show();
    }

    function manualChangeDP() {
      var params = {};
      params.deviceId = vm.currentUnit.id;
      params.type = vm.currentUnit.type;
      params.action = 6;
      params.parameter1 = Number(vm.currentTray.pk.id) - 1;
      params.parameter2 = Number(vm.currentTray.dp);
      manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
    }

    function manualChangeCD() {
      var params = {};
      params.deviceId = vm.currentUnit.id;
      params.type = vm.currentUnit.type;
      params.action = 7;
      params.parameter1 = Number(vm.currentTray.pk.id) - 1;
      params.parameter2 = Number(vm.currentTray.cd);
      manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
          }
          if (vm.currentPage !== 'manual') {
            $state.go('a7trays', { unit: vm.currentUnit });
          }
          vm.currentPage = null;
          vm.currentTray = null;
        });
      } else {
        document.querySelector('vegga-overlay')?.dismiss();
        if (vm.currentPage !== 'manual') {
          $state.go('a7trays', { unit: vm.currentUnit });
        }
        vm.currentPage = null;
        vm.currentTray = null;
      }
    }

    function save(_ev) {
      if (vm.currentPage === 'manual') {
      }

      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function saveManual() {
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(() => {
        if (vm.currentTray.dp && vm.currentTray.dp !== vm.currentTray.olderDP) {
          vm.currentTray.olderDP = vm.currentTray.dp;
          manualChangeDP();
        }

        if (vm.currentTray.cd && vm.currentTray.cd !== vm.currentTray.olderCD) {
          vm.currentTray.olderCD = vm.currentTray.cd;
          manualChangeCD();
        }
        vm.form.$setPristine();
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          loadAllTrays();
        });
      });
    }

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });

    $scope.$on('refresh', function (event, args) {});
  }
})();
