import veggaDropdownTemplate from './vegga-dropdown.template.html';

(function () {
  'use strict';

  angular.module('agronicwebApp').directive('veggaDropdownLatest', veggaDropdown);

  function veggaDropdown() {
    return {
      restrict: 'E',
      template: veggaDropdownTemplate,
      transclude: true,
      scope: {
        label: '@',
        name: '@',
        multiple: '@',
        ngModel: '=',
        vgForm: '=',
        placeholder: '@?',
        helperMessage: '@?',
        errorMessage: '@?',
        prefixText: '@?',
        from: '@?',
        onChange: '&',
        onInit: '&',
        disabled: '=',
        readonly: '=',
        onBlur: '&',
        required: '@',
        filter: '@',
        size: '@',
      },
      // controller: function($scope,$element) {
      // },
      link: function ($scope, $element, attrs, ngModel) {
        if ($scope.onInit) {
          $scope.onInit();

          if ($scope.size) {
            const dropdown = $element[0].querySelector('vegga-dropdown');
            dropdown.classList.add($scope.size);
          }
        }

        $scope.onVeggaDropdownChange = function ($event) {
          $scope.dropDownChange = true;
          if (!$scope.multiple) {
            $scope.ngModel = $event.detail.value;
          } else {
            $scope.ngModel = [];
            $event.detail.forEach((item) => {
              $scope.ngModel.push(item.value);
            });
          }

          $scope.$emit('VeggaDropdownChange', $event.detail);
        };

        $scope.onVeggaDropdownBlur = function ($event) {
          if ($scope.onBlur) {
            $scope.onBlur();
          }
          $scope.$emit('VeggaDropdownBlur', $event.detail);
        };

        $scope.$watch('ngModel', () => {
          if ($scope.dropDownChange) {
            if ($scope.vgForm) {
              $scope.vgForm.$setDirty();
            }

            if (attrs.onChange) {
              $scope.onChange();
            }

            $scope.dropDownChange = false;
          }
        });
      },
    };
  }
})();
