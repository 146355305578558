import { MEASUREMENT_UNITS } from '@vegga/front-utils';
import { UNITS } from './units.enum';

const metersAccumulated = [
  { deviceFormatKey: 0, format: '0000 ' + MEASUREMENT_UNITS[1].symbol },
  { deviceFormatKey: 1, format: '000.0 ' + MEASUREMENT_UNITS[1].symbol },
  { deviceFormatKey: 2, format: '000.00 ' + MEASUREMENT_UNITS[1].symbol },
  { deviceFormatKey: 3, format: '0000 ' + MEASUREMENT_UNITS[5].symbol },
  { deviceFormatKey: 4, format: '000.0 ' + MEASUREMENT_UNITS[5].symbol },
  { deviceFormatKey: 5, format: '000.00 ' + MEASUREMENT_UNITS[5].symbol },
  { deviceFormatKey: 6, format: '0000 ' + MEASUREMENT_UNITS[20].symbol },
  { deviceFormatKey: 7, format: '000.0 ' + MEASUREMENT_UNITS[20].symbol },
  { deviceFormatKey: 8, format: '000.00 ' + MEASUREMENT_UNITS[20].symbol },
];

export const A2500_PLUS_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[2] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[4] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[6] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
    ],
    flow: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[14] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[15] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[16] },
    ],
  },
  fertilization: {
    measurementUnits: [
      { key: 'HHMM', unit: MEASUREMENT_UNITS[0] },
      { key: 'L', unit: MEASUREMENT_UNITS[5] },
      { key: 'LHA', unit: MEASUREMENT_UNITS[6] },
      { key: 'MMSS', unit: MEASUREMENT_UNITS[3] },
    ],
    type: [
      { type: 'fert.serie', application: '-' },
      { type: 'fert.paral', application: '-' },
      { type: 'fert.paral', application: 'fert.uni' },
    ],
    configurationStatus: [
      { key: 'CONFIGURED', style: 'success' },
      { key: 'NOT_CONFIGURED', style: 'black' },
      { key: 'FINALIZED', style: 'default', icon: 'success-circle' },
      { key: 'PENDING', style: 'default', icon: 'timer' },
      { key: 'EXECUTING', style: 'success', icon: 'drop' },
    ],
  },
  formats: {
    metersAccumulated: metersAccumulated,
  },
};

export const A2500_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[1] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[6] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
    ],
    flow: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[14] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[15] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[16] },
    ],
  },
  formats: {
    metersAccumulated: metersAccumulated,
  },
};

export const A4000_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 16, measurementUnit: MEASUREMENT_UNITS[2] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 8, measurementUnit: MEASUREMENT_UNITS[7] },
      { deviceMeasurementUnitKey: 32, measurementUnit: MEASUREMENT_UNITS[6] },
      { deviceMeasurementUnitKey: 128, measurementUnit: MEASUREMENT_UNITS[8] },
    ],
  },
};

export const A4500_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[2] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[10] },
      { deviceMeasurementUnitKey: 5, measurementUnit: MEASUREMENT_UNITS[9] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[6] },
    ],
  },
  formats: {
    metersFlow: {
      volume: [
        { deviceFormatKey: 0, format: '0000 ' + MEASUREMENT_UNITS[14].symbol },
        { deviceFormatKey: 1, format: '000.0 ' + MEASUREMENT_UNITS[14].symbol },
        { deviceFormatKey: 2, format: '00.00 ' + MEASUREMENT_UNITS[14].symbol },
        { deviceFormatKey: 3, format: '0000 ' + MEASUREMENT_UNITS[15].symbol },
        { deviceFormatKey: 4, format: '000.0 ' + MEASUREMENT_UNITS[15].symbol },
        { deviceFormatKey: 5, format: '00.00 ' + MEASUREMENT_UNITS[15].symbol },
        { deviceFormatKey: 6, format: '0000 ' + MEASUREMENT_UNITS[16].symbol },
        { deviceFormatKey: 7, format: '000.0 ' + MEASUREMENT_UNITS[16].symbol },
        { deviceFormatKey: 8, format: '00.00 ' + MEASUREMENT_UNITS[16].symbol },
        { deviceFormatKey: 9, format: '0000 ' + MEASUREMENT_UNITS[17].symbol },
        { deviceFormatKey: 10, format: '000.0 ' + MEASUREMENT_UNITS[17].symbol },
        { deviceFormatKey: 11, format: '00.00 ' + MEASUREMENT_UNITS[17].symbol },
      ],
      energy: [
        { deviceFormatKey: 12, format: '0000 ' + MEASUREMENT_UNITS[19].symbol },
        { deviceFormatKey: 13, format: '000.0 ' + MEASUREMENT_UNITS[19].symbol },
        { deviceFormatKey: 14, format: '00.00 ' + MEASUREMENT_UNITS[19].symbol },
      ],
      unit: [{ deviceFormatKey: 15, format: MEASUREMENT_UNITS[18].symbol }],
    },
    metersAccumulated: {
      volume: [
        { deviceFormatKey: 0, format: MEASUREMENT_UNITS[1].symbol },
        { deviceFormatKey: 1, format: MEASUREMENT_UNITS[5].symbol },
        { deviceFormatKey: 2, format: MEASUREMENT_UNITS[7].symbol },
      ],
      energy: [{ deviceFormatKey: 3, format: MEASUREMENT_UNITS[19].symbol }],
      unit: [{ deviceFormatKey: 4, format: MEASUREMENT_UNITS[18].symbol }],
    },
  },
};

export const A5500_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[2] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[4] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[6] },
    ],
  },
  formats: {
    metersAccumulated: metersAccumulated,
  },
};

export const A7000_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[5] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[7] },
    ],
  },
  formats: {
    metersAccumulated: metersAccumulated,
  },
};

export const ABIT_DEFAULT_CONFIG = {
  measurementUnits: {
    irrigation: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[1] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[2] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
      { deviceMeasurementUnitKey: 4, measurementUnit: MEASUREMENT_UNITS[4] },
    ],
    fertilization: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[0] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[5] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[6] },
      { deviceMeasurementUnitKey: 3, measurementUnit: MEASUREMENT_UNITS[3] },
    ],
    flow: [
      { deviceMeasurementUnitKey: 0, measurementUnit: MEASUREMENT_UNITS[14] },
      { deviceMeasurementUnitKey: 1, measurementUnit: MEASUREMENT_UNITS[15] },
      { deviceMeasurementUnitKey: 2, measurementUnit: MEASUREMENT_UNITS[16] },
    ],
  },
  formats: {
    metersAccumulated: metersAccumulated,
  },
};

export function getDeviceConfig(unit) {
  switch (unit.type) {
    case UNITS.A_2500:
      return unit.inoptions.plus ? A2500_PLUS_DEFAULT_CONFIG : A2500_DEFAULT_CONFIG;
    case UNITS.A_4000:
      return A4000_DEFAULT_CONFIG;
    case UNITS.A_BIT:
      return ABIT_DEFAULT_CONFIG;
    case UNITS.A_5500:
      return A5500_DEFAULT_CONFIG;
    case UNITS.A_7000:
      return A7000_DEFAULT_CONFIG;
    case UNITS.A_4500:
      return A4500_DEFAULT_CONFIG;
  }
}
