(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('solarFactory', solarFactory);

  solarFactory.$inject = ['Restangular', '$q'];

  function solarFactory(Restangular, $q) {
    var op = {
      solar: solar,
      update: update,
    };

    return op;

    function solar(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('solar')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('solar')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
  }
})();
