import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outGeneralController', outGeneralController);

  outGeneralController.$inject = ['$rootScope', '$scope', '$state', 'configFactory'];

  function outGeneralController(
    $rootScope,
    $scope,
    $state,

    configFactory
  ) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.currentUnit = angular.copy($state.params.unit);

        if (
          $state.current.name === 'outputs.diesel' &&
          vm.currentUnit.inoptions.ge !== null &&
          !vm.currentUnit.inoptions.ge
        ) {
          $state.go('outputs.diesel');
        }
        if (
          $state.current.name === 'outputs.pressure' &&
          vm.currentUnit.inoptions.rp !== null &&
          !vm.currentUnit.inoptions.rp
        ) {
          $state.go('outputs.pressure');
        }
        if (
          $state.current.name === 'outputs.ph' &&
          vm.currentUnit.inoptions !== undefined &&
          vm.currentUnit.inoptions.ph !== null &&
          !vm.currentUnit.inoptions.ph
        ) {
          $state.go('outputs.ph');
        }

        loadConfig();
        vm.selected = 0;
      } else {
        $state.go('units');
      }
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadConfig() {
      var params = {};
      vm.loading = true;
      configFactory.get(vm.currentUnit.id, params).then((result) => {
        vm.config = result;
        backup = angular.copy(vm.config);
        vm.loading = false;
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = angular.copy(vm.currentUnit.type);

      vm.loading = true;
      configFactory.updateConfig(vm.currentUnit.id, vm.config).then((result) => {
        vm.config = result;
        backup = angular.copy(vm.config);
        vm.loading = false;
      });
    }

    function cancel_edition() {
      vm.config = angular.copy(backup);
    }

    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );
  }
})();
