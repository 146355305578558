import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('nebulizationDetailController', nebulizationDetailController);

  nebulizationDetailController.$inject = ['$scope', '$state', '$confirm', 'manualFactory', '$filter'];

  function nebulizationDetailController($scope, $state, $confirm, manualFactory, $filter) {
    var vm = this;
    vm.currentNebulization;
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('programs.prog2') },
      { headerName: $filter('translate')('programs.detail.name') },
      { headerName: $filter('translate')('fert.state') },
    ];

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.doManualAction = doManualAction;
      vm.manualStart = manualStart;
      vm.manualStop = manualStop;
      vm.manualAuto = manualAuto;
      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.currentNebulization = $state.params.nebulization;
      } else {
        $state.go('units');
      }
    }

    function doManualAction(prog, type) {
      $confirm({
        text: $filter('translate')('general.sendaction'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('general.y'),
        cancel: $filter('translate')('general.n'),
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = type;
        params.parameter1 = Number(prog.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          activate();
        });
      });
    }

    function manualStart(prog) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 42;
          params.parameter1 = Number(vm.currentNebulization.pk.id);
        } else {
          params.action = 28;
          params.parameter1 = Number(prog.pk.id) - 1;
        }
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop(prog) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 41;
          params.parameter1 = Number(vm.currentNebulization.pk.id);
        } else {
          params.action = 27;
          params.parameter1 = Number(prog.pk.id) - 1;
        }
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualAuto(prog) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 43;
          params.parameter1 = Number(vm.currentNebulization.pk.id);
        } else {
          params.action = 29;
          params.parameter1 = Number(prog.pk.id) - 1;
        }
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        vm.nebulization = $state.params.nebulization;
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });
  }
})();
