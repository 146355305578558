import { UNITS, UNITS_V2 } from '../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('nebulizationConfigController', nebulizationConfigController);

  nebulizationConfigController.$inject = ['$scope', '$state', 'sensorsFactory', 'unitFactory', 'nebulizationFactory'];

  function nebulizationConfigController($scope, $state, sensorsFactory, unitFactory, nebulizationFactory) {
    var vm = this;
    vm.configNebulization;
    vm.activeList;
    vm.currentHeader;
    vm.form;

    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.changeSensor = changeSensor;
      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.currentHeader = 0;
        vm.configNebulization = $state.params.nebulization;
        vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
        vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });
          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
        }
        loadSensors();
        loadConditioners();
        backup();
      } else {
        $state.go('units');
      }
    }

    function changeSensor(s_id) {
      if (vm.configNebulization.sensors[s_id].sensor === 0) {
        vm.configNebulization.dpvControl = 0;
      }
    }

    function loadSensors() {
      sensorsFactory.analogsPage(vm.currentUnit.id, { page: 1, limit: 120 }).then(function (data) {
        if (data.content) {
          vm.sensorsList = data.content;
        }
      });
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.conditioners = data.plain();
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    function backup() {
      vm.nebulization_backup = {};
      angular.copy(vm.configNebulization, vm.nebulization_backup);
    }

    function cancel_edition() {
      angular.copy(vm.nebulization_backup, vm.configNebulization);
    }

    function save() {
      vm.form.$setPristine();

      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(nebulizationFactory.update45(vm.currentUnit.id, vm.configNebulization));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe();
          break;
        default:
          nebulizationFactory.update45(vm.currentUnit.id, vm.configNebulization).then(() => {});
          break;
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
