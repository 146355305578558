import { getContainer } from '@vegga/front-store';
import { UNITS } from '../utils/units.enum';
import { Subject, switchMap, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsSectorsController', totalsSectorsController);

  totalsSectorsController.$inject = ['$scope', '$state', '$confirm', 'manualFactory', 'unitFactory', '$filter'];

  function totalsSectorsController($scope, $state, $confirm, manualFactory, unitFactory, $filter) {
    var vm = this;
    vm.activeList;
    vm.getTotalVolume;
    vm.parsefert;
    vm.getTotalVolume = getTotalVolume;
    vm.getTotalFert = getTotalFert;
    vm.parsefert = parsefert;
    vm.parseFert25 = parseFert25;
    vm.deleteTotals = deleteTotals;
    vm.UNITS = UNITS;

    const commonColDefs = [
      {
        field: 'id',
        headerName: $filter('translate')('totals.sector'),
        cellTemplate: ({ id }) =>
          +id === 0 ? `<vegga-text>${$filter('translate')('general.general')}</vegga-text>` : id,
      },
      {
        field: 'irrigation',
        headerName: $filter('translate')('totals.time'),
        cellTemplate: ({ irrigation }) => `<vegga-text>${$filter('parsetime')(irrigation)}</vegga-text>`,
      },
    ];

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.showSkeleton = true;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      initSubscriptions();
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          switchMap((currentUnit) => {
            if (!currentUnit) {
              return;
            }
            vm.currentUnit = currentUnit;
            unitFactory.totals(vm.currentUnit.id);

            return unitFactory.getTotalsResponse();
          }),
          takeUntil(vm.destroy$)
        )
        .subscribe((res) => {
          initGrid(res);
        });
    }

    function initGrid(res) {
      if (!res) return;
      vm.showSkeleton = false;
      vm.gridData = res.map((el) => {
        return {
          ...el,
          realFlow: (el.irrigationVolume / 1000 / (el.irrigation / 3600)).toFixed(2),
        };
      });

      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          get4000GridColumnsDef();
          break;
        case UNITS.A_2500:
          get2500GridColumnsDef();
          break;
        case UNITS.A_7000:
          get7000GridColumnsDef();
          break;
        case UNITS.A_BIT:
          getBITGridColumnsDef();
          break;
        default:
          break;
      }
    }

    function deleteTotals() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        manualFactory.showLoadingBar();
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
          case 6:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 3:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 4:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
          case 5:
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
      });
    }

    function get4000GridColumnsDef() {
      vm.gridColumnsDef = [
        ...commonColDefs,
        {
          field: 'irrigationVolume',
          headerName: $filter('translate')('totals.volume'),
          cellTemplate: ({ id, irrigationVolume }) =>
            +id === 0
              ? `<vegga-text>${$filter('parsevolume')(vm.getTotalVolume())}</vegga-text>`
              : `<vegga-text>${$filter('parsevolume')(irrigationVolume)}</vegga-text>`,
        },
        {
          field: 'fert1',
          headerName: $filter('translate')('totals.f1'),
          cellTemplate: ({ fert1, id }) => `<vegga-text>${vm.parsefert(fert1, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert2',
          headerName: $filter('translate')('totals.f2'),
          cellTemplate: ({ fert2, id }) => `<vegga-text>${vm.parsefert(fert2, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert3',
          headerName: $filter('translate')('totals.f3'),
          cellTemplate: ({ fert3, id }) => `<vegga-text>${vm.parsefert(fert3, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert4',
          headerName: $filter('translate')('totals.f4'),
          cellTemplate: ({ fert4, id }) => `<vegga-text>${vm.parsefert(fert4, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert5',
          headerName: $filter('translate')('totals.f5'),
          cellTemplate: ({ fert5, id }) => `<vegga-text>${vm.parsefert(fert5, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert6',
          headerName: $filter('translate')('totals.f6'),
          cellTemplate: ({ fert6, id }) => `<vegga-text>${vm.parsefert(fert6, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert7',
          headerName: $filter('translate')('totals.f7'),
          cellTemplate: ({ fert7, id }) => `<vegga-text>${vm.parsefert(fert7, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert8',
          headerName: $filter('translate')('totals.f8'),
          cellTemplate: ({ fert8, id }) => `<vegga-text>${vm.parsefert(fert8, +id === 0)}</vegga-text>`,
        },
        {
          field: 'date',
          headerName: $filter('translate')('totals.date'),
        },
      ];
    }
    function get2500GridColumnsDef() {
      vm.gridColumnsDef = [
        ...commonColDefs,
        {
          field: 'irrigationVolume',
          headerName: $filter('translate')('totals.volume'),
          cellTemplate: ({ id, irrigationVolume }) =>
            `<vegga-text>${$filter('parsevolume')(+id === 0 ? vm.getTotalVolume() : irrigationVolume)}</vegga-text>`,
        },
        {
          field: 'fert5',
          headerName: $filter('translate')('totals.f1'),
          cellTemplate: ({ fert5 }) => `<vegga-text>${$filter('parsetime')(fert5)}</vegga-text>`,
        },
        {
          field: 'fert6',
          headerName: $filter('translate')('totals.f2'),
          cellTemplate: ({ fert6 }) => `<vegga-text>${$filter('parsetime')(fert6)}</vegga-text>`,
        },
        {
          field: 'fert7',
          headerName: $filter('translate')('totals.f3'),
          cellTemplate: ({ fert7 }) => `<vegga-text>${$filter('parsetime')(fert7)}</vegga-text>`,
        },
        {
          field: 'fert8',
          headerName: $filter('translate')('totals.f4'),
          cellTemplate: ({ fert8 }) => `<vegga-text>${$filter('parsetime')(fert8)}</vegga-text>`,
        },
        {
          field: 'fert1',
          headerName: $filter('translate')('totals.f1v'),
          cellTemplate: ({ fert1, id }) =>
            `<vegga-text>${+id === 0 ? vm.getTotalFert('fert1') : vm.parseFert25(fert1)}</vegga-text>`,
        },
        {
          field: 'fert2',
          headerName: $filter('translate')('totals.f2v'),
          cellTemplate: ({ fert2, id }) => {
            return `<vegga-text>${+id === 0 ? vm.getTotalFert('fert2') : vm.parseFert25(fert2)}</vegga-text>`;
          },
        },
        {
          field: 'fert3',
          headerName: $filter('translate')('totals.f3v'),
          cellTemplate: ({ fert3, id }) =>
            `<vegga-text>${+id === 0 ? vm.getTotalFert('fert3') : vm.parseFert25(fert3)}</vegga-text>`,
        },
        {
          field: 'fert4',
          headerName: $filter('translate')('totals.f4v'),
          cellTemplate: ({ fert4, id }) =>
            `<vegga-text>${+id === 0 ? vm.getTotalFert('fert4') : vm.parseFert25(fert4)}</vegga-text>`,
        },
        {
          field: 'date',
          headerName: $filter('translate')('totals.date'),
        },
      ];
    }
    function get7000GridColumnsDef() {
      vm.gridColumnsDef = [
        ...commonColDefs,
        {
          field: 'irrigationVolume',
          headerName: $filter('translate')('totals.volume'),
          cellTemplate: ({ id, irrigationVolume }) =>
            +id === 0
              ? `<vegga-text>${$filter('parsevolume')(vm.getTotalVolume())}</vegga-text>`
              : `<vegga-text>${$filter('parsevolume')(irrigationVolume)}</vegga-text>`,
        },
        {
          field: 'fert1',
          headerName: $filter('translate')('totals.f1'),
          cellTemplate: ({ fert1, id }) => `<vegga-text>${vm.parsefert(fert1, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert2',
          headerName: $filter('translate')('totals.f2'),
          cellTemplate: ({ fert2, id }) => `<vegga-text>${vm.parsefert(fert2, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert3',
          headerName: $filter('translate')('totals.f3'),
          cellTemplate: ({ fert3, id }) => `<vegga-text>${vm.parsefert(fert3, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert4',
          headerName: $filter('translate')('totals.f4'),
          cellTemplate: ({ fert4, id }) => `<vegga-text>${vm.parsefert(fert4, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert5',
          headerName: $filter('translate')('totals.f5'),
          cellTemplate: ({ fert5, id }) => `<vegga-text>${vm.parsefert(fert5, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert6',
          headerName: $filter('translate')('totals.f6'),
          cellTemplate: ({ fert6, id }) => `<vegga-text>${vm.parsefert(fert6, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert7',
          headerName: $filter('translate')('totals.f7'),
          cellTemplate: ({ fert7, id }) => `<vegga-text>${vm.parsefert(fert7, +id === 0)}</vegga-text>`,
        },
        {
          field: 'fert8',
          headerName: $filter('translate')('totals.f8'),
          cellTemplate: ({ fert8, id }) => `<vegga-text>${vm.parsefert(fert8, +id === 0)}</vegga-text>`,
        },
        {
          field: 'realFlow',
          headerName: $filter('translate')('totals.f8'),
          cellTemplate: ({ realFlow }) => `<vegga-text>${realFlow} m3/h</vegga-text>`,
        },
        {
          field: 'date',
          headerName: $filter('translate')('totals.date'),
        },
      ];
    }
    function getBITGridColumnsDef() {
      vm.gridColumnsDef = [
        ...commonColDefs,
        {
          field: 'irrigationVolume',
          headerName: $filter('translate')('totals.volume'),
          cellTemplate: ({ id, irrigationVolume }) =>
            +id === 0
              ? `<vegga-text>${$filter('parsevolume')(vm.getTotalVolume())}</vegga-text>`
              : `<vegga-text>${$filter('parsevolume')(irrigationVolume)}</vegga-text>`,
        },
        {
          field: 'date',
          headerName: $filter('translate')('totals.date'),
        },
      ];
    }

    function getTotalVolume() {
      return vm.gridData.reduce((acc, val) => acc + val.irrigationVolume, 0);
    }

    function parseFert25(input) {
      return input === 0 || input === null ? '-' : (input / 100).toFixed(2) + ' l';
    }

    function getTotalFert(input) {
      const total = vm.gridData.reduce((acc, val) => acc + val[input], 0);

      return parseFert25(total);
    }

    function parsefert(input, first) {
      if (vm.currentUnit.flow) {
        switch (vm.currentUnit.flow.fertilizationUnit) {
          case 0:
          case 1:
            return $filter('parsetime')(input);

          case 4:
          case 8:
          case 32:
          case 128:
            if (first) {
              input = Number(input) / 10;
            }
            input = Number(input) / 10;
            return input === 0 ? '-' : input.toFixed(1) + ' l';

          default:
            return '-';
        }
      } else {
        return '-';
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
