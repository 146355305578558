(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsConfigController', sensorsConfigController);

  sensorsConfigController.$inject = ['$scope', '$state'];

  function sensorsConfigController($scope, $state) {
    var vm = this;
    vm.activeList;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        if ($state.current.parent === 'sensors') {
          $state.go('.ds', { unit: $state.params.unit });
        }
      } else {
        $state.go('units');
      }
    }

    $scope.$on('refresh', function (event, args) {});
    $scope.$on('refresh-format', function (event, args) {
      vm.currentUnit = args.unit;
    });
  }
})();
