import { getContainer } from '@vegga/front-store';
import { UNITS } from '../../utils/units.enum';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { navigateToUrl } from 'single-spa';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('modulesController', modulesController);

  modulesController.$inject = ['$scope', '$state', '$confirm', '$filter', 'unitFactory', 'veggaModalConfirmService'];

  function modulesController($scope, $state, $confirm, $filter, unitFactory, veggaModalConfirmService) {
    var vm = this;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });

      vm.currentState = $state.includes;
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      vm.changeState = changeState;
    }

    function changeState(state) {
      if (vm.form && vm.form.$dirty) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('programs.edit.cancelq'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
              vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
              vm.form = null;
              navigate(state);
            }
          });
      } else {
        navigate(state);
      }
    }

    function navigate(state) {
      if (vm.currentUnit.type === UNITS.A_4500 && state === 'expansion-modules') {
        navigateToUrl(`/irrigation-controls/devices/${vm.currentUnit.id}/communications/modules/${state}`);
        return;
      }
      $state.go('communications.modules.' + state, { unit: vm.currentUnit });
    }

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
      $scope.$emit('formToUnitUpdated', vm.form);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
