export const MapModule = {
  AM120: 1,
  AR868_16: 2,
  AR24: 3,
  AR433: 4,
  MI: 5,
  ME: 6,
  R868: 7,
  AGROBEE: 8,
  AGROBEEL: 9,
  SDI12: 10,
};
