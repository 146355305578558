import { UNITS } from '../utils/units.enum';
import { $q } from 'angular-ui-router';
import { CONDITIONERS_45 } from '../utils/enums/conditioners/conditioners_origin_names';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('conditionerConfigController', conditionerConfigController);

  conditionerConfigController.$inject = [
    '$scope',
    '$state',
    'sensorsFactory',
    'conditionerFactory',
    'unitFactory',
    '$confirm',
    '$filter',
    '$rootScope',
  ];

  function conditionerConfigController(
    $scope,
    $state,
    sensorsFactory,
    conditionerFactory,
    unitFactory,
    $confirm,
    $filter,
    $rootScope
  ) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    vm.changeItem;
    var updateForm;
    var sensorsLoaded;
    var allAnalogicFormats = null;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.formats = vm.currentUnit.formats;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.updateFrom = updateFrom;
        vm.updateSensor = updateSensor;
        vm.updateSensor4500 = updateSensor4500;
        vm.prepareFormat = prepareFormat;
        vm.formatView = formatView;
        vm.getTypeString = getTypeString;
        vm.changeConditioner = changeConditioner;
        vm.setFirstFrom = setFirstFrom;
        vm.changingConditioner = changingConditioner;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.getModuleErrorLabelAccordingToDeviceType = getModuleErrorLabelAccordingToDeviceType;
        vm.setAnalogicFormat = setAnalogicFormat;
        vm.getAnalogicFormatSuffix = getAnalogicFormatSuffix;
        vm.prepareFormatView45 = prepareFormatView45;
        vm.setSensorToZero = setSensorToZero;
        vm.setHeader = setHeader;
        vm.resetOrigin = resetOrigin;

        vm.digitalSensors = null;
        vm.analogSensors = null;
        vm.meterSensors = null;
        vm.logicSensors = null;
        vm.analogs = null;
        vm.format = {};

        vm.smsc = _.range(0, 7);
        vm.sensorerror = _.range(0, 3);
        vm.modules = _.range(0, 17);

        vm.resetOriginDropdown = true;

        //setAnalogicFormat();
        initConditionerTypes();
        loadAnalogs();
        if (vm.currentUnit.type !== vm.UNITS.A_4500) {
          loadConditioners(0);
          vm.overlayEl = document.querySelector('vegga-overlay');
          vm.overlayEl.show();
        } else {
          vm.setOtherHeadersToZero = setOtherHeadersToZero;

          vm.conditioners = $state.params.conditioners;

          vm.conditioner = vm.conditioners[0];

          getSelectedHeader();

          updateFrom();
          updateSensor();
          vm.page = 1;
          vm.totals = _.range(1, vm.conditioners.length + 1);
          vm.conditioner_backup = JSON.parse(JSON.stringify(vm.conditioner));
          vm.overlayEl = document.querySelector('vegga-overlay');
          vm.overlayEl.show();
        }
      } else {
        $state.go('units');
      }
    }

    function setOtherHeadersToZero(headerId) {
      for (let i = 1; i <= 4; i++) {
        if (i !== headerId) {
          eval(`vm.conditioner.header${i} = 0`);
        }
      }
    }

    function getTypeString(key) {
      switch (vm.currentUnit.type) {
        case UNITS.A_5500:
          return 'cond.type55_' + key;
        case UNITS.A_4500:
          return 'cond.type45_' + key;
        default:
          return 'cond.type' + key;
      }
    }

    function getModuleErrorLabelAccordingToDeviceType(deviceErrorType) {
      switch (Number(deviceErrorType)) {
        case 1:
          return 'Coord';
        case 2:
          return 'EAM';
        case 3:
          return 'EAR';
      }
    }

    function initConditionerTypes() {
      switch (vm.currentUnit.type) {
        case UNITS.A_2500:
          vm.types = {
            0: [0, 1, 2, 3, 4],
            1: [0, 1, 2, 3, 4],
            2: [0, 1, 2, 4],
            3: [0, 1, 2],
            4: [0, 1, 2, 3],
            5: [0, 1, 2, 3],
            6: [0, 1, 2, 3],
            7: [0, 1, 2],
            8: [2],
            9: [0, 1],
            10: [0],
            11: [0, 1, 2, 3],
          };
          break;
        case UNITS.A_BIT:
          if (vm.currentUnit.inoptions.cond) {
            vm.types = {
              0: [0, 1, 2, 3, 4],
              1: [0, 1, 2, 3, 4],
              2: [0, 1, 2, 4],
              3: [0, 1, 2],
              4: [0, 1, 2, 3],
              5: [0, 1, 2],
              6: [0, 1, 2, 3],
              7: [0, 1, 2],
              8: [2],
              11: [0, 1, 2, 3],
            };
          } else {
            vm.types = {
              0: [0, 1, 2, 3, 4],
              1: [0, 1, 2, 3, 4],
              2: [0, 1, 2, 4],
              3: [0, 1, 2],
              4: [0, 1, 2, 3],
              5: [0, 1, 2],
            };
          }
          break;
        case UNITS.A_5500:
          vm.types = {
            0: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            2: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            3: [0, 1, 13],
            4: [0, 1, 2, 3],
            5: [0, 1, 3],
            6: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            7: [0, 1, 2],
            8: [0, 1, 2],
            12: [0, 1, 2],
          };
          break;
        case UNITS.A_4500:
          vm.COND = CONDITIONERS_45;
          vm.types = {
            0: [0, 1],
            1: [0, 1, 3, 4, 5, 7, 9, 11, 16, 17, 19],
            2: [0, 1, 3, 4, 5, 7, 9, 11, 16, 17, 18],
            3: [0, 1, 17, 18],
            4: [0, 1, 3, 4],
            5: [0, 1, 3],
            6: [0, 1, 3, 4, 5, 7, 9, 11, 16],
            7: [0, 1, 4],
            8: [0, 1, 4],
            13: [0, 1, 4],
          };

          if (vm.currentUnit.inoptions.optionPlus) {
            for (let [, value] of Object.entries(vm.types)) {
              value.push(2);
            }
          }

          if (vm.currentUnit.version >= +$rootScope.env.A4500_VERSION) {
            vm.types[6].push(20);
          }

          if (vm.currentUnit.inoptions.optionHidro) {
            vm.types[1].push(6, 8, 10, 12, 13, 14, 15);
            vm.types[2].push(6, 8, 10, 12, 13, 14, 15, 19);
            vm.types[6].push(6, 8, 10, 12, 13, 14, 15, 19);
          }

          for (let [, value] of Object.entries(vm.types)) {
            value = value.sort((a, b) => a - b);
          }

          break;
      }
    }

    function updateSensor4500(event) {
      vm.conditioner.sensor = event.detail.value;
      updateSensor();
    }

    function setOneHeader() {
      vm.multipleHeaders = false;
      for (let i = 1; i <= 4; i++) {
        if (eval(`vm.conditioner.header${i}`)) {
          setOtherHeadersToZero(i);
          return;
        }
      }
    }

    function updateSensor() {
      if (vm.currentUnit.type !== UNITS.A_5500 && vm.currentUnit.type !== UNITS.A_4500) {
        switch (Number(vm.conditioner.from)) {
          case 0:
            loadDigitalSensors();
            break;
          case 1:
            vm.sensors = vm.analogs;
            prepareFormat();
            break;
          case 2:
          case 3:
            loadCounters();
            break;
          case 4:
            break;
        }
        formatView();
      } else if (vm.currentUnit.type === UNITS.A_4500) {
        vm.format = null;
        vm.multipleHeaders = true;
        switch (Number(vm.conditioner.from)) {
          case vm.COND.S_DIGITAL:
            loadDigitalSensors();
            break;
          case vm.COND.S_ANALOG:
            vm.sensors = vm.analogs;
            loadAnalogsAndFormats();
            break;
          case vm.COND.S_FLOW_METER:
          case vm.COND.S_ACCOMULATED_METER:
            loadCountersA45();
            prepareFormatView45();
            break;
          case vm.COND.FLOW_ERROR:
            setMarginFormat();
            loadCountersA45();
            prepareFormatView45();
            break;
          case vm.COND.CE_ERROR:
          case vm.COND.PH_ERROR:
          case vm.COND.CE_MIXTURE_ERROR:
          case vm.COND.DRAINAGE_ERROR:
          case vm.COND.CE_DRAINAGE_ERROR:
          case vm.COND.PH_DRAINAGE_ERROR:
          case vm.COND.CE_PROPORTION:
            setMarginFormat();
            prepareFormatView45();
            setOneHeader();
            break;
          case vm.COND.CE_SECURITY:
          case vm.COND.PH_SECURITY:
            setDifferentialFormat();
            prepareFormatView45();
            setOneHeader();
            break;
          case vm.COND.S_LOGIC:
            if (vm.logicSensors == null) {
              var analogsParams = { page: 1, limit: 120 };
              let queries = [];
              queries.push(
                sensorsFactory.analogsPage(vm.currentUnit.id, analogsParams),
                sensorsFactory.analogformatsViewAll(vm.currentUnit.id),
                sensorsFactory.meters(vm.currentUnit.id),
                sensorsFactory.getActiveLogics(vm.currentUnit.id)
              );

              $q.all(queries).then((response) => {
                //analogs sensors
                let filteredAnalogs = _.filter(response[0].content, (sensor) => {
                  return sensor.input > 0;
                });
                vm.analogs = filteredAnalogs;

                allAnalogicFormats = response[1].plain();

                // meter sensors
                vm.meterSensors = _.filter(response[2].plain(), (o) => {
                  return o.input !== 0;
                });

                // logic sensors
                vm.logicSensors = response[3].plain();
                vm.sensors = vm.logicSensors;
                if (vm.conditioner.sensor !== 0) {
                  vm.sensor = vm.sensors.filter((sensor) => sensor.pk.id == vm.conditioner.sensor)[0];
                  setLogicFormat();
                }

                if (vm.currentUnit.type === vm.UNITS.A_4500) {
                  prepareFormatView45();
                }
              });
            } else {
              vm.sensors = vm.logicSensors;
              if (vm.conditioner.sensor !== 0) {
                vm.sensor = vm.sensors.filter((sensor) => sensor.pk.id == vm.conditioner.sensor)[0];
                setLogicFormat();
              }

              if (vm.currentUnit.type === vm.UNITS.A_4500) {
                prepareFormatView45();
              }
            }

            //loadAnalogsAndFormats();
            //loadCountersA45();
            //loadLogicSensors();
            break;
          // case vm.COND.CE_ERROR:
          case vm.COND.CE_100:
            // case vm.COND.CE_SECURITY:
            // case vm.COND.CE_PROPORTION:
            // case vm.COND.CE_MIXTURE_ERROR:
            // case vm.COND.PH_DRAINAGE_ERROR:
            loadFormatCE();
            prepareFormatView45();
            setOneHeader();
            break;
          // case vm.COND.PH_ERROR:
          case vm.COND.PH_100:
            // case vm.COND.PH_SECURITY:
            // case vm.COND.PH_DRAINAGE_ERROR:
            loadFormatPH();
            prepareFormatView45();
            setOneHeader();
            break;
          case vm.COND.FERT_TANK:
          case vm.COND.METER_TANK:
            setOneHeader();
            prepareFormatView45();
            break;
          default:
            if (vm.conditioner.deviceError === 0) vm.conditioner.deviceError = 1; // cant be 0
            prepareFormatView45();
            break;
        }
      } else {
        //Agronic 5500
        switch (Number(vm.conditioner.from)) {
          case 0:
            loadDigitalSensors();
            break;
          case 1:
            vm.sensors = vm.analogs;
            prepareFormat();
            break;
          case 2:
          case 3:
          case 4:
            loadCounters();
            break;
        }
        formatView();
      }
    }

    function loadFormatCE() {
      vm.format = 'mS';
    }

    function loadFormatPH() {
      vm.format = 'pH';
    }

    function setDifferentialFormat() {
      switch (Number(vm.conditioner.from)) {
        case vm.COND.CE_SECURITY:
          vm.differentialFormat = {
            decimals: '1',
            integers: '1',
            min: '0',
            max: '5.0',
            suffix: 'mS',
          };
          break;
        case vm.COND.PH_SECURITY:
          vm.differentialFormat = {
            decimals: '1',
            integers: '1',
            min: '0',
            max: '5.0',
            suffix: 'pH',
          };
          break;
        case vm.COND.S_ANALOG:
          vm.differentialFormat = filterAnalogicFormats(allAnalogicFormats);
          break;
      }
    }

    function setMarginFormat() {
      switch (Number(vm.conditioner.from)) {
        case vm.COND.FLOW_ERROR:
        case vm.COND.CE_PROPORTION:
          vm.marginFormat = {
            decimals: '0',
            integers: '3',
            min: '0',
            max: '100',
            suffix: '%',
          };
          break;
        case vm.COND.DRAINAGE_ERROR:
          vm.marginFormat = {
            decimals: 0,
            integers: 2,
            min: 0,
            max: 50,
            suffix: '%',
          };
          break;
        case vm.COND.CE_ERROR:
        case vm.COND.CE_MIXTURE_ERROR:
          vm.marginFormat = {
            decimals: '1',
            integers: '1',
            min: '0',
            max: '5.0',
            suffix: 'mS',
          };
          break;
        case vm.COND.PH_ERROR:
          vm.marginFormat = {
            decimals: '1',
            integers: '1',
            min: '0',
            max: '5.0',
            suffix: 'pH',
          };
          break;
        case vm.COND.CE_DRAINAGE_ERROR:
          vm.marginFormat = {
            decimals: '1',
            integers: '2',
            min: '0',
            max: '15.0',
            suffix: 'mS',
          };
          break;
        case vm.COND.PH_DRAINAGE_ERROR:
          vm.marginFormat = {
            decimals: '1',
            integers: '2',
            min: '0',
            max: '15.0',
            suffix: 'pH',
          };
          break;
      }
    }

    function setSensorToZero() {
      vm.conditioner.sensor = 0;
    }

    function loadCountersA45() {
      if (vm.meterSensors === null) {
        sensorsFactory.meters(vm.currentUnit.id).then((data) => {
          vm.meterSensors = _.filter(data.plain(), (o) => {
            return o.input !== 0;
          });
          vm.sensors = vm.meterSensors;
          setMetersFormat(vm.conditioner.sensor);
        });
      } else {
        vm.sensors = vm.meterSensors;
        setMetersFormat(vm.conditioner.sensor);
      }
    }

    function setMetersFormat(sensorId) {
      var meterFormatUnits = {
        0: [
          '0000 m3/h',
          '000.0 m3/h',
          '00.00 m3/h',
          '0000 L/h',
          '000.0 L/h',
          '00.00 L/h',
          '0000 L/s',
          '000.0 L/s',
          '00.00 L/s',
          '0000 m3/s',
          '000.0 m3/s',
          '00.00 m3/s',
          '0000 kwh',
          '000.0 kwh',
          '00.00 kwh',
          '0000U',
          '00.00 L/m2',
          '0000 mm',
        ],
        1: ['m3', 'L', 'cl'],
        2: ['0000 U'],
      };

      if (sensorId == 0) {
        vm.format = {
          suffix: '',
          integers: 0,
          decimals: 0,
        };
      }
      setFlowMeterFormat(meterFormatUnits, sensorId);
      setAccumulatedMeterFormat(meterFormatUnits, sensorId);
      vm.differentialFormat = vm.format;
    }

    function setFlowMeterFormat(meterFormatUnits, sensorId) {
      const from = Number(vm.conditioner.from);
      if ((from === vm.COND.S_FLOW_METER || from === vm.COND.S_LOGIC || from === vm.COND.FLOW_ERROR) && sensorId > 0) {
        //Flow
        const sensor = vm.meterSensors.filter((sensor) => sensor.pk.id == sensorId)[0];
        if (sensor !== undefined) {
          const formatId = sensor.flowFormat;
          var splittedFormat = meterFormatUnits[0][formatId].split(' ');
          var integers = getIntegers(splittedFormat[0]);
          var decimals = getDecimals(splittedFormat[0]);
          var suffix = splittedFormat[1];
          var max = 10 ** integers - 1 + (10 ** decimals - 1) / 10 ** decimals;
          vm.format = {
            suffix: suffix,
            integers: integers,
            decimals: decimals,
            max: max,
            min: 0,
          };
        }
      }
    }

    function setAccumulatedMeterFormat(meterFormatUnits, sensorId) {
      const from = Number(vm.conditioner.from);
      if (from === vm.COND.S_ACCOMULATED_METER && sensorId > 0) {
        // accumulated
        var integers = 5;
        var decimals = 0;
        var suffix = 'L';
        vm.format = {
          suffix: suffix,
          integers: integers,
          decimals: decimals,
          min: 0,
          max: 65535,
        };
      }
    }

    function getIntegers(units) {
      var splittedUnits = units.split('.');
      return splittedUnits[0].length;
    }

    function getDecimals(units) {
      var splittedUnits = units.split('.');
      if (splittedUnits.length == 2) {
        return splittedUnits[1].length;
      }
      return 0;
    }

    function setAnalogicFormat() {
      if (allAnalogicFormats === null) {
        sensorsFactory.analogformatsViewAll(vm.currentUnit.id).then(function (data) {
          allAnalogicFormats = data.plain();
          vm.format = filterAnalogicFormats(allAnalogicFormats);
          if (vm.format !== null) {
            vm.format.suffix = getAnalogicFormatSuffix();
          }
          setDifferentialFormat();
        });
      } else {
        vm.format = filterAnalogicFormats(allAnalogicFormats);
        if (vm.format !== null) {
          vm.format.suffix = getAnalogicFormatSuffix();
        }
        setDifferentialFormat();
      }
    }

    function filterAnalogicFormats(allFormats) {
      if (vm.conditioner.sensor > 0 && vm.analogs?.length) {
        var sensor = vm.analogs.filter((sensor) => sensor.pk.id == vm.conditioner.sensor)[0];

        var formatId = sensor.formatId;
        var format = allFormats[formatId - 1];
        format.max = (10 ** (format.integers + format.decimals) - 1) / 10 ** format.decimals;

        format.min = format.sign ? -format.max : 0;
        return format;
      }
      return null;
    }

    function getAnalogicFormatSuffix() {
      if (vm.format !== null && vm.format !== undefined && vm.format.format !== undefined) {
        return vm.format.format.split(' ')[1];
      }
    }

    function setLogicFormat() {
      switch (getLogicSensorType(vm.sensor)) {
        case 6: // analogico
          setLogicSensorFormat(vm.sensor);
          break;
        case 7: // contador
          setLogicSensorFormat(vm.sensor);
          break;
        case 8:
          // Logic Format easter egg
          vm.format = {
            integers: 0,
            decimals: 0,
            min: 0,
            max: 0,
            suffix: 'Relax',
          };
          vm.differentialFormat = vm.format;
          break;
        case 9: // salida analogica
          vm.format = {
            integers: 3,
            decimals: 0,
            min: 0,
            max: 999,
            suffix: '%',
          };
          vm.differentialFormat = vm.format;
          break;
      }
    }

    function setLogicSensorFormat(logicSensor, alreadyVisitedLogics = new Set()) {
      if (alreadyVisitedLogics.has(logicSensor.pk.id)) {
        return 8; // logic sensor has an infinite loop (example: LSensor1 has LSensor2 and LSensor2 has LSensor1)
      }
      alreadyVisitedLogics.add(logicSensor.pk.id);
      if (logicSensor.elements[0].elementType === 6) {
        // if element is not logic, return type
        let sensor = vm.analogs.filter((sensor) => sensor.pk.id == logicSensor.elements[0].elementId)[0];
        let format = allAnalogicFormats[sensor.formatId - 1];
        vm.format = format;
        vm.format.max = (10 ** (vm.format.integers + vm.format.decimals) - 1) / 10 ** vm.format.decimals;
        vm.format.suffix = getAnalogicFormatSuffix();
        vm.differentialFormat = vm.format;
      } else if (logicSensor.elements[0].elementType === 7) {
        setMetersFormat(logicSensor.elements[0].elementId - 1);
        vm.differentialFormat = vm.format;
      } else {
        let nextLogicSensor = vm.logicSensors.filter((sensor) => logicSensor.elements[0].elementId == sensor.pk.id);
        setLogicSensorFormat(nextLogicSensor[0], alreadyVisitedLogics);
      }
    }

    function getLogicSensorType(logicSensor, alreadyVisitedLogics = new Set()) {
      if (alreadyVisitedLogics.has(logicSensor.pk.id)) {
        return 8; // logic sensor has an infinite loop (example: LSensor1 has LSensor2 and LSensor2 has LSensor1)
      }
      alreadyVisitedLogics.add(logicSensor.pk.id);
      if (logicSensor.elements[0].elementType !== 8) {
        // if element is not logic, return type
        return logicSensor.elements[0].elementType;
      } else {
        let nextLogicSensor = vm.logicSensors.filter((sensor) => logicSensor.elements[0].elementId == sensor.pk.id);
        if (nextLogicSensor.length > 0) {
          return getLogicSensorType(nextLogicSensor[0], alreadyVisitedLogics);
        } else {
          return 8;
        }
      }
    }

    function prepareFormat() {
      if (vm.conditioner.sensor !== null) {
        var id = Number(vm.conditioner.sensor) - 1;
        if (id >= 0) {
          var sensor = vm.sensors[id];
          var fid;
          if (sensor && sensor.formatId !== undefined) {
            fid = sensor.formatId - 1;
          }
          if (fid >= 0) {
            //angular.copy(vm.formats[fid], vm.format);
            vm.format = vm.formats[fid];
          } else if (sensor && sensor.format !== undefined && sensor.format !== null) {
            //angular.copy(sensor.format, vm.format);
            vm.format = sensor.format;
          } else {
            vm.format = { decimals: 0, integers: 3, units: '-' };
          }
          formatView();
        }
      }
    }

    function prepareFormatMeter() {
      if (vm.conditioner.sensor !== null) {
        var id = Number(vm.conditioner.sensor) - 1;
        if (id >= 0) {
          var sensor = vm.sensors[id];
          if (sensor !== undefined && sensor.format !== undefined) {
            vm.format = sensor.format;
          } else {
            vm.format = { decimals: 0, integers: 3, units: '-' };
          }
        }
      }
    }

    function loadDigitalSensors() {
      if (vm.digitalSensors === null) {
        sensorsFactory
          .digitalsPage(vm.currentUnit.id, { limit: vm.currentUnit.type === UNITS.A_4500 ? 80 : 50, page: 1 })
          .then(function (data) {
            vm.digitalSensors = _.filter(data.content, (o) => {
              return o.input !== 0;
            });
            vm.sensors = vm.digitalSensors;
            if (vm.currentUnit.type === vm.UNITS.A_4500) {
              prepareFormatView45();
            }
          });
      } else {
        vm.sensors = vm.digitalSensors;
        if (vm.currentUnit.type === vm.UNITS.A_4500) {
          prepareFormatView45();
        }
      }
    }

    function loadCounters() {
      if (vm.meterSensors === null) {
        sensorsFactory.meters(vm.currentUnit.id).then((data) => {
          vm.meterSensors = data.plain().filter((o) => {
            return o.input !== 0;
          });
          prepareCountersA2(vm.meterSensors);
          vm.sensors = vm.meterSensors;
          prepareFormatMeter();
        });
      } else {
        vm.sensors = vm.meterSensors;
        prepareFormatMeter();
      }
    }

    function prepareCountersA2(counters) {
      var formats = {
        0: 'm3/h',
        1: 'L/h',
        2: 'L/s',
      };
      _.forEach(counters, (c) => {
        var decimals = 2;
        c.xFlow = c.xFlow.toString();
        var first = c.xFlow.slice(0, c.xFlow.length - decimals);
        var last = c.xFlow.slice(c.xFlow.length - decimals, c.xFlow.length);
        c.xFlow = first + '.' + last;
        c.value = Number(c.xFlow) + formats[c.flowFormat];
        c.format = { decimals: 2, integers: 4, units: formats[c.flowFormat] };
        switch (c.usedBy) {
          case 0:
            if (Number(c.xFlow) !== 0) {
              c.active = true;
              c.error = true;
            }
            break;
          case 1:
          case 2:
            c.active = true;
            break;
        }
      });
    }

    function loadAnalogsAndFormats() {
      if (vm.analogs === null) {
        var params = { page: 1, limit: 40 };
        vm.sensor = {};

        let queries = [];
        queries.push(
          sensorsFactory.analogsPage(vm.currentUnit.id, params),
          sensorsFactory.analogformatsViewAll(vm.currentUnit.id)
        );
        $q.all(queries).then((response) => {
          let filteredAnalogs = _.filter(response[0].content, (sensor) => {
            return sensor.input > 0;
          });

          vm.sensors = filteredAnalogs;
          vm.analogs = filteredAnalogs;
          allAnalogicFormats = response[1].plain();
          vm.format = filterAnalogicFormats(allAnalogicFormats);
          setDifferentialFormat();

          if (vm.currentUnit.type === vm.UNITS.A_4500) {
            prepareFormatView45();
          }
        });
      } else {
        vm.sensors = vm.analogs;
        setAnalogicFormat();
        if (vm.currentUnit.type === vm.UNITS.A_4500) {
          prepareFormatView45();
        }
      }
    }

    function setFirstFrom() {
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        vm.conditioner.sensor = 0;
      }
      updateSensor();
    }

    function updateFrom() {
      vm.origins = [];
      if (vm.types !== undefined) vm.origins = vm.types[vm.conditioner.function];
      if (sensorsLoaded) {
        updateSensor();
        formatView();
      } else {
        updateForm = true;
      }
    }

    function loadConditioners(n) {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.conditioners = data.plain();

        vm.conditioner = {};
        vm.loading = false;
        vm.page = n + 1;
        vm.conditioner = vm.conditioners[n];

        vm.conditioner_backup = {};
        angular.copy(vm.conditioner, vm.conditioner_backup);
        vm.totals = _.range(1, vm.conditioners.length + 1);
        updateFrom();
        updateSensor();
      });
    }

    function loadAnalogs() {
      vm.analogs = [];
      var params = { page: 1, limit: vm.currentUnit.type === UNITS.A_4500 ? 120 : 40 };
      vm.loading = true;
      vm.sensor = {};
      return sensorsFactory.analogsPage(vm.currentUnit.id, params).then(function (data) {
        var paginator = data;
        vm.analogs = paginator.content;
        angular.copy(paginator.content[0], vm.sensor);
        //vm.totals = _.range(1, vm.currentUnit.conditioners.length + 1);
        vm.page = paginator.number + 1;
        vm.loading = false;
        sensorsLoaded = true;
        if (updateForm) {
          updateFrom();
          /*if (vm.types !== undefined) vm.origins = vm.types[vm.conditioner.function];
              updateSensor();
              formatView();*/
        }

        vm.isDataLoaded = true;
      });
    }

    function changeConditioner($event) {
      if ($event.detail.value) {
        vm.page = $event.detail.value;
        vm.conditioner = vm.conditioners[vm.page - 1];
        getSelectedHeader();

        angular.copy(vm.conditioner, vm.conditioner_backup);
        updateFrom();
        if (vm.currentUnit.type === UNITS.A_4500) {
          updateSensor();
        }
        vm.resetOriginDropdown = true;
      }
    }

    function getSelectedHeader() {
      vm.selectedHeader = null;

      vm.selectedHeader = [
        vm.conditioner.header1,
        vm.conditioner.header2,
        vm.conditioner.header3,
        vm.conditioner.header4,
      ].findIndex((header) => header === true);
    }

    function changingConditioner($event) {
      vm.resetOriginDropdown = false;
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          angular.copy(vm.conditioner_backup, vm.conditioner);
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeConditioner($event);
          break;
      }
    }

    function save() {
      vm.loading = true;
      vm.form.$setPristine();
      vm.conditioner.unittype = vm.currentUnit.type;
      conditionerFactory.update(vm.currentUnit.id, [vm.conditioner]).then((data) => {
        if (data) {
          vm.conditioners[vm.page - 1] = data.plain()[0];
        }
        vm.loading = false;
        $scope.$emit('conditioners-form-saved');
      });
    }

    function formatView() {
      vm.hide = [];
      if (vm.currentUnit.type !== UNITS.A_5500 && vm.currentUnit.type !== UNITS.A_4500) {
        switch (vm.conditioner.from) {
          case 0: // DIGITAL
            formatDigital(vm.conditioner.function);
            break;
          case 1: // ANALOG
            formatAnalog(vm.conditioner.function);
            break;
          case 2: // Meter
            formatMeter(vm.conditioner.function);
            //prepareFormatMeter();
            break;
          case 3:
            formatErrorMeter(vm.conditioner.function);
            break;
          case 4:
            formatComm(vm.conditioner.function);
            break;
        }
      } else if (vm.currentUnit.type === UNITS.A_4500) {
        return;
      } else {
        prepareFormatView55();
      }
    }

    function formatDigital(func) {
      switch (Number(func)) {
        case 0:
        case 1:
        case 2:
        case 9:
        case 10:
          vm.hide[0] = true;
          vm.hide[2] = true;
          break;
        case 3:
        case 4:
        case 5:
        case 11:
          vm.hide[1] = true;
          vm.hide[2] = true;
          break;
        case 6:
        case 7:
          vm.hide[3] = true;
          break;
      }

      if (vm.currentUnit.type === UNITS.A_BIT) {
        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 11:
            vm.showErrorStatusDropdown = true;
            break;
          default:
            vm.showErrorStatusDropdown = false;
            break;
        }

        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 8:
          case 11:
            vm.showAllProgramsCheckbox = true;
            break;
          default:
            vm.showAllProgramsCheckbox = false;
            break;
        }
      }
    }

    function formatAnalog(func) {
      switch (Number(func)) {
        case 0:
        case 1:
        case 3:
        case 9:
        case 11:
          vm.hide[0] = true;
          vm.hide[1] = true;
          vm.hide[2] = true;
          vm.hide[4] = true;
          vm.hide[5] = true;
          break;
        case 2:
        case 4:
        case 5:
          vm.hide[0] = true;
          vm.hide[1] = true;
          vm.hide[2] = true;
          vm.hide[4] = true;
          vm.hide[5] = true;
          vm.hide[6] = true;
          break;
        case 6:
        case 7:
          vm.hide[7] = true;
          vm.hide[8] = true;
          vm.hide[9] = true;
          vm.hide[10] = true;
          vm.hide[11] = true;
          break;
      }

      if (vm.currentUnit.type === UNITS.A_BIT) {
        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 11:
            vm.showErrorStatusDropdown = true;
            break;
          default:
            vm.showErrorStatusDropdown = false;
            break;
        }

        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 11:
            vm.showAllProgramsCheckbox = true;
            break;
          default:
            vm.showAllProgramsCheckbox = false;
            break;
        }
      }
    }

    function formatMeter(func) {
      switch (Number(func)) {
        case 0:
        case 1:
        case 3:
        case 11:
          vm.hide[0] = true;
          vm.hide[1] = true;
          vm.hide[2] = true;
          vm.hide[4] = true;
          vm.hide[5] = true;
          vm.hide[12] = true;
          break;
        case 2:
        case 4:
        case 5:
          vm.hide[0] = true;
          vm.hide[1] = true;
          vm.hide[2] = true;
          vm.hide[4] = true;
          vm.hide[5] = true;
          vm.hide[6] = true;
          vm.hide[12] = true;
          break;
        case 6:
        case 7:
          vm.hide[7] = true;
          vm.hide[8] = true;
          vm.hide[9] = true;
          vm.hide[10] = true;
          vm.hide[11] = true;
          break;
        case 8:
          vm.hide[1] = true;
          vm.hide[4] = false;
          vm.hide[11] = true;
          vm.hide[13] = true;
          break;
      }

      if (vm.currentUnit.type === UNITS.A_BIT) {
        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 11:
            vm.showErrorStatusDropdown = true;
            break;
          default:
            vm.showErrorStatusDropdown = false;
            break;
        }

        switch (+func) {
          case 0:
          case 1:
          case 2:
          case 8:
          case 11:
            vm.showAllProgramsCheckbox = true;
            break;
          default:
            vm.showAllProgramsCheckbox = false;
            break;
        }
      }
    }

    function formatErrorMeter(func) {
      switch (Number(func)) {
        case 0:
        case 1:
        case 5:
          vm.hide[0] = true;
          vm.hide[1] = true;
          vm.hide[16] = true;
          vm.hide[2] = true;
          vm.hide[17] = true;
          vm.hide[18] = true;
          vm.hide[19] = true;
          vm.hide[20] = true;
          vm.hide[21] = true;
          break;
        case 11:
          vm.hide[21] = true;
          vm.hide[0] = true;
          vm.hide[1] = true;
          break;
      }
      if (vm.currentUnit.type === UNITS.A_BIT) {
        switch (+func) {
          case 0:
          case 1:
          case 5:
          case 11:
            vm.showErrorStatusDropdown = true;
            break;
          default:
            vm.showErrorStatusDropdown = false;
            break;
        }

        switch (+func) {
          case 0:
          case 1:
          case 11:
            vm.showAllProgramsCheckbox = true;
            break;
          default:
            vm.showAllProgramsCheckbox = false;
            break;
        }
      }
    }

    function formatComm(func) {
      vm.hide[22] = true;
      vm.hide[23] = true;

      if (vm.currentUnit.type === UNITS.A_BIT) {
        vm.showErrorStatusDropdown = false;

        switch (+func) {
          case 0:
          case 1:
          case 2:
            vm.showAllProgramsCheckbox = true;
            break;
          default:
            vm.showAllProgramsCheckbox = false;
            break;
        }
      }
    }

    function cancel_edition() {
      $state.go('sensors.detail', { unit: vm.currentUnit });
    }

    function prepareFormatView45() {
      vm.show = {};
      switch (Number(vm.conditioner.function)) {
        case 1: // Paro definitivo
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = vm.show[5] = vm.show[28] = true;
              break;

            case vm.COND.S_ANALOG: //Sensor analogico
              vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[28] = vm.show[32] = vm.show[34] = true;
              break;

            case vm.COND.S_FLOW_METER: // Sensor contador caudal
              vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[28] = vm.show[33] = vm.show[34] = true;

              break;

            case vm.COND.FLOW_ERROR: // Error caudal
              vm.show[0] =
                vm.show[2] =
                vm.show[3] =
                vm.show[5] =
                vm.show[6] =
                vm.show[8] =
                vm.show[9] =
                vm.show[10] =
                vm.show[28] =
                  true;
              break;

            case vm.COND.S_LOGIC: // Sensor logico
              if (vm.conditioner.sensor === 0) {
                vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[28] = vm.show[33] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(chosenSensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[28] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = vm.show[5] = vm.show[28] = true;
                    break;
                }
              }
              break;

            case vm.COND.S_ACCOMULATED_METER: // Sensor contador acumulado
              vm.show[2] = vm.show[5] = vm.show[7] = vm.show[28] = vm.show[33] = true;
              break;

            case vm.COND.CE_ERROR:
            case vm.COND.PH_ERROR:
            case vm.COND.CE_MIXTURE_ERROR:
            case vm.COND.CE_DRAINAGE_ERROR:
            case vm.COND.PH_DRAINAGE_ERROR: // Error CE / Error pH / Error CE mezcla / Error Drenaje / Error CE Drenaje / Error pH Drenaje
              vm.show[0] = vm.show[5] = vm.show[11] = vm.show[12] = vm.show[28] = true;
              break;
            case vm.COND.DRAINAGE_ERROR:
              vm.show[0] = vm.show[3] = vm.show[5] = vm.show[9] = vm.show[10] = vm.show[28] = true;
              break;
            case vm.COND.CE_100:
            case vm.COND.PH_100: // CE al 100% / pH al 100%
              vm.show[0] = vm.show[5] = vm.show[28] = true;
              break;
            case vm.COND.CE_SECURITY:
            case vm.COND.PH_SECURITY: // CE seguridad / pH seguridad
              vm.show[0] = vm.show[1] = vm.show[5] = vm.show[28] = true;
              break;
            case vm.COND.CE_PROPORTION: // Proporcion CE
              vm.show[0] = vm.show[4] = vm.show[5] = vm.show[28] = true;
              break;
            // case vm.COND.FLOW_ERROR: // Error caudal
            //   vm.show[0] = vm.show[4] = vm.show[5] = vm.show[28] = true;
            //   break;
            case vm.COND.COMMUNICATION: // Comunicaion
              vm.show[5] = vm.show[28] = vm.show[30] = vm.show[31] = true;
              break;
            case 18: // Horario vm.show[5] = vm.show[21] = vm.show[22] = vm.show[23] = vm.show[24] = vm.show[25] = vm.show[26] = vm.show[27] = vm.show[28] = vm.show[35] = vm.show[36] = true;
              break;
            case vm.COND.FERT_TANK: // Deposito F.
              vm.show[0] =
                vm.show[5] =
                vm.show[13] =
                vm.show[14] =
                vm.show[15] =
                vm.show[16] =
                vm.show[17] =
                vm.show[18] =
                vm.show[19] =
                vm.show[20] =
                vm.show[28] =
                vm.show[29] =
                  true;
              break;
          }
          break;
        case 2: // Paro temporal
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = vm.show[5] = true;
              break;

            case vm.COND.S_ANALOG: //Sensor analogico
              vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[32] = vm.show[34] = true;
              break;

            case vm.COND.S_FLOW_METER: // Sensor contador caudal
              vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[33] = vm.show[34] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[33] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(chosenSensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[0] = vm.show[2] = vm.show[3] = vm.show[5] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = vm.show[5] = true;
                    break;
                }
              }
              break;

            case vm.COND.S_ACCOMULATED_METER: // Sensor contador acumulado
              vm.show[2] = vm.show[5] = vm.show[7] = vm.show[33] = true;
              break;

            case vm.COND.FLOW_ERROR: // Error caudal
              vm.show[0] =
                vm.show[2] =
                vm.show[3] =
                vm.show[5] =
                vm.show[6] =
                vm.show[8] =
                vm.show[9] =
                vm.show[10] =
                  true;
              break;

            case vm.COND.CE_ERROR:
            case vm.COND.PH_ERROR: // Error CE / Error pH
              vm.show[0] = vm.show[5] = vm.show[11] = vm.show[12] = true;
              break;

            case vm.COND.CE_100:
            case vm.COND.PH_100: // CE al 100% / pH al 100%
              vm.show[0] = vm.show[5] = true;
              break;

            case vm.COND.CE_SECURITY:
            case vm.COND.PH_SECURITY: // CE seguridad // pH seguridad
              vm.show[0] = vm.show[1] = vm.show[5] = true;
              break;

            case vm.COND.CE_PROPORTION: // Proporcion CE
              vm.show[0] = vm.show[4] = vm.show[5] = true;
              break;

            case vm.COND.CE_MIXTURE_ERROR:
            case vm.COND.CE_DRAINAGE_ERROR:
            case vm.COND.PH_DRAINAGE_ERROR: // Error CE mezcla / Eror CE Drenaje / Error pH Drenaje
              vm.show[0] = vm.show[5] = vm.show[11] = vm.show[12] = true;
              break;

            case vm.COND.DRAINAGE_ERROR: // Error Drenaje
              vm.show[0] = vm.show[5] = vm.show[9] = vm.show[10] = true;
              break;

            case vm.COND.COMMUNICATION: // Comunicacion
              vm.show[5] = vm.show[30] = vm.show[31] = true;
              break;

            case vm.COND.SCHEDULE: // Horario
              vm.show[5] =
                vm.show[21] =
                vm.show[22] =
                vm.show[23] =
                vm.show[24] =
                vm.show[25] =
                vm.show[26] =
                vm.show[27] =
                vm.show[35] =
                vm.show[36] =
                  true;
              break;

            case vm.COND.FERT_TANK: // Deposito F.
              vm.show[0] =
                vm.show[4] =
                vm.show[5] =
                vm.show[13] =
                vm.show[14] =
                vm.show[15] =
                vm.show[16] =
                vm.show[17] =
                vm.show[18] =
                vm.show[19] =
                vm.show[20] =
                  true;
              break;
          }
          break;
        case 3: // Paro condicional
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = true;
              break;

            case vm.COND.S_ANALOG: //Sensor analogico
              vm.show[0] = vm.show[1] = vm.show[2] = vm.show[32] = vm.show[34] = true;
              break;

            case vm.COND.COMMUNICATION: // Comunicacion
              vm.show[30] = vm.show[31] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[0] = vm.show[1] = vm.show[2] = vm.show[32] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[0] = vm.show[1] = vm.show[2] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = true;
                    break;
                }
              }
              break;

            case vm.COND.SCHEDULE: // Horario
              vm.show[21] =
                vm.show[22] =
                vm.show[23] =
                vm.show[24] =
                vm.show[25] =
                vm.show[26] =
                vm.show[27] =
                vm.show[35] =
                vm.show[36] =
                  true;
              break;
          }
          break;
        case 4: // Inicio
          vm.conditioner.allPrograms = false;
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = true;
              break;

            case vm.COND.S_ANALOG:
            case vm.COND.S_FLOW_METER: // Sensor contador caudal
              vm.show[2] = vm.show[0] = vm.show[37] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[2] = vm.show[0] = vm.show[37] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[2] = vm.show[0] = vm.show[37] = vm.show[1] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = true;
                    break;
                }
              }
              break;

            case vm.COND.S_ACCOMULATED_METER: // Sensor contador acumulado
              vm.show[2] = vm.show[33] = vm.show[7] = true;
              break;
          }
          break;
        case 5: // Inicio/Paro
          vm.conditioner.allPrograms = false;
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = true;
              break;

            case vm.COND.S_ANALOG:
            case vm.COND.S_ACCOMULATED_METER: //Sensor analogico / Sensor contador caudal
              vm.show[2] = vm.show[0] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              break;

            case vm.COND.S_FLOW_METER: // Sensor contador caudal
              vm.show[2] = vm.show[0] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[2] = vm.show[0] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[2] = vm.show[0] = vm.show[32] = vm.show[1] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = true;
                    break;
                }
              }
              break;
          }
          break;
        case 6: // Aviso
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[0] = vm.show[2] = true;
              break;

            case vm.COND.S_ANALOG: //Sensor analogico
              vm.show[2] = vm.show[0] = vm.show[7] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              break;

            case vm.COND.S_FLOW_METER: // Sensor contador caudal
              vm.show[2] = vm.show[0] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[2] = vm.show[0] = vm.show[7] = vm.show[32] = vm.show[1] = vm.show[34] = true;
              } else {
                const chosenSensor = vm.sensors.find(
                  (sensor) => Number(sensor.pk.id) === Number(vm.conditioner.sensor)
                );
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[2] = vm.show[0] = vm.show[7] = vm.show[32] = vm.show[1] = vm.show[34] = true;
                    switch (getLogicSensorType(chosenSensor)) {
                      case 6: // analogic type
                        vm.show[32] = true;
                        break;
                      case 7: // meter type
                        vm.show[33] = true;
                        break;
                      case 8:
                        vm.show[33] = true;
                        break;
                      case 9: // salida analogica type
                        vm.show[42] = true;
                        break;
                    }
                    break;
                  case 4:
                  case 5:
                    vm.show[0] = vm.show[2] = true;
                    break;
                }
              }
              break;

            case vm.COND.S_ACCOMULATED_METER: // Sensor contador acumulado
              vm.show[2] = vm.show[0] = vm.show[33] = vm.show[7] = true;
              break;

            case vm.COND.FLOW_ERROR: // Error caudal
              vm.show[2] =
                vm.show[0] =
                vm.show[3] =
                vm.show[39] =
                vm.show[38] =
                vm.show[8] =
                vm.show[9] =
                vm.show[10] =
                vm.show[6] =
                  true;
              break;

            case vm.COND.CE_ERROR:
            case vm.COND.PH_ERROR: // Error CE / Error pH
              vm.show[0] = vm.show[11] = vm.show[12] = true;
              break;

            case vm.COND.CE_100:
            case vm.COND.PH_100:
            case vm.COND.CE_MIXTURE_ERROR: // CE al 100% / pH al 100% / Error CE mezcla
              vm.show[0] = true;
              break;

            case vm.COND.CE_SECURITY:
            case vm.COND.PH_SECURITY: // CE seguridad / pH seguridad
              vm.show[0] = vm.show[1] = true;
              break;

            case vm.COND.CE_PROPORTION: // Proporcion. CE
              vm.show[0] = vm.show[4] = true;
              break;

            case vm.COND.DRAINAGE_ERROR: // Error drenaje
              vm.show[0] = vm.show[11] = vm.show[12] = true;
              break;

            case vm.COND.CE_DRAINAGE_ERROR:
            case vm.COND.PH_DRAINAGE_ERROR: // Error CE Drenaje / Error pH Drenaje
              vm.show[0] = vm.show[11] = vm.show[12] = true;
              break;

            case vm.COND.FERT_TANK: // Deposito F.
              vm.show[0] =
                vm.show[13] =
                vm.show[14] =
                vm.show[15] =
                vm.show[16] =
                vm.show[17] =
                vm.show[18] =
                vm.show[19] =
                vm.show[20] =
                vm.show[29] =
                  true;
              break;

            case vm.COND.METER_TANK:
              vm.show[0] =
                //vm.show[13] =
                vm.show[43] =
                vm.show[44] =
                  true;
              break;
          }
          break;
        case 7: // Modifica riego
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[2] = vm.show[40] = true;
              break;

            case vm.COND.S_ANALOG:
            case vm.COND.S_FLOW_METER: //Sensor analogico / Sensor contador caudal
              vm.show[2] = vm.show[7] = vm.show[41] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[2] = vm.show[7] = vm.show[41] = true;
              } else {
                // const chosenSensor = vm.sensors.find(sensor => Number(sensor.pk.id) === Number(vm.conditioner.sensor));
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[2] = vm.show[7] = vm.show[41] = true;
                    break;
                  case 4:
                  case 5:
                    vm.show[2] = vm.show[40] = true;
                    break;
                }
              }
              break;
            case vm.COND.S_ACCOMULATED_METER:
              vm.show[2] = vm.show[7] = vm.show[41] = true;

              break;
          }
          break;

        case 8:
        case 13: // Modifica fertilizante / Modifica frecuencia
          switch (vm.conditioner.from) {
            case vm.COND.S_DIGITAL: // Sensor digital
              vm.show[2] = vm.show[40] = true;
              break;

            case vm.COND.S_ANALOG:
            case vm.COND.S_FLOW_METER: //Sensor analogico / Sensor contador caudal
              vm.show[2] = vm.show[7] = vm.show[41] = true;
              break;

            case vm.COND.S_LOGIC:
              if (vm.conditioner.sensor === 0) {
                vm.show[2] = vm.show[7] = vm.show[41] = true;
              } else {
                // const chosenSensor = vm.sensors.find(sensor => Number(sensor.pk.id) === Number(vm.conditioner.sensor));
                switch (Number(vm.sensor.operation)) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    vm.show[2] = vm.show[7] = vm.show[41] = true;
                    break;
                  case 4:
                  case 5:
                    vm.show[2] = vm.show[40] = true;
                    break;
                }
              }
              break;
            case vm.COND.S_ACCOMULATED_METER:
              vm.show[2] = vm.show[7] = vm.show[41] = true;

              break;
          }
          break;
      }
    }

    function prepareFormatView55() {
      switch (vm.conditioner.from) {
        case 0: //DIGITAL
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2: //Paro temp
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = true;
              break;
            case 3: //Condicional
              vm.hide[0] = vm.hide[1] = vm.hide[2] = true;
              break;
            case 4:
            case 5:
            case 6:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = true;
              break;
            case 7:
            case 8:
            case 12: //Modifica riego //Modifica fert
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[3] = true;
              break;
            case 10:
            case 9:
            case 11:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = true;
              break;
          }
          break;
        case 1: //ANALOG
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2: //Paro temp
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[12] = vm.hide[21] = true;
              break;
            case 3: //Paro cond
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[6] = true;
              break;
            case 4: //Inicio
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[28] = true;
              break;
            case 5: //Inicio/Paro
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[6] = true;
              break;
            case 6: //Aviso
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[6] = vm.hide[11] = true;
              break;
            case 7:
            case 8:
            case 12: //Modifica riego//Modifica frec
              vm.hide[0] = vm.hide[1] = vm.hide[7] = vm.hide[8] = vm.hide[9] = vm.hide[10] = vm.hide[11] = true;
              break;
            case 10:
            case 9:
            case 11:
              vm.hide[0] =
                vm.hide[1] =
                vm.hide[2] =
                vm.hide[4] =
                vm.hide[5] =
                vm.hide[12] =
                vm.hide[21] =
                vm.hide[22] =
                  true;
              break;
          }
          break;
        case 2: //Contador acumulado
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 3:
            case 5:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[11] = vm.hide[13] = vm.hide[21] = vm.hide[22] = true;
              break;
            case 2: //paro temp
              vm.hide[0] = vm.hide[1] = vm.hide[11] = vm.hide[13] = vm.hide[21] = true;
              break;
            case 4:
            case 6: //Inicio //Aviso
              vm.hide[0] = vm.hide[1] = vm.hide[11] = vm.hide[13] = true;
              break;
            case 7:
            case 8:
              vm.hide[0] = vm.hide[1] = vm.hide[7] = vm.hide[8] = vm.hide[9] = vm.hide[10] = vm.hide[11] = true;
              vm.format = { decimals: 0, integers: 3, units: 'L/m2' };
              break;
          }
          break;
        case 3: //Contador caudal
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 3:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] =
                vm.hide[1] =
                vm.hide[2] =
                vm.hide[5] =
                vm.hide[12] =
                vm.hide[23] =
                vm.hide[21] =
                vm.hide[22] =
                  true;
              break;
            case 2: //Paro temp
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[5] = vm.hide[12] = vm.hide[23] = vm.hide[21] = true;
              break;
            case 4: //Inicio
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[5] = vm.hide[23] = true;
              break;
            case 5: //Inicio/Paro
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[5] = vm.hide[6] = vm.hide[12] = vm.hide[23] = true;
              break;
            case 6: //Aviso
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[4] = vm.hide[5] = vm.hide[6] = true;
              break;
          }
          break;
        case 4: //Error caudal
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2: //Paro temp
              vm.hide[0] =
                vm.hide[1] =
                vm.hide[2] =
                vm.hide[5] =
                vm.hide[12] =
                vm.hide[16] =
                vm.hide[17] =
                vm.hide[18] =
                vm.hide[21] =
                  true;
              break;
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] =
                vm.hide[1] =
                vm.hide[2] =
                vm.hide[5] =
                vm.hide[12] =
                vm.hide[16] =
                vm.hide[17] =
                vm.hide[18] =
                vm.hide[21] =
                vm.hide[22] =
                  true;
              break;
            case 6: //Aviso
              vm.hide[0] =
                vm.hide[1] =
                vm.hide[2] =
                vm.hide[12] =
                vm.hide[14] =
                vm.hide[15] =
                vm.hide[16] =
                vm.hide[17] =
                vm.hide[18] =
                  true;
              break;
          }
          break;
        case 5: //Error CE
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[24] = vm.hide[25] = true;
              break;
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = vm.hide[24] = vm.hide[25] = true;
              break;
            case 6: //Aviso
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[24] = vm.hide[25] = true;
              break;
          }
          break;
        case 6: //Error PH
        case 12: //Error CE mezcla
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[24] = vm.hide[25] = true;
              break;
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = vm.hide[24] = vm.hide[25] = true;
              break;
            case 6:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[24] = vm.hide[25] = true;
              break;
          }
          break;
        case 7: //CE al 100
        case 8: //PH al 100
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 2:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = true;
              break;
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = true;
              break;
            case 6:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = true;
              break;
          }
          break;
        case 9: //CE de seguridad
        case 10: //pH de seguridad
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = vm.hide[26] = true;
              break;
            case 2:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[26] = true;
              break;
            case 6:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[26] = true;
              break;
          }
          break;
        case 11: //proproción CE
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[22] = vm.hide[27] = true;
              break;
            case 2:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[21] = vm.hide[27] = true;
              break;
            case 6:
              vm.hide[0] = vm.hide[1] = vm.hide[2] = vm.hide[27] = true;
              break;
          }
          break;
        case 13: //Comunicación
          switch (Number(vm.conditioner.function)) {
            case 0: // No config;
              break;
            case 1: // Paro def
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              vm.hide[0] = vm.hide[1] = vm.hide[21] = vm.hide[22] = vm.hide[19] = vm.hide[20] = true;
              break;
            case 2:
            case 3:
              vm.hide[0] = vm.hide[1] = vm.hide[21] = vm.hide[19] = vm.hide[20] = true;
              break;
          }
          break;
      }
    }

    function setHeader(header) {
      vm.conditioner.header1 = false;
      vm.conditioner.header2 = false;
      vm.conditioner.header3 = false;
      vm.conditioner.header4 = false;

      vm.conditioner[header] = true;
    }

    function resetOrigin() {
      vm.conditioner.from = 0;
      updateSensor();
    }

    $scope.$on('refresh', function () {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('conditionerFormSave', () => {
      save();
    });
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
